<div cdkDropList (cdkDropListDropped)="droppedIntoList($event)" [class.item-dragging]="dragging" class="dual-list" *ngIf="items.length > 0">
	<div (cdkDragDropped)="dropped($event)" (cdkDragEnded)="dragEnded()" (cdkDragStarted)="dragStarted($event, index)" (click)="select($event, index)"
			 *ngFor="let item of items; let index = index" [class.selected]="isSelected(index)" cdkDrag
			 class="item">
		<div *ngIf="!dragging || !isSelected(index)">
			<ng-container *ngTemplateOutlet="templateRef; context: { $implicit: item, item: item, index: index }">
			</ng-container>
		</div>

		<div *cdkDragPreview>
			<div *ngFor="let sel of selections" class="cdk-drag-preview">
				<ng-container
					*ngTemplateOutlet="templateRef; context: { $implicit: items[sel], item: sel, index: index }">
				</ng-container>
			</div>
		</div>
	</div>

</div>
<div cdkDropList (cdkDropListDropped)="droppedIntoList($event)" *ngIf="items.length == 0" class="ghost-item">
	<div>
		<ng-container>
			Empty list.
		</ng-container>
	</div>
</div>


