export class ResultLaboQpcr {

	constructor(
		public analysis_id: number,
		public vmr_sg1_15: string,
		public sg1_15: number,
		public vmr_sg1: string,
		public sg1: number,
		public comment: string
	) {
	}
}
