<h2 mat-dialog-title>Column legends</h2>
<mat-dialog-content class="mat-typography" fxLayoutAlign="center">

	<mat-card style="margin-bottom: 20px">
		<div class="form-group">
			<label for="search-text">Search column</label>
			<input [(ngModel)]="searchText" aria-describedby="search-text" autofocus class="form-control" id="search-text"
						 placeholder="Enter a column name" type="text" (ngModelChange)="closeListElements()">
		</div>
		<mat-nav-list class="list-container">
			<div *ngFor="let item of columnsDefinitions | appFilter: searchText; let i = index;">
				<mat-list-item (click)="toggle[i] = !toggle[i]">
					<a matLine appHighlight [searchedWord]="searchText" [content]="item.var_tag"
						 [classToApply]="'highlighted'" [setTitle]="true">{{ item.var_tag }}</a>
					<span>
						<mat-icon *ngIf="!toggle[i]">expand_more</mat-icon>
						<mat-icon *ngIf="toggle[i]">expand_less</mat-icon>
					</span>
				</mat-list-item>

				<div [hidden]="!toggle[i]" class="description-container">
					<p style="word-wrap: anywhere; max-width: 300px">{{ item.description }}</p>
				</div>
			</div>
		</mat-nav-list>
	</mat-card>

</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end">
	<button [mat-dialog-close]="true" color="primary" mat-raised-button>Ok</button>
</mat-dialog-actions>


