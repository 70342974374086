import {FullscreenChartService} from 'src/app/fullscreen-chart/fullscreen-chart.service';
import {SPChartFactory} from '../SPChartFactory';
import {SPChart} from '../../SamplingPointProduct/SPChart';
import {SPTtmVariationChart} from '../../SamplingPointProduct/ConcreteSamplingPointProduct/sp-ttm-variation-chart';

/**
 * The Creator class declares the factory method that is supposed to return an
 * object of a Custom Chart Data class. The Creator's subclasses usually provide the
 * implementation of this method.
 */
export class SPTTmVariationChartFactory extends SPChartFactory {
	/**
	 * Note that the signature of the method still uses the abstract product
	 * type, even though the concrete product is actually returned from the
	 * method. This way the Creator can stay independent of concrete product
	 * classes.
	 */
	constructor(private _service: FullscreenChartService) {
		super();
	}

	public factoryMethod(): SPChart {
		return new SPTtmVariationChart(this._service);
	}
}
