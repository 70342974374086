<body>
<mat-tab-group mat-align-tabs="start" (selectedTabChange)="onTabClick($event)">

	<mat-tab label="Users">
		<app-user-settings *ngIf="currentTab==0"></app-user-settings>
	</mat-tab>

	<mat-tab label="Roles">
		<app-permission-settings *ngIf="currentTab==1"></app-permission-settings>
	</mat-tab>

	<mat-tab label="Machines" *ngIf="checkForPermission()">
		<app-machine-settings *ngIf="currentTab==2"></app-machine-settings>
	</mat-tab>

	<mat-tab label="Sampling points" *ngIf="checkForPermission()">
		<app-sampling-point-settings *ngIf="currentTab==3"></app-sampling-point-settings>
	</mat-tab>

	<mat-tab label="Control limits" *ngIf="checkForPermission()">
		<machine-limits *ngIf="currentTab==4"></machine-limits>
	</mat-tab>

</mat-tab-group>
</body>
