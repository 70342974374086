import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Machine} from '../Models/Machine';
import {CookieServiceService} from '../cookie-service.service';
import {environment} from '../../environments/environment';
import {Analysis} from '../Models/Analysis';

import _moment from 'moment';
const moment = _moment;

@Injectable({
	providedIn: 'root'
})
export class DashboardMachineService {

	header;
	machineUrl = environment.machineUrl;
	analysisUrl = environment.analysisUrl;
	clientUrl = environment.clientUrl;
	baseUrl = environment.notificationUrl;

	constructor(private http: HttpClient, private cookieService: CookieServiceService) {
		this.header = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + this.cookieService.get('token'),
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'DELETE, GET, POST, OPTIONS',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		});
	}

	getActiveMachines(): Observable<Machine[]> {
		return this.http.get<Machine[]>(this.machineUrl + '/machines?state=active,standby', {headers: this.header});
	}

	getInactiveMachines(): Observable<Machine[]> {
		return this.http.get<Machine[]>(this.machineUrl + '/machines?state=inactive', {headers: this.header});
	}

	getMachine<Machine>(tag: string): Observable<Machine> {
		return this.http.get<Machine>(this.machineUrl + '/machines/tag/' + tag, {headers: this.header});
	}

	getMachineAnalysisIdHistory(machine_id: number, limit: number, offset: number) {
		return this.http.get<Analysis[]>(this.analysisUrl + '/analyses?machineId=' + machine_id + '&limit=' + limit + '&offset=' + offset, {headers: this.header}).toPromise();
	}

	getMachineAnalysisFullDetail(analysis_id: number) {
		return this.http.get<any>(this.analysisUrl + '/analyses/' + analysis_id + '/detailed', {headers: this.header}).toPromise();
	}

	getAnalysisMissingVariables(analysis_id: number) {
		return this.http.get<any>(this.analysisUrl + '/analyses/variables/' + analysis_id + '/MissingVariables', {headers: this.header}).toPromise();
	}

	async getLatestVariable(machine_id: number, var_tag: string) {
		return await this.http.get<number>(this.analysisUrl + '/analyses/variables/' + var_tag + '/machine/' + machine_id + '/latest', {headers: this.header}).toPromise();
	}

	async getLatestVariableWithClLevel(machine_id: number, var_tag: string) {
		return await this.http.get<any>(this.analysisUrl + '/analyses/variables/' + machine_id + '/' + var_tag + '/controlLevel', {headers: this.header}).toPromise();
	}

	async getAverageVariableForNumberOfAnalysis(machine_id: number, var_id: number, nb_analysis: number) {
		const average = await this.http.get<any>(this.analysisUrl + '/analyses/variables/variableAverageLast?machine_id=' + machine_id + '&var_id=' + var_id + '&nb_analysis=' + nb_analysis, {headers: this.header}).toPromise();
		return {value: average.average}
	}

	async getArrayOfLatestVariableWithClLevel(machine_id: number, var_tags: string[]) {
		return await this.http.get<any>(this.analysisUrl + '/analyses/variables/machine/' + machine_id + '/controlLevels?var_tags=' + var_tags, {headers: this.header}).toPromise();
	}

	async getLatestVariableBySamplingPoint(machine_id: number, var_tag: string, sp_id: number) {
		return await this.http.get<any>(this.analysisUrl + '/analyses/variables/' + var_tag + '/machine/' + machine_id + '/samplingPoints/' + sp_id + '/controlLevel', {headers: this.header}).toPromise();
	}

	async getSamplingPointsByMachineId(machine_id: number) {
		return await this.http.get<any>(this.clientUrl + '/clients/samplingPoints/machine/' + machine_id, {headers: this.header}).toPromise();
	}

	async getErrorCodeDefinition(error_code: number) {
		return await this.http.get<any>(this.machineUrl + '/machines/error/' + error_code, {headers: this.header}).toPromise();
	}

	async getMachinesStatus(machine_id: number) {
		const lastStatusTelemetry = await this.http.get<any>(this.machineUrl + '/machines/' + machine_id + '/status', {headers: this.header}).toPromise();
		return lastStatusTelemetry['status']
	}

	async getLastAnalysisDate(machine_id: number) {
		return await this.http.get<any>(this.analysisUrl + '/analyses/lastAnalysis?machine=' + machine_id, {headers: this.header}).toPromise();
	}
	async getNbOfAnalysisSince(machine_id: number, last_maintenance: string) {
		return await this.http.get<any>(this.analysisUrl + '/analyses/NbOfAnalysisSince?machine=' + machine_id + '&last_maintenance=' + last_maintenance, {headers: this.header}).toPromise();
	}

	async getNextMaintenanceDate(machineId, lastMaintenanceDate, nbAnalysisBeforeMaintenance) {
		const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
		let nextMaintenanceDate = null;
		let daysNextMaintenance = null;
		let nbAnalSinceMaint = null;
		let nbDaysSinceMaintenance = null;
		if (lastMaintenanceDate != null){
			nbDaysSinceMaintenance = Math.floor((new Date().getTime() - new Date(lastMaintenanceDate).getTime()) / oneDay);
			if (nbDaysSinceMaintenance > 365) {
				nextMaintenanceDate = 'Overdue';
			} else {
				const nbAnalysisSinceMaintenance = await this.getNbOfAnalysisSince(machineId, lastMaintenanceDate);
				nbAnalSinceMaint = nbAnalysisSinceMaintenance.number_of_analysis;
				if ( nbAnalSinceMaint > nbAnalysisBeforeMaintenance) {
					nextMaintenanceDate = 'Overdue';
				} else {
					const averageAnalysisPerDay = nbAnalysisSinceMaintenance.number_of_analysis / nbDaysSinceMaintenance;
					const remainingAnalysis = nbAnalysisBeforeMaintenance - nbAnalysisSinceMaintenance.number_of_analysis;
					const nbDaysForAnalysis = Math.ceil(remainingAnalysis / averageAnalysisPerDay);
					const nbDaysFor365 = 365 - nbDaysSinceMaintenance;
					daysNextMaintenance = nbDaysForAnalysis < nbDaysFor365 ? nbDaysForAnalysis : nbDaysFor365;
					nextMaintenanceDate = moment().add(daysNextMaintenance, 'd').format('YYYY-MM-DD');
				}
			}
		}
		return {
			'daysNextMaintenance': daysNextMaintenance,
			'nextMaintenanceDate': nextMaintenanceDate,
			'nbAnalysisSinceMaintenance': nbAnalSinceMaint,
			'nbDaysSinceMaintenance': nbDaysSinceMaintenance
		};
	}

	getNotifications(notifTimeFrame: string): Observable<Notification[]> {
		let params = new HttpParams();
		params = params.append('notification_timeframe', notifTimeFrame);

		return this.http.get<Notification[]>(this.baseUrl + '/notifications', {headers: this.header, params});
	}

	getNotificationByMachineTag(machine_tag: string, notifTimeFrame: string): Observable<Notification[]> {
		let params = new HttpParams();
		params = params.append('notification_timeframe', notifTimeFrame);
		return this.http.get<Notification[]>(this.baseUrl + '/notifications/' + machine_tag, {headers: this.header, params});
	}

	dismissNotification(notif_id: number, userEmail: string): Observable<number> {
		const params = new HttpParams();
		return this.http.patch<number>(this.baseUrl + '/notifications/dismiss?notification_id=' + notif_id + '&dismissed_by=' + userEmail, {headers: this.header, params});
	}

	raiseNotification(notif_id: number) {
		const params = new HttpParams();
		return this.http.patch<number>(this.baseUrl + '/notifications/raise?notification_id=' + notif_id, {headers: this.header, params});
	}
}
