import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CookieServiceService} from '../cookie-service.service';
import {ApiMsg} from '../Models/ApiMsg';
import {environment} from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class LoginService {

	header;
	authUrl = environment.authUrl;

	constructor(private http: HttpClient, private cookieService: CookieServiceService) {
	}

	/*public getAccessRight(email: string): Observable<ApiMsg> {
		this.header = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + this.cookieService.get('token'),
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'DELETE, GET, POST, OPTIONS',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		})
		return this.http.get<ApiMsg>(this.baseUrl + "/accessRight/" + email + "/email", {headers: this.header});
	}*/

	public signInWithMicroService(email: string, password: string): Observable<any> {
		this.header = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + this.cookieService.get('token'),
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'DELETE, GET, POST, OPTIONS',
			'Access-Control-Allow-Headers': 'Origin, Content-Type, Authorization',
		});
		return this.http.post<any>(this.authUrl + "/auth/signIn", {"email": email, "password": password}, {headers: this.header});
	}

	public testFetch(idToken: string): Observable<any> {
		const authHeader = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + idToken,
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'DELETE, GET, POST, OPTIONS',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		})
		return this.http.get<any>("http://localhost:8081/auth/test", {headers: authHeader});
	}

	public testFetchAnalysis(idToken: string): Observable<any> {
		const authHeader = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + idToken,
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'DELETE, GET, POST, OPTIONS',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		});
		return this.http.get<any>('http://localhost:8082/analyses', {headers: authHeader});
	}
}
