import {Plotly} from 'angular-plotly.js/lib/plotly.interface';


export class CustomChartData {

	constructor(chartData: Plotly.Data[], chartLayout: Plotly.Layout, chartConfig: Plotly.Config,
							      chartFigure?: Plotly.Figure[]) {
		this.chartData = chartData;
		this.chartLayout = chartLayout;
		this.chartFigure = chartFigure;
		this.chartConfig = chartConfig;
	}

	public chartData: Plotly.Data[];

	public chartLayout: Plotly.Layout;

	public chartFigure: Plotly.Figure[];

	public chartConfig: Plotly.Config;
}
