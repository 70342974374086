import {Inlets} from './Inlets';
import {Machine} from './Machine';

export class CompleteMachine {

	public machine: Machine;
	public inlets: Inlets[];

	constructor(machine: Machine, inlets: Inlets[]) {
		this.machine = machine;
		this.inlets = inlets;
	}
}
