export class SamplingPoint {

	public sp_id: number;
	public site_id: number;
	public site_tag: string;
	public description: string;
	public sp_num: number;
	public sp_tag: string;
	public access_level: number;
	public business_tag: string;
	public sampling_name: string;

	constructor(sp_id: number, site_id: number, description: string, sp_num: number, sp_tag?: string) {
		this.sp_id = sp_id;
		this.site_id = site_id;
		this.description = description;
		this.sp_num = sp_num;
		this.sp_tag = sp_tag || null;
	}
}
