import {Component, OnInit} from '@angular/core';
import {NavbarService} from "../header/navbar.service";

@Component({
	selector: 'app-admin-panel',
	templateUrl: './admin-panel.component.html',
	styleUrls: ['./admin-panel.component.css']
})


export class AdminPanelComponent implements OnInit {

	currentTab: number;

	constructor(public nav: NavbarService) {
		this.currentTab = 0;
	}

	ngOnInit(): void {
	}

	onTabClick($event: any){
		this.currentTab = $event.index;
	}

	checkForPermission(): boolean{
		if (this.nav._role === 'super-admin'){
			return true;
		}else {
			return false;
		}
	}
}
