import {FullscreenChartService} from '../../../fullscreen-chart/fullscreen-chart.service';
import {CustomChartData} from '../../CustomChartData';
import {SPChart} from '../SPChart';

/* Concrete Chart for Factory Pattern */
export class SPCalibratorsChart implements SPChart {

	c1: any;
	c2: any;
	c1minc2: any;
	cartAvailable: any;
	datas: CustomChartData;

	private config = {
		responsive: true,
		displaylogo: false
	};

	private layout = {
		height: 450,
		// items with a `name` attribute in template.images will be added to any
		// plot using this template
		images: [{
			source: '../../../../favicon.ico',
			xref: 'paper',
			yref: 'paper',
			x: 0.5,
			y: 0.5,
			xanchor: 'center',
			yanchor: 'middle',
			sizex: 0.7,
			sizey: 0.7,
			opacity: 0.1,
			layer: 'below'
		}],
		legend: {
			x: 0,
			y: 1.15,
			orientation: 'h',
			itemsizing: 'constant',
			itemwidth: 25
		},
		margin: {
			l: 35,
			r: 35,
			b: 50,
			t: 10
		},
		xaxis: {
			tickformat: '%d %b',
			tickangle: -45,
			showgrid: true,
			nticks: 36,
			showline: true,
			zeroline: false,
			mirror: true,
			hoverformat: '%Y-%m-%d %H:%M:%S'
		},
		yaxis: {
			range: [350, 700],
			showgrid: true,
			showline: true,
			zeroline: false,
			mirror: true
		},
		/*
		yaxis2: {
			title: 'Cart available',
			range: [0, 18],
			overlaying: 'y',
			side: 'right',
			showgrid: false,
			showline: false
		},
		modebar: {
			color: '#000000',
			bgcolor: '#FFFFFF'
		}

		 */
	};

	constructor(private service: FullscreenChartService) {
	}

	public async createChart(sp_id: number, limit: number, time: string): Promise<CustomChartData> {

		this.c1 = await this.service.getSamplingPointVariableHistory(sp_id, 'result_tt1', time, limit);
		this.c2 = await this.service.getSamplingPointVariableHistory(sp_id, 'result_tt4', time, limit);
		this.c1minc2 = await this.service.getSamplingPointVariableHistory(sp_id, 'result_delta_c1_c2', time, limit);
		//this.cartAvailable = await this.service.getVariableHistory(sp_id, 'cart_available', time, limit);


		let c1 = [];
		let c2 = [];
		let date = [];
		let c1minc2 = [];
		const cartAvailable = [];

		this.datas = new CustomChartData([], this.layout, this.config);

		for (let i = 0; i < this.c1.length; i++) {
			c1.push(this.c1[i].map.value);
			c2.push(this.c2[i].map.value);
			c1minc2.push(this.c1minc2[i].map.value);
			date.push(this.c1[i].map.analysis_time);
		}

		/*
		for (let i = 0; i < this.cartAvailable.length; i++) {
			cartAvailable.push(this.cartAvailable[i].map.value);
		}

		 */

		const trace_c1 = {
				name: 'C1',
				x: date,
				y: c1,
				type: 'scatter',
				mode: 'markers',
				hovertemplate: '%{y:.3f}',
				marker: {
					size: 10,
					color: '#4ecdc4'
				}};
		const trace_c2 = {
				name: 'C2',
				x: date,
				y: c2,
				type: 'scatter',
				mode: 'markers',
				hovertemplate: '%{y:.3f}',
				marker: {
					size: 10,
					color: '#f89406'
				}
			};
		const trace_c1_c2 = {
				name: 'C1-C2',
				x: date,
				y: c1minc2,
				type: 'scatter',
				mode: 'markers',
				hovertemplate: '%{y:.3f}',
				marker: {
					size: 10,
					color: 'rgb(118,118,118)'
				}
			};
		const trace_bar = {
				name: '',
				base: c2,
				x: date,
				y: c1minc2,
				type: 'bar',
				marker: {
					size: 10,
					color: 'rgba(77, 19, 209, 1)'
				},
				hoverinfo: 'skip',
				showlegend: false,
			};
		const trace_cart_available = {
			line: {shape: 'hv'},
			mode: 'lines+markers',
			name: 'Cart available',
			x: date,
			y: cartAvailable,
			type: 'sctatter',
			marker: {
				size: 5,
				color: 'rgb(96,96,96,96)'
			},
			yaxis: 'y2'
		};
		this.datas.chartData = [trace_c1, trace_c2, trace_c1_c2, trace_bar];
		return this.datas;
	}
}


