import {Injectable} from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	NavigationEnd,
	Router,
	RouterStateSnapshot,
	UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {CookieServiceService} from './cookie-service.service';
import {NavbarService} from "./header/navbar.service";
import {NavigationService} from "./navigation-service.service";
import {LocalStorageService} from "./local-storage.service";
import jwt_decode from "jwt-decode";
import {MatDialog} from "@angular/material/dialog";
import {MdLoginPopupComponentComponent} from "./login/md-login-popup.component";

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(private _router: Router, public nav: NavbarService, public cookieService: CookieServiceService, public localStorageService: LocalStorageService, private navigation: NavigationService, public dialog: MatDialog) {
	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		const token = this.cookieService.get('token');

		if (token != null) {
			this.navigation.push();
			const decoded = jwt_decode(token);
			this.nav.loggedIn(decoded['role']);
			return true;
		} else {
			this.localStorageService.set('current_url', window.location.pathname)
			if (this.dialog.openDialogs.length == 0){
				this.dialog.open(MdLoginPopupComponentComponent, {
					disableClose: true
				});
			}
		}

	}
}
