import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ImportDataFilesService} from "./import-data-files.service";


@Component({
	selector: 'app-import-data-files',
	templateUrl: './import-data-files.component.html',
	styleUrls: ['./import-data-files.component.css']
})

export class ImportDataFilesComponent implements OnInit {

	constructor(
		private service: ImportDataFilesService,
		public dialog: MatDialog,
	) {
	}

	ngOnInit(): void {

	}
}
