import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';


import {CookieServiceService} from '../cookie-service.service';
import {environment} from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ImportDataFilesService {

	totalAngularPackages: any;
	header;

	constructor(private http: HttpClient, private cookieService: CookieServiceService) {
		this.header = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + this.cookieService.get('token'),
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'DELETE, GET, POST, OPTIONS',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		})
	}

	baseUrl = environment.machineUrl;


}
