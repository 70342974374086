import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AdminPanelService} from '../../admin-panel.service';
import * as _ from 'lodash';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ExportFormColumnInfoDialog} from '../../../export-forms/column-info-dialog/column-info-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {ReplaySubject, Subject} from 'rxjs';
import {MatSelect} from '@angular/material/select';
import {ExportFormHelpDialog} from '../../../export-forms/export-help-dialog/export-help-dialog.component';
import {EditApprovalDialogComponent} from '../../edit-approval-dialog/edit-approval-dialog.component';


@Component({
	selector: 'machine-limits',
	templateUrl: './machine-limits.component.html',
	styleUrls: ['./machine-limits.component.css']
})
export class MachineLimitsComponent implements OnInit {

	limitForm: FormGroup;
	formState = '';

	machines: any[] = [];
	limits: any[] = [];
	groupedLimits: any[] = [];
	variables: any[] = [];

	oldLimit: any = {};

	cl_methods: string[] = ['multiple'];

	fetchingLimits = true;
	limitBeingEdited: any = {};

	selectedMachines: any[] = [];
	selectedVariable: any = {};
	selectedMethod: string;

	public variableFilterCtrl: FormControl = new FormControl();
	public filteredVariables: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
	@ViewChild('variableSelect', {static: true}) variableSelect: MatSelect;
	protected _onDestroy = new Subject<void>();

	constructor(private service: AdminPanelService,
							      public formBuilder: FormBuilder,
							      public dialog: MatDialog,
							      private snackbar: MatSnackBar) {
	}

	async ngOnInit() {

		this.createLimitForm();

		await this.service.getVariableDefinitions().subscribe(variables => {
			this.variables = [];
			for (const single_variable of variables) {
				this.variables.push(single_variable.map);
			}
			this.filteredVariables.next(this.variables.slice());
		});


		await this.service.getMachines().subscribe(machines => {
			this.machines = machines;


			this.service.getLimits().subscribe(limits => {
				this.fetchingLimits = false;

				this.limits = limits;
				this.limits.forEach(limit => {
					const tmp = [];
					if (!limit.meta_info.empty){
						limit.meta_info.map.target_machines.forEach(machine_id => {
							tmp.push(_.find(this.machines, x => {
								if (x.machine_id === machine_id) {
									return true;
								}
							}));
						});
					}
					limit.target_machines = tmp;
				});

				const tempGroupedLimits = _.groupBy(this.limits, 'var_tag');

				Object.entries(tempGroupedLimits).forEach(x => {
					const tempLimit: any = {};

					tempLimit.limit_group_name = x[0];
					tempLimit.limits = x[1];
					tempLimit.var_id = tempLimit.limits[0].var_id;
					tempLimit.limit_group_description = tempLimit.limits[0].description;
					this.groupedLimits.push(tempLimit);
				});
			});
		});
	}

	createLimitForm() {
		this.variableFilterCtrl.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe(() => {
				this.filterVariables();
			});

		this.limitForm = this.formBuilder.group({
			vl_tag: ['', Validators.required],
			vl_description: [''],
			variable: ['', Validators.required],
			lcl_a: [],
			lcl_w: [],
			ucl_w: [],
			ucl_a: [],
			cl_method: [],
			cl_span: [],
			target_machines: [],
			is_active: [],
		});
	}

	SetFormCreateNewLimit() {
		this.formState = 'create';
		this.selectedMethod = undefined;

		this.limitForm.get('variable').enable();

		this.resetForm();
	}

	setFormDuplicateLimit(limit: any, groupIndex: number, limitIndex: number) {
		this.formState = 'duplicate';
		this.limitForm.get('variable').enable();

		this.fillForm(limit, groupIndex, limitIndex);
	}

	setFormUpdateLimit(limit: any, groupIndex: number, limitIndex: number) {
		this.formState = 'update';
		this.limitForm.get('variable').disable();

		this.fillForm(limit, groupIndex, limitIndex);
	}

	fillForm(limit: any, groupIndex: number, limitIndex: number) {
		this.selectedMachines = [];

		this.limitBeingEdited = limit;
		this.limitBeingEdited.groupIndex = groupIndex;
		this.limitBeingEdited.limitIndex = limitIndex;

		const selectedVariable = _.find(this.variables, (variable) => {
			return variable.var_id === limit.var_id;
		});
		this.selectedVariable = selectedVariable;
		this.selectedMethod = limit.control_limits?.map?.cl_method;

		if (limit.target_machines.length === 0) {
			this.selectedMachines = [];
		} else {
			limit.target_machines.forEach(x => this.selectedMachines.push(x));
		}

		this.limitForm = this.formBuilder.group({
			vl_tag: [limit.vl_tag, Validators.required],
			vl_description: [limit.vl_description],
			variable: [[this.selectedVariable], Validators.required],
			lcl_a: [limit.control_limits?.map?.lcl_a],
			lcl_w: [limit.control_limits?.map?.lcl_w],
			ucl_w: [limit.control_limits?.map?.ucl_w],
			ucl_a: [limit.control_limits?.map?.ucl_a],
			cl_method: [this.selectedMethod],
			cl_span: [limit.control_limits?.map?.cl_span],
			target_machines: [this.selectedMachines],
			is_active: [limit.is_active],
		});

		this.oldLimit.cl_id = limit.cl_id;
		this.oldLimit.vl_tag = limit.vl_tag;
		this.oldLimit.vl_description = limit.vl_description;
		this.oldLimit.var_id = limit.var_id;
		this.oldLimit.lcl_a = limit.control_limits?.map?.lcl_a;
		this.oldLimit.lcl_w = limit.control_limits?.map?.lcl_w;
		this.oldLimit.ucl_w = limit.control_limits?.map?.ucl_w;
		this.oldLimit.ucl_a = limit.control_limits?.map?.ucl_a;
		this.oldLimit.cl_method = this.selectedMethod;
		this.oldLimit.cl_span = limit.control_limits?.map?.cl_span;
		this.oldLimit.target_machines = this.selectedMachines;
		this.oldLimit.is_active = limit.is_active;

		this.limitForm.markAllAsTouched();
	}

	updateLimit() {
		if (this.limitForm.invalid) {
			this.snackbar.open('Some fields are invalid', 'Ok', {
				duration: 5000
			});
		} else {
			const updatedLimit = this.buildNewLimit();
			const objectControlLimit = updatedLimit.control_limits;
			updatedLimit.meta_info = JSON.stringify({target_machines: updatedLimit.target_machines.map(({ machine_id }) => machine_id)});
			updatedLimit.control_limits = JSON.stringify(updatedLimit.control_limits);

			this.service.updateLimit(updatedLimit).subscribe(resp => {
				if (resp) {
					this.snackbar.open('Limit successfully changed.', 'Ok', {
						duration: 2000
					});
					updatedLimit.control_limits = objectControlLimit;
					this.updateLocalLimit(updatedLimit);
					this.limitForm.markAsPristine();
				} else {
					this.snackbar.open('An error occurred while editing.', 'Ok', {
						duration: 5000
					});
				}
			});
		}
	}

	private updateLocalLimit(updatedLimit: any) {
		const groupIndex = this.limitBeingEdited.groupIndex;
		const limitIndex = this.limitBeingEdited.limitIndex;

		const tempLimit = this.groupedLimits[groupIndex].limits[limitIndex];

		tempLimit.vl_tag = updatedLimit.vl_tag;
		tempLimit.vl_description = updatedLimit.vl_description;
		tempLimit.control_limits.map.lcl_a = updatedLimit.control_limits.lcl_a;
		tempLimit.control_limits.map.lcl_w = updatedLimit.control_limits.lcl_w;
		tempLimit.control_limits.map.ucl_w = updatedLimit.control_limits.ucl_w;
		tempLimit.control_limits.map.ucl_a = updatedLimit.control_limits.ucl_a;
		tempLimit.control_limits.map.cl_method = updatedLimit.control_limits.cl_method;
		tempLimit.control_limits.map.cl_span = updatedLimit.control_limits.cl_span;
		tempLimit.target_machines = updatedLimit.target_machines;
		tempLimit.is_active = updatedLimit.is_active;

		if (this.oldLimit.var_id === updatedLimit.var_id) {
			this.groupedLimits[groupIndex].limits[limitIndex] = tempLimit;
			this.sortLimits(groupIndex);
		} else {
			this.deleteLocalLimit(groupIndex, limitIndex);
			this.createLocalLimit(tempLimit);
		}
	}

	deleteLimit() {
		const dialogRef = this.dialog.open(EditApprovalDialogComponent, {
			width: '35%',
			data: [{mod_description: 'Deleting the rule: \'' + this.limitBeingEdited.vl_tag + '\'.'}]
		});

		/* If user approved the modifications */
		dialogRef.afterClosed().subscribe(async data => {
			if (data) {
				this.service.deleteLimite(this.oldLimit.cl_id).subscribe(resp => {
					if (resp) {
						this.snackbar.open('Limit successfully deleted.', 'Ok', {
							duration: 2000
						});
						this.formState = '';
						this.deleteLocalLimit(this.limitBeingEdited.groupIndex, this.limitBeingEdited.limitIndex);
					} else {
						this.snackbar.open('An error occurred while deleting.', 'Ok', {
							duration: 5000
						});
					}
				});
			}
		});
	}

	private deleteLocalLimit(groupIndex: number, limitIndex: number) {
		this.groupedLimits[groupIndex].limits.splice(limitIndex, 1);
		if (this.groupedLimits[groupIndex].limits.length === 0) {
			this.groupedLimits.splice(groupIndex, 1);
		}
		this.resetForm();
	}

	createNewLimit() {

		const newLimit = this.buildNewLimit();
		const objectControlLimit = newLimit.control_limits;
		newLimit.meta_info = JSON.stringify({target_machines: newLimit.target_machines.map(({ machine_id }) => machine_id)});
		newLimit.control_limits = JSON.stringify(newLimit.control_limits);
		try {
			this.service.createLimit(newLimit).subscribe((response) => {
				const createdLimit = JSON.parse(response);
				this.snackbar.open('Limit successfully created.', 'Ok', {
					duration: 2000
				});
				newLimit.cl_id = createdLimit.cl_id;
				newLimit.control_limits = {map : objectControlLimit};
				this.createLocalLimit(newLimit);
			});
		} catch (e) {
			this.snackbar.open('An error occurred while editing.', 'Ok', {
				duration: 5000
			});
		}
	}

	private createLocalLimit(newLimit: any) {
		let groupExists = false;
		for (let i = 0; i < this.groupedLimits.length; i++) {
			if (this.groupedLimits[i].limit_group_name === newLimit.var_tag) {
				groupExists = true;
				this.groupedLimits[i].limits.push(newLimit);
				this.sortLimits(i);
			}
		}
		if (!groupExists) {
			const newGroup: any = {};
			newGroup.limit_group_name = newLimit.var_tag;
			newGroup.limit_group_description = newLimit.description;
			newGroup.var_id = newLimit.var_id;
			newGroup.limits = [];
			newGroup.limits.push(newLimit);
			this.groupedLimits.push(newGroup);
			this.sortLimitGroups();
		}
		this.resetForm();
	}

	private buildNewLimit(): any {
		const form = this.limitForm.value;
		const limit: any = {};
		limit.control_limits = {};
		limit.target_machines = [];
		limit.target_machinesIds = [];

		limit.vl_tag = form.vl_tag;
		limit.vl_description = form.vl_description;
		limit.var_id = Array.isArray(form.variable) ? form.variable[0].var_id : form.variable.var_id;
		limit.var_tag = Array.isArray(form.variable) ? form.variable[0].var_tag : form.variable.var_tag;
		limit.description = form.variable.description;
		limit.cl_id = this.oldLimit.cl_id;
		limit.is_active = form.is_active == null ? false : form.is_active;
		limit.application = 'Notification';

		if (form.lcl_a !== null && form.lcl_a !== '') {
			limit.control_limits.lcl_a = +form.lcl_a;
		}
		if (form.lcl_w !== null && form.lcl_w !== '') {
			limit.control_limits.lcl_w = +form.lcl_w;
		}
		if (form.ucl_w !== null && form.ucl_w !== '') {
			limit.control_limits.ucl_w = +form.ucl_w;
		}
		if (form.ucl_a !== null && form.ucl_a !== '') {
			limit.control_limits.ucl_a = +form.ucl_a;
		}
		if (form.cl_method != null && form.cl_method !== '') {
			limit.control_limits.cl_method = form.cl_method;
		}
		if ((form.cl_span != null && form.cl_span !== '') && this.selectedMethod === 'multiple') {
			limit.control_limits.cl_span = +form.cl_span;
		}
		if (form.target_machines != null) {
			form.target_machines.forEach(machine => limit.target_machines.push(machine));
			form.target_machines.forEach(machine => limit.target_machinesIds.push(machine.machine_id));
		}

		return limit;
	}

	protected filterVariables() {
		if (!this.variables) {
			return;
		}

		let search = this.variableFilterCtrl.value;
		if (!search) {
			this.filteredVariables.next(this.variables.slice());
			return;
		} else {
			search = search.toLowerCase();
		}

		this.filteredVariables.next(
			this.variables.filter((variable) => {
				return variable.map.var_tag.toLowerCase().indexOf(search) > -1;
			}
		));
	}

	methodChange() {
		if (this.selectedMethod === 'multiple') {
			this.limitForm.get('cl_span').setValidators([Validators.required, Validators.pattern('^([1-9]+[0-9]*)')]);
		} else {
			this.limitForm.get('cl_span').setValidators(null);
		}
	}

	updateTargetMachinesCtrl(action: string) {
		if (action === 'select all') {
			this.limitForm.get('target_machines').setValue(this.machines);
		} else if (action === 'clear') {
			this.limitForm.get('target_machines').setValue([]);
		}
		this.limitForm.get('target_machines').markAsDirty();
	}

	private resetForm() {
		this.selectedMethod = '';
		this.limitForm.reset();
		this.limitForm.markAllAsTouched();
	}

	private sortLimits(groupIndex: number) {
		this.groupedLimits[groupIndex].limits = _.orderBy(this.groupedLimits[groupIndex].limits, (item) => [item.target_machines.length === 0, item.target_machines.length, item.cl_id], ['asc', 'desc', 'desc']);
		this.groupedLimits[groupIndex].limits = _.orderBy(this.groupedLimits[groupIndex].limits, (item) => [item.is_active === true], ['desc']);
	}

	private sortLimitGroups() {
		this.groupedLimits = _.orderBy(this.groupedLimits, ['var_id'], ['asc']);
	}

	restoreFormValue() {
		this.limitForm.get('vl_tag').setValue(this.oldLimit.vl_tag);
		this.limitForm.get('vl_description').setValue(this.oldLimit.vl_description);
		this.limitForm.get('is_active').setValue(this.oldLimit.is_active);
		// this.limitEditorForm.get('variable').setValue(this.selectedVariable);
		this.limitForm.get('lcl_a').setValue(this.oldLimit.lcl_a);
		this.limitForm.get('lcl_w').setValue(this.oldLimit.lcl_w);
		this.limitForm.get('ucl_w').setValue(this.oldLimit.ucl_w);
		this.limitForm.get('ucl_a').setValue(this.oldLimit.ucl_a);
		this.limitForm.get('cl_method').setValue(this.oldLimit.cl_method);
		this.limitForm.get('cl_span').setValue(this.oldLimit.cl_span);
		this.limitForm.get('target_machines').setValue(this.selectedMachines);
		this.limitForm.get('is_active').setValue(this.oldLimit.is_active);

		this.limitForm.markAsPristine();
	}

	// Opens help dialog that explains how the tool works.
	openHelp(): void {
		this.dialog.open(ExportFormHelpDialog, {
			width: '60%',
			height: '80%',
			data: {
				caller: 'MachineLimits'
			}
		});
	}

	openColumnInfo(): void {
		this.dialog.open(ExportFormColumnInfoDialog, {
			height: '80%',
			data: {
				caller: 'MachineLimits'
			}
		});
	}
}
