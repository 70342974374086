export class ResultLaboAtp {

	constructor(
		public analysis_id: number,
		public company_name: string,
		public free: number,
		public total: number,
		public bacterial: number,
		public equivalent: number,
		public standard: string,
		public comment: string
	) {
	}
}
