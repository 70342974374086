import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DashboardAnalysisService} from '../dashboard-analysis.service';
import {Analysis} from '../../Models/Analysis';
import {any} from 'codelyzer/util/function';

@Component({
	selector: 'app-analysis-search-engine',
	templateUrl: './analysis-search-engine.component.html',
	styleUrls: ['./analysis-search-engine.component.css']
})
export class AnalysisSearchEngineComponent implements OnInit {

	public searchText: any;
	public searchCtrl: FormControl = new FormControl();

	analysis: any;

	constructor(
		private service: DashboardAnalysisService,
	) {
	}

	ngOnInit(): void {
	}

	onSearchChange(searchValue: string): void {

		if (searchValue === '') {
			this.searchText = null;
		} else {
			this.service.searchAnalysis(searchValue.toString(), 15, 0).subscribe(data => {
				this.searchText = data;
			});

		}
	}
}
