<body>
<mat-card class="sampling-point-header">
	<mat-card-title fxLayoutAlign="space-between">
		<button mat-mini-fab color="primary" (click)="gotoSamplingPoint()">
			<mat-icon>keyboard_arrow_left</mat-icon>
		</button>
		<h2>Sampling-point dashboard : {{ sampling_points?.sampling_name }} </h2>
		<div>
			<!--
			<button class="button-settings" mat-button color="primary">
				<mat-icon>settings</mat-icon>
			</button>
			-->
			<button mat-raised-button *ngIf="checkForPermission()" color="basic" (click)="openLegend()">Calculation legend</button>
		</div>
	</mat-card-title>
</mat-card>

<!--SAMPLING POINT INFORMATION-->
<mat-card class="sampling-point-information">
	<mat-card-title fxLayoutAlign="space-between">
		Sampling point information
		<button mat-mini-fab color="basic" (click)="showSamplingPointInfo=!showSamplingPointInfo">
			<mat-icon *ngIf="showSamplingPointInfo">remove</mat-icon>
			<mat-icon *ngIf="!showSamplingPointInfo">add</mat-icon>
		</button>
	</mat-card-title>
	<mat-card-content>

		<mat-form-field class="number-sample-sel" appearance="fill" [formGroup]="samplingPointSettingsForm">
			<mat-label>Number of samples</mat-label>
			<mat-select (selectionChange)="numberOfSampleSamplingPointChanged($event)" formControlName="numberOfSample">
				<mat-option *ngFor="let num of numberOfSample" [value]="num">
					{{num}}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field color="primary" appearance="fill" [formGroup]="samplingPointSettingsForm">
			<mat-label>Last sample date</mat-label>
			<input matInput [matDatepicker]="picker2" [max]="maxDate" formControlName="dateFormat">
			<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
			<mat-datepicker #picker2 color="primary"></mat-datepicker>
		</mat-form-field>

		<button mat-raised-button color="primary" (click)=refreshSamplingPointInfo()>Submit</button>

		<mat-grid-list cols="5" rowHeight="65px">
			<mat-grid-tile>
				Number of analysis:
				<button  mat-fab class="status-info" [disabled]="true" [ngStyle]="{'background-color': 'black'}">
					{{sampling_point_nb_analysis}}
				</button>
			</mat-grid-tile>
			<mat-grid-tile *ngIf="sampling_pointCalibrationSuccessMoy && checkForPermission()">
				Average calibration :
				<button mat-fab class="status-info" [disabled]="true"
								[ngStyle]="
          sampling_pointCalibrationSuccessMoy == null?{'background-color':'#EEEEEE'} :
          sampling_pointCalibrationSuccessMoy.cl_level == 0?{'background-color': 'green'}:
          sampling_pointCalibrationSuccessMoy.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
          sampling_pointCalibrationSuccessMoy.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
          {'background-color': '#EEEEEE'}">
					{{sampling_pointCalibrationSuccessMoy.value}}%
				</button>
			</mat-grid-tile>
			<mat-grid-tile *ngIf="latestSampling_pointCalibrationSuccess_5 && checkForPermission()">
				Calibration 5A :
				<button mat-fab class="status-info" [disabled]="true"
								[ngStyle]="
        latestSampling_pointCalibrationSuccess_5 == null?{'background-color':'#EEEEEE'} :
        latestSampling_pointCalibrationSuccess_5.cl_level == 0?{'background-color': 'green'}:
        latestSampling_pointCalibrationSuccess_5.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
        latestSampling_pointCalibrationSuccess_5.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
        {'background-color': '#EEEEEE'}">
					{{latestSampling_pointCalibrationSuccess_5.value}}%
				</button>
			</mat-grid-tile>
			<mat-grid-tile *ngIf="averageQualityScore_5 && checkForPermission()">
				Quality Score 5A :
				<button mat-fab class="status-info" [disabled]="true"
								[ngStyle]="
        averageQualityScore_5.value == null?{'background-color':'#999999'} :
        averageQualityScore_5.value <= 50? {'background-color': '#E0242F'}:
        averageQualityScore_5.value <= 75 ?{'background-color':'#FFA500'} :
        averageQualityScore_5.value <= 85 ?{'background-color':'#F9D054'} :
        {'background-color': 'green'}">
					<p *ngIf="averageQualityScore_5.value != null; else defaultValue">{{averageQualityScore_5.value}}%</p>
				</button>
			</mat-grid-tile>
			<mat-grid-tile *ngIf="numberInvalidLoaded && checkForPermission()">
				Invalid UG 5A :
				<button mat-fab class="status-info" [disabled]="true"
								[ngStyle]="
        numberInvalidUgLast_5 == null?{'background-color':'#999999'} :
        numberInvalidUgLast_5 >= 3 ? {'background-color': '#E0242F'}:
        numberInvalidUgLast_5 == 2 ? {'background-color':'#FFA500'} :
        numberInvalidUgLast_5 == 1 ? {'background-color':'#F9D054'} :
        {'background-color': 'green'}">
					<p *ngIf="numberInvalidUgLast_5 != null; else defaultValue">{{numberInvalidUgLast_5}}</p>
				</button>
			</mat-grid-tile>
			<ng-template #defaultValue>
				<p>No data</p>
			</ng-template>
		</mat-grid-list>

		<!--SAMPLING POINT Charts-->
		<mat-card class="chart-container" fxLayout="row wrap" fxLayoutAlign="start center">
			<!--Reaction time of C1 & C2 [1][1]-->
			<mat-card class="sampling-point-chart" *ngIf="checkForPermission()">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					Reaction time of C1 and C2
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('Sampling point Calibrators Chart')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="sp_calibrators_chart && showSamplingPointChart[1]">
					<plotly-plot
						[data]="sp_calibrators_chart.chartData"
						[layout]="sp_calibrators_chart.chartLayout"
						[config]="sp_calibrators_chart.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!sp_calibrators_chart || !showSamplingPointChart[1]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showSamplingPointChart[1]= !showSamplingPointChart[1]">
						<mat-icon *ngIf="showSamplingPointChart[1]">remove</mat-icon>
						<mat-icon *ngIf="!showSamplingPointChart[1]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv" (click)="exportToCsv('Sampling point Calibrators Chart')">
						<mat-icon>cloud_download</mat-icon>
					</button>

<!--
					<button mat-mini-fab color="primary" (click)="sampling_pointChartSettings[1]=!sampling_pointChartSettings[1]">
						<mat-icon *ngIf="sampling_pointChartSettings[1]">remove</mat-icon>
						<mat-icon *ngIf="!sampling_pointChartSettings[1]">format_list_bulleted</mat-icon>
					</button>
-->
				</mat-card-actions>
<!--
				<mat-card *ngIf="sampling_pointChartSettings[1]">

					<mat-form-field class="number-sample-sel" appearance="fill" [formGroup]="calibratorsForm">
						<mat-label>Number of samples</mat-label>
						<mat-select (selectionChange)="numberOfSampleSamplingPointChanged($event)" formControlName="numberOfSample">
							<mat-option *ngFor="let num of numberOfSample" [value]="num">
								{{num}}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill" [formGroup]="calibratorsForm">
						<mat-label>Last sample date</mat-label>
						<input matInput [matDatepicker]="picker2" [max]="maxDate" formControlName="dateFormat">
						<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
						<mat-datepicker #picker2 color="primary"></mat-datepicker>
					</mat-form-field>
					<button mat-raised-button color="primary" (click)="submitCalibratorsSettings()">Submit</button>

					<mat-divider></mat-divider>

					<mat-form-field class="primary divider-setting" appearance="fill">
						<mat-label>X axis range</mat-label>
						<mat-date-range-input [rangePicker]="picker">
							<input matStartDate placeholder="Start date" #x1_i_calibrators>
							<input matEndDate placeholder="End date" #x2_i_calibrators>
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill">
						<mat-label>Min y axis</mat-label>
						<input matInput type="number" #y1_i_calibrators>
					</mat-form-field>
					<mat-form-field color="primary" appearance="fill">
						<mat-label>Max y axis</mat-label>
						<input matInput type="number" #y2_i_calibrators>
					</mat-form-field>
					<button mat-raised-button color="primary"
									*ngIf="x1_i_calibrators.value && x2_i_calibrators.value && y1_i_calibrators.value && y2_i_calibrators.value"
									matSuffix mat-icon-button (click)="modifyChart(sp_calibrators_chart,
           [x1_i_calibrators.value, x2_i_calibrators.value], [y1_i_calibrators.value, y2_i_calibrators.value])">
						<mat-icon>send</mat-icon>
					</button>
				</mat-card>
-->
			</mat-card>
			<!--Ttm [0][2]-->
			<mat-card class="sampling-point-chart" *ngIf="checkForPermission()">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					TTm
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('Sampling point TTm Chart')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="sp_ttm_chart && showSamplingPointChart[0]">
					<plotly-plot
						[data]="sp_ttm_chart.chartData"
						[layout]="sp_ttm_chart.chartLayout"
						[config]="sp_ttm_chart.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!sp_ttm_chart || !showSamplingPointChart[0]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showSamplingPointChart[0]= !showSamplingPointChart[0]">
						<mat-icon *ngIf="showSamplingPointChart[0]">remove</mat-icon>
						<mat-icon *ngIf="!showSamplingPointChart[0]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv" (click)="exportToCsv('Sampling point TTm Chart')">
						<mat-icon>cloud_download</mat-icon>
					</button>

<!--
					<button mat-mini-fab color="primary" (click)="sampling_pointChartSettings[2]=!sampling_pointChartSettings[2]">
						<mat-icon *ngIf="sampling_pointChartSettings[2]">remove</mat-icon>
						<mat-icon *ngIf="!sampling_pointChartSettings[2]">format_list_bulleted</mat-icon>
					</button>
-->
				</mat-card-actions>
<!--
				<mat-card *ngIf="sampling_pointChartSettings[2]">

					<mat-form-field class="number-sample-sel" appearance="fill" [formGroup]="ttmForm">
						<mat-label>Number of samples</mat-label>
						<mat-select (selectionChange)="numberOfSampleSamplingPointChanged($event)" formControlName="numberOfSample">
							<mat-option *ngFor="let num of numberOfSample" [value]="num">
								{{num}}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill" [formGroup]="ttmForm">
						<mat-label>Last sample date</mat-label>
						<input matInput [matDatepicker]="picker2" [max]="maxDate" formControlName="dateFormat">
						<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
						<mat-datepicker #picker2 color="primary"></mat-datepicker>
					</mat-form-field>
					<button mat-raised-button color="primary" (click)="submitTTmSettings()">Submit</button>

					<mat-divider></mat-divider>

					<mat-form-field class="primary divider-setting" appearance="fill">
						<mat-label>X axis range</mat-label>
						<mat-date-range-input [rangePicker]="picker">
							<input matStartDate placeholder="Start date" #x1_sp_ttm>
							<input matEndDate placeholder="End date" #x2_sp_ttm>
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill">
						<mat-label>Min y axis</mat-label>
						<input matInput type="number" #y1_sp_ttm>
					</mat-form-field>
					<mat-form-field color="primary" appearance="fill">
						<mat-label>Max y axis</mat-label>
						<input matInput type="number" #y2_sp_ttm>
					</mat-form-field>
					<button mat-raised-button color="primary"
									*ngIf="x1_sp_ttm.value && x2_sp_ttm.value && y1_sp_ttm.value && y2_sp_ttm.value"
									matSuffix mat-icon-button (click)="modifyChart(sp_ttm_chart,
           [x1_sp_ttm.value, x2_sp_ttm.value], [y1_sp_ttm.value, y2_sp_ttm.value])">
						<mat-icon>send</mat-icon>
					</button>
				</mat-card>
-->
			</mat-card>
			<!--Calibration success [2][3]-->
			<mat-card class="sampling-point-chart" *ngIf="checkForPermission()">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					Calibration success
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('Sampling point Calibration Success Chart')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="sp_calibSuccess_chart && showSamplingPointChart[2]">
					<plotly-plot
						[data]="sp_calibSuccess_chart.chartData"
						[layout]="sp_calibSuccess_chart.chartLayout"
						[config]="sp_calibSuccess_chart.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!sp_calibSuccess_chart || !showSamplingPointChart[2]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showSamplingPointChart[2]= !showSamplingPointChart[2]">
						<mat-icon *ngIf="showSamplingPointChart[2]">remove</mat-icon>
						<mat-icon *ngIf="!showSamplingPointChart[2]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv" (click)="exportToCsv('Sampling point Calibration Success Chart')">
						<mat-icon>cloud_download</mat-icon>
					</button>

<!--
					<button mat-mini-fab color="primary" (click)="sampling_pointChartSettings[3]=!sampling_pointChartSettings[3]">
						<mat-icon *ngIf="sampling_pointChartSettings[3]">remove</mat-icon>
						<mat-icon *ngIf="!sampling_pointChartSettings[3]">format_list_bulleted</mat-icon>
					</button>
-->
				</mat-card-actions>
<!--
				<mat-card *ngIf="sampling_pointChartSettings[3]">

					<mat-form-field class="number-sample-sel" appearance="fill" [formGroup]="calibrationSuccessForm">
						<mat-label>Number of samples</mat-label>
						<mat-select (selectionChange)="numberOfSampleSamplingPointChanged($event)" formControlName="numberOfSample">
							<mat-option *ngFor="let num of numberOfSample" [value]="num">
								{{num}}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill" [formGroup]="calibrationSuccessForm">
						<mat-label>Last sample date</mat-label>
						<input matInput [matDatepicker]="picker2" [max]="maxDate" formControlName="dateFormat">
						<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
						<mat-datepicker #picker2 color="primary"></mat-datepicker>
					</mat-form-field>
					<button mat-raised-button color="primary" (click)="submitCalibrationSuccessSettings()">Submit</button>

					<mat-divider></mat-divider>

					<mat-form-field class="primary divider-setting" appearance="fill">
						<mat-label>X axis range</mat-label>
						<mat-date-range-input [rangePicker]="picker">
							<input matStartDate placeholder="Start date" #x1_sp_calibSuccess>
							<input matEndDate placeholder="End date" #x2_sp_calibSuccess>
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill">
						<mat-label>Min y axis</mat-label>
						<input matInput type="number" #y1_sp_calibSuccess>
					</mat-form-field>
					<mat-form-field color="primary" appearance="fill">
						<mat-label>Max y axis</mat-label>
						<input matInput type="number" #y2_sp_calibSuccess>
					</mat-form-field>
					<button mat-raised-button color="primary"
									*ngIf="x1_sp_calibSuccess.value && x2_sp_calibSuccess.value && y1_sp_calibSuccess.value && y2_sp_calibSuccess.value"
									matSuffix mat-icon-button (click)="modifyChart(sp_calibSuccess_chart,
           [x1_sp_calibSuccess.value, x2_sp_calibSuccess.value], [y1_sp_calibSuccess.value, y2_sp_calibSuccess.value])">
						<mat-icon>send</mat-icon>
					</button>
				</mat-card>
-->
			</mat-card>
			<!--Ug chart [3][4]-->
			<mat-card class="sampling-point-chart">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					UG/mL Chart
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('Sampling point Ug Chart')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="sp_ug_chart && showSamplingPointChart[3]">
					<plotly-plot
						[data]="sp_ug_chart.chartData"
						[layout]="sp_ug_chart.chartLayout"
						[config]="sp_ug_chart.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!sp_ug_chart || !showSamplingPointChart[3]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showSamplingPointChart[3]= !showSamplingPointChart[3]">
						<mat-icon *ngIf="showSamplingPointChart[3]">remove</mat-icon>
						<mat-icon *ngIf="!showSamplingPointChart[3]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv" (click)="exportToCsv('Sampling point Ug Chart')">
						<mat-icon>cloud_download</mat-icon>
					</button>

<!--
					<button mat-mini-fab color="primary" (click)="sampling_pointChartSettings[4]=!sampling_pointChartSettings[4]">
						<mat-icon *ngIf="sampling_pointChartSettings[4]">remove</mat-icon>
						<mat-icon *ngIf="!sampling_pointChartSettings[4]">format_list_bulleted</mat-icon>
					</button>
-->
				</mat-card-actions>
<!--
				<mat-card *ngIf="sampling_pointChartSettings[4]">

					<mat-form-field class="number-sample-sel" appearance="fill" [formGroup]="ugForm">
						<mat-label>Number of samples</mat-label>
						<mat-select (selectionChange)="numberOfSampleSamplingPointChanged($event)" formControlName="numberOfSample">
							<mat-option *ngFor="let num of numberOfSample" [value]="num">
								{{num}}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill" [formGroup]="ugForm">
						<mat-label>Last sample date</mat-label>
						<input matInput [matDatepicker]="picker2" [max]="maxDate" formControlName="dateFormat">
						<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
						<mat-datepicker #picker2 color="primary"></mat-datepicker>
					</mat-form-field>
					<button mat-raised-button color="primary" (click)="submitUgSettings()">Submit</button>

					<mat-divider></mat-divider>

					<mat-form-field class="primary divider-setting" appearance="fill">
						<mat-label>X axis range</mat-label>
						<mat-date-range-input [rangePicker]="picker">
							<input matStartDate placeholder="Start date" #x1_sp_ug>
							<input matEndDate placeholder="End date" #x2_sp_ug>
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill">
						<mat-label>Min y axis</mat-label>
						<input matInput type="number" #y1_sp_ug>
					</mat-form-field>
					<mat-form-field color="primary" appearance="fill">
						<mat-label>Max y axis</mat-label>
						<input matInput type="number" #y2_sp_ug>
					</mat-form-field>
					<button mat-raised-button color="primary"
									*ngIf="x1_sp_ug.value && x2_sp_ug.value && y1_sp_ug.value && y2_sp_ug.value"
									matSuffix mat-icon-button (click)="modifyChart(sp_ug_chart,
           [x1_sp_ug.value, x2_sp_ug.value], [y1_sp_ug.value, y2_sp_ug.value])">
						<mat-icon>send</mat-icon>
					</button>
				</mat-card>
-->
			</mat-card>
			<!--History of sampling-point calibration success[4][0]-->
			<mat-card class="sampling-point-chart" *ngIf="checkForPermission()">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					<!--Historique du succès de calibration de la machine-->
					History of sampling point calibration success
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('Sampling point Calibration Success History Chart')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="sp_calibSuccessHistory_chart && showSamplingPointChart[4]">
					<plotly-plot
						[data]="sp_calibSuccessHistory_chart.chartData"
						[layout]="sp_calibSuccessHistory_chart.chartLayout"
						[config]="sp_calibSuccessHistory_chart.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!sp_calibSuccessHistory_chart || !showSamplingPointChart[4]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showSamplingPointChart[4]= !showSamplingPointChart[4]">
						<mat-icon *ngIf="showSamplingPointChart[4]">remove</mat-icon>
						<mat-icon *ngIf="!showSamplingPointChart[4]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv"
									(click)="exportToCsv('Sampling point Calibration Success History Chart')">
						<mat-icon>cloud_download</mat-icon>
					</button>

<!--
					<button mat-mini-fab color="primary" (click)="sampling_pointChartSettings[0]=!sampling_pointChartSettings[0]">
						<mat-icon *ngIf="sampling_pointChartSettings[0]">remove</mat-icon>
						<mat-icon *ngIf="!sampling_pointChartSettings[0]">format_list_bulleted</mat-icon>
					</button>
-->
				</mat-card-actions>

<!--
				<mat-card class="card" *ngIf="sampling_pointChartSettings[0]">
					<mat-form-field class="number-sample-sel" appearance="fill" [formGroup]="sampling_pointCalSuccessHistoryForm">
						<mat-label>Number of samples</mat-label>
						<mat-select (selectionChange)="numberOfSampleSamplingPointChanged($event)" formControlName="numberOfSample">
							<mat-option *ngFor="let num of numberOfSample" [value]="num">
								{{num}}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill" [formGroup]="sampling_pointCalSuccessHistoryForm">
						<mat-label>Last sample date</mat-label>
						<input matInput [matDatepicker]="picker2" [max]="maxDate" formControlName="dateFormat">
						<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
						<mat-datepicker #picker2 color="primary"></mat-datepicker>
					</mat-form-field>
					<button mat-raised-button color="primary" (click)="submitSamplingPointCalSuccessHistorySettings()">Submit</button>

					<mat-divider></mat-divider>

					<mat-form-field class="primary divider-setting" appearance="fill">
						<mat-label>X axis range</mat-label>
						<mat-date-range-input [rangePicker]="picker">
							<input matStartDate placeholder="Start date" #x1_sp_calibSuccessHistory>
							<input matEndDate placeholder="End date" #x2_sp_calibSuccessHistory>
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill">
						<mat-label>Min y axis</mat-label>
						<input matInput type="number" #y1_sp_calibSuccessHistory>
					</mat-form-field>
					<mat-form-field color="primary" appearance="fill">
						<mat-label>Max y axis</mat-label>
						<input matInput type="number" #y2_sp_calibSuccessHistory>
					</mat-form-field>
					<button mat-raised-button color="primary"
									*ngIf="x1_sp_calibSuccessHistory.value && x2_sp_calibSuccessHistory.value && y1_sp_calibSuccessHistory.value && y2_sp_calibSuccessHistory.value"
									matSuffix mat-icon-button (click)="modifyChart(sp_calibSuccessHistory_chart,
           [x1_sp_calibSuccessHistory.value, x2_sp_calibSuccessHistory.value], [y1_sp_calibSuccessHistory.value, y2_sp_calibSuccessHistory.value])">
						<mat-icon>send</mat-icon>
					</button>
				</mat-card>
-->
			</mat-card>
			<!--Scope (C1-C2) [5][5]-->
			<mat-card class="sampling-point-chart" *ngIf="checkForPermission()">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					Scope (C1-C2)
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('Sampling point C1-C2 Chart')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="sp_c1minc2_chart && showSamplingPointChart[5]">
					<plotly-plot
						[data]="sp_c1minc2_chart.chartData"
						[layout]="sp_c1minc2_chart.chartLayout"
						[config]="sp_c1minc2_chart.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!sp_c1minc2_chart || !showSamplingPointChart[5]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showSamplingPointChart[5]= !showSamplingPointChart[5]">
						<mat-icon *ngIf="showSamplingPointChart[5]">remove</mat-icon>
						<mat-icon *ngIf="!showSamplingPointChart[5]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv"
									(click)="exportToCsv('Sampling point C1-C2 Chart')">
						<mat-icon>cloud_download</mat-icon>
					</button>

<!--
					<button mat-mini-fab color="primary" (click)="sampling_pointChartSettings[5]=!sampling_pointChartSettings[5]">
						<mat-icon *ngIf="sampling_pointChartSettings[5]">remove</mat-icon>
						<mat-icon *ngIf="!sampling_pointChartSettings[5]">format_list_bulleted</mat-icon>
					</button>
-->
				</mat-card-actions>

<!--
				<mat-card class="card" *ngIf="sampling_pointChartSettings[5]">
					<mat-form-field class="number-sample-sel" appearance="fill" [formGroup]="c1minC2Form">
						<mat-label>Number of samples</mat-label>
						<mat-select (selectionChange)="numberOfSampleSamplingPointChanged($event)" formControlName="numberOfSample">
							<mat-option *ngFor="let num of numberOfSample" [value]="num">
								{{num}}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill" [formGroup]="c1minC2Form">
						<mat-label>Last sample date</mat-label>
						<input matInput [matDatepicker]="picker2" [max]="maxDate" formControlName="dateFormat">
						<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
						<mat-datepicker #picker2 color="primary"></mat-datepicker>
					</mat-form-field>
					<button mat-raised-button color="primary" (click)="submitC1minC2Settings()">Submit</button>

					<mat-divider></mat-divider>

					<mat-form-field class="primary divider-setting" appearance="fill">
						<mat-label>X axis range</mat-label>
						<mat-date-range-input [rangePicker]="picker">
							<input matStartDate placeholder="Start date" #x1_sp_c1minc2>
							<input matEndDate placeholder="End date" #x2_sp_c1minc2>
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill">
						<mat-label>Min y axis</mat-label>
						<input matInput type="number" #y1_sp_c1minc2>
					</mat-form-field>
					<mat-form-field color="primary" appearance="fill">
						<mat-label>Max y axis</mat-label>
						<input matInput type="number" #y2_sp_c1minc2>
					</mat-form-field>
					<button mat-raised-button color="primary"
									*ngIf="x1_sp_c1minc2.value && x2_sp_c1minc2.value && y1_sp_c1minc2.value && y2_sp_c1minc2.value"
									matSuffix mat-icon-button (click)="modifyChart(sp_c1minc2_chart,
           [x1_sp_c1minc2.value, x2_sp_c1minc2.value], [y1_sp_c1minc2.value, y2_sp_c1minc2.value])">
						<mat-icon>send</mat-icon>
					</button>
				</mat-card>
-->
			</mat-card>
			<!--Ttm variation [6][6]-->
			<mat-card class="sampling-point-chart"  *ngIf="checkForPermission()">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					TTm variation
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('Sampling point TTm Variation Chart')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="sp_ttm_variation_chart && showSamplingPointChart[6]">
					<plotly-plot
						[data]="sp_ttm_variation_chart.chartData"
						[layout]="sp_ttm_variation_chart.chartLayout"
						[config]="sp_ttm_variation_chart.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!sp_ttm_variation_chart || !showSamplingPointChart[6]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showSamplingPointChart[6]= !showSamplingPointChart[6]">
						<mat-icon *ngIf="showSamplingPointChart[6]">remove</mat-icon>
						<mat-icon *ngIf="!showSamplingPointChart[6]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv"
									(click)="exportToCsv('Sampling point TTm Variation Chart')">
						<mat-icon>cloud_download</mat-icon>
					</button>

<!--
					<button mat-mini-fab color="primary" (click)="sampling_pointChartSettings[6]=!sampling_pointChartSettings[6]">
						<mat-icon *ngIf="sampling_pointChartSettings[6]">remove</mat-icon>
						<mat-icon *ngIf="!sampling_pointChartSettings[6]">format_list_bulleted</mat-icon>
					</button>
-->
				</mat-card-actions>

<!--
				<mat-card class="card" *ngIf="sampling_pointChartSettings[6]">
					<mat-form-field class="number-sample-sel" appearance="fill" [formGroup]="ttmVariationForm">
						<mat-label>Number of samples</mat-label>
						<mat-select (selectionChange)="numberOfSampleSamplingPointChanged($event)" formControlName="numberOfSample">
							<mat-option *ngFor="let num of numberOfSample" [value]="num">
								{{num}}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill" [formGroup]="ttmVariationForm">
						<mat-label>Last sample date</mat-label>
						<input matInput [matDatepicker]="picker2" [max]="maxDate" formControlName="dateFormat">
						<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
						<mat-datepicker #picker2 color="primary"></mat-datepicker>
					</mat-form-field>
					<button mat-raised-button color="primary" (click)="submitTTmVariationSettings()">Submit</button>

					<mat-divider></mat-divider>

					<mat-form-field class="primary divider-setting" appearance="fill">
						<mat-label>X axis range</mat-label>
						<mat-date-range-input [rangePicker]="picker">
							<input matStartDate placeholder="Start date" #x1_sp_ttm_variation>
							<input matEndDate placeholder="End date" #x2_sp_ttm_variation>
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill">
						<mat-label>Min y axis</mat-label>
						<input matInput type="number" #y1_sp_ttm_variation>
					</mat-form-field>
					<mat-form-field color="primary" appearance="fill">
						<mat-label>Max y axis</mat-label>
						<input matInput type="number" #y2_sp_ttm_variation>
					</mat-form-field>
					<button mat-raised-button color="primary"
									*ngIf="x1_sp_ttm_variation.value && x2_sp_ttm_variation.value && y1_sp_ttm_variation.value && y2_sp_ttm_variation.value"
									matSuffix mat-icon-button (click)="modifyChart(sp_ttm_variation_chart,
           [x1_sp_ttm_variation.value, x2_sp_ttm_variation.value], [y1_sp_ttm_variation.value, y2_sp_ttm_variation.value])">
						<mat-icon>send</mat-icon>
					</button>
				</mat-card>
-->
			</mat-card>

		</mat-card>
	</mat-card-content>
</mat-card>

<mat-divider></mat-divider>
<!--TABLE-->
<mat-card class="sampling-point-table">
	<mat-card-title fxLayoutAlign="space-between">
		<!--Historique des analyses du sampling point {{machine?.machine_tag}}-->
		Sampling point analysis history {{sampling_points?.sampling_name}}
		<button mat-mini-fab color="basic" (click)="showAnalysisHistory=!showAnalysisHistory">
			<mat-icon *ngIf="showAnalysisHistory">remove</mat-icon>
			<mat-icon *ngIf="!showAnalysisHistory">add</mat-icon>
		</button>
	</mat-card-title>
	<mat-form-field appearance="standard">
		<mat-label>Filter</mat-label>
		<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Ex. machine tag" #input>
	</mat-form-field>
	<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

	<table mat-table *ngIf="detailedAnalysisHistory && showAnalysisHistory" [dataSource]="dataSource"
				 class="card-right-body-table" matSort (matSortChange)="sortData($event)">

		<ng-container matColumnDef="machine_tag">
			<th mat-header-cell *matHeaderCellDef> Machine tag</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="!element.machine_tag?{'background-color': '#EEEEEE'}:{'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.machine_tag; else defaultValue">
					<a [routerLink]="['/dashboards/machines', element.machine_tag ]" class="analysis_id_ref">
						<button mat-button>
							{{element.machine_tag}}
						</button>
					</a>
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="analysis_id">
			<th mat-header-cell *matHeaderCellDef> Analysis id</th>
			<td mat-cell *matCellDef="let element; let i=index" [ngStyle]="
              !element.analysis_id?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.analysis_id; else defaultValue">
					<a [routerLink]="['/dashboards/analysis', element.analysis_id ]" class="analysis_id_ref">
						<button mat-button [disabled]="!checkForPermission()" [ngStyle]="
            !element.analysis_id?{'background-color': '#EEEEEE'}:
            element.missing_variable && element.missing_variable.length > 3?{'color':'#E0242F', 'background-color': '#EEEEEE'} :
            {'background-color': '#FFFFFF'}">
							{{element.analysis_id}}
						</button>
					</a>

				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="sampling_time">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="sampling_time"> Time of sample</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.sampling_time?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.sampling_time; else defaultValue">
					{{element.sampling_time | date:'yyyy-MM-dd HH:mm'}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="inlet_num">
			<th mat-header-cell *matHeaderCellDef> Inlet number</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.machine_inlet_number?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.machine_inlet_number; else defaultValue">
					{{element.machine_inlet_number.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="cartouchesdisponibles">
			<th mat-header-cell *matHeaderCellDef> Cartridges available</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
          !element.cart_available?{'background-color': '#EEEEEE'}:
          element.cart_available.cl_level==0?{'background-color': '#FFFFFF'}:
          element.cart_available.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
          element.cart_available.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
          {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.cart_available; else defaultValue">
					{{element.cart_available.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="lotc01">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="lotc01"> Batch</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.cart_batch_number?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.cart_batch_number; else defaultValue">
					{{element.cart_batch_number.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="ug">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="ug"> UG/mL</th>
			<td mat-cell *matCellDef="let element; let i=index" [ngStyle]="
            !element.result_value_ug_rounded?{'background-color': '#EEEEEE'}:
            element.result_value_ug_rounded.cl_level==0?{'background-color': '#FFFFFF'}:
            element.result_value_ug_rounded.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
            element.result_value_ug_rounded.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
            {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.result_value_ug_rounded; else defaultValue">
          <span
						*ngIf="element.mrv_relation_symbol">{{convertASCIItoChar(element.mrv_relation_symbol.value)}}</span> {{element.result_value_ug_rounded.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="ug*">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="ug*"> UG*/mL</th>
			<td mat-cell *matCellDef="let element; let i=index" [ngStyle]="
            !element.result_ug_optimized_rounded ?{'background-color': '#EEEEEE'}:
            element.result_ug_optimized_rounded.cl_level==0 || element.result_ug_optimized_rounded.value == null?{'background-color': '#FFFFFF'}:
            element.result_ug_optimized_rounded.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
            element.result_ug_optimized_rounded.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
            {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.result_ug_optimized_rounded ; else defaultValue">
					{{element.result_value_ug_optimized.value < element.result_vmr.value  && element.result_ug_optimized_rounded.value != null?'< ':''}} {{element.result_ug_optimized_rounded.value == Null?'-1':element.result_ug_optimized_rounded.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="ttm">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="ttm"> TTm</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_ttm?{'background-color': '#EEEEEE'}:
              element.result_ttm.cl_level==0?{'background-color': '#FFFFFF'}:
              element.result_ttm.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
              element.result_ttm.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.result_ttm; else defaultValue">
					{{element.result_ttm.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="vmr">
			<th mat-header-cell *matHeaderCellDef> VMR</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_vrm?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.result_vrm; else defaultValue">
					{{element.result_vrm.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="c1">
			<th mat-header-cell *matHeaderCellDef> C1</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_tt1?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.result_tt1; else defaultValue">
					{{element.result_tt1.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="v1">
			<th mat-header-cell *matHeaderCellDef> V1</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_tt2?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.result_tt2; else defaultValue">
					{{element.result_tt2.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="v2">
			<th mat-header-cell *matHeaderCellDef> V2</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_tt3?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.result_tt3; else defaultValue">
					{{element.result_tt3.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="c2">
			<th mat-header-cell *matHeaderCellDef> C2</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_tt4?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.result_tt4; else defaultValue">
					{{element.result_tt4.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="c1MinusC2">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="c1MinusC2"> C1-C2</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_delta_c1_c2?{'background-color': '#EEEEEE'}:
              element.result_delta_c1_c2.cl_level==0?{'background-color': '#FFFFFF'}:
              element.result_delta_c1_c2.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
              element.result_delta_c1_c2.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.result_delta_c1_c2; else defaultValue">
					{{element.result_delta_c1_c2.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="v1MinusV2">
			<th mat-header-cell *matHeaderCellDef> V1-V2</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_delta_v1_v2?{'background-color': '#EEEEEE'}:
              element.result_delta_v1_v2.cl_level==0?{'background-color': '#FFFFFF'}:
              element.result_delta_v1_v2.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
              element.result_delta_v1_v2.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.result_delta_v1_v2; else defaultValue">
					{{element.result_delta_v1_v2.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="calibration_success">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="calibration_success"> Calibration Success</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_calib_success?{'background-color': '#EEEEEE'}:
              element.result_calib_success.cl_level==0?{'background-color': '#FFFFFF'}:
              element.result_calib_success.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
              element.result_calib_success.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.result_calib_success; else defaultValue">
					{{element.result_calib_success.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="quality_score">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="quality_score"> Quality score</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.quality_score?{'background-color': '#EEEEEE'}:
              element.quality_score.value <= 50?{'background-color':'#E0242F'}:
              element.quality_score.value <= 75?{'background-color':'#FFA500'}:
              element.quality_score.value <= 85?{'background-color':'#F9D054'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.quality_score; else defaultValue">
					{{element.quality_score.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="error_code">
			<th mat-header-cell *matHeaderCellDef> Error</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_error_code?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.result_error_code; else defaultValue">
					<button mat-raised-button
									matTooltip="{{element.error_desc}}"
									[matTooltipPosition]="'left'">
						{{element.result_error_code.value}}
					</button>
				</ng-container>

			</td>
		</ng-container>

		<ng-template #defaultValue>No Data</ng-template>
		<mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
	</table>

	<mat-paginator [length]="sampling_point_nb_analysis" [pageSize]="10"
								 [pageSizeOptions]="[5, 10, 15, 20]"></mat-paginator>
</mat-card>

</body>
