import {Component, OnInit} from '@angular/core';
import {DashboardMachineService} from '../dashboard-machine.service';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MdLegendDialogComponent} from '../md-legend-dialog/md-legend-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import * as _ from 'lodash';
import {NavbarService} from "../../header/navbar.service";

@Component({
	selector: 'app-machine-list',
	templateUrl: './machine-list.component.html',
	styleUrls: ['./machine-list.component.css'],
})
export class MachineListComponent implements OnInit {

	isLoading: boolean = true;
	standbyMachineLoading: boolean = true;
	inactiveMachineLoading: boolean = true;
	allAnalysisHistoryStandbyLoading: boolean = true;
	allAnalysisHistoryInactiveLoading: boolean = true;

	ascIdMachine: boolean = true;
	orderedByIdMachine: boolean = true;
	ascDaysWithoutAnalysis: boolean = false;
	orderedByDaysWithoutAnalysis: boolean = false;
	ascCartDisp: boolean = false;
	orderedByCartDisp: boolean = false;
	ascLast5AnalysisSuccess: boolean = false;
	orderedByLast5AnalysisSuccess: boolean = false;

	dynamicForm: FormGroup;

	numberOfSample: number[] = [1, 3, 5, 10, 20];
	currentNumberofSample: number;

	displayedColumns: string[] = [];

	standbyMachines: any[];
	inactiveMachines: any[];

	showStandbyMachine: boolean[] = [];
	showInactiveMachine: boolean[] = [];

	constructor(
		private service: DashboardMachineService,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		public dialog: MatDialog,
		public nav: NavbarService,
	) {
	}

	ngOnInit() {

		if (this.nav._role === 'admin' || this.nav._role === 'super-admin') {
			this.displayedColumns = ['samplingpoint_name', 'analysis_id', 'sampling_time', 'analysis_time', 'inlet_num', 'cartouchesdisponibles', 'lotc01', 'ug', 'ttm', 'vmr', 'c1', 'v1', 'v2', 'c2', 'c1MinusC2', 'v1MinusV2', 'calibration_success', 'error_code'];
		} else {
			this.displayedColumns = ['samplingpoint_name', 'analysis_id', 'sampling_time', 'analysis_time', 'inlet_num', 'cartouchesdisponibles', 'lotc01', 'ug'];
		}

		this.currentNumberofSample = 3;
		this.dynamicForm = this.formBuilder.group({
			numberOfSample: [this.currentNumberofSample, [Validators.required]],
		});

		this.service.getActiveMachines().subscribe(machines => {
			this.standbyMachines = machines;
			for (let i = 0; i < this.standbyMachines.length; i++) {
				this.showStandbyMachine[i] = false;
			}
			this.loadDashboardFlags(this.standbyMachines).then(() => this.standbyMachineLoading = false);
		});

		this.service.getInactiveMachines().subscribe(machines => {
			this.inactiveMachines = machines;
			for (let i = 0; i < this.inactiveMachines.length; i++) {
				this.showInactiveMachine[i] = false;
			}
			this.loadDashboardFlags(this.inactiveMachines).then(() => this.inactiveMachineLoading = false);
		});
	}

	async loadDashboardFlags(machines: any[]) {
		const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

		for (const machine of machines) {

			let daysWithoutAnalysis = null;
			const lastAnalysisTime = await this.service.getLastAnalysisDate(machine.machine_id);

			const nextMaintenance = await this.service.getNextMaintenanceDate(machine.machine_id, machine.last_maintenance, machine.nb_analysis_before_maintenance);

			if (lastAnalysisTime != null) {
				const firstDate = new Date(lastAnalysisTime.analysis_time);
				daysWithoutAnalysis = Math.round(Math.abs((firstDate.getTime() - new Date().getTime()) / oneDay));
			}

			let flags = await this.service.getArrayOfLatestVariableWithClLevel(machine.machine_id, ['cart_available', 'calib_success_machine_5', 'result_value_ug_rounded']);
			let status =await this.service.getMachinesStatus(machine.machine_id)
			machine.flags = {};
			machine.flags.status = status;
			machine.flags.days_without_analysis = daysWithoutAnalysis;
			machine.flags.cartouches_disponibles = flags.cart_available;
			machine.flags.last_5_analysis_success = flags.calib_success_machine_5;
			machine.flags.ug = flags.result_value_ug_rounded;
			machine.flags.daysNextMaintenance = nextMaintenance.daysNextMaintenance;
			machine.flags.nextMaintenanceDate = nextMaintenance.nextMaintenanceDate;
			machine.flagsLoaded = true;

		}
	}

	async loadDashboardAnalysisHistory(machine: any) {
		let tmp = [];
		machine.detailedAnalysisHistoryIsLoaded = false;

		const analysisIds = await this.service.getMachineAnalysisIdHistory(machine.machine_id, this.currentNumberofSample, 0);

		for (const analysisId of analysisIds) {
			let detailedAnalysis = await this.service.getMachineAnalysisFullDetail(analysisId.analysis_id);
			if (detailedAnalysis.result_error_code && detailedAnalysis.result_error_code.value !== 0) {
				detailedAnalysis.error_desc = (await this.service.getErrorCodeDefinition(detailedAnalysis.result_error_code.value)).message;
			}
			detailedAnalysis.missing_variable = await this.service.getAnalysisMissingVariables(detailedAnalysis.analysis_id);
			tmp.push(detailedAnalysis);

		}

		machine.detailedAnalysisHistory = tmp;
		machine.detailedAnalysisHistoryIsLoaded = true;
	}

	orderByMachineTag() {
		if (this.ascIdMachine) {
			this.standbyMachines = _.orderBy(this.standbyMachines, ['machine_id'], ['desc']);
		} else {
			this.standbyMachines = _.orderBy(this.standbyMachines, ['machine_id'], ['asc']);
		}
		this.ascIdMachine = !this.ascIdMachine;

		this.orderedByIdMachine = true;
		this.orderedByDaysWithoutAnalysis = false;
		this.orderedByCartDisp = false;
		this.orderedByLast5AnalysisSuccess = false;
	}

	orderByDaysWithoutAnalysis() {

		if (this.ascDaysWithoutAnalysis) {
			this.standbyMachines = _.orderBy(this.standbyMachines, ['flags.days_without_analysis', 'machine_tag'], ['desc', 'asc']);
		} else {
			this.standbyMachines = _.orderBy(this.standbyMachines, ['flags.days_without_analysis', 'machine_tag'], ['asc', 'asc']);
		}
		this.ascDaysWithoutAnalysis = !this.ascDaysWithoutAnalysis;

		this.orderedByIdMachine = false;
		this.orderedByDaysWithoutAnalysis = true;
		this.orderedByCartDisp = false;
		this.orderedByLast5AnalysisSuccess = false;
	}

	orderByCartDisp() {

		if (this.ascCartDisp) {
			this.standbyMachines = _.orderBy(this.standbyMachines, ['flags.cartouches_disponibles.value', 'machine_tag'], ['desc', 'asc']);
		} else {
			this.standbyMachines = _.orderBy(this.standbyMachines, ['flags.cartouches_disponibles.value', 'machine_tag'], ['asc', 'asc']);
		}
		this.ascCartDisp = !this.ascCartDisp;

		this.orderedByIdMachine = false;
		this.orderedByDaysWithoutAnalysis = false;
		this.orderedByCartDisp = true;
		this.orderedByLast5AnalysisSuccess = false;
	}

	orderBy5LastCalibSuccess() {

		if (this.ascLast5AnalysisSuccess) {
			this.standbyMachines = _.orderBy(this.standbyMachines, ['flags.last_5_analysis_success.value', 'machine_tag'], ['desc', 'asc']);
		} else {
			this.standbyMachines = _.orderBy(this.standbyMachines, ['flags.last_5_analysis_success.value', 'machine_tag'], ['asc', 'asc']);
		}
		this.ascLast5AnalysisSuccess = !this.ascLast5AnalysisSuccess;

		this.orderedByIdMachine = false;
		this.orderedByDaysWithoutAnalysis = false;
		this.orderedByCartDisp = false;
		this.orderedByLast5AnalysisSuccess = true;
	}

	numberOfSampleChanged($event: any) {
		this.currentNumberofSample = $event.value;
	}

	checkForPermission(): boolean{
		if (this.nav._role === 'admin' || this.nav._role === 'super-admin'){
			return true;
		}else {
			return false;
		}
	}

	openLegend(): void {
		this.dialog.open(MdLegendDialogComponent, {
			width: '80%',
			height: '80%'
		});
	}

	convertASCIItoChar(code: number) {
		if (code) {
			return String.fromCharCode(code);
		} else {
			return '';
		}
	}
}
