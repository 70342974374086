import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AdminPanelService} from '../admin-panel.service';
import {EditApprovalDialogComponent} from '../edit-approval-dialog/edit-approval-dialog.component';
import * as _ from 'lodash';
import {Subject} from 'rxjs';
import {User} from '../../Models/User';
import {NavbarService} from "../../header/navbar.service";

@Component({
	selector: 'app-user-settings',
	templateUrl: './user-settings.component.html',
	styleUrls: ['./user-settings.component.css']
})

export class UserSettingsComponent implements OnInit {

	userForm: FormGroup;
	permissionForm: FormGroup;
	formState: String = '';

	userList: any[] = [];
	possibleRoles: any[] = [];
	possibleClients: any[] = [];
	oldUser: any;
	claims: any[];

	currentClaim: string;

	isLoading: boolean;
	selected?: string = undefined;

	protected _onDestroy = new Subject<void>();

	constructor(private service: AdminPanelService, public dialog: MatDialog, private snackbar: MatSnackBar,
				         public formBuilder: FormBuilder, public nav: NavbarService) {
	}

	async ngOnInit() {
		if (this.nav._role === 'user-admin'){
			this.claims = ['user', 'user-admin'];
		}else{
			this.claims = ['user', 'user-admin', 'admin', 'super-admin'];
		}
		this.createUserForm();

		this.initForms();
	}

	initForms() {
		this.isLoading = true;

		this.service.getUsers().subscribe(users => {
			this.userList = users;
			for (const u of users){
				this.service.getUserRole(u.user_id).subscribe(role => {
					u.user_roleName = role.role_name;
				});
			}
			this.isLoading = false;
		});
	}

	private createUserForm() {
		this.userForm = this.formBuilder.group({
			userName: [null, [Validators.required, Validators.pattern('([a-zA-Z]+(([\',. -][a-zA-Z ])?[a-zA-Z]*)*)')]],
			userEmail: [null, [Validators.required, Validators.email]],
			userPassword: [null, [Validators.required, Validators.minLength(6)]],
			userClient: [null, [Validators.required]],
			userRole: [null, [Validators.required]],
			userClaim: [null, [Validators.required]]
		});

		this.service.getBusinesses().subscribe(data => {
			this.possibleClients = _.orderBy(data, 'business_tag', 'asc');
			this.userForm.get('userRole').disable();
		}, error => {
			this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
			this.isLoading = false;
		});
	}

	userClientChanged($event: any) {
		this.service.getRolesByBusinessId($event.value.business_id).subscribe(data => {
			this.possibleRoles = _.orderBy(data, 'role_name', 'asc');
		}, error => {
			this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
			this.isLoading = false;
		});

		this.userForm.get('userRole').reset();
		this.userForm.get('userRole').enable();
	}

	userRoleChanged($event: any) {
	}

	updateUserForm(user: any){
		const client = this.possibleClients.find(x => x.business_id === this.oldUser.user_business);
		const role = this.possibleRoles.find(x => x.role_id === this.oldUser.user_role);

		this.userForm = this.formBuilder.group({
			userName: [this.oldUser.user_name, [Validators.required, Validators.pattern('([a-zA-Z]+(([\',. -][a-zA-Z ])?[a-zA-Z]*)*)')]],
			userEmail: [this.oldUser.user_email, [Validators.required, Validators.email]],
			userClient: [client, [Validators.required]],
			userRole: [role, [Validators.required]],
			userClaim: [this.currentClaim, [Validators.required]]
		});
	}

	setFormUpdateUser(user: any) {
		this.formState = 'update';
		this.oldUser = user;

		this.service.getRolesByBusinessId(user.user_business).subscribe(data => {
			this.possibleRoles = _.orderBy(data, 'role_name', 'asc');
			this.service.getClaims(user.user_id).subscribe(res => {
				this.currentClaim = res.role;
				this.updateUserForm(user);
			});
		}, error => {
			this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
			this.isLoading = false;
		});

		this.userForm.get('userRole').enable();
	}

	SetFormCreateUser() {
		this.formState = 'create';

		this.createUserForm();

		this.userForm.markAllAsTouched();
	}

	async createUser() {
		const user = new User(null, this.userForm.get('userEmail').value,
			this.userForm.get('userName').value, this.userForm.get('userPassword').value , this.userForm.get('userClient').value.business_id,
			this.userForm.get('userRole').value.role_id, null, null);

		const pos = this.userList.findIndex(x => x.user_email === user.user_email);

		if (pos === -1) {
			this.isLoading = true;
			try {
				this.service.createUser(user).subscribe(resp => {
					this.service.updateClaims(resp.message, this.userForm.get('userClaim').value).subscribe(res => {
						this.formState = '';
						this.resetForms();
					});
				});

			} catch (error) {
				this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok');
			}
			this.isLoading = false;
		} else {
			this.snackbar.open('This user already exist', 'Ok');
		}
	}

	async updateUser() {
		const dialogRef = this.dialog.open(EditApprovalDialogComponent, {
			width: '60%'
		});
		dialogRef.afterClosed().subscribe(data => {
			if (data) {
				const user = new User(this.oldUser.user_id, this.userForm.get('userEmail').value,
					this.userForm.get('userName').value, null, this.userForm.get('userClient').value.business_id,
					this.userForm.get('userRole').value.role_id, null, this.oldUser.access_level);
				this.service.updateUser(user).subscribe(resp => {
					this.service.updateClaims(user.user_id, this.userForm.get('userClaim').value).subscribe(res => {
						this.formState = '';
						this.resetForms();
						this.snackbar.open('User successfully updated.', 'Ok', {
							duration: 3000
						});
					});
				}, error => {
					this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
						duration: 5000
					});
				});
				this.oldUser = user;
			}
		});
	}

	deleteUser() {
		const dialogRef = this.dialog.open(EditApprovalDialogComponent, {
			width: '60%'
		});
		dialogRef.afterClosed().subscribe(data => {
			if (data) {
				this.service.deleteUser(this.oldUser.user_id).subscribe(resp => {
					this.formState = '';
					this.oldUser = null;
					this.resetForms();
					this.snackbar.open('User successfully deleted.', 'Ok', {
						duration: 3000
					});
				}, error => {
					this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
						duration: 5000
					});
				});
			}
		});
	}

	private resetForms() {
		this.userList = [];

		this.initForms();

		this.userForm.markAllAsTouched();
	}

	restoreFormValue() {
		this.setFormUpdateUser(this.oldUser);
	}

	cancel() {
		this.userForm.reset();
	}

	openSnackBar(message, action) {
		this.snackbar.open(message, action, {duration: 5000});
	}

	onSelected(item: string) {
		if (item === this.selected){
			this.selected = null;
		}
		else{
			this.selected = item;
		}
	}
}
