import {Component, OnInit, ViewChild} from '@angular/core';
import {MdLegendDialogComponent} from '../../dashboard-machine/md-legend-dialog/md-legend-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {DashboardSamplingPointService} from '../dashboard-sampling-point.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NavbarService} from '../../header/navbar.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as _ from 'lodash';
import {takeUntil} from "rxjs/operators";
import {ReplaySubject, Subject} from "rxjs";
import {MatSelect} from "@angular/material/select";

@Component({
  selector: 'app-sampling-point-list',
  templateUrl: './sampling-point-list.component.html',
  styleUrls: ['./sampling-point-list.component.css']
})
export class SamplingPointListComponent implements OnInit {

  samplingPointLoading = true;
  allAnalysisHistoryLoading = true;
	selection = false;

  dynamicForm: FormGroup;

  numberOfSample: number[] = [1, 3, 5, 10, 20];
  currentNumberofSample: number;
  clientTags: string[] = [];
	clients: any[] = [];
  currentClientTag: string = null;
  siteTags: any[] = [];
	sites: any[] = [];
  currentSiteId: number = null;
	currentSiteTag: any[] = null;

  displayedColumns: string[] = [];

  sampling_points: any[];

  showSamplingPoint: boolean[] = [];

	public businessFilterCtrl: FormControl = new FormControl();
	protected _onDestroy = new Subject<void>();
	public filteredBusinesses: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
	@ViewChild('businessSelect', {static: true}) businessSelect: MatSelect;

	public siteFilterCtrl: FormControl = new FormControl();
	public filteredSites: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
	@ViewChild('siteSelect', {static: true}) siteSelect: MatSelect;

  constructor(
      private service: DashboardSamplingPointService,
      private formBuilder: FormBuilder,
      public dialog: MatDialog,
      public nav: NavbarService,
			private snackbar: MatSnackBar
  ) { }

  ngOnInit(): void {

    if (this.nav._role === 'admin' || this.nav._role === 'super-admin') {
      this.displayedColumns = ['machine_tag', 'analysis_id', 'sampling_time', 'inlet_num', 'cartouchesdisponibles', 'lotc01', 'ug', 'ttm', 'vmr', 'c1', 'v1', 'v2', 'c2', 'c1MinusC2', 'v1MinusV2', 'calibration_success', 'error_code'];
    } else {
      this.displayedColumns = ['machine_tag', 'analysis_id', 'sampling_time', 'inlet_num', 'cartouchesdisponibles', 'lotc01', 'ug'];
    }

    this.service.getBusinesses().subscribe(data => {
			this.clients = data;
			this.clientTags = data.map(b => b.business_tag);
			this.filteredBusinesses.next(this.clients.slice());

			this.businessFilterCtrl.valueChanges
				.pipe(takeUntil(this._onDestroy))
				.subscribe(() => {
					this.filterBusinesses();
				});
    }, error => {
			this.snackbar.open('Une erreur est survenue. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
		});

    this.service.getSites().subscribe(tag_list => {
			this.siteTags = tag_list;
			this.filteredSites.next(this.siteTags.slice());
			this.siteFilterCtrl.valueChanges
				.pipe(takeUntil(this._onDestroy))
				.subscribe(() => {
					this.filterSites();
				});
		}, error => {
			this.snackbar.open('Une erreur est survenue. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
		});

    this.currentNumberofSample = 3;
    this.dynamicForm = this.formBuilder.group({
      numberOfSample: [this.currentNumberofSample, [Validators.required]],
      clientTag: [this.currentClientTag],
      siteId: [this.currentSiteId],
    });

    this.service.getSamplingPoints(this.currentClientTag, this.currentSiteId).subscribe(sampling_list => {
      this.sampling_points = sampling_list;
      this.loadDashboardFlags(this.sampling_points).then(() => this.samplingPointLoading = false);
    });
  }

	protected filterBusinesses() {
		if (!this.clients) {
			return;
		}
		let search = this.businessFilterCtrl.value;

		if (!search) {
			this.filteredBusinesses.next(this.clients.slice());
			return;
		} else {
			search = search.toLowerCase();
		}

		this.filteredBusinesses.next(
			this.clients.filter(business => business.business_tag.toLowerCase().indexOf(search) > -1)
		);
	}

	protected filterSites() {
		if (!this.siteTags) {
			return;
		}

		let search = this.siteFilterCtrl.value;
		if (!search) {
			this.filteredSites.next(this.siteTags.slice());
			return;
		} else {
			search = search.toLowerCase();
		}

		this.filteredSites.next(
			this.siteTags.filter(site => site.site_tag.toLowerCase().indexOf(search) > -1)
		);
	}

  async loadDashboardFlags(sampling_points: any[]) {

    for (const sampling of sampling_points) {

      const ttm_flag = await this.service.getSamplingPointLatestVariableWithClLevel(sampling.sp_id, 'result_ttm').catch(() => {
          return null;
      });
      const total_flag = await this.service.getSamplingPointLatestVariableWithClLevel(sampling.sp_id, 'total_filtration_time_s0_s1').catch(() => {
        return null;
      });
      const ug_flag = await this.service.getSamplingPointLatestVariableWithClLevel(sampling.sp_id, 'result_value_ug_rounded').catch(() => {
        return null;
      });

      sampling.flags = {};
      sampling.flags.ttm_result = ttm_flag;
      sampling.flags.total_filtration = total_flag;
      sampling.flags.ug = ug_flag;
      sampling.flagsLoaded = true;
    }
  }

  async loadDashboardAnalysisHistory(sampling: any) {
    const tmp = [];
    sampling.detailedAnalysisHistoryIsLoaded = false;

    const analysisIds = await this.service.getSamplingPointAnalysisIdHistory(sampling.sp_id, this.currentNumberofSample, 0);

    for (const analysisId of analysisIds) {
      const detailedAnalysis = await this.service.getSamplingPointAnalysisFullDetail(analysisId.analysis_id);
      if (detailedAnalysis != null){
        if (detailedAnalysis.result_error_code && detailedAnalysis.result_error_code.value !== 0) {
          detailedAnalysis.error_desc = (await this.service.getErrorCodeDefinition(detailedAnalysis.result_error_code.value)).message;
        }
        detailedAnalysis.missing_variable = await this.service.getAnalysisMissingVariables(detailedAnalysis.analysis_id);
        tmp.push(detailedAnalysis);
      }
      else{
        tmp.push(analysisId);
      }
    }

    sampling.detailedAnalysisHistory = tmp;
    sampling.detailedAnalysisHistoryIsLoaded = true;
  }

  numberOfSampleChanged($event: any) {
    this.currentNumberofSample = $event.value;
  }

  siteTagChanged($event: any) {
    if ($event.value == null){
      this.currentSiteId = null;
    }else{
			this.currentSiteId = $event.value.site_id;
    }
    this.updateDashboard();
  }

	clientSelectedChanged($event: any){
		if ($event.value == null){
			this.service.getSites().subscribe(data => {
				this.siteTags = data;
			});
			this.currentClientTag = null;
		}else {
			this.service.getSitesByBusinessId($event.value.business_id, 1000).subscribe(data => {
				if (data.length !== 0){
					this.siteTags = data;
				}else {
					this.openSnackBar('This client does not contain any sites', 'Ok');
				}
			});
			this.currentClientTag = $event.value.business_tag;
		}
		this.updateDashboard();

	}

	checkForPermission(): boolean{
		if (this.nav._role === 'admin' || this.nav._role === 'super-admin'){
			return true;
		}else {
			return false;
		}
	}


  updateDashboard(): void{
    this.samplingPointLoading = true;
		this.selection = true;
    this.service.getSamplingPoints(this.currentClientTag, this.currentSiteId).subscribe(sampling_list => {
      this.sampling_points = _.orderBy(sampling_list, 'business_tag', 'asc');
      for (let i = 0; i < this.sampling_points.length; i++) {
        this.showSamplingPoint[i] = false;
      }
      this.loadDashboardFlags(this.sampling_points).then(() => this.samplingPointLoading = false);
    });
  }

  openLegend(): void {
    this.dialog.open(MdLegendDialogComponent, {
      width: '80%',
      height: '80%'
    });
  }

  convertASCIItoChar(code: number) {
    if (code) {
      return String.fromCharCode(code);
    } else {
      return '';
    }
  }

	openSnackBar(message, action) {
		this.snackbar.open(message, action, {duration: 5000});
	}
}
