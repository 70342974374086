import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MachineInput} from 'src/app/Models/MachineInput';
import {Modification} from 'src/app/Models/Modification';
import {AdminPanelService} from '../../admin-panel.service';
import * as _ from 'lodash';
import {ReplaySubject, Subject} from "rxjs";
import {MatSelect} from "@angular/material/select";
import {takeUntil} from "rxjs/operators";


@Component({
	selector: 'app-inlet-creator',
	templateUrl: './inlet-creator.component.html',
	styleUrls: ['./inlet-creator.component.css']
})
export class InletCreatorComponent implements OnInit {

	iCreatorForm: FormGroup;

	spList: any[] = [];

	inlet_num: number = null;
	sampling_point: any = null;
	machine_tag: string;
	machine_id: number;

	isLoading: boolean = false;

	public samplingPointFilterCtrl: FormControl = new FormControl();
	public filteredSamplingPoint: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
	@ViewChild('samplingPointSelect', {static: true}) samplingPointSelect: MatSelect;
	protected _onDestroy = new Subject<void>();

	constructor(private service: AdminPanelService, public dialog: MatDialog, private snackbar: MatSnackBar, public dialogRef: MatDialogRef<InletCreatorComponent>, public formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any) {
	}

	ngOnInit(): void {

		this.iCreatorForm = this.formBuilder.group({
			iCreatorRow: this.formBuilder.array([
				this.getICreatorForm()
			])
		});

		this.machine_tag = this.data.machine_tag;
		this.machine_id = this.data.machine_id;

		this.service.getUniqueSamplingPoints().subscribe(sp => {
			this.spList = _.orderBy(sp, 'sp_tag', 'asc');
			this.filteredSamplingPoint.next(this.spList.slice());

			this.samplingPointFilterCtrl.valueChanges
				.pipe(takeUntil(this._onDestroy))
				.subscribe(() => {
					this.filterSamplingPoint();
				});
		});

		this.iCreatorForm.markAllAsTouched();
	}

	getICreatorForm() {
		return this.formBuilder.group({
			inlet_num: ['', [Validators.required, Validators.pattern('[0-9]{1,2}')]],
			sp_name: [this.spList, Validators.required]
		});
	}

	spNameChanged($event: any) {
		if ($event.isUserInput) {
			this.sampling_point = $event.source.value;
		}
	}

	inletNumChanged($event: any) {
		this.inlet_num = $event.target.value;
	}

	private filterSamplingPoint() {
		if (!this.spList) {
			return;
		}

		let search = this.samplingPointFilterCtrl.value;
		if (!search) {
			this.filteredSamplingPoint.next(this.spList.slice());
			return;
		} else {
			search = search.toLowerCase();
		}

		this.filteredSamplingPoint.next(
			this.spList.filter(samplingPoint => samplingPoint.sp_tag.toLowerCase().indexOf(search) > -1)
		);
	}

	async submitAndClose() {
		this.isLoading = true;

		const m_input = new MachineInput(null, this.machine_id, this.inlet_num, this.sampling_point.sp_id);

		try {
			const mi_id = await this.service.insertMachineInlet(m_input);

			const d = [this.inlet_num, this.sampling_point];
			this.dialogRef.close(d);
			this.isLoading = false;
		} catch (error) {
			this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
			this.isLoading = false;
		}
	}

	cancelAndClose() {
		this.dialogRef.close();
	}
}
