import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'appFilter'})

// Lets the user search for a specific column.
export class FilterPipe implements PipeTransform {
	transform(items: any[], searchText: string): any[] {
		if (!items) {
			return [];
		}
		if (!searchText) {
			return items;
		}
		searchText = searchText.toLocaleLowerCase();

		return items.filter(it => {
			return it.var_tag.toLocaleLowerCase().includes(searchText);
		});
	}
}
