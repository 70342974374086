import {FullscreenChartService} from '../../../fullscreen-chart/fullscreen-chart.service';
import {CustomChartData} from '../../CustomChartData';
import {TChart} from '../TChart';

/* Concrete Chart for Factory Pattern */
export class TTurbidityChart implements TChart {

	turbidity: any;
	ugData: any;
	flowUptime: any;
	datas: CustomChartData;

	private config = {
		responsive: true,
		displaylogo: false
	};

	private layout = {
		// items with a `name` attribute in template.images will be added to any
		// plot using this template
		images: [{
			source: '../../../../favicon.ico',
			xref: 'paper',
			yref: 'paper',
			x: 0.5,
			y: 0.5,
			xanchor: 'center',
			yanchor: 'middle',
			sizex: 0.7,
			sizey: 0.7,
			opacity: 0.1,
			layer: 'below'
		}],
		legend: {
			x: 0,
			y: 1.15,
			orientation: 'h',
			itemsizing: 'constant',
			itemwidth: 25
		},
		margin: {
			l: 35,
			r: 35,
			b: 50,
			t: 10
		},
		xaxis: {
			tickformat: '%d %b',
			tickangle: -45,
			showgrid: true,
			nticks: 36,
			showline: true,
			zeroline: false,
			mirror: true,
			hoverformat: '%Y-%m-%d %H:%M:%S'
		},
		yaxis: {
			showgrid: false,
			showline: false,
			showticklabels: false,
			fixedrange: true,
			zeroline: false,
			range: [-0.5, 4.5],
		},
		yaxis2: {
			title: 'UG/mL',
			showgrid: false,
			showline: false,
			zeroline: false,
			side: 'right',
			overlaying: 'y',
		},
		yaxis3: {
			showgrid: true,
			showline: true,
			zeroline: false,
			mirror: true,
			overlaying: 'y',
		},
		modebar: {
			color: '#000000',
			bgcolor: '#FFFFFF'
		}
	};


	constructor(private service: FullscreenChartService) {
	}

	public async createChart(machine_id: number, start: string, end: string): Promise<CustomChartData> {

		this.turbidity = await this.service.getTpiVariableData(machine_id, 56, start, end);
		this.ugData = await this.service.getVariableData(machine_id, 30, start, end);
		this.flowUptime = await this.service.getTpiVariableData(machine_id, 59, start, end);

		this.datas = new CustomChartData([], this.layout, this.config);

		const turbidity_data = [];
		const turbidity_time = [];
		const ug_data = [];
		const ug_time = [];
		const flow_data = [];
		const flow_time = [];

		this.datas = new CustomChartData([], this.layout, this.config);

		for (const turbidity_pts of this.turbidity) {
			if (turbidity_pts.map.value !== null) {
				turbidity_data.push(turbidity_pts.map.value);
				turbidity_time.push(turbidity_pts.map.timestamp);
			}
		}

		for (const ug_pts of this.ugData) {
			if (!ug_pts.empty) {
				ug_data.push(ug_pts.map.value);
				ug_time.push(ug_pts.map.analysis_time);
			}
		}

		for (const flow_pts of this.flowUptime) {
			if (flow_pts.map.value !== null) {
				flow_data.push(flow_pts.map.value);
				flow_time.push(flow_pts.map.timestamp);
			}
		}

		const trace_turbidity = {
			name: 'Turbidity (NTU)',
			x: turbidity_time,
			y: turbidity_data,
			type: 'scatter',
			marker: {
				size: 5,
				color: 'rgba(44, 130, 201, 1)'
			},
			yaxis: 'y3'
		};

		const trace_ug = {
			name: 'UG/mL',
			x: ug_time,
			y: ug_data,
			type: 'scatter',
			marker: {
				size: 5,
				color: 'rgb(0, 0, 0)'
			},
			yaxis: 'y2'
		};

		const trace_flow = {
			line: {shape: 'hv'},
			mode: 'lines',
			name: 'Flow Switch',
			x: flow_time,
			y: flow_data,
			type: 'scatter',
			marker: {
				size: 5,
				color: '#f89406'
			}
		};

		this.datas.chartData = [trace_turbidity, trace_ug, trace_flow];
		return this.datas;
	}
}
