export class Permission {

    public acl_id: number;
    public role_id: number;
    public resource_type: string[];
    public access_level: number;
    public client: string[];
    public site: string[];
    public machine: string[];
    public analyse: string[];
    public user: string[];

    constructor(acl_id: number, role_id: number, resource_type: string[], access_level: number, client: string[],
                site: string[], machine: string[], analyse: string[], user: string[]) {
        this.acl_id = acl_id;
        this.role_id = role_id;
        this.resource_type = resource_type;
        this.access_level = access_level;
        this.client = client;
        this.site = site;
        this.machine = machine;
        this.analyse = analyse;
        this.user = user;
    }
}