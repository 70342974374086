import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomePageComponent} from './home-page/home-page.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {LayoutModule} from '@angular/cdk/layout';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {NgxCsvParserModule} from 'ngx-csv-parser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatStepperModule} from '@angular/material/stepper';
import {ImportFormsComponent} from './import-forms/import-forms.component';
import {ExportFormsComponent} from './export-forms/export-forms.component';
import {DualListComponent} from './export-forms/dual-list/dual-list.component';
import {DashboardAnalysisComponent} from './dashboard-analysis/dashboard-analysis.component';
import {MachineListComponent} from './dashboard-machine/machine-list/machine-list.component';
import {MachineDetailComponent} from './dashboard-machine/machine-detail/machine-detail.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatExpansionModule} from '@angular/material/expansion';
import { PlotlyModule } from 'angular-plotly.js';
import * as PlotlyJS from 'plotly.js/dist/plotly.js';

import {ImportTextDialogComponent} from './import-forms/import-text-dialog/import-text-dialog.component';
import {ExportFormHelpDialog} from './export-forms/export-help-dialog/export-help-dialog.component';
import {GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from 'angularx-social-login';
import {AuthGuard} from './auth.guard';
import {LoginComponent} from './login/login.component';
import {MdLoginPopupComponentComponent} from './login/md-login-popup.component';
import {MdLegendDialogComponent} from './dashboard-machine/md-legend-dialog/md-legend-dialog.component';
import {FullscreenChartComponent} from './fullscreen-chart/fullscreen-chart.component';
import {AdminPanelComponent} from './admin-panel/admin-panel.component';
import {MachineSettingsComponent} from './admin-panel/machine-settings/machine-settings.component';
import {EditApprovalDialogComponent} from './admin-panel/edit-approval-dialog/edit-approval-dialog.component';
import {SamplingPointSettingsComponent} from './admin-panel/sampling-point-settings/sampling-point-settings.component';
import {SamplingPointCreatorComponent} from './admin-panel/sampling-point-settings/sampling-point-creator/sampling-point-creator.component';
import {MachineCreatorComponent} from './admin-panel/machine-settings/machine-creator/machine-creator.component';
import {InletCreatorComponent} from './admin-panel/machine-settings/inlet-creator/inlet-creator.component';
import {AnalysisSearchEngineComponent} from './dashboard-analysis/analysis-search-engine/analysis-search-engine.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {A11yModule} from '@angular/cdk/a11y';
import {ExportFormColumnInfoDialog} from './export-forms/column-info-dialog/column-info-dialog.component';
import {FilterPipe} from './export-forms/column-info-dialog/filter.pipe';
import {HighlightDirective} from './export-forms/column-info-dialog/highlight.directive';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ImportDataFilesComponent} from './import-data-files/import-data-files.component';
import {MachineLimitsComponent} from './admin-panel/machine-settings/machine-limits/machine-limits.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {UserSettingsComponent} from './admin-panel/user-settings/user-settings.component';
import {AuthInterceptorProvider} from './auth.interceptor';
import {HeaderComponent} from './header/header.component';
import { SamplingPointListComponent } from './dashboard-sampling-point/sampling-point-list/sampling-point-list.component';
import {SamplingPointDetailComponent} from './dashboard-sampling-point/sampling-point-detail/sampling-point-detail.component';
import {DashboardTpiDetailComponent} from './dashboard-tpi/dashboard-tpi-detail.component';
import { AccountPageComponent } from './account-page/account-page.component';
import { RoleSettingsComponent } from './admin-panel/role-settings/role-settings.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import { MomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ISO_DATE_FORMATS } from './custom-date-formats';

PlotlyModule.plotlyjs = PlotlyJS;

@NgModule({
	declarations: [
		AdminPanelComponent,
		AnalysisSearchEngineComponent,
		AppComponent,
		DashboardAnalysisComponent,
		DualListComponent,
		EditApprovalDialogComponent,
		ExportFormsComponent,
		FilterPipe,
		FullscreenChartComponent,
		HighlightDirective,
		HomePageComponent,
		ImportDataFilesComponent,
		ImportFormsComponent,
		ImportTextDialogComponent,
		ExportFormHelpDialog,
		ExportFormColumnInfoDialog,
		InletCreatorComponent,
		LoginComponent,
		MdLoginPopupComponentComponent,
		MachineCreatorComponent,
		MachineDetailComponent,
		MachineListComponent,
		MachineLimitsComponent,
		MachineSettingsComponent,
		MdLegendDialogComponent,
		SamplingPointSettingsComponent,
		SamplingPointCreatorComponent,
		UserSettingsComponent,
		HeaderComponent,
		SamplingPointListComponent,
		SamplingPointDetailComponent,
		DashboardTpiDetailComponent,
		AccountPageComponent,
		RoleSettingsComponent
	],
  imports: [
		A11yModule,
		AppRoutingModule,
		BrowserModule,
		BrowserAnimationsModule,
		DragDropModule,
		FlexLayoutModule,
		FormsModule,
		HttpClientModule,
		LayoutModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatDialogModule,
		MatExpansionModule,
		MatFormFieldModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatNativeDateModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatSelectModule,
		MatSidenavModule,
		MatSnackBarModule,
		MatSortModule,
		MatStepperModule,
		MatTableModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		PlotlyModule,
		ReactiveFormsModule,
		SocialLoginModule,
		MatProgressSpinnerModule,
		NgxMatSelectSearchModule,
		MatSlideToggleModule,
		MomentDateModule,
	],
	providers: [
		AuthInterceptorProvider,
		AuthGuard,
		{ provide: 'SocialAuthServiceConfig',
			useValue: {
				autoLogin: false,
				providers: [
					{
						id: GoogleLoginProvider.PROVIDER_ID,
						provider: new GoogleLoginProvider(
							'803281504540-8umn5hrb6eu4ata98veum1nqrm13t04k.apps.googleusercontent.com'
						),
					}
				]
			} as SocialAuthServiceConfig,
		},
		{ provide: MAT_DATE_FORMATS, useValue: ISO_DATE_FORMATS}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }

