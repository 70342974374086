import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AdminPanelService} from '../admin-panel.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {EditApprovalDialogComponent} from '../edit-approval-dialog/edit-approval-dialog.component';
import * as _ from 'lodash';
import {Role} from '../../Models/Role';
import {takeUntil} from 'rxjs/operators';
import {ReplaySubject, Subject} from 'rxjs';
import {MatOption} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {Permission} from '../../Models/Permission';
import {Modification} from "../../Models/Modification";

@Component({
  selector: 'app-permission-settings',
  templateUrl: './role-settings.component.html',
  styleUrls: ['./role-settings.component.css']
})
export class RoleSettingsComponent implements OnInit, OnDestroy {

  roleForm: FormGroup;
  permissionForm: FormGroup;
  formState: String = '';
  currentAcl: number;
  currentTab: number;

  roleList: any[] = [];
  oldRole: any;

  clientPermissions: any[] = [];
  sitePermissions: any[] = [];
  machinePermissions: any[] = [];
  analysisPermissions: boolean[] = [];
  samplesPermissions: boolean[] = [];
  variablesPermissions: boolean[] = [];
  usersPermissions: boolean[] = [];
  rolesPermissions: boolean[] = [];
  notificationsPermissions: boolean[] = [];
  samplingPermissions: boolean[] = [];
  permissionsPermissions: boolean[] = [];

  machinesList: any[] = [];
  @ViewChild('selectMachine') selectMachine: MatSelect;
  public machineMultiFilterCtrl: FormControl = new FormControl();
  public filteredMachinesMulti: ReplaySubject<any> = new ReplaySubject(1);

  clientsList: any[] = [];
  @ViewChild('selectClient') selectClient: MatSelect;
  public clientMultiFilterCtrl: FormControl = new FormControl();
  public filteredClientsMulti: ReplaySubject<any> = new ReplaySubject(1);

  sitesList: any[] = [];
  @ViewChild('selectSite') selectSite: MatSelect;
  public siteMultiFilterCtrl: FormControl = new FormControl();
  public filteredSitesMulti: ReplaySubject<any> = new ReplaySubject(1);

  isLoading: boolean;
  isLoadingPermissions: boolean;
  selected?: string = undefined;

  protected _onDestroy = new Subject();

  constructor(private service: AdminPanelService, public dialog: MatDialog, private snackbar: MatSnackBar,
              public formBuilder: FormBuilder) {
  }

  async ngOnInit() {
    this.currentAcl = 10;
    this.currentTab = 0;
    this.createRoleForm();

    this.initForms();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  initForms() {
    this.isLoading = true;

    this.service.getRoles().subscribe(roles => {
      this.roleList = roles;
      for (const r of this.roleList){
        this.service.getBusinessById(r.business_id).subscribe(client => {
          r.business_tag = client.business_tag;
        });
      }
    });

    this.machineMultiFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterMachineMulti();
        });

    this.clientMultiFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterClientMulti();
        });

    this.siteMultiFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterSiteMulti();
        });

    this.isLoading = false;
  }

  private createRoleForm() {
    this.roleForm = this.formBuilder.group({
      roleName: [null, [Validators.required, Validators.pattern('([a-zA-Z]+(([\',. -][a-zA-Z ])?[a-zA-Z]*)*)')]],
      roleDesc: [null, [Validators.required]],
      roleClient: [null, [Validators.required]],
      clientMultiCtrl: [null],
      siteMultiCtrl: [null],
      machineMultiCtrl: [null],
      analysisToggle: [false],
      samplesToggle: [false],
      samplingToggle: [false],
      variablesToggle: [false],
      usersToggle: [false],
      rolesToggle: [false],
      permissionToggle: [false],
      notificationsToggle: [false]
    });

    this.roleForm.controls.analysisToggle.disable();
    this.roleForm.controls.samplesToggle.disable();
    this.roleForm.controls.samplingToggle.disable();
    this.roleForm.controls.variablesToggle.disable();
    this.roleForm.controls.usersToggle.disable();
    this.roleForm.controls.rolesToggle.disable();
    this.roleForm.controls.notificationsToggle.disable();
    this.roleForm.controls.permissionToggle.disable();

    this.service.getMachines().subscribe(machines => {
      this.machinesList = machines;
      this.filteredMachinesMulti.next(this.machinesList.slice());
    }, error => {
      this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
        duration: 5000
      });
    });

    this.service.getBusinesses().subscribe(clients => {
      this.clientsList = clients;
      this.filteredClientsMulti.next(this.clientsList.slice());
    }, error => {
      this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
        duration: 5000
      });
    });

    this.service.getSites().subscribe(sites => {
      this.sitesList = sites;
      this.filteredSitesMulti.next(this.sitesList.slice());
    }, error => {
      this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
        duration: 5000
      });
    });

    this.isLoading = false;
  }

  updateRoleForm(){
    this.isLoadingPermissions = true;
    const client = this.clientsList.find(x => x.business_id === this.oldRole.business_id);

    this.service.getPermissions(this.oldRole.role_id).subscribe(permissions => {
      permissions.forEach(perm => {
        let temp = [];
        if (perm.resource_type.includes('clients')){
          if (perm.client.includes('*')){
            temp = this.clientsList.slice();
          }else{
            temp = this.clientsList.filter(x => perm.client.indexOf(x.business_id.toString()) !== -1).slice();
          }
          temp.forEach(x => x.access_level = perm.access_level);
          this.clientPermissions.push(...temp);
        }
        if (perm.resource_type.includes('sites')){
          if (perm.site != null && perm.site.includes('*')){
            temp = this.sitesList.slice();
          }else{
            if (perm.client != null){
              temp = this.sitesList.filter(x => perm.client.indexOf(x.business_id.toString()) !== -1).slice();
            }
            if (perm.site != null){
              temp = this.sitesList.filter(x => perm.site.indexOf(x.site_id.toString()) !== -1).slice();
            }
          }
          temp.forEach(x => x.access_level = perm.access_level);
          this.sitePermissions.push(...temp);
        }
        if (perm.resource_type.includes('machines')){
          if (perm.machine != null && perm.machine.includes('*')){
            temp = this.machinesList.slice();
          }else{
            if (perm.client != null){
              temp = this.machinesList.filter(x => perm.client.indexOf(this.sitesList.filter(y => y.site_id === x.site_id).map(s => s.site_id.toString())) !== -1).slice();
            }
            if (perm.site != null){
              temp = this.machinesList.filter(x => perm.site.indexOf(x.site_id.toString()) !== -1).slice();
            }
            if (perm.machine != null){
              temp = this.machinesList.filter(x => perm.machine.indexOf(x.machine_id.toString()) !== -1).slice();
            }
          }
          temp.forEach(x => x.access_level = perm.access_level);
          this.machinePermissions.push(...temp);
        }
        if (perm.resource_type.includes('analyses')){
            this.analysisPermissions[(perm.access_level / 10) - 1] = true;
        }
        if (perm.resource_type.includes('analyses/variables')){
          this.variablesPermissions[(perm.access_level / 10) - 1] = true;
        }
        if (perm.resource_type.includes('analyses/samples')){
          this.samplesPermissions[(perm.access_level / 10) - 1] = true;
        }
        if (perm.resource_type.includes('users')){
          this.usersPermissions[(perm.access_level / 10) - 1] = true;
        }
        if (perm.resource_type.includes('roles')){
          this.rolesPermissions[(perm.access_level / 10) - 1] = true;
        }
        if (perm.resource_type.includes('notifications')){
          this.notificationsPermissions[(perm.access_level / 10) - 1] = true;
        }
        if (perm.resource_type.includes('clients/sampling_points')){
          this.samplingPermissions[(perm.access_level / 10) - 1] = true;
        }
        if (perm.resource_type.includes('permissions')){
          this.permissionsPermissions[(perm.access_level / 10) - 1] = true;
        }
      });
      this.disableToggles();
      this.roleForm = this.formBuilder.group({
        roleName: [this.oldRole.role_name, [Validators.required, Validators.pattern('([a-zA-Z]+(([\',. -][a-zA-Z ])?[a-zA-Z]*)*)')]],
        roleDesc: [this.oldRole.role_description, [Validators.required]],
        roleClient: [client, [Validators.required]],
        clientMultiCtrl: [this.clientPermissions.filter(x => x.access_level === this.currentAcl)],
        siteMultiCtrl: [this.sitePermissions.filter(x => x.access_level === this.currentAcl)],
        machineMultiCtrl: [this.machinePermissions.filter(x => x.access_level === this.currentAcl)],
        analysisToggle: [this.analysisPermissions[this.currentTab]],
        samplesToggle: [this.samplesPermissions[this.currentTab]],
        samplingToggle: [this.samplingPermissions[this.currentTab]],
        variablesToggle: [this.variablesPermissions[this.currentTab]],
        usersToggle: [this.usersPermissions[this.currentTab]],
        rolesToggle: [this.rolesPermissions[this.currentTab]],
        notificationsToggle: [this.notificationsPermissions[this.currentTab]],
        permissionToggle: [this.permissionsPermissions[this.currentTab]]
      });
      this.isLoadingPermissions = false;
    }, error => {
      this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
        duration: 5000
      });
      this.isLoadingPermissions = false;
    });
  }

  clientChanged(client: any) {
      const index = this.clientPermissions.findIndex(x => x.business_id === client.business_id);
      if (index === -1){
        let temp;
        temp = client;
        temp.access_level = this.currentAcl;
        this.clientPermissions.push(temp);
      }else{
        if (this.clientPermissions[index].access_level !== this.currentAcl){
          if (this.clientPermissions.filter(x => x.access_level === this.clientPermissions[index].access_level).length === 0 &&
              this.sitePermissions.filter(x => x.access_level === this.clientPermissions[index].access_level).length === 0 &&
              this.machinePermissions.filter(x => x.access_level === this.clientPermissions[index].access_level).length === 0){
            const i = (this.clientPermissions[index].access_level / 10) - 1;
            this.analysisPermissions[i] = false;
            this.samplesPermissions[i] = false;
            this.variablesPermissions[i] = false;
            this.usersPermissions[i] = false;
            this.rolesPermissions[i] = false;
            this.samplingPermissions[i] = false;
            this.notificationsPermissions[i] = false;
            this.permissionsPermissions[i] = false;
          }
          client.access_level = this.currentAcl;
        }
        else{
          this.clientPermissions.splice(index, 1);
        }
      }
      this.disableToggles();
  }

  siteChanged(site: any) {
    const index = this.sitePermissions.findIndex(x => x.site_id === site.site_id);
    if (index === -1){
      let temp;
      temp = site;
      temp.access_level = this.currentAcl;
      this.sitePermissions.push(temp);
    }else{
      if (this.sitePermissions[index].access_level !== this.currentAcl){
        if (this.clientPermissions.filter(x => x.access_level === this.sitePermissions[index].access_level).length === 0 &&
            this.sitePermissions.filter(x => x.access_level === this.sitePermissions[index].access_level).length === 0 &&
            this.machinePermissions.filter(x => x.access_level === this.sitePermissions[index].access_level).length === 0){
          const i = (this.sitePermissions[index].access_level / 10) - 1;
          this.analysisPermissions[i] = false;
          this.samplesPermissions[i] = false;
          this.variablesPermissions[i] = false;
          this.usersPermissions[i] = false;
          this.rolesPermissions[i] = false;
          this.samplingPermissions[i] = false;
          this.notificationsPermissions[i] = false;
          this.permissionsPermissions[i] = false;
        }
        site.access_level = this.currentAcl;
      }
      else{
        this.sitePermissions.splice(index, 1);
      }
    }
    this.disableToggles();
  }

  machineChanged(machine: any) {
    const index = this.machinePermissions.findIndex(x => x.machine_id === machine.machine_id);
    if (index === -1){
      let temp;
      temp = machine;
      temp.access_level = this.currentAcl;
      this.machinePermissions.push(temp);
    }else{
      if (this.machinePermissions[index].access_level !== this.currentAcl){
        if (this.clientPermissions.filter(x => x.access_level === this.machinePermissions[index].access_level).length === 0 &&
            this.sitePermissions.filter(x => x.access_level === this.machinePermissions[index].access_level).length === 0 &&
            this.machinePermissions.filter(x => x.access_level === this.machinePermissions[index].access_level).length === 0){
          const i = (this.machinePermissions[index].access_level / 10) - 1;
          this.analysisPermissions[i] = false;
          this.samplesPermissions[i] = false;
          this.variablesPermissions[i] = false;
          this.usersPermissions[i] = false;
          this.rolesPermissions[i] = false;
          this.samplingPermissions[i] = false;
          this.notificationsPermissions[i] = false;
          this.permissionsPermissions[i] = false;
        }
        machine.access_level = this.currentAcl;
      }
      else{
        this.machinePermissions.splice(index, 1);
      }
    }
    this.disableToggles();
  }

  setFormUpdateRole(role: any) {
    this.formState = 'update';
    this.oldRole = role;
    this.clientPermissions = [];
    this.sitePermissions = [];
    this.machinePermissions = [];
    this.analysisPermissions = [];
    this.samplesPermissions = [];
    this.variablesPermissions = [];
    this.usersPermissions = [];
    this.rolesPermissions = [];
    this.samplingPermissions = [];
    this.notificationsPermissions = [];
    this.permissionsPermissions = [];

    this.updateRoleForm();
    this.isLoading = false;
  }

  SetFormCreateRole() {
    this.selected = null;
    this.formState = 'create';
    this.clientPermissions = [];
    this.sitePermissions = [];
    this.machinePermissions = [];
    this.analysisPermissions = [false, false, false, false];
    this.samplesPermissions = [false, false, false, false];
    this.variablesPermissions = [false, false, false, false];
    this.usersPermissions = [false, false, false, false];
    this.rolesPermissions = [false, false, false, false];
    this.samplingPermissions = [false, false, false, false];
    this.notificationsPermissions = [false, false, false, false];
    this.permissionsPermissions = [false, false, false, false];

    this.createRoleForm();

    this.roleForm.markAllAsTouched();
  }

  async createRole() {
    const role = new Role(null, this.roleForm.get('roleName').value,
        this.roleForm.get('roleDesc').value, this.roleForm.get('roleClient').value.business_id, null);

    const pos = this.roleList.findIndex(x => x.role_name === role.role_name && x.business_id === role.business_id);

    if (pos === -1) {
      this.isLoading = true;
      try {
        this.service.createRole(role).subscribe(resp => {
          this.createPermission(resp).then(() => {
            this.formState = '';
            this.resetForms();
          });
          this.snackbar.open('Role successfully created.', 'Ok', {
            duration: 3000
          });
        });
      } catch (error) {
        this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok');
      }
      this.isLoading = false;
    } else {
      this.snackbar.open('This role already exist', 'Ok');
    }
  }

  async createPermission(role_id: number) {

    for (let i = 0; i < 4; i++) {
      const acl = (i + 1) * 10;
      let clientList: string[] = [];
      let siteList: string[] = [];
      let machineList: string[] = [];
      // const analyseList: string[] = [];
      // const userList: string[] = [];
      const resourceList: string[] = [];

      const permClient = this.clientPermissions.filter(x => x.access_level === acl);
      const permSite = this.sitePermissions.filter(x => x.access_level === acl);
      const permMachine = this.machinePermissions.filter(x => x.access_level === acl);
      const permAnalysis = this.analysisPermissions[i];
      const permSamples = this.samplesPermissions[i];
      const permVariables = this.variablesPermissions[i];
      const permUsers = this.usersPermissions[i];
      const permRoles = this.rolesPermissions[i];
      const permNotifications = this.notificationsPermissions[i];
      const permSampling = this.samplingPermissions[i];
      const permPermission = this.permissionsPermissions[i];

      if (permClient.length > 0){
        resourceList.push('clients');
      }
      if (permSite.length > 0){
        resourceList.push('sites');
      }
      if (permMachine.length > 0){
        resourceList.push('machines');
        resourceList.push('inlets');
      }
      if (permAnalysis){
        resourceList.push('analyses');
      }
      if (permSamples){
        resourceList.push('analyses/samples');
      }
      if (permVariables){
        resourceList.push('analyses/variables');
      }
      if (permUsers){
        resourceList.push('users');
      }
      if (permRoles){
        resourceList.push('roles');
      }
      if (permNotifications){
        resourceList.push('notifications');
      }
      if (permSampling){
        resourceList.push('clients/sampling_points');
      }
      if (permPermission){
        resourceList.push('permissions');
      }

      if (resourceList.length > 0){
        let perm;

        // TODO Donner toutes les permissions avec des étoiles --> seulement pour les super-admin
        /*if (permClient.length === this.clientsList.length && permSite.length === this.sitesList.length && permMachine.length === this.machinesList.length){
          perm = new Permission(null, role_id, resourceList, acl, ['*'], ['*'], ['*'], ['*'], ['*']);
        }*/

        if (permClient.length > 0){
          permClient.forEach(x => clientList.push(x.business_id.toString()));
        }else{
          clientList = null;
        }
        if (permSite.length > 0){
          permSite.forEach(x => siteList.push(x.site_id.toString()));
        }
        else{
          siteList = null;
        }
        if (permMachine.length > 0){
          permMachine.forEach(x => machineList.push(x.machine_id.toString()));
        }
        else{
          machineList = null;
        }
        perm = new Permission(null, role_id, resourceList, acl, clientList, siteList, machineList, null, null);

        // Pour une éventuelle restriction sur certaine analyses et utilisateurs
        // analyseList.push(permSite.map(list => list.site_id).toString());
        // userList.push(permClient.map(list => list.business_id).toString());

        try {
          this.service.createPermissions(perm).subscribe();
        } catch (error) {
          this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok');
        }
      }
    }
  }

  async updateRole() {
    const dialogRef = this.dialog.open(EditApprovalDialogComponent, {
      width: '60%'
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        const role = new Role(this.oldRole.role_id, this.roleForm.get('roleName').value,
            this.roleForm.get('roleDesc').value, this.roleForm.get('roleClient').value.business_id, null);
        this.service.updateRole(role).subscribe(resp => {
          this.updatePermissions(this.oldRole.role_id).then(() => {
            this.formState = '';
            this.selected = null;
            this.resetForms();
          });
          this.snackbar.open('Role successfully updated.', 'Ok', {
            duration: 3000
          });
        }, error => {
          this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
            duration: 5000
          });
        });
        this.oldRole = role;
      }
    });
  }

  async updatePermissions(role_id: number){

    for (let i = 0; i < 4; i++) {
      const acl = (i + 1) * 10;
      let clientList: string[] = [];
      let siteList: string[] = [];
      let machineList: string[] = [];
      // const analyseList: string[] = [];
      // const userList: string[] = [];
      const resourceList: string[] = [];

      const permClient = this.clientPermissions.filter(x => x.access_level === acl);
      const permSite = this.sitePermissions.filter(x => x.access_level === acl);
      const permMachine = this.machinePermissions.filter(x => x.access_level === acl);
      const permAnalysis = this.analysisPermissions[i];
      const permSamples = this.samplesPermissions[i];
      const permVariables = this.variablesPermissions[i];
      const permUsers = this.usersPermissions[i];
      const permRoles = this.rolesPermissions[i];
      const permNotifications = this.notificationsPermissions[i];
      const permSampling = this.samplingPermissions[i];
      const permPermission = this.permissionsPermissions[i];

      if (permClient.length > 0){
        resourceList.push('clients');
      }
      if (permSite.length > 0){
        resourceList.push('sites');
      }
      if (permMachine.length > 0){
        resourceList.push('machines');
        resourceList.push('inlets');
      }
      if (permAnalysis){
        resourceList.push('analyses');
      }
      if (permSamples){
        resourceList.push('analyses/samples');
      }
      if (permVariables){
        resourceList.push('analyses/variables');
      }
      if (permUsers){
        resourceList.push('users');
      }
      if (permRoles){
        resourceList.push('roles');
      }
      if (permNotifications){
        resourceList.push('notifications');
      }
      if (permSampling){
        resourceList.push('clients/sampling_points');
      }
      if (permPermission){
        resourceList.push('permissions');
      }

      if (resourceList.length > 0){
        let perm;

        if (permClient.length > 0) {
          permClient.forEach(x => clientList.push(x.business_id.toString()));
        } else {
          clientList = null;
        }
        if (permSite.length > 0) {
          permSite.forEach(x => siteList.push(x.site_id.toString()));
        } else {
          siteList = null;
        }
        if (permMachine.length > 0) {
          permMachine.forEach(x => machineList.push(x.machine_id.toString()));
        } else {
          machineList = null;
        }
        perm = new Permission(null, role_id, resourceList, acl, clientList, siteList, machineList, null, null);

        if (permClient.length === this.clientsList.length && permSite.length === this.sitesList.length && permMachine.length === this.machinesList.length){
          // TODO Donner toutes les permissions avec des étoiles --> seulement pour les super-admin
          // perm = new Permission(null, role_id, resourceList, acl, ['*'], ['*'], ['*'], ['*'], ['*']);
          try {
            this.service.deletePermissions(role_id, null).subscribe(() => {
              try {
                this.service.createPermissions(perm).subscribe();
              } catch (error) {
                this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok');
              }
            });
          } catch (error) {
            this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok');
          }
        }else{
          try {
            this.service.deletePermissions(role_id, acl).subscribe(() => {
              try {
                this.service.createPermissions(perm).subscribe();
              } catch (error) {
                this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok');
              }
            });
          } catch (error) {
            this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok');
          }
        }
        // Pour une éventuelle restriction sur certaine analyses et utilisateurs
        // analyseList.push(permSite.map(list => list.site_id).toString());
        // userList.push(permClient.map(list => list.business_id).toString());
      }
    }
  }

  deleteRole() {
    const mod = new Modification();
    mod.set_mod_description('Make sure to remove all users associated with this role before delete it.');
    const dialogRef = this.dialog.open(EditApprovalDialogComponent, {
        width: '60%',
        data: [mod]
    });
    dialogRef.afterClosed().subscribe(data => {
        if (data) {
            this.service.deleteRole(this.oldRole.role_id).subscribe(resp => {
                this.service.deletePermissions(this.oldRole.role_id, null).subscribe(r => {
                  this.formState = '';
                  this.oldRole = null;
                  this.resetForms();
                  this.snackbar.open('Role successfully deleted.', 'Ok', {
                    duration: 3000
                  });
                });
            }, error => {
                this.snackbar.open('An error has occurred. Make sure to remove all users associated with this role. Code: ' + error.status, 'Ok', {
                    duration: 5000
                });
            });
        }
    });
  }

  private resetForms() {
    this.roleList = [];

    this.initForms();

    this.roleForm.markAllAsTouched();
  }

  restoreFormValue() {
    this.setFormUpdateRole(this.oldRole);
  }

  cancel() {
    this.roleForm.reset();
  }

  openSnackBar(message, action) {
    this.snackbar.open(message, action, {duration: 5000});
  }

  onSelected(item: string) {
    if (item === this.selected){
      this.selected = null;
    }
    else{
      this.selected = item;
    }
  }

  protected filterMachineMulti() {
    if (!this.machinesList) {
      return;
    }

    let search = this.machineMultiFilterCtrl.value;
    if (!search) {
      this.filteredMachinesMulti.next(this.machinesList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredMachinesMulti.next(
        this.machinesList.filter(m => m.machine_tag.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterClientMulti() {
    if (!this.clientsList) {
      return;
    }

    let search = this.clientMultiFilterCtrl.value;
    if (!search) {
      this.filteredClientsMulti.next(this.clientsList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredClientsMulti.next(
        this.clientsList.filter(c => c.business_tag.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterSiteMulti() {
    if (!this.sitesList) {
      return;
    }

    let search = this.siteMultiFilterCtrl.value;
    if (!search) {
      this.filteredSitesMulti.next(this.sitesList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredSitesMulti.next(
        this.sitesList.filter(s => s.site_tag.toLowerCase().indexOf(search) > -1)
    );
  }

  toggleAllSelection(select: MatSelect, options: string) {
    const selected = select.options.find(opt => opt.selected);
    if (selected) {
      select.options.forEach((item: MatOption) => item.deselect());
      switch (options) {
        case 'clients': {
          this.clientPermissions = [];
          break;
        }
        case 'sites': {
          this.sitePermissions = [];
          break;
        }
        case 'machines': {
          this.machinePermissions = [];
          break;
        }
      }
    } else {
      select.options.forEach((item: MatOption) => item.select());
      switch (options) {
        case 'clients': {
          this.clientPermissions = this.clientsList.slice();
          this.clientPermissions.forEach(x => x.access_level = this.currentAcl);
          break;
        }
        case 'sites': {
          this.sitePermissions = this.sitesList.slice();
          this.sitePermissions.forEach(x => x.access_level = this.currentAcl);
          break;
        }
        case 'machines': {
          this.machinePermissions = this.machinesList.slice();
          this.machinePermissions.forEach(x => x.access_level = this.currentAcl);
          break;
        }
      }
    }
    this.disableToggles();
    select.options.first.deselect();
  }

  onTabClick($event: any) {
    this.currentAcl = ($event.index + 1) * 10;
    this.currentTab = $event.index;

    this.roleForm.patchValue({
      clientMultiCtrl: this.clientPermissions.filter(x => x.access_level === this.currentAcl),
      siteMultiCtrl: this.sitePermissions.filter(x => x.access_level === this.currentAcl),
      machineMultiCtrl: this.machinePermissions.filter(x => x.access_level === this.currentAcl),
      analysisToggle: this.analysisPermissions[this.currentTab],
      samplesToggle: this.samplesPermissions[this.currentTab],
      samplingToggle: this.samplingPermissions[this.currentTab],
      variablesToggle: this.variablesPermissions[this.currentTab],
      usersToggle: this.usersPermissions[this.currentTab],
      rolesToggle: this.rolesPermissions[this.currentTab],
      permissionToggle: this.permissionsPermissions[this.currentTab],
      notificationsToggle: this.notificationsPermissions[this.currentTab]});

    this.disableToggles();
  }

  onChange($event: any, perms: boolean[]){
    perms[this.currentTab] = $event.checked;
  }

  disableToggles(){
    if (this.clientPermissions.filter(x => x.access_level === this.currentAcl).length === 0 &&
        this.sitePermissions.filter(x => x.access_level === this.currentAcl).length === 0 &&
        this.machinePermissions.filter(x => x.access_level === this.currentAcl).length === 0){
      this.roleForm.controls.analysisToggle.disable();
      this.roleForm.controls.samplesToggle.disable();
      this.roleForm.controls.samplingToggle.disable();
      this.roleForm.controls.variablesToggle.disable();
      this.roleForm.controls.usersToggle.disable();
      this.roleForm.controls.rolesToggle.disable();
      this.roleForm.controls.notificationsToggle.disable();
      this.roleForm.controls.permissionToggle.disable();
    }
    else{
      this.roleForm.controls.analysisToggle.enable();
      this.roleForm.controls.samplesToggle.enable();
      this.roleForm.controls.samplingToggle.enable();
      this.roleForm.controls.variablesToggle.enable();
      this.roleForm.controls.usersToggle.enable();
      this.roleForm.controls.rolesToggle.enable();
      this.roleForm.controls.notificationsToggle.enable();
      this.roleForm.controls.permissionToggle.enable();
    }
  }

  allAccessSelection(){

    this.clientPermissions = this.clientsList.slice();
    this.clientPermissions.forEach(x => x.access_level = this.currentAcl);

    this.sitePermissions = this.sitesList.slice();
    this.sitePermissions.forEach(x => x.access_level = this.currentAcl);

    this.machinePermissions = this.machinesList.slice();
    this.machinePermissions.forEach(x => x.access_level = this.currentAcl);

    this.analysisPermissions = [false, false, false, false];
    this.samplesPermissions = [false, false, false, false];
    this.variablesPermissions = [false, false, false, false];
    this.usersPermissions = [false, false, false, false];
    this.rolesPermissions = [false, false, false, false];
    this.samplingPermissions = [false, false, false, false];
    this.notificationsPermissions = [false, false, false, false];
    this.permissionsPermissions = [false, false, false, false];

    this.analysisPermissions[this.currentTab] = true;
    this.variablesPermissions[this.currentTab] = true;
    this.samplesPermissions[this.currentTab] = true;
    this.usersPermissions[this.currentTab] = true;
    this.rolesPermissions[this.currentTab] = true;
    this.notificationsPermissions[this.currentTab] = true;
    this.samplingPermissions[this.currentTab] = true;
    this.permissionsPermissions[this.currentTab] = true;

    this.disableToggles();

    this.roleForm.patchValue({
      clientMultiCtrl: this.clientPermissions.filter(x => x.access_level === this.currentAcl),
      siteMultiCtrl: this.sitePermissions.filter(x => x.access_level === this.currentAcl),
      machineMultiCtrl: this.machinePermissions.filter(x => x.access_level === this.currentAcl),
      analysisToggle: this.analysisPermissions[this.currentTab],
      samplesToggle: this.samplesPermissions[this.currentTab],
      samplingToggle: this.samplingPermissions[this.currentTab],
      variablesToggle: this.variablesPermissions[this.currentTab],
      usersToggle: this.usersPermissions[this.currentTab],
      rolesToggle: this.rolesPermissions[this.currentTab],
      permissionToggle: this.permissionsPermissions[this.currentTab],
      notificationsToggle: this.notificationsPermissions[this.currentTab]});
    this.roleForm.markAsDirty();
  }
}
