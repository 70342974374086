import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Sample} from '../Models/Sample';
import {Analysis} from '../Models/Analysis';
import {ResultBioAlert} from '../Models/ResultBioAlert';
import {Site} from '../Models/Site';
import {ResultLaboCulture} from '../Models/ResultLaboCulture';
import {ResultLaboQpcr} from '../Models/ResultLaboQpcr';
import {ResultLaboQlamp} from '../Models/ResultLaboQlamp';
import {ResultLaboAtp} from '../Models/ResultLaboAtp';


import {CookieServiceService} from '../cookie-service.service';
import {ApiMsg} from '../Models/ApiMsg';
import {environment} from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ImportFormsService {

	header;
	clientUrl = environment.clientUrl;
	analysisUrl = environment.analysisUrl;
	machinesUrl = environment.machineUrl;

	constructor(private http: HttpClient, private cookieService: CookieServiceService) {
		this.header = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + this.cookieService.get('token'),
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'DELETE, GET, POST, OPTIONS',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		})
	}

	getBusinesses(limit: number) {
		return this.http.get<any>(this.clientUrl + "/clients?limit=" + limit, {headers: this.header});
	}

	getSitesByBusinessId(business_id: string, limit: number): Observable<any> {
		return this.http.get<Site>(this.clientUrl + "/clients/sites/clients?client=" + business_id + "&?limit=" + limit, {headers: this.header});
	}

	getSamplingPointBySiteId(site_id: string, limit: number): Observable<any> {
		return this.http.get<any>(this.clientUrl + "/clients/samplingPoints/" + site_id + "/siteId?limit=" + limit, {headers: this.header})
	}

	async insertSample(sample: Sample) {
		return await this.http.post<any>(this.analysisUrl + "/analyses/samples", sample, {headers: this.header}).toPromise();
	}

	async insertAnalysis(analysis: Analysis) {
		return await this.http.post<any>(this.analysisUrl + "/analyses", analysis, {headers: this.header}).toPromise();
	}

	async insertResultBioAlert(result: ResultBioAlert) {
		return await this.http.post<any>(this.machinesUrl + "/machines/results/BioAlert", result, {headers: this.header}).toPromise();
	}

	insertResultLaboCulture(result: ResultLaboCulture) {
		return this.http.post<any>(this.analysisUrl + "/analyses/results/laboCulture", result, {headers: this.header});
	}

	insertResultLaboQpcr(result: ResultLaboQpcr) {
		return this.http.post<any>(this.analysisUrl + "/analyses/results/laboQpcr", result, {headers: this.header});
	}

	insertResultLaboQlamp(result: ResultLaboQlamp) {
		return this.http.post<any>(this.analysisUrl + "/analyses/results/laboQlamp", result, {headers: this.header});
	}

	insertResultLaboAtp(result: ResultLaboAtp) {
		return this.http.post<any>(this.analysisUrl + "/analyses/results/laboAtp", result, {headers: this.header});
	}

	async getMiIdByMachineIdAndInletNum(machine_id: number, inlet_num: number) {
		return await this.http.get(this.machinesUrl + "/machines/inlets/machineInputs?machineId=" + machine_id + "&inletNum=" + inlet_num, {headers: this.header}).toPromise();
	}

	async checkDuplicatedSampleAndAnalysis(time: string, spId: number, methodId: number) {
		return await this.http.get<any>( this.analysisUrl + "/analyses/samples/checkIfDuplicate/time/" + time + "/spId/" + spId + "/methodId/" + methodId, {headers: this.header}).toPromise();
	}

	async checkIfAnalysisIsDuplicate(machine_timestamp, machine_tag: string) {
		return await this.http.get<boolean>(this.analysisUrl + "/analyses/checkIfDuplicate" + "?time=" + machine_timestamp + "&machineTag=" + machine_tag, {headers: this.header}).toPromise();
	}

	async getMachineIdByMachineTag(machine_tag: string) {
		return await this.http.get<number>(this.machinesUrl + "/machines/id/tag/" + machine_tag, {headers: this.header}).toPromise();
	}
}
