import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {GoogleLoginProvider, SocialAuthService, SocialUser} from 'angularx-social-login';
import {CookieServiceService} from '../cookie-service.service';
import {LocalStorageService} from '../local-storage.service';
import {LoginService} from './login.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import jwt_decode from "jwt-decode";
import {NavbarService} from "../header/navbar.service";


@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css'],
	providers: [SocialAuthService]
})
export class LoginComponent implements OnInit {

	user: SocialUser;
	loggedIn: boolean;

	email: string;
	password: string;
	loggedInViaMS: boolean;
	loadingLogin: boolean;

	constructor(
		public authService: SocialAuthService,
		private route: Router,
		public cookieService: CookieServiceService,
		public localStorageService: LocalStorageService,
		public service: LoginService,
		private snackbar: MatSnackBar,
		public nav: NavbarService,
	) {
		/*this.authService.authState.subscribe((user) => {
			this.user = user;
			this.cookieService.set('token', user.idToken);
			this.localStorageService.set('userFirstName', user.firstName);
			this.localStorageService.set('userLastName', user.lastName);
			this.localStorageService.set('userImg', user.photoUrl);
			this.localStorageService.set('userEmail', user.email);

			this.loggedIn = (user != null);
			if (this.loggedIn.valueOf() == true) {
				this.route.navigate(['/home']);
				this.service.getAccessRight(user.email).subscribe(data => {
					this.localStorageService.set('role', data.text);
				});
			}
		});*/
	}

	ngOnInit(): void {
		this.loggedInViaMS = (this.cookieService.get('token') != null);
		if (this.loggedInViaMS.valueOf() === true) {
			this.nav.loggedIn('user');
			this.route.navigate(['/home']);
		}
		else{
			this.nav.loggedOut();
		}
	}

	/*signInWithGoogle(): void {
		this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(() => {
			console.log('Successful connection!');
		}).catch(data => {
			if (data.error != 'popup_closed_by_user') {
				this.snackbar.open('The connection provider is not ready yet, please try again.', 'Ok', {
					duration: 10000
				});
			}
		});
	}*/

	signOut(): void {
		this.authService.signOut().then(() => {
			console.log('Déconnexion réussie!');
		}).catch(() => {
			this.snackbar.open('Logout failed, please try again.', 'Ok', {
				duration: 10000
			});
		});
	}

	signInWithMicroService(): void {
		this.loadingLogin = true;

		this.service.signInWithMicroService(this.email, this.password).subscribe(resp => {

			if (resp.idToken != null) {
				const decoded = jwt_decode(resp.idToken);
				this.cookieService.set('token', resp.idToken);
				this.localStorageService.set('userFirstName', resp.displayName);
				this.localStorageService.set('userEmail', resp.email);
				this.nav.loggedIn(decoded['role']);
				this.snackbar.open('Connexion successful!', 'Ok', {
					duration: 3000
				});
				this.loggedInViaMS = true;

				if (this.nav._role !== 'user'){
					this.route.navigate(['/home']);
				}else {
					this.route.navigate(['/dashboards/machines']);
				}

			} else if (resp.status === 400) {
				console.log(resp.message);
				this.snackbar.open('Error during connection, Please retry', 'Ok');
			} else {
				this.snackbar.open('An unknown error has occurred.', 'Ok');
			}


			this.loadingLogin = false;
		}, error => {
			this.loadingLogin = false;
			console.log(error.message);
			this.snackbar.open('Error during connection, Please retry', 'Ok');
		});
	}
}
