import {Component, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Machine} from 'src/app/Models/Machine';
import {MachineInput} from 'src/app/Models/MachineInput';
import {Modification} from 'src/app/Models/Modification';
import {AdminPanelService} from '../../admin-panel.service';
import {EditApprovalDialogComponent} from '../../edit-approval-dialog/edit-approval-dialog.component';
import {SamplingPointCreatorComponent} from '../../sampling-point-settings/sampling-point-creator/sampling-point-creator.component';
import * as _ from 'lodash';
import {ReplaySubject, Subject} from "rxjs";
import {MatSelect} from "@angular/material/select";
import {takeUntil} from "rxjs/operators";

@Component({
	selector: 'app-machine-creator',
	templateUrl: './machine-creator.component.html',
	styleUrls: ['./machine-creator.component.css']
})
export class MachineCreatorComponent implements OnInit {

	mCreatorForm: FormGroup;

	inletForm: FormGroup;
	inletRowCount: number = 1;

	suggestedMachineId: number;
	suggestedMachineTag: string;

	samplingPoints: any;
	selectedSamplingPoints: any[] = [];

	machineList: any[] = [];

	isLoading: boolean = false;

	possibleStates = ['inactive', 'standby'];
	modifications: Modification[] = [];

	public samplingPointFilterCtrl: FormControl = new FormControl();
	public filteredSamplingPoint: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
	@ViewChild('samplingPointSelect', {static: true}) samplingPointSelect: MatSelect;

	protected _onDestroy = new Subject<void>();

	constructor(private service: AdminPanelService, public dialog: MatDialog, private snackbar: MatSnackBar, public dialogRef: MatDialogRef<SamplingPointCreatorComponent>, public formBuilder: FormBuilder) {
	}

	ngOnInit(): void {
		this.buildMachineForm();
		this.buildInletForm();

		this.initForms();

		this.mCreatorForm.markAllAsTouched();
		this.inletForm.markAllAsTouched();
	}

	initForms() {
		this.isLoading = true;

		this.service.getNewMachineId().subscribe(newId => {
			this.suggestedMachineId = newId;
			this.mCreatorForm.get('machineId').setValue(this.suggestedMachineId);

			this.service.getNewMachineTag().subscribe(newTag => {
				this.suggestedMachineTag = newTag.text;
				this.mCreatorForm.get('machineTag').setValue(this.suggestedMachineTag);

				this.service.getMachinesInObservable().subscribe(machines => {
					this.machineList = machines;

					this.service.getUniqueSamplingPoints().subscribe(sp => {
						this.samplingPoints = _.orderBy(sp, 'sp_tag', 'asc');
						this.filteredSamplingPoint.next(this.samplingPoints.slice());

						this.isLoading = false;
					});
				});
			});
		});

		this.samplingPointFilterCtrl.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe(() => {
				this.filterSamplingPoint();
			});
	}

	private buildMachineForm() {
		this.mCreatorForm = this.formBuilder.group({
			machineId: [this.suggestedMachineId, [Validators.required, Validators.pattern('([0-9]{2,3})')]],
			machineTag: [this.suggestedMachineTag, [Validators.required, Validators.pattern('([A-Z]{1})+([0-9]{4})')]],
			state: ['inactive', Validators.required],
			machineSite: [1, [Validators.required, Validators.pattern('([0-9]{1,3})')]],
			lastMaintenance: [null],
			nbAnalysisBeforeMaintenance: [null]
		});
	}

	private buildInletForm() {
		this.inletRowCount = 1;
		this.inletForm = this.formBuilder.group({
			inletRow: this.formBuilder.array([
				this.getInletFormRow()
			])
		});
	}

	private getInletFormRow() {
		return this.formBuilder.group({
			inlet: [this.inletRowCount, [Validators.required, Validators.pattern('[0-9]{1,2}')]],
			sp: [null, Validators.required]
		});
	}

	addInletRow() {
		this.inletRowCount++;
		const control = <FormArray>this.inletForm.controls['inletRow'];
		control.push(this.getInletFormRow());
	}

	removeInletRow(i: number) {
		this.inletRowCount--;
		const control = <FormArray>this.inletForm.controls['inletRow'];
		this.selectedSamplingPoints.splice(i, 1);
		control.removeAt(i);
	}

	samplingPointSelectedChanged($event: any, i: number) {
		this.selectedSamplingPoints[i] = $event.value;
	}

	async submitAndClose() {
		const i_control = <FormArray>this.inletForm.controls['inletRow'];

		const machine = new Machine(this.mCreatorForm.get('machineId').value, this.mCreatorForm.get('machineTag').value
			, 0, this.mCreatorForm.get('state').value, this.mCreatorForm.get('machineSite').value.site_id, null, this.mCreatorForm.get('lastMaintenance').value, this.mCreatorForm.get('nbAnalysisBeforeMaintenance').value);

		const mod = new Modification();
		mod.set_mod_description('Creating the machine ' + machine.machine_tag);

		this.modifications.push(mod);

		/* Asks the user to approve the modifications */
		if (this.inletForm.valid) {
			const pos = this.machineList.findIndex(x => x.machine_id === machine.machine_id);

			if (pos == -1) {
				const dialogRef = this.dialog.open(EditApprovalDialogComponent, {
					width: '60%',
					data: this.modifications
				});
				this.modifications = [];

				/* If user approved the modifications */
				dialogRef.afterClosed().subscribe(async data => {
					if (data) {
						this.isLoading = true;

						try {
							const r = await this.service.insertMachine(machine);

							if (r) {

								for (const i_elem of i_control.value) {

									const m_input = new MachineInput(null, this.mCreatorForm.get('machineId').value, i_elem.inlet, i_elem.sp.sp_id);
									const mi_id = await this.service.insertMachineInlet(m_input);
								}
								// create machine in hmi db
								// const respCode = await this.service.createHmiMachine(machine);
								// if (respCode != 200) {
								// 	this.openSnackBar('La machine est insérée dans la base de données mais une erreur est survenue lors de sa création dans le PythonDispatcher', 'Ok',);
								// } else {
								// 	this.snackbar.open('Machine créée avec succès.', 'Ok', {
								// 		duration: 5000
								// 	});
								// }
								this.resetMachineCreationForms();
								// this.dialogRef.close();
							}
						} catch (error) {
							this.openSnackBar('An error has occurred. Code: ' + error.status, 'Ok',);
						}
						this.isLoading = false;
					}
				});
			} else {
				this.openSnackBar('This machine already exist', 'Close');
			}
		} else {
			this.openSnackBar('Please fill the fields correctly', 'Close');
		}
	}

	cancelAndClose() {
		this.dialogRef.close();
	}

	openSnackBar(message, action) {
		this.snackbar.open(message, action, {duration: 5000});
	}

	private filterSamplingPoint() {
		if (!this.samplingPoints) {
			return;
		}

		let search = this.samplingPointFilterCtrl.value;
		if (!search) {
			this.filteredSamplingPoint.next(this.samplingPoints.slice());
			return;
		} else {
			search = search.toLowerCase();
		}

		this.filteredSamplingPoint.next(
			this.samplingPoints.filter(samplingPoint => samplingPoint.sp_tag.toLowerCase().indexOf(search) > -1)
		);
	}

	private resetMachineCreationForms() {
		this.buildMachineForm();
		this.buildInletForm();

		this.initForms();

		this.mCreatorForm.markAllAsTouched();
		this.inletForm.markAllAsTouched();
	}
}
