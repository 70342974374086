import {FullscreenChartService} from '../../../fullscreen-chart/fullscreen-chart.service';
import {CustomChartData} from '../../CustomChartData';
import {MChart} from '../MChart';


/* Concrete Chart for Factory Pattern */
export class MCalibSuccessHistoryChart implements MChart {

	last5: any;
	last10: any;
	last20: any;
	cartAvailable: any;

	datas: CustomChartData;

	private config = {
		responsive: true,
		displaylogo: false
	};

	private layout = {
		// items with a `name` attribute in template.images will be added to any
		// plot using this template
		images: [{
			source: '../../../../favicon.ico',
			xref: 'paper',
			yref: 'paper',
			x: 0.5,
			y: 0.5,
			xanchor: 'center',
			yanchor: 'middle',
			sizex: 0.7,
			sizey: 0.7,
			opacity: 0.1,
			layer: 'below'
		}],
		legend: {
			x: 0,
			y: 1.15,
			orientation: 'h',
			itemsizing: 'constant',
			itemwidth: 25
		},
		margin: {
			l: 35,
			r: 35,
			b: 50,
			t: 10
		},
		xaxis: {
			tickformat: '%d %b',
			tickangle: -45,
			showgrid: true,
			nticks: 36,
			showline: true,
			zeroline: false,
			mirror: true,
			hoverformat: '%Y-%m-%d %H:%M:%S'
		},
		yaxis: {
			range: [0, 100],
			showgrid: true,
			showline: true,
			zeroline: false,
			mirror: true
		},
		yaxis2: {
			title: 'Cart available',
			range: [0, 18],
			overlaying: 'y',
			side: 'right',
			showgrid: false,
			showline: false,
		},
		modebar: {
			color: '#000000',
			bgcolor: '#FFFFFF'
		}
	};


	constructor(private service: FullscreenChartService) {
	}

	public async createChart(machine_id: number, limit: number, time: string): Promise<CustomChartData> {

		this.last5 = await this.service.getVariableHistory(machine_id, 'calib_success_samplingpoint_5', time, limit);
		this.last10 = await this.service.getVariableHistory(machine_id, 'calib_success_samplingpoint_10', time, limit);
		this.last20 = await this.service.getVariableHistory(machine_id, 'calib_success_samplingpoint_20', time, limit);
		this.cartAvailable = await this.service.getVariableHistory(machine_id, 'cart_available', time, limit);

		const last5 = [];
		const last10 = [];
		const last20 = [];
		const cartAvailable = [];
		const date_last5 = [];
		const date_last10 = [];
		const date_last20 = [];
		const date_cart = [];

		this.datas = new CustomChartData([], this.layout, this.config);

		for (const last5_pts of this.last5){
			last5.push(last5_pts.map.value);
			date_last5.push(last5_pts.map.analysis_time);
		}
		for (const last10_pts of this.last10){
			last10.push(last10_pts.map.value);
			date_last10.push(last10_pts.map.analysis_time);
		}
		for (const last20_pts of this.last20){
			last20.push(last20_pts.map.value);
			date_last20.push(last20_pts.map.analysis_time);
		}
		for (const cart_pts of this.cartAvailable){
			cartAvailable.push(cart_pts.map.value);
			date_cart.push(cart_pts.map.analysis_time);
		}

		const trace_last5 = {
				name: 'Succès de calibration des 5 dernières analyses',
				x: date_last5,
				y: last5,
				type: 'scatter',
				mode: 'lines+markers',
				marker: {
					color: 'rgba(77, 19, 209, 1)'
				},
				line: {
					color: 'rgba(77, 19, 209, 1)'
				}
			};
		const trace_last10 = {
				name: 'Succès de calibration des 10 dernières analyses',
				x: date_last10,
				y: last10,
				type: 'scatter',
				mode: 'lines+markers',
				marker: {
					color: '#4ecdc4'
				},
				line: {
					color: '#4ecdc4'
				}
			};

		const trace_last20 = {
				name: 'Succès de calibration des 20 dernières analyses',
				x: date_last20,
				y: last20,
				type: 'scatter',
				mode: 'lines+markers',
				marker: {
					color: '#f89406'
				},
				line: {
					color: '#f89406'
				}
			};
		const trace_cart_available = {
			line: {shape: 'vh'},
			mode: 'lines+markers',
			name: 'Cart available',
			x: date_cart,
			y: cartAvailable,
			type: 'sctatter',
			marker: {
				size: 5,
				color: 'rgb(96,96,96)'
			},
			yaxis: 'y2'
		};
		this.datas.chartData = [trace_last5, trace_last10, trace_last20, trace_cart_available];
		return this.datas;
	}
}


