<body>

<mat-card>
	<mat-card-title>
		Calculation legend
	</mat-card-title>
	<mat-card-content>
		<mat-card>
			<mat-card-title> Calibration success </mat-card-title>
			<mat-card-content>
				<h3> Calibration failure condition </h3>
				<ul>
					<li> Experiment time = 30 mins </li>
					<p>&#8198; This difference represents a difference large enough to be a late delivery of one log compared to the other. Note that the experiment times for which the difference is less than 1.2 are observed in
						the scope control chart. </p>

					<li> | C1 - C2 | > 1.2 </li>
					<p> 1.2 corresponds to the UCL in absolute value of the scope </p>
				</ul>

				<h3> Calculation of calibration success </h3>
				<ul>
					<li> Calibration successful (100%): </li>
					<p> C1 = 50% and C2 = 50% </p>
					<li> Calibration failure (50%): </li>
					<p> C1 = 50% and C2 = 0% OR C1 = 0% and C2 = 50% </p>
					<li> Calibration failure (0%): </li>
					<p> C1 = 0% and C2 = 0% </p>
				</ul>

				<h3> Control limits </h3>
				<ul>
					<li> LCL warning (yellow) </li>
					<p> The average calibration success is less than 95% </p>
					<li> LCL alert (red) </li>
					<p> The average calibration success is less than 90% </p>
				</ul>
			</mat-card-content>
		</mat-card>

		<mat-card>
			<mat-card-title>TTm</mat-card-title>
			<mat-card-content>
				<h3> Control limits </h3>
				<ul>
					<li> UCL warning (yellow) </li>
					<p> Average TTm of the 30 previous analyzes + 1.5 x (standard deviation of TTm) </p>
					<li> UCL alert (red) </li>
					<p> Average TTm of the 30 previous analyzes + 2.5 x (standard deviation of TTm) </p>
					<li> LCL warning (yellow) </li>
					<p> Average TTm of the 30 previous analyzes - 1.5 x (standard deviation of TTm) </p>
					<li> LCL alert (red) </li>
					<p> Average TTm of the 30 previous analyzes - 2.5 x (standard deviation of TTm) </p>
				</ul>
			</mat-card-content>
		</mat-card>

		<mat-card>
			<mat-card-title> Scope (C1-C2) </mat-card-title>
			<mat-card-content>
				<h3> Application to Bio Alert </h3>
				<p> Use of only the scope (R chart) since the average of the calibrators is displayed with the TTm. This control chart shows the difference in output time between the two calibrators. This graph observes a
					abnormal deviation of the time difference of the calibrators. This control chart detects a deviation greater than 3 standard deviations. </p>

				<h3> Control limits </h3>
				<ul>
					<li> UCL warning (yellow) </li>
					<p> 1.1 </p>
					<li> UCL alert (red) </li>
					<p> 1.2 </p>
					<li> LCL warning (yellow) </li>
					<p> -1.1 </p>
					<li> LCL alert (red) </li>
					<p> -1.2 </p>
				</ul>
			</mat-card-content>
		</mat-card>


		<mat-card>
			<mat-card-title> TTm Variation </mat-card-title>
			<mat-card-content>
				<h3> Application to Bio Alert </h3>
				<p> Observation of the TTm calculated at each analysis and of its variation between them. These graphs may show abnormal deviation of customer's TAR water, consumables and / or device condition (machine failure). This
					control card detects a deviation greater than 3 standard deviations. </p>

				<h3> Control limits </h3>
				<ul>
					<li> UCL warning (yellow) </li>
					<p> Average of the variation of the TTm of the 30 previous analyzes + 1.5 x (standard deviation of the variation of the TTm of the 30 previous analyzes) </p>
					<li> UCL alert (red) </li>
					<p> Average of the variation of the TTm of the 30 previous analyzes + 2.5 x (standard deviation of the variation of the TTm of the 30 previous analyzes) </p>
					<li> LCL warning (yellow) </li>
					<p> Average of the TTm variation of the 30 previous analyzes - 1.5 x (standard deviation of the TTm variation of the previous 30 analyzes) </p>
					<li> LCL alert (red) </li>
					<p> Average of the TTm variation of the 30 previous analyzes - 2.5 x (standard deviation of the TTm variation of the previous 30 analyzes) </p>
				</ul>
			</mat-card-content>
		</mat-card>

		<mat-card>
			<mat-card-title> History of calibration success </mat-card-title>
			<mat-card-content>
				<h3> Application to Bio Alert </h3>
				<p> These data represent the average of the calibration success of the last X analyzes from a given date </p>
				<h3> Control limits </h3>
				<ul>
					<li> LCL warning (yellow) </li>
					<p> The average calibration success of the last X analyzes is less than 95% </p>
					<li> LCL alert (red) </li>
					<p> The average calibration success of the last X analyzes is less than 90% </p>
				</ul>

			</mat-card-content>
		</mat-card>

	</mat-card-content>
	<mat-card-actions fxLayoutAlign="end">
		<button mat-raised-button color="warn" (click)="closeDialog()">Close</button>
	</mat-card-actions>
</mat-card>

</body>
