<body>
<mat-card class="main-card">
	<div class="main-container" fxLayout="column">
		<div fxLayoutAlign="space-between">
			<div>
				<mat-card-title>Sampling points
					<!-- The button is only used for alignment (with the other settings pages) -->
					<button style="visibility: hidden" mat-button>
						<mat-icon>help_outline</mat-icon>
					</button>
				</mat-card-title>
			</div>
		</div>
		<mat-divider class="title-divider"></mat-divider>

		<div fxLayout="column" fxFlex="50%">
			<mat-drawer-container class="drawer-container">
				<div class="drawer-sidenav-content">
					<mat-card class="limits-container">

						<div class="overlay" *ngIf="fetchingSamplingPoints">
							<div class="overlay__wrapper">
								<div class="overlay__spinner">
									<button mat-raised-button color="primary" class="spinner"></button>
								</div>
							</div>
						</div>

						<div fxLayout="column" fxFlex="100%">
							<mat-accordion *ngFor="let samplingPoint of spList;  let i = index">
								<mat-expansion-panel>
									<mat-expansion-panel-header>
										<mat-panel-title class="panel-header-info">
											{{samplingPoint.sampling_name}}
										</mat-panel-title>

										<mat-panel-description>
											<mat-label>{{samplingPoint.description ? samplingPoint.description : '-'}}</mat-label>
										</mat-panel-description>

									</mat-expansion-panel-header>
									<div fxFlex fxLayout="row" fxLayoutGap="2.5%" fxLayoutAlign="start start">
										<div fxLayout="column">
											<button class="button-edit" mat-button color="primary" [hidden]="samplingPoint.access_level <=10"
															(click)="drawer.opened && samplingPoint.sp_id == oldSamplingPoint?.sp_id && formState == 'update' ? drawer.toggle() : drawer.open(); setFormUpdateSamplingPoint(samplingPoint, i);"
															matTooltip="Modify sampling point." matTooltipPosition="above"
															matTooltipShowDelay="500">
												<mat-icon>edit</mat-icon>
											</button>
										</div>

										<div fxLayout="column">
											<mat-label style="font-weight: bold">Infos</mat-label>
											<mat-label>ID: {{samplingPoint.sp_id}}</mat-label>
										</div>

										<div fxLayout="column">
											<mat-label style="font-weight: bold">Associated to</mat-label>
											<mat-label *ngIf="samplingPoint.site_tag">Site: {{samplingPoint.site_tag}}</mat-label>
											<mat-label *ngIf="!samplingPoint.site_tag" style="color: #797979">No site</mat-label>
											<mat-label *ngIf="samplingPoint.business_tag">Client: {{samplingPoint.business_tag}}</mat-label>
											<mat-label *ngIf="!samplingPoint.business_tag" style="color: #797979">No client</mat-label>
										</div>
									</div>

									<div fxFlex fxLayout="row" fxLayoutGap="2.5%" fxLayoutAlign="end start">
										<div fxLayout="column">
												<button class="button-delete"  mat-button color="warn" [hidden]="samplingPoint.access_level <=30"
																(click)="deleteSamplingPoint(samplingPoint)"
																matTooltip="Delete sampling point." matTooltipPosition="above"
																matTooltipShowDelay="500">
													<mat-icon>delete</mat-icon>
												</button>
										</div>
									</div>

								</mat-expansion-panel>
							</mat-accordion>

						</div>
					</mat-card>
					<button class="button-add" mat-button (click)="SetFormCreateSamplingPoint(); !drawer.opened ? drawer.toggle() : drawer.open" matTooltip="Create sampling point." matTooltipPosition="above" matTooltipShowDelay="500">
						<mat-icon>add</mat-icon>
					</button>
				</div>

				<mat-drawer #drawer class="drawer-sidenav" mode="side" position="end" [opened]="formState != ''">
					<mat-card class="form-container">

						<mat-card-title *ngIf="formState == 'update'">Sampling point modification</mat-card-title>
						<mat-card-title *ngIf="formState == 'create'">Sampling point creation</mat-card-title>

						<mat-divider class="title-divider"></mat-divider>

						<form [formGroup]="spCreatorForm">
							<div fxLayout="column" fxLayoutAlign="center start">
								<mat-progress-bar *ngIf="isLoading" mode="indeterminate" class="progress-bar"></mat-progress-bar>

								<div fxLayout="row" fxLayoutGap="2.5%" fxLayoutAlign="start center" style="margin-bottom: 5px">
									<div fxLayout="row" *ngIf="!addBusiness" style="align-items: center">
										<mat-form-field appearance="fill">
											<mat-label>Client</mat-label>
											<mat-select (selectionChange)='clientSelectedChanged($event)' formControlName="businessTag" required>
												<mat-option>
													<ngx-mat-select-search [formControl]="businessFilterCtrl" placeholderLabel="Search" noEntriesFoundLabel="No result"></ngx-mat-select-search>
												</mat-option>
												<ng-container *ngFor="let business of filteredBusinesses | async">
													<mat-option *ngIf="business.access_level >=30" [value]="business">
														{{business.business_tag}}
													</mat-option>
												</ng-container>
											</mat-select>

										</mat-form-field>
										<button (click)="clearBusinessSelect();" aria-label="Clear" mat-button mat-icon-button [disabled]="formState === 'update'">
											<mat-icon>close</mat-icon>
										</button>
									</div>

									<mat-form-field *ngIf="addBusiness" appearance="fill" style="margin-bottom: 5px">
										<mat-label>Create client</mat-label>
										<input formControlName="newBusinessTagInput" matInput placeholder="Client tag" type="text"
													 (change)='newBusinessTag=$event.target.value' [value]="newBusinessTag">
										<mat-error *ngIf="spCreatorForm.get('newBusinessTagInput').invalid">Format: AA00</mat-error>
									</mat-form-field>
									<button mat-mini-fab color="primary" *ngIf="addBusiness" (click)="createBusiness();" [disabled]="spCreatorForm.get('newBusinessTagInput').invalid || formState == 'update'">
										Create
									</button>

									<button mat-mini-fab color="basic" (click)="addBusiness=!addBusiness" [disabled]="formState == 'update'">
										<mat-icon *ngIf="addBusiness">close</mat-icon>
										<mat-icon *ngIf="!addBusiness">add</mat-icon>
									</button>
								</div>

								<div fxLayout="row" fxLayoutGap="2.5%" fxLayoutAlign="start center" style="margin-bottom: 5px">

									<mat-form-field appearance="fill" *ngIf="!addSite">
										<mat-label>Sites</mat-label>
										<mat-select formControlName="siteTag" (selectionChange)='siteSelectedChanged($event)' required>
											<ng-container *ngFor="let site of filteredSites | async">
												<mat-option *ngIf="site.access_level >=30" [value]="site">
													{{site.site_tag}}
												</mat-option>
											</ng-container>
										</mat-select>
									</mat-form-field>

									<mat-form-field *ngIf="addSite" appearance="fill">
										<mat-label>Create site</mat-label>
										<input formControlName="newSiteTagInput" matInput placeholder="Site tag" type="text"
													 (change)='newSite=$event.target.value' [value]="newSite">
										<mat-error *ngIf="spCreatorForm.get('newSiteTagInput').invalid">Format: AA0</mat-error>
									</mat-form-field>

									<button mat-mini-fab color="primary" *ngIf="addSite" (click)="createSite()" [disabled]="spCreatorForm.get('newSiteTagInput').invalid || formState == 'update'">
										Create
									</button>
									<button mat-mini-fab color="basic" (click)="addSite=!addSite" [disabled]="selectedBusiness == null || formState === 'update'">
										<mat-icon *ngIf="addSite">close</mat-icon>
										<mat-icon *ngIf="!addSite">add</mat-icon>
									</button>
								</div>

								<mat-form-field appearance="fill" style="margin-bottom: 5px">
									<mat-label>Sampling point number</mat-label>
									<input matInput placeholder="" formControlName="sp_num" required>
									<mat-error *ngIf="spCreatorForm.get('sp_num').hasError('pattern')">Format: 00</mat-error>
								</mat-form-field>

								<mat-form-field appearance="fill" style="margin-bottom: 5px">
									<mat-label>Description</mat-label>
									<input matInput placeholder="" formControlName="description">
								</mat-form-field>
							</div>

							<div fxLayoutAlign="space-between">
								<div>
									<button mat-raised-button style="margin-right: 10px" (click)="spCreatorForm.reset(); drawer.close()" type="button">Cancel</button>
									<button *ngIf="formState == 'update'" mat-raised-button style="min-width: 40px; padding: 0" (click)="restoreFormValue()" type="button" [disabled]="spCreatorForm.pristine" matTooltip="Initial values"
													matTooltipPosition="above" matTooltipShowDelay="300">
										<mat-icon>restore</mat-icon>
									</button>
								</div>
								<div *ngIf="formState == 'update'">
									<!--
									<button mat-raised-button color="warn" (click)="deleteSamplingPoint()" type="button" style="margin-right: 10px">Supprimer</button>
									-->
									<button mat-raised-button color="primary" (click)="updateSamplingPoint()" type="button" [disabled]="!spCreatorForm.dirty">Apply modifications</button>
								</div>
								<button mat-raised-button color="primary" *ngIf="formState == 'create'" (click)="createSamplingPoint()"
												[disabled]="(spCreatorForm.get('businessTag').invalid || spCreatorForm.get('siteTag').invalid || spCreatorForm.get('sp_num').invalid) || addBusiness || addSite">
									Create
								</button>
							</div>
						</form>
					</mat-card>
				</mat-drawer>
			</mat-drawer-container>
		</div>
	</div>
</mat-card>
</body>
