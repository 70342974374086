<body>
<mat-card class="main-card">
	<div class="main-container" fxLayout="column">
		<div fxLayoutAlign="space-between">
			<div>
				<mat-card-title>Notification rules
					<button (click)="openHelp()" mat-button matTooltip="Open help" matTooltipPosition="above" matTooltipShowDelay="500">
						<mat-icon>help_outline</mat-icon>
						Help
					</button>
				</mat-card-title>
			</div>
		</div>
		<mat-divider class="title-divider"></mat-divider>

		<div fxLayout="column" fxFlex="50%">
			<mat-drawer-container class="drawer-container">
				<div class="drawer-sidenav-content">

					<mat-card class="limits-container">

						<div class="overlay" *ngIf="fetchingLimits">
							<div class="overlay__wrapper">
								<div class="overlay__spinner">
									<button mat-raised-button color="primary" class="spinner"></button>
								</div>
							</div>
						</div>

						<div fxLayout="column" fxFlex="100%">
							<mat-accordion *ngFor="let limitGroup of groupedLimits;  let i = index">
								<mat-expansion-panel>
									<mat-expansion-panel-header>
										<mat-panel-title class="panel-header-info">
											{{limitGroup.limit_group_name}}
										</mat-panel-title>

										<mat-panel-description>
											<mat-label style="width: 150px">({{limitGroup.limits.length > 1 ? limitGroup.limits.length + ' limits' : limitGroup.limits.length + ' limit'}})</mat-label>
											<mat-label>{{limitGroup.limit_group_description}}</mat-label>
										</mat-panel-description>

									</mat-expansion-panel-header>
									<div>
										<div [class]="limit.is_active ? 'limit-row' : 'inactive-limit-row'" *ngFor="let limit of limitGroup.limits;  let j = index">
											<div fxLayout="column">
												<button class="button-edit" mat-button color="primary"
																(click)="drawer.opened && limit.cl_id == oldLimit.cl_id && formState == 'update' ? drawer.toggle() : drawer.open(); setFormUpdateLimit(limit, i, j)" matTooltip="Modify rule." matTooltipPosition="above"
																matTooltipShowDelay="500">
													<mat-icon>edit</mat-icon>
												</button>
												<button class="button-edit" mat-button color="primary"
																(click)="drawer.opened && formState == 'duplicate' ? drawer.toggle() : drawer.open(); setFormDuplicateLimit(limit, i, j)" matTooltip="Duplicate rule." matTooltipPosition="above" matTooltipShowDelay="500">
													<mat-icon>content_copy</mat-icon>
												</button>
											</div>
											<div class="limit-info-container" fxLayout="column" fxFlex="30%">
												<div class="limit-info" fxFlex fxLayout="column">
													<mat-label><span *ngIf="!limit.is_active">(inactive)</span> {{limit.vl_tag ? limit.vl_tag : '-'}}</mat-label>

													<mat-label style="color: #797979">{{limit.vl_description ? limit.vl_description : '-'}}</mat-label>

													<mat-divider class="title-divider"></mat-divider>
													<div>
														<span style="font-weight: bold">Variable: </span>
														<mat-label>{{limit.var_tag ? limit.var_tag : '-'}}</mat-label>
														<mat-icon *ngIf="limit.var_tag" class="variable-definition-tooltip" matTooltipPosition="above" matTooltipShowDelay="100" [matTooltip]="limit.description">info_outline
														</mat-icon>
													</div>
												</div>
											</div>
											<div class="limit-values-container" fxLayout="column" fxFlex="30%">
												<mat-label class="section-title">Limits values:</mat-label>
												<div fxLayout="row" fxLayout.lt-lg="column">
													<div class="limit-values" fxLayout="column">
														<table>
															<tr>
																<td style="background-color: #CECECE">lcl_a</td>
																<td style="background-color: #CECECE">lcl_w</td>
																<td style="background-color: #CECECE">ucl_w</td>
																<td style="background-color: #CECECE">ucl_a</td>
															</tr>
															<tr>
																<td>{{ limit.control_limits?.map?.lcl_a}}</td>
																<td>{{ limit.control_limits?.map?.lcl_w }}</td>
																<td>{{ limit.control_limits?.map?.ucl_w }}</td>
																<td>{{ limit.control_limits?.map?.ucl_a }}</td>
															</tr>
														</table>
													</div>
													<div class="limit-values" fxLayout="column">
														<mat-label>Method: {{limit.control_limits?.map?.cl_method}}</mat-label>
														<mat-label>Span: {{limit.control_limits?.map?.cl_span}}</mat-label>
													</div>
												</div>
											</div>

											<div class="target-machine-container" fxLayout="column" fxFlex="40%">
												<mat-label class="section-title">Targeted machine:</mat-label>
												<div class="target-machine">
													<div fxLayout="row wrap" *ngIf="limit.target_machines.length > 0 && limit.target_machines.length != machines.length; else allMachineTemplate">
														<div *ngFor="let machine of limit.target_machines; let i = index" style="padding-right: 5px">
															<mat-label>{{i < limit.target_machines.length - 1 ? machine.machine_tag + ',' : machine.machine_tag}}</mat-label>
														</div>
													</div>
													<ng-template #allMachineTemplate>
														<mat-label>All machines</mat-label>
													</ng-template>
												</div>
											</div>
										</div>
									</div>
								</mat-expansion-panel>
							</mat-accordion>
						</div>
					</mat-card>
					<button class="button-add" mat-button (click)="SetFormCreateNewLimit(); !drawer.opened ? drawer.toggle() : drawer.open" matTooltip="Create rule." matTooltipPosition="above" matTooltipShowDelay="500">
						<mat-icon>add</mat-icon>
					</button>
				</div>

				<mat-drawer #drawer class="drawer-sidenav" mode="side" position="end" [opened]="formState != ''">
					<mat-card class="form-container">

						<mat-card-title *ngIf="formState == 'update'">Limit modification</mat-card-title>
						<mat-card-title *ngIf="formState == 'create' || formState == 'duplicate'">Limit creation</mat-card-title>
						<mat-divider class="title-divider"></mat-divider>

						<form [formGroup]="limitForm">
							<div>
								<div>
									<h3 style="margin-bottom: 5px">Infos</h3>
									<mat-form-field appearance="fill" style="margin-right: 5px">
										<mat-label>Name of the limit</mat-label>
										<input matInput formControlName="vl_tag">
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Description of the limit</mat-label>
										<input matInput formControlName="vl_description">
									</mat-form-field>
								</div>
								<div style="margin-bottom: 10px">
									<mat-checkbox formControlName="is_active">Active</mat-checkbox>
								</div>

								<mat-divider></mat-divider>

								<div style="padding-top: 10px">
									<h3 style="margin-bottom: 5px">Watched variables</h3>
									<mat-form-field appearance="fill">
										<mat-label>Variables*</mat-label>
										<mat-select #variableSelect formControlName="variable" [(value)]="selectedVariable">
											<mat-option>
												<ngx-mat-select-search [formControl]="variableFilterCtrl" placeholderLabel="Search" noEntriesFoundLabel="No result"></ngx-mat-select-search>
											</mat-option>
											<mat-option *ngFor="let variable of filteredVariables | async" [value]="variable" fxLayoutAlign="space-between">
												{{variable.var_tag}}
												<span fxFlex="100%"></span>
												<mat-icon *ngIf="variableSelect.panelOpen" class="variable-list-definition-tooltip" matTooltipPosition="above" matTooltipShowDelay="200" [matTooltip]="variable.description">
													info_outline
												</mat-icon>
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>

								<mat-divider></mat-divider>

								<div style="padding-top: 10px">
									<div>
										<h3 style="margin-bottom: 5px">Limits values
											<!--<mat-icon style="align-self: center;" matTooltip="Toutes les limites sont exclusive sauf pour 'lcl_a' qui est inclusive (plus de détail dans la page d'aide)." matTooltipPosition="above" matTooltipShowDelay="300">
												help_outline
											</mat-icon>-->
											<mat-icon style="align-self: center;" matTooltip="All limits are exclusive except for 'lcl_a' which is inclusive (Visit our help page for more details)." matTooltipPosition="above" matTooltipShowDelay="300">
												help_outline
											</mat-icon>
										</h3>
									</div>

									<div>
										<mat-form-field appearance="fill" style="width: 75px; margin-right: 5px">
											<mat-label>lcl_a</mat-label>
											<input matInput formControlName="lcl_a">
										</mat-form-field>

										<mat-form-field appearance="fill" style="width: 75px; margin-right: 5px">
											<mat-label>lcl_w</mat-label>
											<input matInput formControlName="lcl_w">
										</mat-form-field>

										<mat-form-field appearance="fill" style="width: 75px; margin-right: 5px">
											<mat-label>ucl_w</mat-label>
											<input matInput formControlName="ucl_w">
										</mat-form-field>

										<mat-form-field appearance="fill" style="width: 75px; margin-right: 5px">
											<mat-label>ucl_a</mat-label>
											<input matInput formControlName="ucl_a">
										</mat-form-field>
									</div>

									<div>
										<mat-form-field #cl_method appearance="fill" style="width: 100px; margin-right: 5px">
											<mat-label>Method</mat-label>
											<mat-select formControlName="cl_method" [(value)]="selectedMethod" (selectionChange)="methodChange()">
												<mat-option>N/A</mat-option>
												<mat-option *ngFor="let cl_method of cl_methods" [value]="cl_method">
													{{cl_method}}
												</mat-option>
											</mat-select>
										</mat-form-field>

										<mat-form-field #cl_span appearance="fill" [style.visibility]="selectedMethod === 'multiple' ? 'visible' : 'hidden'" style="width: 175px; margin-right: 5px">
											<mat-label>Span (number of days)</mat-label>
											<input matInput formControlName="cl_span" [formNoValidate]="selectedMethod != 'multiple'">
											<mat-error>Number of days invalid</mat-error>
										</mat-form-field>
									</div>

								</div>

								<mat-divider></mat-divider>

								<div style="padding-top: 10px">
									<h3 style="margin-bottom: 5px">Targeted machines
										<!--<mat-icon matTooltip="Laisser la liste vide pour appliquer la limite à toutes les machines (plus de détail dans la page d'aide)." matTooltipPosition="above" matTooltipShowDelay="300">help_outline</mat-icon>-->
										<mat-icon matTooltip="Leave the list empty to apply the limit to all machines (Visit our help page for more details)" matTooltipPosition="above" matTooltipShowDelay="300">help_outline</mat-icon>
									</h3>

									<mat-form-field appearance="fill">
										<mat-label>Machines</mat-label>
										<mat-select formControlName="target_machines" multiple>
											<mat-option *ngFor="let machine of machines" [value]="machine">
												{{machine.machine_tag}}
											</mat-option>
										</mat-select>
									</mat-form-field>
									<button class="button-info" mat-button matTooltip="Select all" matTooltipPosition="above" matTooltipShowDelay="300" (click)="updateTargetMachinesCtrl('select all')">
										<mat-icon>select_all</mat-icon>
									</button>
									<button class="button-info" mat-button matTooltip="Clear selection" matTooltipPosition="above" matTooltipShowDelay="300" (click)="updateTargetMachinesCtrl('clear')">
										<mat-icon>clear</mat-icon>
									</button>
								</div>
							</div>
							<div fxLayoutAlign="space-between">
								<div>
									<button mat-raised-button style="margin-right: 10px" (click)="limitForm.reset(); drawer.close()" type="button">Cancel</button>
									<button *ngIf="formState == 'update'" mat-raised-button style="min-width: 40px; padding: 0" (click)="restoreFormValue()" type="button" [disabled]="limitForm.pristine" matTooltip="Initial values"
													matTooltipPosition="above" matTooltipShowDelay="300">
										<mat-icon>restore</mat-icon>
									</button>
								</div>
								<div *ngIf="formState == 'update'">
									<button mat-raised-button color="warn" (click)="deleteLimit()" type="button" style="margin-right: 10px">Delete</button>
									<button mat-raised-button color="primary" (click)="updateLimit()" type="button" [disabled]="!limitForm.dirty">Apply modifications</button>
								</div>
								<button mat-raised-button color="primary" (click)="createNewLimit()" type="button" *ngIf="formState == 'create' || formState == 'duplicate'" [disabled]="limitForm.invalid">Create</button>
							</div>
						</form>
					</mat-card>
				</mat-drawer>
			</mat-drawer-container>
		</div>
	</div>
</mat-card>
</body>
