<body>
<mat-tab-group mat-align-tabs="start">

	<!-- BIO ALERT -->
	<mat-tab label="Bio Alert">
		<mat-card class=bioAlert-form>
			<form [formGroup]="bioAlertForm">
				<mat-card formArrayName="bioAlertRow">
					<mat-card-title>BioAlert</mat-card-title>
					<mat-card-subtitle>Form</mat-card-subtitle>
					<mat-divider></mat-divider>

					<div *ngFor="let row of bioAlertForm.get('bioAlertRow')['controls']; let i=index">

						<mat-divider *ngIf="bioAlertForm.get('bioAlertRow')['controls'].length > 1 && i > 0"></mat-divider>
						<br>

						<div [formGroupName]="i">
							<h2>Insertion {{i + 1}}</h2>
							<md-error *ngIf="row.hasError('duplicated')" [style.color]="'red'">
								This form exists in the database.
							</md-error>
							<md-error *ngIf="row.hasError('badUnitNumberFormat')" [style.color]="'red'">
								Please enter a valid 'Unit No.' (2.0XX).
							</md-error>
							<h3>Information : </h3>
							<div>
								<div fxLayout="row wrap" fxLayoutGap="0.5%" fxLayoutAlign="start center">
									<mat-form-field appearance="fill">
										<mat-label>Date</mat-label>
										<input matInput placeholder="YYYY-MM-DD"  formControlName="date" required>
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>Time</mat-label>
										<input matInput placeholder="HH-MM-SS" type="text" formControlName="time" required>
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>Millisecond</mat-label>
										<input matInput placeholder="Millisecond" type="text" formControlName="millisecond">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>UnderVmr</mat-label>
										<input matInput placeholder="UnderVmr" type="text" formControlName="sousVmr">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>UG</mat-label>
										<input matInput placeholder="UG" type="text" formControlName="ug">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>Level</mat-label>
										<input matInput placeholder="Level" type="text" formControlName="niveau">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>Code</mat-label>
										<input matInput placeholder="Code" type="text" formControlName="code">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>TTm</mat-label>
										<input matInput placeholder="TTm" type="text" formControlName="ttm">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>Inlet No.</mat-label>
										<input matInput placeholder="Inlet No." type="text" formControlName="inletNo" required>
									</mat-form-field>
									<!--                      <mat-form-field appearance="fill">-->
									<!--                        <mat-label>Timestamp</mat-label>-->
									<!--                        <input matInput placeholder="Timestamp" type="text" formControlName="timestamp">-->
									<!--                      </mat-form-field>-->
									<mat-form-field appearance="fill">
										<mat-label>TT1</mat-label>
										<input matInput placeholder="TT1" type="text" formControlName="tt1">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>TT2</mat-label>
										<input matInput placeholder="TT2" type="text" formControlName="tt2">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>TT3</mat-label>
										<input matInput placeholder="TT3" type="text" formControlName="tt3">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>TT4</mat-label>
										<input matInput placeholder="TT4" type="text" formControlName="tt4">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>TL</mat-label>
										<input matInput placeholder="TL" type="text" formControlName="tl">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>Cartridges available</mat-label>
										<input matInput placeholder="Cartridges available" type="text" formControlName="cartDisp">
									</mat-form-field>
									<!--                      <mat-form-field appearance="fill">-->
									<!--                        <mat-label>Site No.</mat-label>-->
									<!--                        <input matInput placeholder="Site No." type="text" formControlName="siteNo">-->
									<!--                      </mat-form-field>-->
									<mat-form-field appearance="fill">
										<mat-label>Unit No.</mat-label>
										<input matInput placeholder="Unit No." type="text" formControlName="unitNo" required>
									</mat-form-field>
									<!--                      <mat-form-field appearance="fill">-->
									<!--                        <mat-label>Température début analyse</mat-label>-->
									<!--                        <input matInput placeholder="Température début analyse" type="text" formControlName="tempDebut">-->
									<!--                      </mat-form-field>-->
									<!--                      <mat-form-field appearance="fill">-->
									<!--                        <mat-label>Timestamp Offset</mat-label>-->
									<!--                        <input matInput placeholder="Timestamp Offset" type="text" formControlName="timestampOffset">-->
									<!--                      </mat-form-field>-->
									<mat-form-field appearance="fill">
										<mat-label>TTC_A</mat-label>
										<input matInput placeholder="TTC_A" type="text" formControlName="ttc_a">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>TTC_B</mat-label>
										<input matInput placeholder="TTC_B" type="text" formControlName="ttc_b">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>TTC_C</mat-label>
										<input matInput placeholder="TTC_C" type="text" formControlName="ttc_c">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>TTC_CAL</mat-label>
										<input matInput placeholder="TTC_CAL" type="text" formControlName="ttc_cal">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>TTm_min</mat-label>
										<input matInput placeholder="TTm_min" type="text" formControlName="ttm_min">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>TTm_max</mat-label>
										<input matInput placeholder="TTm_max" type="text" formControlName="ttm_max">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>TT_diff_max</mat-label>
										<input matInput placeholder="TT_diff_max" type="text" formControlName="tt_diff_max">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>TTC_diff_max</mat-label>
										<input matInput placeholder="TTC_diff_max" type="text" formControlName="ttc_diff_max">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>Lot No.</mat-label>
										<input matInput placeholder="no. Lot" type="text" formControlName="noLot">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>VMR</mat-label>
										<input matInput placeholder="VMR" type="text" formControlName="vmr">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>Sample date</mat-label>
										<input matInput placeholder="YYYY-MM-DD" type="text" formControlName="dateEchant">
									</mat-form-field>
									<mat-form-field appearance="fill">
										<mat-label>Sample time</mat-label>
										<input matInput placeholder="HH-MM-SS" type="text" formControlName="heureEchant">
									</mat-form-field>
								</div>
								<div fxLayout="row" fxLayoutAlign="space-between">
									<button type="button" mat-mini-fab color="warn" fxFlex="nogrow"
													*ngIf="bioAlertForm.get('bioAlertRow')['controls'].length > 1" (click)="removeBioAlertRow(i)">
										<mat-icon>delete forever</mat-icon>
									</button>
								</div>
								<mat-divider></mat-divider>
							</div>
						</div>
					</div>

					<mat-divider></mat-divider>
					<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
						<button type="button" mat-raised-button color="primary" (click)="clearAllBioAlertRow()">
							<mat-icon>remove_circle</mat-icon>
							Clear all
						</button>
						<button mat-raised-button color="primary" (click)="fileInput.click()">
							<mat-icon>add box</mat-icon>
							Choose csv
							<input type="file" #fileInput accept=".csv" (change)="fileChangeListener($event)" style="display:none;"/>
						</button>
						<button mat-raised-button color="primary" (click)="openDialog()">
							<mat-icon>add box</mat-icon>
							Enter htm
						</button>
						<button type="button" mat-raised-button color="primary" (click)="addBioAlertFromButtonRow()">
							<mat-icon>add box</mat-icon>
							Add row
						</button>
						<button type="button" mat-raised-button color="primary" (click)="uploadBioAlertForm()">
							<mat-icon>file_upload</mat-icon>
							Submit
						</button>
					</mat-card-actions>
					<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
				</mat-card>
			</form>
		</mat-card>
	</mat-tab>

	<!-- QLAMP -->
	<mat-tab label="Qlamp">
		<mat-card class=qlamp-form>
			<form [formGroup]="qlampForm">
				<mat-card formArrayName="qlampRow">
					<mat-card-title>Qlamp</mat-card-title>
					<mat-card-subtitle>Form</mat-card-subtitle>
					<mat-divider></mat-divider>

					<div *ngFor="let row of qlampForm.get('qlampRow')['controls']; let i=index">

						<mat-divider *ngIf="qlampForm.get('qlampRow')['controls'].length > 1 && i > 0"></mat-divider>
						<br>

						<div [formGroupName]="i">
							<h2>Insertion {{i + 1}}</h2>
							<md-error *ngIf="row.hasError('duplicated')" [style.color]="'red'">
								This form exists in the database.
							</md-error>
							<h3>Informations : </h3>
							<div>
								<div fxLayout="row wrap" fxLayoutGap="0.5%" fxLayoutAlign="start center">
									<mat-form-field appearance="fill">
										<mat-label>Client</mat-label>
										<mat-select (selectionChange)="clientSelectedChanged($event, i, 'qlamp')" formControlName="businessTag" required>
											<mat-option *ngFor="let business of businesses" [value]="business.business_id">
												{{business.business_tag}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Site</mat-label>
										<mat-select (selectionChange)="sitesSelectedChanged($event, i)" formControlName="siteTag" required>
											<mat-option *ngFor="let site of sites" [value]="site.site_id">
												{{site.site_tag}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Sampling point</mat-label>
										<mat-select formControlName="spId" required>
											<mat-option *ngFor="let s of samplingPoints" [value]="s.sp_tag">
												{{s.sp_tag}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Sampling date</mat-label>
										<input matInput placeholder="YYYY-MM-DD" formControlName="datePrelevement" required>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Sampling time</mat-label>
										<input matInput placeholder="HH-MM-SS" formControlName="timePrelevement" required>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Analysis date</mat-label>
										<input matInput placeholder="YYYY-MM-DD" formControlName="dateAnalyse" required>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Analysis time</mat-label>
										<input matInput placeholder="HH-MM-SS" formControlName="heureAnalyse" required>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Laboratory name</mat-label>
										<mat-select formControlName="labName" required>
											<mat-option *ngFor="let lab of labs" [value]="lab">
												{{lab}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Analysis type</mat-label>
										<input matInput placeholder="Analysis type" formControlName="analysisType" required>
									</mat-form-field>
								</div>

								<h3>Results :</h3>

								<div fxLayout="row wrap" fxLayoutGap="0.5%" fxLayoutAlign="start center">
									<mat-form-field appearance="fill" fxFlex=7%>
										<mat-label>vmr_result</mat-label>
										<mat-select formControlName="vmr_result" required>
											<mat-option *ngFor="let option of vmrOptions" [value]="option">
												{{option}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Result (UG/ml)</mat-label>
										<input matInput placeholder="Result (UG/ml)" formControlName="result" required>
									</mat-form-field>

									<button type="button" mat-mini-fab color="warn" fxFlex="nogrow"
													*ngIf="qlampForm.get('qlampRow')['controls'].length > 1" (click)="removeQlampRow(i)">
										<mat-icon>delete forever</mat-icon>
									</button>
								</div>
							</div>
						</div>
					</div>

					<mat-divider></mat-divider>
					<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
						<button type="button" mat-raised-button color="primary" (click)="clearAllQlampRow()">
							<mat-icon>remove_circle</mat-icon>
							Clear all
						</button>
						<button type="button" mat-raised-button color="primary" (click)="addQlampRow()">
							<mat-icon>add box</mat-icon>
							Add row
						</button>
						<button type="button" mat-raised-button color="primary" (click)="uploadQlampForm()">
							<mat-icon>file_upload</mat-icon>
							Submit
						</button>
					</mat-card-actions>
					<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
				</mat-card>
			</form>
		</mat-card>
	</mat-tab>

	<!-- QPCR -->
	<mat-tab label="QPcr">
		<mat-card class=qpcr-form>
			<form [formGroup]="qpcrForm">
				<mat-card formArrayName="qpcrRow">
					<mat-card-title>Qpcr</mat-card-title>
					<mat-card-subtitle>Form</mat-card-subtitle>
					<mat-divider></mat-divider>

					<div *ngFor="let row of qpcrForm.get('qpcrRow')['controls']; let i=index">

						<mat-divider *ngIf="qpcrForm.get('qpcrRow')['controls'].length > 1 && i > 0"></mat-divider>
						<br>

						<div [formGroupName]="i">
							<h2>Insertion {{i + 1}}</h2>
							<md-error *ngIf="row.hasError('duplicated')" [style.color]="'red'">
								This form exists in the database.
							</md-error>
							<h3>Informations : </h3>
							<div>
								<div fxLayout="row wrap" fxLayoutGap="0.5%" fxLayoutAlign="start center">
									<mat-form-field appearance="fill">
										<mat-label>Client</mat-label>
										<mat-select (selectionChange)="clientSelectedChanged($event, i, 'qpcr')" formControlName="businessTag" required>
											<mat-option *ngFor="let business of businesses" [value]="business.business_id">
												{{business.business_tag}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Site</mat-label>
										<mat-select (selectionChange)="sitesSelectedChanged($event, i)" formControlName="siteTag" required>
											<mat-option *ngFor="let site of sites" [value]="site.site_id">
												{{site.site_tag}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Sampling point</mat-label>
										<mat-select formControlName="spId" required>
											<mat-option *ngFor="let s of samplingPoints" [value]="s.sp_tag">
												{{s.sp_tag}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Sampling date</mat-label>
										<input matInput placeholder="YYYY-MM-DD" formControlName="datePrelevement" required>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Sampling time</mat-label>
										<input matInput placeholder="HH-MM-SS" formControlName="timePrelevement" required>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Analysis date</mat-label>
										<input matInput placeholder="YYYY-MM-DD" formControlName="dateAnalyse" required>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Analysis time</mat-label>
										<input matInput placeholder="HH-MM-SS" formControlName="heureAnalyse" required>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Laboratory name</mat-label>
										<mat-select formControlName="labName" required>
											<mat-option *ngFor="let lab of labs" [value]="lab">
												{{lab}}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>

								<h3>Results :</h3>

								<div fxLayout="row wrap" fxLayoutGap="0.5%" fxLayoutAlign="start center">
									<mat-form-field appearance="fill" fxFlex=7%>
										<mat-label>vmr_sg1_15</mat-label>
										<mat-select formControlName="vmr_sg1_15" required>
											<mat-option *ngFor="let option of vmrOptions" [value]="option">
												{{option}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Result | SG1-15 (UFC/ml)</mat-label>
										<input matInput placeholder="Result | SG1-15 (UFC/ml)" formControlName="sg1_15" required>
									</mat-form-field>

									<mat-form-field appearance="fill" fxFlex=7%>
										<mat-label>vmr_sg1</mat-label>
										<mat-select formControlName="vmr_sg1" required>
											<mat-option *ngFor="let option of vmrOptions" [value]="option">
												{{option}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Result | SG1 (UFC/ml)</mat-label>
										<input matInput placeholder="Result | SG1 (UFC/ml)" formControlName="sg1" required>
									</mat-form-field>

									<button type="button" mat-mini-fab color="warn" fxFlex="nogrow"
													*ngIf="qpcrForm.get('qpcrRow')['controls'].length > 1" (click)="removeQpcrRow(i)">
										<mat-icon>delete forever</mat-icon>
									</button>
								</div>
							</div>
						</div>
					</div>

					<mat-divider></mat-divider>
					<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
						<button type="button" mat-raised-button color="primary" (click)="clearAllQpcrRow()">
							<mat-icon>remove_circle</mat-icon>
							Clear all
						</button>
						<button type="button" mat-raised-button color="primary" (click)="addQpcrRow()">
							<mat-icon>add box</mat-icon>
							Add row
						</button>
						<button type="button" mat-raised-button color="primary" (click)="uploadQpcrForm()">
							<mat-icon>file_upload</mat-icon>
							Submit
						</button>
					</mat-card-actions>
					<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
				</mat-card>
			</form>
		</mat-card>
	</mat-tab>

	<!-- CULTURE -->
	<mat-tab label="Culture">
		<mat-card class=culture-form>
			<form [formGroup]="cultureForm">
				<mat-card formArrayName="cultureRow">
					<mat-card-title>Culture</mat-card-title>
					<mat-card-subtitle>Form</mat-card-subtitle>
					<mat-divider></mat-divider>

					<div *ngFor="let row of cultureForm.get('cultureRow')['controls']; let i=index">

						<mat-divider *ngIf="cultureForm.get('cultureRow')['controls'].length > 1 && i > 0"></mat-divider>
						<br>

						<div [formGroupName]="i">
							<h2>Insertion {{i + 1}}</h2>
							<md-error *ngIf="row.hasError('duplicated')" [style.color]="'red'">
								This form exists in the database.
							</md-error>
							<h3>Informations : </h3>
							<div>
								<div fxLayout="row wrap" fxLayoutGap="0.5%" fxLayoutAlign="start center">
									<mat-form-field appearance="fill">
										<mat-label>Client</mat-label>
										<mat-select (selectionChange)="clientSelectedChanged($event, i, 'culture')" formControlName="businessTag" required>
											<mat-option *ngFor="let business of businesses" [value]="business.business_id">
												{{business.business_tag}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Site</mat-label>
										<mat-select (selectionChange)="sitesSelectedChanged($event, i)" formControlName="siteTag" required>
											<mat-option *ngFor="let site of sites" [value]="site.site_id">
												{{site.site_tag}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Sampling point</mat-label>
										<mat-select formControlName="spId" required>
											<mat-option *ngFor="let s of samplingPoints" [value]="s.sp_tag">
												{{s.sp_tag}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Sampling date</mat-label>
										<input matInput placeholder="YYYY-MM-DD" formControlName="datePrelevement" required>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Sampling time</mat-label>
										<input matInput placeholder="HH-MM-SS" formControlName="timePrelevement" required>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Analysis date</mat-label>
										<input matInput placeholder="YYYY-MM-DD" formControlName="dateAnalyse" required>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Analysis time</mat-label>
										<input matInput placeholder="HH-MM-SS" formControlName="heureAnalyse" required>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Laboratory name</mat-label>
										<mat-select formControlName="labName" required>
											<mat-option *ngFor="let lab of labs" [value]="lab">
												{{lab}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>RBQ</mat-label>
										<mat-select formControlName="rbq" required>
											<mat-option *ngFor="let resp of YesNo" [value]="resp">
												{{resp}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Treatment</mat-label>
										<mat-select (selectionChange)="treatmentSelectionChanged($event, i)" formControlName="treatment" required>
											<mat-option *ngFor="let resp of YesNo" [value]="resp">
												{{resp}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill" *ngIf="treatment_val[i] == 'true'">
										<mat-label>Treatment (Description)</mat-label>
										<mat-select formControlName="treatment_desc">
											<mat-option *ngFor="let resp of treatment_options" [value]="resp">
												{{resp}}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<h3>Results :</h3>
								<div fxLayout="row wrap" fxLayoutGap="0.5%" fxLayoutAlign="start center">

									<mat-form-field appearance="fill" fxFlex=7%>
										<mat-label>vmr_spp</mat-label>
										<mat-select formControlName="vmr_spp" required>
											<mat-option *ngFor="let option of vmrOptions" [value]="option">
												{{option}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Result | L.spp.(UFC/ml)</mat-label>
										<input matInput placeholder="Result | L.spp.(UFC/ml)" formControlName="spp" required>
									</mat-form-field>

									<mat-form-field appearance="fill" fxFlex=7%>
										<mat-label>vmr_sg1_15</mat-label>
										<mat-select formControlName="vmr_sg1_15" required>
											<mat-option *ngFor="let option of vmrOptions" [value]="option">
												{{option}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Result | SG1-15 (UFC/ml)</mat-label>
										<input matInput placeholder="Result | SG1-15 (UFC/ml)" formControlName="sg1_15" required>
									</mat-form-field>

									<mat-form-field appearance="fill" fxFlex=7%>
										<mat-label>vmr_sg1</mat-label>
										<mat-select formControlName="vmr_sg1" required>
											<mat-option *ngFor="let option of vmrOptions" [value]="option">
												{{option}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Result | SG1 (UFC/ml)</mat-label>
										<input matInput placeholder="Result | SG1 (UFC/ml)" formControlName="sg1" required>
									</mat-form-field>

									<mat-form-field appearance="fill" fxFlex=7%>
										<mat-label>vmr_sg2_15</mat-label>
										<mat-select formControlName="vmr_sg2_15" required>
											<mat-option *ngFor="let option of vmrOptions" [value]="option">
												{{option}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Result | SG2-15 (UFC/ml)</mat-label>
										<input matInput placeholder="Result | SG2-15 (UFC/ml)" formControlName="sg2_15" required>
									</mat-form-field>

									<button type="button" mat-mini-fab color="warn" fxFlex="nogrow"
													*ngIf="cultureForm.get('cultureRow')['controls'].length > 1" (click)="removeCultureRow(i)">
										<mat-icon>delete forever</mat-icon>
									</button>
								</div>
							</div>
						</div>
					</div>

					<mat-divider></mat-divider>
					<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
						<button type="button" mat-raised-button color="primary" (click)="clearAllCultureRow()">
							<mat-icon>remove_circle</mat-icon>
							Clear all
						</button>
						<button type="button" mat-raised-button color="primary" (click)="addCultureRow()">
							<mat-icon>add box</mat-icon>
							Add row
						</button>
						<button type="button" mat-raised-button color="primary" (click)="uploadCultureForm()">
							<mat-icon>file_upload</mat-icon>
							Submit
						</button>
					</mat-card-actions>
					<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
				</mat-card>
			</form>
		</mat-card>
	</mat-tab>

	<!-- ATP -->
	<mat-tab label="Atp">
		<mat-card class=atp-form>
			<form [formGroup]="atpForm">
				<mat-card formArrayName="atpRow">
					<mat-card-title>Atp</mat-card-title>
					<mat-card-subtitle>Form</mat-card-subtitle>
					<mat-divider></mat-divider>

					<div *ngFor="let row of atpForm.get('atpRow')['controls']; let i=index">

						<mat-divider *ngIf="atpForm.get('atpRow')['controls'].length > 1 && i > 0"></mat-divider>
						<br>

						<div [formGroupName]="i">
							<h2>Insertion {{i + 1}}</h2>
							<md-error *ngIf="row.hasError('duplicated')" [style.color]="'red'">
								This form exists in the database.
							</md-error>
							<h3>Informations : </h3>
							<div>
								<div fxLayout="row wrap" fxLayoutGap="0.5%" fxLayoutAlign="start center">
									<mat-form-field appearance="fill">
										<mat-label>Client</mat-label>
										<mat-select (selectionChange)="clientSelectedChanged($event, i, 'atp')" formControlName="businessTag" required>
											<mat-option *ngFor="let business of businesses" [value]="business.business_id">
												{{business.business_tag}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Site</mat-label>
										<mat-select (selectionChange)="sitesSelectedChanged($event, i)" formControlName="siteTag" required>
											<mat-option *ngFor="let site of sites" [value]="site.site_id">
												{{site.site_tag}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Sampling point</mat-label>
										<mat-select formControlName="spId" required>
											<mat-option *ngFor="let s of samplingPoints" [value]="s.sp_tag">
												{{s.sp_tag}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Sampling date</mat-label>
										<input matInput placeholder="YYYY-MM-DD" formControlName="datePrelevement" required>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Sampling time</mat-label>
										<input matInput placeholder="HH-MM-SS" formControlName="timePrelevement" required>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Analysis date</mat-label>
										<input matInput placeholder="YYYY-MM-DD" formControlName="dateAnalyse" required>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Analysis time</mat-label>
										<input matInput placeholder="HH-MM-SS" formControlName="heureAnalyse" required>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Laboratory name</mat-label>
										<mat-select formControlName="labName" required>
											<mat-option *ngFor="let lab of labs" [value]="lab">
												{{lab}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Company</mat-label>
										<input matInput placeholder="Company" formControlName="company" required>
									</mat-form-field>
								</div>
								<h3>Results :</h3>
								<div fxLayout="row wrap" fxLayoutGap="0.5%" fxLayoutAlign="start center">

									<mat-form-field appearance="fill">
										<mat-label>Result | Free (RLU)</mat-label>
										<input matInput placeholder="Result | Free (RLU)" formControlName="free" required>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Result | Total (RLU)</mat-label>
										<input matInput placeholder="Result | Total (RLU)" formControlName="total" required>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Result | Bacterial (RLU)</mat-label>
										<input matInput placeholder="Result | Bacterial (RLU)" formControlName="bacterial" required>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Result | Equivalent (RLU)</mat-label>
										<input matInput placeholder="Result | Equivalent (RLU)" formControlName="equivalent" required>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<!--<mat-label>Result | Niveau de conformité</mat-label>-->
										<mat-label>Result | Standard level</mat-label>
										<mat-select formControlName="standard" required>
											<mat-option *ngFor="let s of standards" [value]="s">
												{{s}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<button type="button" mat-mini-fab color="warn" fxFlex="nogrow"
													*ngIf="atpForm.get('atpRow')['controls'].length > 1" (click)="removeAtpRow(i)">
										<mat-icon>delete forever</mat-icon>
									</button>
								</div>
							</div>
						</div>
					</div>

					<mat-divider></mat-divider>
					<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
						<button type="button" mat-raised-button color="primary" (click)="clearAllAtpRow()">
							<mat-icon>remove_circle</mat-icon>
							Clear all
						</button>
						<button type="button" mat-raised-button color="primary" (click)="addAtpRow()">
							<mat-icon>add box</mat-icon>
							Add row
						</button>
						<button type="button" mat-raised-button color="primary" (click)="uploadAtpForm()">
							<mat-icon>file_upload</mat-icon>
							Submit
						</button>
					</mat-card-actions>
					<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
				</mat-card>
			</form>
		</mat-card>
	</mat-tab>
</mat-tab-group>
</body>
