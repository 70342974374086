export class Analysis {

	constructor(
		public analysis_id: number,
		public sample_id: number,
		public method_id: number,
		public analysis_time: any,
		public machine_id: number,
		public meta_info: String
	) {
	}

}
