<h2 mat-dialog-title *ngIf="caller === 'ExportForm'">Exporting analysis and calculations</h2>
<h2 mat-dialog-title *ngIf="caller === 'MachineLimits'">Notification rules</h2>
<mat-dialog-content class="mat-typography">
	<div *ngIf="caller === 'ExportForm'">
		<mat-card style="margin: 10px 0 20px 0; padding: 20px">
			<mat-card-title>Parameter selection</mat-card-title>
			<mat-divider style="position: relative"></mat-divider>

			<h3 class="help-sub-title">For all form parameters</h3>
			<p> Leaving the input empty to select all of its values (same thing for the list of selected columns). </p>

			<h3 class="help-sub-title">Machines</h3>
			<p> Displays all available machines. </p>
			<p> Allows you to filter the export by keeping only certain machines. </p>

			<h3 class="help-sub-title">Sampling Points</h3>
			<p> Displays the sampling points according to the selected machines. </p>
			<p> Allows you to filter the export by keeping only certain sampling points. </p>

			<h3 class = "help-sub-title"> Date interval </h3>
			<p> Allows you to select analysis according to a period. </p>
			<ul>
				<li> Selecting a start and end date allows you to choose a period. </li>
				<li> Selecting only a start date will export analyzes from that start date. </li>
				<li> Selecting only an end date exports all analyzes up to this one (including the end date).
					<p>To select only an end date, you must choose an end and start date and then manually delete the start date.</p>
				</li>
			</ul>

			<h3 class = "help-sub-title">>Consumable lots </h3>
			<p> Allows you to filter the export by keeping only certain batches of consumables. </p>
			<p> Batch selection works like page selection when printing (i.e. "3,6" -> 3,6 and "3-6" -> 3,4,5,6). </p>

			<h3 class = "help-sub-title"> Select columns </h3>
			<p> The lists allow you to choose the columns that will appear in the CSV file. <br> The list on the left represents the available columns and the one on the right, the columns
				selected. </p>
			<p> Some selection methods using Drag and Drops: </p>
			<ul>
				<li> Drag columns one at a time. </li>
				<li> Ctrl + Click allows you to select several columns and then drag the group to the list on the right. </li>
				<li> Click on an item and Shift + Click on another allows you to select a group of columns and then drag them to the right. </li>
			</ul>


			<h3 class = "help-sub-title"> Export to CSV </h3>
			<p> The "Add the generated query to the CSV" CheckBox allows you to add to the CSV the generated query that contains the parameters entered in the form. </p>
			<p> The "Export data" button retrieves the information and creates a CSV. </p>
		</mat-card>

		<mat-card>
			<mat-card-title> Generating / Using queries </mat-card-title>
			<mat-divider> </mat-divider>

			<h3 class = "help-sub-title"> Description </h3>
			<p> The Generating / Using queries tool allows users to save the settings of an export to reproduce it in the future. </p>

			<h3 class = "help-sub-title"> Generating a query </h3>
			<p> Start by filling out the form with the desired parameters and then click on the "generate query" button. <br>
				A textual query will then be generated and can be set aside for future use. </p>

			<h3 class = "help-sub-title"> Using a query </h3>
			<p> To use a previously created query, paste it in the text box and the parameters will then be updated. </p>
			<p> All that remains is to export the data in CSV. </p>
		</mat-card>
	</div>


	<div *ngIf="caller === 'MachineLimits'">
		<mat-card style = "margin: 10px 0 20px 0; padding: 20px">

			<h3 class = "help-sub-title"> How the rules work </h3>
			<p> Notification rules are limits that allow you to monitor the value of certain analysis variables and raise notifications as needed. </p>

			<p> A variable can have multiple rules that target different machines. </p>

			<p> An inactive rule will not raise a notification if its limits are exceeded. </p>

			<h3 class = "help-sub-title"> Target machines </h3>
			<p> A rule can apply to one machine, several machines, or all machines. </p>

			<p> To assign a rule to all machines (even future machines), the list of selected machines must be empty. <br>
				If all the machines in the list are selected, a machine added later will not be included in the rule. </p>

			<h3 class = "help-sub-title"> Rule priority </h3>

			<p> The order of the rules in the list represents their priority. Rules above take precedence over lower ones. </p>

			<p> If a machine is affected by more than one rule, the rule that affects the fewest machines will take precedence. <br>
				<span style = "font-weight: bold"> Eg </span> For 'B0005', the rule that targets 'B0005','B0006' will take precedence over another that targets 'B0005', 'B0007', 'B0013'. </p>

			<p> If a machine is targeted by several rules which have the same priority (one targeting 'B0005', 'B0006' and the other 'B0005', 'B0009'), the most recently created rule will take priority. <p> / p>

			<h3 class = "help-sub-title"> Values of the limits </h3>
			<p> All limits are exclusive except for lcl_a which is inclusive. </p>
			<p> lcl_a is inclusive to allow triggering an alert when the number of cartridges equals 0. </p>

			<h3 class = "help-sub-title"> Method of a rule </h3>
			<p> The method of a rule represents how the value will be calculated. </p>

			<p> <span style = "font-weight: bold"> No method: </span> limits will be checked against the raw value of the variable. </p>

			<p> <span style = "font-weight: bold"> 'Multiple' method: </span> the limits will be checked according to the delta between the raw value of a variable and its average over a certain number of past days. <br>
				You will need to enter a 'span' which represents the number of days over which the average will be calculated. </p>

		</mat-card>
	</div>


	<div *ngIf="caller == undefined">
		This page must receive a 'caller' when it is opened.
	</div>
</mat-dialog-content>

<!-- French Version
<h2 mat-dialog-title *ngIf="caller === 'ExportForm'">Exportation d'analyses et calculs</h2>
<h2 mat-dialog-title *ngIf="caller === 'MachineLimits'">Règles de notification</h2>
<mat-dialog-content class="mat-typography">
	<div *ngIf="caller === 'ExportForm'">
		<mat-card style="margin: 10px 0 20px 0; padding: 20px">
			<mat-card-title>Sélection de paramètres</mat-card-title>
			<mat-divider style="position: relative"></mat-divider>

			<h3 class="help-sub-title">Pour tous les paramètres du formulaire</h3>
			<p>Laisser l'input vide équivaut à sélectionner l'entièreté de ses valeurs (même chose pour la liste des colonnes sélectionnées).</p>

			<h3 class="help-sub-title">Machines</h3>
			<p>Affiche toutes les machines disponibles.</p>
			<p>Permet de filtrer l'exportation en ne gardant que certaines machines.</p>

			<h3 class="help-sub-title">Sampling Points</h3>
			<p>Affiche les sampling points en fonction des machines sélectionnées.</p>
			<p>Permet de filtrer l'exportation en ne gardant que certains sampling points.</p>

			<h3 class="help-sub-title">Intervalle de date</h3>
			<p>Permet de sélectionner des analyses selon une période.</p>
			<ul>
				<li>Sélectionner une date de début et de fin permet de choisir une période.</li>
				<li>Sélectionner seulement une date de début permet d'exporter les analyses depuis celle-ci.</li>
				<li>Sélectionner seulement une date de fin exporte toutes les analyses jusqu'à celle-ci (incluant la date de fin).
					<p>Pour sélectionner seulement une date de fin, il faut choisir une date de fin et de début et ensuite effacer manuellement la date de début.</p>
				</li>
			</ul>

			<h3 class="help-sub-title">Lots de consommables</h3>
			<p>Permet de filtrer l'exportation en ne gardant que certains lots de consommables.</p>
			<p>La sélection de lots fonctionne comme la sélection de pages lors d'une impression (i.e. "3,6" -> 3,6 et "3-6" -> 3,4,5,6).</p>

			<h3 class="help-sub-title">Sélection de colonnes</h3>
			<p>Les listes permettent de choisir les colonnes qui apparaitront dans le fichier CSV.<br>La liste de gauche représente les colonnes disponibles et celle de droite, les colonnes
				sélectionnées.</p>
			<p>Quelques méthodes de sélection à l'aide de Drag and Drops:</p>
			<ul>
				<li>Glisser les colonnes une à la fois.</li>
				<li>Ctrl+Clic permet de sélectionner plusieurs colonnes et d'ensuite glisser le groupe vers la liste de droite.</li>
				<li>Clic sur un item et Shift+Clic sur un autre permet de sélectionner un groupe de colonnes et d'ensuite les glisser à droite.</li>
			</ul>


			<h3 class="help-sub-title">Exportation en CSV</h3>
			<p>Le CheckBox "Ajouter la requête générée au CSV" permet d'ajouter au CSV la requête générée qui contient les paramètres entrés dans le formulaire.</p>
			<p>Le bouton "Exporter les données" récupère les informations et crée un CSV.</p>

		</mat-card>
		<mat-card>
			<mat-card-title>Génération / Utilisation de requêtes</mat-card-title>
			<mat-divider></mat-divider>

			<h3 class="help-sub-title">Description</h3>
			<p>L'outil de génération/utilisation de requêtes permet aux utilisateurs de sauvegarder les paramètres d'une exportation pour la reproduire dans le futur.</p>

			<h3 class="help-sub-title">Génération d'une requête</h3>
			<p>Commencer par remplir le formulaire avec les paramètres souhaités et cliquer ensuite sur le bouton "générer requête".<br>
				Une requête textuelle sera alors générée et pourra être mise de côté pour utilisation future.</p>

			<h3 class="help-sub-title">Utilisation d'une requête</h3>
			<p>Pour utiliser une requête préalablement créée, la coller dans la zone de texte et les paramètres seront alors mis à jour.</p>
			<p>Il ne reste plus qu'à exporter les données en CSV.</p>
		</mat-card>
	</div>


	<div *ngIf="caller === 'MachineLimits'">
		<mat-card style="margin: 10px 0 20px 0; padding: 20px">

			<h3 class="help-sub-title">Fonctionnement des règles</h3>
			<p>Les règles de notification sont des limites qui permettent de surveiller la valeur de certaines variables d'analyses et de lancer des notifications au besoin.</p>

			<p>Une variable peut avoir plusieurs règles qui visent différentes machines.</p>

			<p>Une règle inactive ne lancera pas de notification si ses limites sont dépassées.</p>


			<h3 class="help-sub-title">Viser des machines</h3>
			<p>Une règle peut viser une machine, plusieurs machines ou toutes les machines.</p>

			<p>Pour attribuer une règle à toutes les machines (même les futures machines), la liste des machines sélectionnées doit être vide.<br>
				Si toutes les machines de la liste sont sélectionnées, une machine ajoutée plus tard ne sera pas incluse dans la règle.</p>

			<h3 class="help-sub-title">Priorité des règles</h3>

			<p>L'ordre des règles dans la liste représente leur priorité. Les règles au-dessus sont prioritaires sur celles plus basses.</p>

			<p>Si une machine est visée par plusieurs règles, la règle qui touche le moins de machines aura priorité.<br>
				<span style="font-weight: bold">E.g.</span> Pour 'B0005', la règle qui vise 'B0005', 'B0006' aura priorité sur une autre qui vise 'B0005', 'B0007', 'B0013'.</p>

			<p>Si une machine est visée par plusieurs règles qui ont la même priorité (une qui vise 'B0005', 'B0006' et l'autre 'B0005', 'B0009'), la règle la plus récemment créée aura priorité.</p>

			<h3 class="help-sub-title">Valeurs des limites d'une règle</h3>
			<p>Toutes les limites sont exclusives sauf pour lcl_a qui est inclusive.</p>
			<p>lcl_a est inclusive pour permettre de lancer une alerte lorsque le nombre de cartouches est égal à 0.</p>

			<h3 class="help-sub-title">Méthode d'une règle</h3>
			<p>La méthode d'une règle représente la façon dont la valeur sera calculée.</p>

			<p><span style="font-weight: bold">Aucune méthode:</span> les limites seront vérifiées selon la valeur brute de la variable.</p>

			<p><span style="font-weight: bold">Méthode 'multiple':</span> les limites seront vérifiées selon le delta entre la valeur brute d'une variable et sa moyenne sur un certain nombre de jours passé.<br>
				Il faudra entrer un 'span' qui représente le nombre de jours sur lequel la moyenne sera calculée.</p>

		</mat-card>
	</div>


	<div *ngIf="caller == undefined">
		Cette page doit recevoir un 'caller' à son ouverture.
	</div>
</mat-dialog-content>
-->

<mat-dialog-actions fxLayoutAlign="end">
	<button [mat-dialog-close]="true" color="primary" mat-raised-button>Ok</button>
</mat-dialog-actions>


