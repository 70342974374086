import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from "../local-storage.service";
import {SocialAuthService} from "angularx-social-login";
import {CookieServiceService} from "../cookie-service.service";
import {Router} from "@angular/router";
import {NavbarService} from './navbar.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  title = 'bioalert-app';
  userFirstname = '';

  constructor(
      public localStorageService: LocalStorageService,
      private authService: SocialAuthService,
      private cookieService: CookieServiceService,
      public route: Router,
      public nav: NavbarService,
  ) {
    this.userFirstname = this.localStorageService.get('userFirstName');
  }

  ngOnInit(): void {
  }

    checkForPermission(): boolean{
        if (this.nav._role === 'admin' || this.nav._role === 'super-admin'){
            return true;
        }else {
            return false;
        }
    }

  signOut(): void {
    this.route.navigate(['/login']);
    this.cookieService.remove('token');
    this.localStorageService.remove('userFirstName');
    this.localStorageService.remove('userLastName');
    this.localStorageService.remove('userEmail');
    this.localStorageService.remove('role');
    this.userFirstname = '';
    this.nav.loggedOut();
  }
}
