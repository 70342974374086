import {TChart} from '../TpiProduct/TChart';

/* Machine chart Factory */

export abstract class TChartFactory {

	constructor() {};

	public abstract factoryMethod(): TChart;

	/**
	 * Also note that, despite its name, the Creator's primary responsibility is
	 * not creating products. Usually, it contains some core business logic that
	 * relies on Product objects, returned by the factory method. Subclasses can
	 * indirectly change that business logic by overriding the factory method
	 * and returning a different type of product from it.
	 */
	public createChart(machine_id: number, start: string, end: string): any {
		// Call the factory method to create a Product object.
		const m = this.factoryMethod();
		// Now, use the product.
		return m.createChart(machine_id, start, end);
	}
}
