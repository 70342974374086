<body>
<mat-card class="sampling-list-container">
    <mat-card-title fxLayoutAlign="space-between">
        <h2>Sampling point dashboard</h2>
        <button mat-raised-button *ngIf="checkForPermission()" color="basic"  (click)="openLegend()">Calculation legend</button>
    </mat-card-title>

    <mat-form-field class="mat-sel" appearance="fill" [formGroup]="dynamicForm">
        <mat-label>Number of samples</mat-label>
        <mat-select (selectionChange)="numberOfSampleChanged($event)" formControlName="numberOfSample">
            <mat-option *ngFor="let num of numberOfSample" [value]="num">
                {{num}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="mat-sel" appearance="fill" [formGroup]="dynamicForm" >
        <mat-label>Client tag</mat-label>
        <mat-select (selectionChange)="clientSelectedChanged($event)" formControlName="clientTag" required>
					<mat-option>
						<ngx-mat-select-search [formControl]="businessFilterCtrl" placeholderLabel="Search" noEntriesFoundLabel="No result"></ngx-mat-select-search>
					</mat-option>
            <mat-option>-- No filter --</mat-option>
            <mat-option *ngFor="let client of filteredBusinesses | async" [value]="client">
                {{client.business_tag}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" [formGroup]="dynamicForm" >
        <mat-label>Site tag</mat-label>
        <mat-select (selectionChange)="siteTagChanged($event)" formControlName="clientTag">
					<mat-option>
						<ngx-mat-select-search [formControl]="siteFilterCtrl" placeholderLabel="Search" noEntriesFoundLabel="No result"></ngx-mat-select-search>
					</mat-option>
            <mat-option>-- No filter --</mat-option>
            <mat-option *ngFor="let site of filteredSites | async" [value]="site">
                {{site.site_tag}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div class="sampling-list">
			<mat-option *ngIf="!selection" style="color: #E0242F">*To start, please select a 'Client Tag'</mat-option>
        <h2 *ngIf="selection">Actives sampling point</h2>
        <mat-divider></mat-divider>
        <mat-progress-bar *ngIf="samplingPointLoading && selection" mode="indeterminate" class="progress-bar"></mat-progress-bar>

        <ul class="samplingPoints" *ngIf="selection">
            <li *ngFor="let sampling of sampling_points; let i = index">
                <mat-card class="card">
                    <mat-card-header style="height: 60px" fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="start center">
                        <mat-card-title style="margin-bottom: 0" fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="start center">
                            <button mat-mini-fab color="basic" (click)="showSamplingPoint[i]=!showSamplingPoint[i]; loadDashboardAnalysisHistory(sampling)">
                                <mat-icon *ngIf="showSamplingPoint[i]">remove</mat-icon>
                                <mat-icon *ngIf="!showSamplingPoint[i]">add</mat-icon>
                            </button>
                            <a [routerLink]="['/dashboards/samplingPoint', sampling.sp_id]">
                                <button mat-raised-button color="primary">{{ sampling?.sampling_name  }}</button>
                            </a>
                        </mat-card-title>

                        <mat-card class="general-sampling-info" fxLayout="column" fxLayoutAlign="center center" *ngIf="sampling.flagsLoaded && checkForPermission()" [ngStyle]="
               sampling.flags.ttm_result == null?{'border-color':'#D5D5D5'} :
               sampling.flags.ttm_result.cl_level == 0?{'border-color': 'green'}:
               sampling.flags.ttm_result.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
               sampling.flags.ttm_result.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
              {'border-color': 'green'}">
                            <p>TTm result :
                                <ng-container *ngIf="sampling.flags.ttm_result != null; else defaultValue">
                                    {{sampling.flags.ttm_result.value}}
                                </ng-container>
                            </p>
                        </mat-card>

                        <mat-card class="general-sampling-info" fxLayout="column" fxLayoutAlign="center center" *ngIf="sampling.flagsLoaded && checkForPermission()" [ngStyle]="
               sampling.flags.total_filtration == null?{'border-color':'#D5D5D5'} :
               sampling.flags.total_filtration.cl_level == 0?{'border-color': 'green'}:
               sampling.flags.total_filtration.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
               sampling.flags.total_filtration.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
              {'border-color': 'green'}">
                            <p>Total filtration time S0 and S1:
                                <ng-container *ngIf=" sampling.flags.total_filtration != null; else defaultValue">
                                    {{ sampling.flags.total_filtration.value}}
                                </ng-container>
                            </p>
                        </mat-card>

                        <mat-card class="general-sampling-info" fxLayout="column" fxLayoutAlign="center center" *ngIf="sampling.flagsLoaded" [ngStyle]="
                            !sampling.flags.ug?{'border-color': '#EEEEEE'}:
                            sampling.flags.ug.cl_level==0?{'border-color': '#FFFFFF'}:
                            sampling.flags.ug.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
                            sampling.flags.ug.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                            {'border-color': '#FFFFFF'}">
                            <p>UG result :
                                <ng-container *ngIf="sampling.flags.ug != null; else defaultValue">
                                    {{sampling.flags.ug.value}}
                                </ng-container>
                            </p>
                        </mat-card>

												<mat-card class="general-sampling-info" fxLayout="column" fxLayoutAlign="center center" >
													<p>Description :
															<ng-container *ngIf="sampling.description != null; else defaultValue">
																 {{sampling?.description}}
															</ng-container>
													</p>
												</mat-card>
                        <ng-template #defaultValue>No Data</ng-template>
                        <span fxFlex></span>
                    </mat-card-header>
                    <mat-card-content>
                        <div *ngIf="showSamplingPoint[i]">
                            <mat-progress-bar *ngIf="!sampling.detailedAnalysisHistoryIsLoaded" mode="indeterminate" class="progress-bar"></mat-progress-bar>
                            <table mat-table [dataSource]="sampling.detailedAnalysisHistory" class="card-right-body-table">
                                <ng-container matColumnDef="machine_tag">
                                    <th mat-header-cell *matHeaderCellDef> Machine tag</th>
                                    <td mat-cell *matCellDef="let element" [ngStyle]="
              													!element.machine_tag?{'background-color': '#EEEEEE'}:
              																{'background-color': '#FFFFFF'}">
                                        <ng-container *ngIf="element.machine_tag; else defaultValue">
                                            <a [routerLink]="['/dashboards/machines', element.machine_tag ]" class="analysis_id_ref">
                                                <button mat-button>
                                                    {{element.machine_tag}}
                                                </button>
                                            </a>
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="analysis_id">
                                    <th mat-header-cell *matHeaderCellDef> Analysis id</th>
                                    <td mat-cell *matCellDef="let element; let i=index" [ngStyle]="
                  !element.analysis_id?{'background-color': '#EEEEEE'}:
                  {'background-color': '#FFFFFF'}">
                                        <ng-container *ngIf="element.analysis_id; else defaultValue">
                                            <a [routerLink]="['/dashboards/analysis', element.analysis_id ]" class="analysis_id_ref">
                                                <button mat-button [disabled]="!checkForPermission()" [ngStyle]="
                          !element.analysis_id?{'background-color': '#EEEEEE'}:
                          element.missing_variable && element.missing_variable.length > 3?{'color':'#E0242F', 'background-color': '#EEEEEE'} :
                          {'background-color': '#FFFFFF'}">
                                                    {{element.analysis_id}}
                                                </button>
                                            </a>
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="sampling_time">
                                    <th mat-header-cell *matHeaderCellDef> Time of sample</th>
                                    <td mat-cell *matCellDef="let element" [ngStyle]="
              !element.sampling_time?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
                                        <ng-container *ngIf="element.sampling_time; else defaultValue">
                                            {{element.sampling_time | date:'yyyy-MM-dd HH:mm'}}
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="inlet_num">
                                    <th mat-header-cell *matHeaderCellDef> Inlet number</th>
                                    <td mat-cell *matCellDef="let element" [ngStyle]="
              !element.machine_inlet_number?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
                                        <ng-container *ngIf="element.machine_inlet_number; else defaultValue">
                                            {{element.machine_inlet_number.value}}
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="cartouchesdisponibles">
                                    <th mat-header-cell *matHeaderCellDef> Cartridges available</th>
                                    <td mat-cell *matCellDef="let element"
                                        [ngStyle]="
                    !element.cart_available?{'background-color': '#EEEEEE'}:
                    element.cart_available.cl_level==0?{'background-color': '#FFFFFF'}:
                    element.cart_available.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
                    element.cart_available.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
                    {'background-color': '#FFFFFF'}">
                                        <ng-container *ngIf="element.cart_available; else defaultValue">
                                            {{element.cart_available.value}}
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="lotc01">
                                    <th mat-header-cell *matHeaderCellDef> Batch</th>
                                    <td mat-cell *matCellDef="let element" [ngStyle]="
              !element.cart_batch_number?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
                                        <ng-container *ngIf="element.cart_batch_number; else defaultValue">
                                            {{element.cart_batch_number.value}}
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="ug">
                                    <th mat-header-cell *matHeaderCellDef> UG/mL</th>
                                    <td mat-cell *matCellDef="let element; let i=index" [ngStyle]="
                    !element.result_value_ug_rounded?{'background-color': '#EEEEEE'}:
                    element.result_value_ug_rounded.cl_level==0?{'background-color': '#FFFFFF'}:
                    element.result_value_ug_rounded.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
                    element.result_value_ug_rounded.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
                    {'background-color': '#FFFFFF'}">
                                        <ng-container *ngIf="element.result_value_ug_rounded; else defaultValue">
                    <span
                            *ngIf="element.mrv_relation_symbol">{{convertASCIItoChar(element.mrv_relation_symbol.value)}}</span> {{element.result_value_ug_rounded.value}}
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="ttm">
                                    <th mat-header-cell *matHeaderCellDef> TTm</th>
                                    <td mat-cell *matCellDef="let element" [ngStyle]="
                  !element.result_ttm?{'background-color': '#EEEEEE'}:
                  element.result_ttm.cl_level==0?{'background-color': '#FFFFFF'}:
                  element.result_ttm.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
                  element.result_ttm.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
                  {'background-color': '#FFFFFF'}">
                                        <ng-container *ngIf="element.result_ttm; else defaultValue">
                                            {{element.result_ttm.value}}
                                        </ng-container>
                                    </td>
                                </ng-container>

															<ng-container matColumnDef="vmr">
																<th mat-header-cell *matHeaderCellDef> VMR</th>
																<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_vmr?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
																	<ng-container *ngIf="element.result_vmr; else defaultValue">
																		{{element.result_vmr.value}}
																	</ng-container>
																</td>
															</ng-container>

                                <ng-container matColumnDef="c1">
                                    <th mat-header-cell *matHeaderCellDef> C1</th>
                                    <td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_tt1?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
                                        <ng-container *ngIf="element.result_tt1; else defaultValue">
                                            {{element.result_tt1.value}}
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="v1">
                                    <th mat-header-cell *matHeaderCellDef> V1</th>
                                    <td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_tt2?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
                                        <ng-container *ngIf="element.result_tt2; else defaultValue">
                                            {{element.result_tt2.value}}
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="v2">
                                    <th mat-header-cell *matHeaderCellDef> V2</th>
                                    <td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_tt3?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
                                        <ng-container *ngIf="element.result_tt3; else defaultValue">
                                            {{element.result_tt3.value}}
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="c2">
                                    <th mat-header-cell *matHeaderCellDef> C2</th>
                                    <td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_tt4?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
                                        <ng-container *ngIf="element.result_tt4; else defaultValue">
                                            {{element.result_tt4.value}}
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="c1MinusC2">
                                    <th mat-header-cell *matHeaderCellDef> C1-C2</th>
                                    <td mat-cell *matCellDef="let element" [ngStyle]="
                  !element.result_delta_c1_c2?{'background-color': '#EEEEEE'}:
                  element.result_delta_c1_c2.cl_level==0?{'background-color': '#FFFFFF'}:
                  element.result_delta_c1_c2.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
                  element.result_delta_c1_c2.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
                  {'background-color': '#FFFFFF'}">
                                        <ng-container *ngIf="element.result_delta_c1_c2; else defaultValue">
                                            {{element.result_delta_c1_c2.value}}
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="v1MinusV2">
                                    <th mat-header-cell *matHeaderCellDef> V1-V2</th>
                                    <td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_delta_v1_v2?{'background-color': '#EEEEEE'}:
              element.result_delta_v1_v2.cl_level==0?{'background-color': '#FFFFFF'}:
              element.result_delta_v1_v2.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
              element.result_delta_v1_v2.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
              {'background-color': '#FFFFFF'}">
                                        <ng-container *ngIf="element.result_delta_v1_v2; else defaultValue">
                                            {{element.result_delta_v1_v2.value}}
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="calibration_success">
                                    <th mat-header-cell *matHeaderCellDef> Calibration Success</th>
                                    <td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_calib_success?{'background-color': '#EEEEEE'}:
              element.result_calib_success.cl_level==0?{'background-color': '#FFFFFF'}:
              element.result_calib_success.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
              element.result_calib_success.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
              {'background-color': '#FFFFFF'}">
                                        <ng-container *ngIf="element.result_calib_success; else defaultValue">
                                            {{element.result_calib_success.value}}
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="error_code">
                                    <th mat-header-cell  *matHeaderCellDef> Error</th>
                                    <td mat-cell  *matCellDef="let element" [ngStyle]="
              !element.result_error_code?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
                                        <ng-container *ngIf="element.result_error_code; else defaultValue">
                                            <button mat-raised-button
                                                    matTooltip="{{element.error_desc}}"
                                                    [matTooltipPosition]="'left'">
                                                {{element.result_error_code.value}}
                                            </button>
                                        </ng-container>

                                    </td>
                                </ng-container>

                                <ng-template #defaultValue>No Data</ng-template>
                                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                            </table>
                        </div>
                    </mat-card-content>
                </mat-card>
            </li>
        </ul>
    </div>
</mat-card>
</body>
