import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  _loggedIn: boolean;
  _role: string;

  constructor() { this._loggedIn = false; this._role = null; }

  loggedOut() {
    this._loggedIn = false;
    this._role = null;
  }

  loggedIn(role: string) {
    this._loggedIn = true;
    this._role = role;
  }
}
