<mat-card>

	<mat-card-header>
		<mat-card-title>
			Inlet creation on machine {{machine_tag}}
		</mat-card-title>
	</mat-card-header>

	<mat-card-content>
		<mat-progress-bar *ngIf="isLoading" mode="indeterminate" class="progress-bar"></mat-progress-bar>

		<form [formGroup]="iCreatorForm" style="margin-left: 15px">
			<div fxLayout="column" fxLayoutAlign="space-arround start" formArrayName="iCreatorRow">
				<div *ngFor="let row of iCreatorForm.get('iCreatorRow')['controls']; let y=index" fxLayout="row" fxLayoutGap="2.5%" fxLayoutAlign="space-between center">
					<mat-form-field appearance="fill" [formGroupName]="y" style="width: 100px">
						<mat-label>No. Inlet</mat-label>
						<input matInput (change)="inletNumChanged($event)" formControlName="inlet_num" required>
						<mat-error *ngIf="row.get('inlet_num').hasError('pattern')">Format: 00</mat-error>
					</mat-form-field>

					<mat-form-field appearance="fill" [formGroupName]="y">
						<mat-label>Sampling point</mat-label>
						<mat-select formControlName="sp_name" required>
							<mat-option>
								<ngx-mat-select-search [formControl]="samplingPointFilterCtrl" placeholderLabel="Search" noEntriesFoundLabel="No result"></ngx-mat-select-search>
							</mat-option>
							<mat-option (onSelectionChange)="spNameChanged($event)" *ngFor="let samplingPoint of filteredSamplingPoint | async" [value]="samplingPoint">
								{{samplingPoint.sp_tag}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>
		</form>
	</mat-card-content>

	<mat-card-actions fxLayout="flex" fxLayoutAlign="end center">
		<button mat-raised-button color="basic" (click)="cancelAndClose()">
			<mat-icon>
				close
			</mat-icon>
		</button>
		<button mat-raised-button color="primary" (click)="submitAndClose()" [disabled]="iCreatorForm.invalid">
			Create
		</button>
	</mat-card-actions>
</mat-card>
