import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CookieServiceService} from '../cookie-service.service';
import {Machine} from '../Models/Machine';

@Injectable({
  providedIn: 'root'
})
export class DashboardTPIService {

	header;
	machineUrl = environment.machineUrl;

  constructor(private http: HttpClient, private cookieService: CookieServiceService) {
		this.header = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + this.cookieService.get('token'),
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'DELETE, GET, POST, OPTIONS',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		});
	}

	getMachines() {
		return this.http.get<Machine[]>(this.machineUrl + '/machines', {headers: this.header});
	}
}
