<body>
<mat-card class="main-graph">
	<mat-card-header>
		<mat-card-title>
			{{chartName}} - {{analysis_id || machine_tag || sp_name}}
		</mat-card-title>
		<mat-card-subtitle>
			Inlet: {{inletNo}} - Max date: {{time}}
		</mat-card-subtitle>
	</mat-card-header>
	<mat-divider></mat-divider>
	<div class="plotly-chart" *ngIf="chart">
		<plotly-plot
			[data]="chart.chartData"
			[layout]="chart.chartLayout"
			[config]="chart.chartConfig">
		</plotly-plot>
	</div>

	<mat-card-actions *ngIf="!this.analysisChart">
		<mat-form-field class="primary" appearance="fill">
			<mat-label>X axis range</mat-label>
			<mat-date-range-input [rangePicker]="picker">
				<input matStartDate placeholder="Start date" #x1>
				<input matEndDate placeholder="End date" #x2>
			</mat-date-range-input>
			<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
			<mat-date-range-picker #picker></mat-date-range-picker>
		</mat-form-field>

		<mat-form-field color="primary" appearance="fill">
			<mat-label>Min y axis</mat-label>
			<input matInput type="number" #y1>
		</mat-form-field>
		<mat-form-field color="primary" appearance="fill">
			<mat-label>Max y axis</mat-label>
			<input matInput type="number" #y2>
		</mat-form-field>
		<button mat-raised-button color="primary" *ngIf="x1.value && x2.value && y1.value && y2.value" matSuffix mat-icon-button (click)="modifyChart(chart,
           [x1.value, x2.value], [y1.value, y2.value])">
			<mat-icon>send</mat-icon>
		</button>
	</mat-card-actions>
	<mat-card-actions *ngIf="this.analysisChart">
		<mat-form-field class="primary" appearance="fill">
			<mat-label>Min X axis</mat-label>
			<input matInput type="time" #x1>
		</mat-form-field>
		<mat-form-field class="primary" appearance="fill">
			<mat-label>Max X axis</mat-label>
			<input matInput type="time" #x2>
		</mat-form-field>

		<mat-form-field color="primary" appearance="fill">
			<mat-label>Min y axis</mat-label>
			<input matInput type="number" #y1>
		</mat-form-field>
		<mat-form-field color="primary" appearance="fill">
			<mat-label>Max y axis</mat-label>
			<input matInput type="number" #y2>
		</mat-form-field>
		<button mat-raised-button color="primary" *ngIf="x1.value && x2.value && y1.value && y2.value" matSuffix mat-icon-button (click)="modifyChart(chart,
             [x1.value, x2.value], [y1.value, y2.value])">
			<mat-icon>send</mat-icon>
		</button>
	</mat-card-actions>
</mat-card>
</body>
