import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CookieServiceService} from '../cookie-service.service';
import {environment} from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class FullscreenChartService {

	header;
	analyseUrl = environment.analysisUrl;
	machineUrl = environment.machineUrl;

	constructor(private http: HttpClient, private cookieService: CookieServiceService) {
		this.header = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + this.cookieService.get('token'),
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'DELETE, GET, POST,PATCH, OPTIONS',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		});
	}

	async getVariableHistoryBySpnum(machine_id: number, var_tag: string, sp_num: number, time: string, limit: number) {
		return await this.http.get(this.analyseUrl + '/analyses/variables/machine/' + machine_id + '/tag/' + var_tag + '/history?limit=' + limit + '&time=' + time + '&sp_id=' + sp_num, {headers: this.header}).toPromise();
	}

	async getSamplingPointVariableHistory(sp_id: number, var_tag: string, time: string, limit: number) {
		return await this.http.get(this.analyseUrl + '/analyses/variables/spId/' + sp_id + '/tag/' + var_tag + '/history?limit=' + limit + '&time=' + time, {headers: this.header}).toPromise();
	}

	async getVariableHistory(machine_id: number, var_tag: string, time: string, limit: number) {
		return await this.http.get(this.analyseUrl + '/analyses/variables/machine/' + machine_id + '/tag/' + var_tag + '/history?limit=' + limit + '&time=' + time, {headers: this.header}).toPromise();
	}

	async getTelemetryChart(analysis_id: number, sensor_id: number) {
		return await this.http.get(this.analyseUrl + '/analyses/' + analysis_id + '/telemetry/' + sensor_id, {headers: this.header}).toPromise();
	}

	async getTelemetryDataInterpolated(analysis_id: number, var_id: number, sensor_id: number) {
		return await this.http.get(this.analyseUrl + '/analyses/' + analysis_id + '/variable/' + var_id + '/sensor/' + sensor_id + '/telemetry', {headers: this.header}).toPromise();
	}

	async getTelemetryByAnalysisIdAnsSensorId(analysis_id: number, sensor_id: number) {
		return await this.http.get(this.analyseUrl + '/analyses/' + analysis_id + '/telemetry/sensor/' + sensor_id, {headers: this.header}).toPromise();
	}

	async getTpiVariableData(machine_id: number, sensor_id: number, start: string, end: string) {
    return await this.http.get<any>(this.machineUrl + '/machines/' + machine_id + '/sensor/' + sensor_id +
			'/telemetry?start=' + start + '&end=' + end, {headers: this.header}).toPromise();
	}

	async getVariableData(machine_id: number, variable_id: number, start: string, end: string) {
		return await this.http.get<any>(this.machineUrl + '/machines/' + machine_id + '/variable/' + variable_id +
			'/data?start=' + start + '&end=' + end, {headers: this.header}).toPromise();
	}

	getMachineAnalysisFullDetail(analysis_id: number) {
		return this.http.get<any>(this.analyseUrl + '/analyses/' + analysis_id + '/detailed', {headers: this.header}).toPromise();
	}
}
