import {FullscreenChartService} from '../../../fullscreen-chart/fullscreen-chart.service';
import {CustomChartData} from '../../CustomChartData';
import {TChart} from '../TChart';

/* Concrete Chart for Factory Pattern */
export class TRiskIndexChart implements TChart {

	riskIndex: any;
	ugData: any;
	datas: CustomChartData;

	private config = {
		responsive: true,
		displaylogo: false
	};

	private layout = {
		// items with a `name` attribute in template.images will be added to any
		// plot using this template
		images: [{
			source: '../../../../favicon.ico',
			xref: 'paper',
			yref: 'paper',
			x: 0.5,
			y: 0.5,
			xanchor: 'center',
			yanchor: 'middle',
			sizex: 0.7,
			sizey: 0.7,
			opacity: 0.1,
			layer: 'below'
		}],
		legend: {
			x: 0,
			y: 1.15,
			orientation: 'h',
			itemsizing: 'constant',
			itemwidth: 25
		},
		margin: {
			l: 35,
			r: 35,
			b: 50,
			t: 10
		},
		xaxis: {
			tickformat: '%d %b',
			tickangle: -45,
			showgrid: true,
			nticks: 36,
			showline: true,
			zeroline: false,
			mirror: true,
			hoverformat: '%Y-%m-%d %H:%M:%S'
		},
		yaxis: {
			showgrid: true,
			showline: true,
			zeroline: false,
			mirror: true,
		},
		yaxis2: {
			title: 'UG/mL',
			showgrid: false,
			showline: false,
			zeroline: false,
			side: 'right',
			overlaying: 'y',
		},
		modebar: {
			color: '#000000',
			bgcolor: '#FFFFFF'
		}
	};


	constructor(private service: FullscreenChartService) {
	}

	public async createChart(machine_id: number, start: string, end: string): Promise<CustomChartData> {

		this.riskIndex = await await this.service.getVariableData(machine_id, 8, start, end);
		this.ugData = await this.service.getVariableData(machine_id, 30, start, end);

		this.datas = new CustomChartData([], this.layout, this.config);

		const risk_index_data = [];
		const risk_index_time = [];
		const ug_data = [];
		const ug_time = [];

		this.datas = new CustomChartData([], this.layout, this.config);

		for (const risk_index_pts of this.riskIndex) {
			if (!risk_index_pts.empty) {
				risk_index_data.push(risk_index_pts.map.value);
				risk_index_time.push(risk_index_pts.map.analysis_time);
			}
		}

		for (const ug_pts of this.ugData) {
			if (!ug_pts.empty) {
				ug_data.push(ug_pts.map.value);
				ug_time.push(ug_pts.map.analysis_time);
			}
		}

		const trace_risk_index = {
			name: 'Risk Index',
			x: risk_index_time,
			y: risk_index_data,
			type: 'scatter',
			marker: {
				size: 5,
				color: 'rgba(44, 130, 201, 1)'
			},
		};

		const trace_ug = {
			name: 'UG/mL',
			x: ug_time,
			y: ug_data,
			type: 'scatter',
			marker: {
				size: 5,
				color: 'rgb(0, 0, 0)'
			},
			yaxis: 'y2'
		};

		this.datas.chartData = [trace_risk_index, trace_ug];
		return this.datas;
	}
}
