import {Component, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CompleteMachine} from 'src/app/Models/CompleteMachine';
import {Machine} from 'src/app/Models/Machine';
import {AdminPanelService} from '../admin-panel.service';
import {EditApprovalDialogComponent} from '../edit-approval-dialog/edit-approval-dialog.component';
import {Modification} from '../../Models/Modification';
import {InletCreatorComponent} from './inlet-creator/inlet-creator.component';
import {Inlets} from 'src/app/Models/Inlets';
import * as _ from 'lodash';
import {ReplaySubject, Subject} from 'rxjs';
import {MatSelect} from '@angular/material/select';
import {takeUntil} from 'rxjs/operators';
import {MachineInput} from '../../Models/MachineInput';
import _moment from 'moment';
const moment = _moment;


enum ModType {
	create,
	update_inlet_num,
	update_sp_name,
	update_machine_state,
	delete,
	update_maintenance_date,
	update_nb_of_analysis_before_maintenance
}

@Component({
	selector: 'app-machine-settings',
	templateUrl: './machine-settings.component.html',
	styleUrls: ['./machine-settings.component.css']
})

export class MachineSettingsComponent implements OnInit {

	inletForm: FormGroup;
	machineForm: FormGroup;
	formState: String = '';

	modifications: Modification[] = [];
	inletToDelete: number [] = [];

	machineList: any[] = [];
	samplingPoints: any[] = [];
	oldMachine: any;

	lastMaintenance: string;
	nbAnalysisBeforeMaintenance: number;

	isLoading: boolean;
	suggestedMachineTag: string;
	suggestedMachineId: number;
	inletRowCount: number;
	possibleStates: string[] = ['inactive', 'standby'];
	possibleSites: any[] = [];
	disableAccess = true;


	public samplingPointFilterCtrl: FormControl = new FormControl();
	public filteredSamplingPoint: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
	@ViewChild('samplingPointSelect', {static: true}) samplingPointSelect: MatSelect;
	protected _onDestroy = new Subject<void>();


	constructor(private service: AdminPanelService, public dialog: MatDialog, private snackbar: MatSnackBar, public formBuilder: FormBuilder) {
	}

	async ngOnInit() {
		this.createMachineForm();
		this.createInletForm();

		this.initForms();
	}

	getMachines() {
		this.isLoading = true;
		this.machineList = [];
		this.service.getCompleteMachines().subscribe(data => {
			data.forEach(m => {
				this.service.getInlets(m.machine_id).subscribe(inl => {
					this.machineList.push(new CompleteMachine(m, inl));
				});
			});
			this.isLoading = false;
		});
	}

	initForms() {
		this.isLoading = true;

		this.service.getNewMachineId().subscribe(newId => {
			this.suggestedMachineId = newId;
			this.machineForm.get('machineId').setValue(this.suggestedMachineId);

			this.service.getNewMachineTag().subscribe(newTag => {
				this.suggestedMachineTag = newTag.text;
				this.machineForm.get('machineTag').setValue(this.suggestedMachineTag);

				this.service.getCompleteMachines().subscribe(machines => {
					for (const m of machines){
						this.service.getInlets(m.machine_id).subscribe(inl => {
							this.machineList.push(new CompleteMachine(m, inl));
						});
					}

					this.service.getUniqueSamplingPoints().subscribe(sp => {
						this.samplingPoints = _.orderBy(sp, 'sp_tag', 'asc');
						this.filteredSamplingPoint.next(this.samplingPoints.slice());

						this.isLoading = false;

						this.samplingPointFilterCtrl.valueChanges
							.pipe(takeUntil(this._onDestroy))
							.subscribe(() => {
								this.filterSamplingPoint();
							});
					});
				});
			});
		});
	}

	private createMachineForm() {
		this.machineForm = this.formBuilder.group({
			machineId: [this.suggestedMachineId, [Validators.required, Validators.pattern('([0-9]{1,3})')]],
			machineTag: [this.suggestedMachineTag, [Validators.required, Validators.pattern('([A-Z]{1})+([0-9]{4})')]],
			state: ['inactive', Validators.required],
			machineSite: [this.possibleSites, Validators.required],
			lastMaintenance: [this.lastMaintenance, Validators.required],
			nbAnalysisBeforeMaintenance: [this.nbAnalysisBeforeMaintenance, Validators.required],
		});

		this.service.getSites().subscribe(data => {
			this.possibleSites = _.orderBy(data.filter(s => s.access_level >= 30), 'site_tag', 'asc');
		}, error => {
			this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
			this.isLoading = false;
		});
	}

	createInletForm() {
		this.inletRowCount = 1;
		this.inletForm = this.formBuilder.group({
			inletRow: this.formBuilder.array([
				this.getInletFormRow()
			])
		});
	}

	private getInletFormRow() {
		return this.formBuilder.group({
			inlet: [this.inletRowCount, [Validators.required, Validators.pattern('[0-9]{1,2}')]],
			sp: [null, Validators.required]
		});
	}

	private getInletForm(inlet: any, samplingPoint: any) {
		let disableUpdate = true;
		let hasAccessDelete = false;
		if (inlet.access_level >= 20){
			disableUpdate = false;
		}
		if (inlet.access_level === 40)
		{
			hasAccessDelete = true;
		}
		return this.formBuilder.group({
			inlet: [{value: inlet.inlet_num, disabled: disableUpdate}, [Validators.required, Validators.pattern('[0-9]{1,2}')]],
			sp: [{value: samplingPoint, disabled: disableUpdate}, Validators.required],
			access: [hasAccessDelete]
		});
	}

	private resetInletForm() {
		this.inletRowCount = 1;

		this.inletForm = (this.formBuilder.group({
			inletRow: this.formBuilder.array([])
		}));
	}

	addInletRow(inlet: any, samplingPoint: any) {
		const control = this.inletForm.controls.inletRow as FormArray;
		control.push(this.getInletForm(inlet, samplingPoint));
	}

	createInlet(i: number) {
		const machine_tag = _.find(this.machineList, ['machine.machine_id', i]).machine.machine_tag;
		const machine_id = _.find(this.machineList, ['machine.machine_id', i]).machine.machine_id;

		const dialogRef = this.dialog.open(InletCreatorComponent, {
			width: '25%',
			data: {
				machine_tag,
				machine_id
			},
		});

		dialogRef.afterClosed().subscribe(data => {
			if (data) {

				this.addInletRow(+data[0], data[1]);

				const inlet: any = {};
				inlet.inlet_num = +data[0];
				inlet.sp_id = +data[1].sp_id;
				inlet.sp_name = data[1].sp_name;
				inlet.access_level = 20;
				inlet.machine_id = this.oldMachine.machine_id;
				this.oldMachine.inlets.push(inlet);

				this.machineList = [];
				this.service.getCompleteMachines().subscribe(data => {
					data.forEach(m => {
						this.service.getInlets(m.machine_id).subscribe(inl => {
							this.machineList.push(new CompleteMachine(m, inl));
						});
					});
				});
				this.setFormUpdateMachine(this.oldMachine);
			}
			this.formState = '';
		});
	}

	removeInletRow(y: number) {
		if (this.formState === 'update') {
			const control = this.inletForm.controls.inletRow as FormArray;
			control.removeAt(y);

			const mod = new Modification();
			mod.set_mod_description('The inlet ' + this.oldMachine.inlets[y].inlet_num + ' of the machine ' + this.oldMachine.machine.machine_tag + ' will be deleted');
			mod.set_mod_type(ModType.delete); // Delete
			mod.set_new_cm(new CompleteMachine(this.oldMachine.machine, this.oldMachine.inlets));

			this.modifications.push(mod);
			this.inletToDelete.push(this.oldMachine.inlets[y].mi_id);

			this.inletForm.markAsDirty();
		}
	}

	machineStateChanged($event: any) {

		if (this.formState === 'update') {
			const mid = this.oldMachine.machine.machine_id;
			const oldState = this.oldMachine.machine.state;
			const sid = this.oldMachine.machine.site_id;
			const acl = this.oldMachine.machine.access_level;

			let newState;
			newState = $event.value;

			const mod = new Modification();
			mod.set_mod_description('The state of the machine ' + mid + ' will go from ' + oldState + ' to ' + newState);
			mod.set_mod_type(ModType.update_machine_state);
			mod.set_old_cm(new CompleteMachine(this.oldMachine.machine, this.oldMachine.inlets));

			const new_machine = new Machine(mid, null, null, newState, sid, acl, null, null);
			mod.set_new_cm(new CompleteMachine(new_machine, this.oldMachine.inlets));

			this.modifications.push(mod);
		}
	}

	machineMaintenanceDateChanged($event: any) {
		if (this.formState === 'update') {
			const mid = this.oldMachine.machine.machine_id;
			const oldMaintenanceDate = moment(this.oldMachine.machine.last_maintenance).format('YYYY-MM-DD');
			const sid = this.oldMachine.machine.site_id;
			const acl = this.oldMachine.machine.access_level;

			let newMaintenanceDate;
			newMaintenanceDate = moment($event.value).format('YYYY-MM-DD');

			const mod = new Modification();
			mod.set_mod_description('The maintenance date of the machine ' + mid + ' will go from ' + oldMaintenanceDate + ' to ' + newMaintenanceDate);
			mod.set_mod_type(ModType.update_maintenance_date);
			mod.set_old_cm(new CompleteMachine(this.oldMachine.machine, this.oldMachine.inlets));

			const new_machine = new Machine(mid, null, null, null, sid, acl, $event.value, null);
			mod.set_new_cm(new CompleteMachine(new_machine, this.oldMachine.inlets));

			this.modifications.push(mod);
		}
	}

	machineNbAnalysisBeforeMaintenanceChanged($event: any) {
		if (this.formState === 'update') {
			const mid = this.oldMachine.machine.machine_id;
			const oldNbOfAnalysis = this.oldMachine.machine.nb_analysis_before_maintenance;
			const sid = this.oldMachine.machine.site_id;
			const acl = this.oldMachine.machine.access_level;

			let newNbOfAnalysis;
			newNbOfAnalysis = $event.target.value;

			const mod = new Modification();
			mod.set_mod_description('The number of analysis before maintenance of the machine ' + mid + ' will go from ' + oldNbOfAnalysis + ' to ' + newNbOfAnalysis);
			mod.set_mod_type(ModType.update_nb_of_analysis_before_maintenance);
			mod.set_old_cm(new CompleteMachine(this.oldMachine.machine, this.oldMachine.inlets));

			const new_machine = new Machine(mid, null, null, null, sid, acl, null, newNbOfAnalysis);
			mod.set_new_cm(new CompleteMachine(new_machine, this.oldMachine.inlets));

			this.modifications.push(mod);
		}
	}

	samplingPointSelectedChanged($event: any, y: number) {

		if (this.formState === 'update') {

			const mod = new Modification();
			mod.set_mod_description('The inlet ' + this.oldMachine.inlets[y].inlet_num + ' of the machine ' + this.oldMachine.machine.machine_tag +
				' will change the sampling point ' + this.oldMachine.inlets[y].sp_name + ' to ' + $event.source.value.sp_tag);
			mod.set_mod_type(ModType.update_sp_name);

			/* set old complete machine */
			const old_inlets = [];
			old_inlets.push(this.oldMachine.inlets[y]); // we only want the inlet that will be changed

			const old_cm = new CompleteMachine(this.oldMachine.machine, old_inlets);
			mod.set_old_cm(old_cm);

			/* set new complete machine */
			const new_inlet = [];
			new_inlet.push(
				new Inlets(this.oldMachine.inlets[y].mi_id, this.oldMachine.inlets[y].machine_id, this.oldMachine.inlets[y].inlet_num, null, this.oldMachine.inlets[y].sp_name, this.oldMachine.inlets[y].access_level)
			); // we only want the inlet that will be change

			new_inlet[0].sp_name = $event.source.value.sp_tag;
			// const pos = this.spNameList.indexOf(new_inlet[0].sp_name);
			new_inlet[0].sp_id = $event.source.value.sp_id;

			mod.set_new_cm(new CompleteMachine(this.oldMachine.machine, new_inlet));

			/* push the modifications */
			this.modifications.push(mod);
		}
	}

	inletNumChanged($event: any, y: number) {
		if (this.formState === 'update') {

			const machine_tag = this.oldMachine.machine.machine_tag;
			const inlet_num = this.oldMachine.inlets[y].inlet_num;

			const mod = new Modification();
			mod.set_mod_description('The inlet ' + inlet_num + ' of the machine ' + machine_tag + ' will change is number for ' + $event.target.value);
			mod.set_mod_type(ModType.update_inlet_num);

			/* set old complete machine */
			const old_inlets = [];
			old_inlets.push(this.oldMachine.inlets[y]); // we only want the inlet that will be change

			const old_cm = new CompleteMachine(this.oldMachine.machine, old_inlets);
			mod.set_old_cm(old_cm);

			/* set new complete machine */
			const new_inlet = [];
			new_inlet.push(
				new Inlets(this.oldMachine.inlets[y].mi_id, this.oldMachine.inlets[y].machine_id, this.oldMachine.inlets[y].inlet_num, this.oldMachine.inlets[y].sp_id, this.oldMachine.inlets[y].sp_name, this.oldMachine.inlets[y].access_level)
			); // we only want the inlet that will be change

			new_inlet[0].inlet_num = $event.target.value;

			mod.set_new_cm(new CompleteMachine(this.oldMachine.machine, new_inlet));

			this.modifications.push(mod);
		}
	}

	submit() {
		if (this.modifications.length !== 0) {

			const dialogRef = this.dialog.open(EditApprovalDialogComponent, {
				width: '60%',
				data: this.modifications
			});
			dialogRef.afterClosed().subscribe(data => {
				if (data) {
					this.modifications.forEach(element => {
						this.modificationDispatcher(element);
					});
					this.modifications = [];
					this.inletForm.markAsPristine();
				}
			});
		} else {
			this.openSnackBar('No change', 'Close');
		}
	}

	modificationDispatcher(mod: Modification) {
		const mod_type = mod.get_mod_type();
		const ncm = mod.get_new_cm();
		const ocm = mod.get_old_cm();

		switch (mod_type) {
			case ModType.create: {
				break;
			}
			case ModType.delete: {
				this.inletToDelete.forEach(mi_id => {
					this.deleteInlet(+mi_id);
				});
				break;
			}
			case ModType.update_inlet_num: {
				this.updateInletNum(ncm, ocm);
				break;
			}
			case ModType.update_machine_state: {
				this.updateMachineInfo(ncm, ocm);
				break;
			}
			case ModType.update_maintenance_date: {
				this.updateMaintenanceDate(ncm, ocm);
				break;
			}
			case ModType.update_nb_of_analysis_before_maintenance: {
				this.updateNumberOfAnalysisBeforeMaintenance(ncm, ocm);
				break;
			}
			case ModType.update_sp_name: {
				this.updateSpName(ncm, ocm);
				break;
			}
		}
	}

	private deleteInlet(mi_id: number) {
		this.service.deleteInlet(mi_id).subscribe(resp => {
			if (resp.status === 200) {
				this.snackbar.open(resp.message, 'Ok');
			} else if (resp.status === 501) {
				this.snackbar.open('An error occurred while deleting an inlet: ' + resp.message, 'Ok');
			} else {
				this.snackbar.open('An error has occurred. Code: ' + resp.status, 'Ok');
			}
		}, error => {
			this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
		});
	}

	updateInletNum(ncm: CompleteMachine, ocm: CompleteMachine) {
		ncm.inlets.forEach(i => {
			this.service.updateInletNum(i).subscribe(data => {
				if (data) {
					const i = this.machineList.findIndex(x => x.machine.machine_id === ocm.machine.machine_id);
					const y = this.machineList[i].inlets.findIndex(y => y.sp_id === ocm.inlets[0].sp_id);

					this.machineList[i].inlets[y].inlet_num = ncm.inlets[0].inlet_num;
				}
			}, error => {
				this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
					duration: 5000
				});
			});
		});
	}

	updateMachineInfo(ncm: CompleteMachine, ocm: CompleteMachine) {
		this.service.updateMachineState(ncm.machine).subscribe(data => {
			if (data) {
				const pos = this.machineList.findIndex(x => x.machine.machine_id === ocm.machine.machine_id);
				this.machineList[pos].machine.state = ncm.machine.state;
			}
		}, error => {
			this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
		});
	}

	updateMaintenanceDate(ncm: CompleteMachine, ocm: CompleteMachine) {
		this.service.updateMachineMaintenanceDate(ncm.machine).subscribe(data => {
			if (data) {
				const pos = this.machineList.findIndex(x => x.machine.machine_id === ocm.machine.machine_id);
				this.machineList[pos].machine.last_maintenance = ncm.machine.last_maintenance;
			}
		}, error => {
			this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
		});
	}

	updateNumberOfAnalysisBeforeMaintenance(ncm: CompleteMachine, ocm: CompleteMachine) {
		this.service.updateMachineAnalysisPerMaintenance(ncm.machine).subscribe(data => {
			if (data) {
				const pos = this.machineList.findIndex(x => x.machine.machine_id === ocm.machine.machine_id);
				this.machineList[pos].machine.nb_analysis_before_maintenance = ncm.machine.nb_analysis_before_maintenance;
			}
		}, error => {
			this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
		});
	}

	updateSpName(ncm: CompleteMachine, ocm: CompleteMachine) {
		ncm.inlets.forEach(i => {
			this.service.updateInletSpName(i).subscribe(data => {
				if (data) {
					const i = this.machineList.findIndex(x => x.machine.machine_id === ocm.machine.machine_id);
					const y = this.machineList[i].inlets.findIndex(y => y.inlet_num === ocm.inlets[0].inlet_num);

					this.machineList[i].inlets[y].sp_name = ncm.inlets[0].sp_name;
					this.machineList[i].inlets[y].sp_id = ncm.inlets[0].sp_id;
				}
			}, error => {
				this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
					duration: 5000
				});
			});
		});
	}

	private filterSamplingPoint() {
		if (!this.samplingPoints) {
			return;
		}

		let search = this.samplingPointFilterCtrl.value;
		if (!search) {
			this.filteredSamplingPoint.next(this.samplingPoints.slice());
			return;
		} else {
			search = search.toLowerCase();
		}

		this.filteredSamplingPoint.next(
			this.samplingPoints.filter(samplingPoint => samplingPoint.sp_tag.toLowerCase().indexOf(search) > -1)
		);
	}

	setFormUpdateMachine(machine: any) {
		this.formState = 'update';
		this.oldMachine = machine;
		this.resetInletForm();
		const tag = this.possibleSites.find(x => x.site_id === this.oldMachine.machine.site_id).site_tag;
		this.lastMaintenance = machine.machine.last_maintenance;
		this.nbAnalysisBeforeMaintenance = machine.machine.nb_analysis_before_maintenance;
		this.machineForm = this.formBuilder.group({
			machineId: [this.oldMachine.machine.machine_id, [Validators.required, Validators.pattern('([0-9]{1,3})')]],
			machineTag: [this.oldMachine.machine.machine_tag, [Validators.required, Validators.pattern('([A-Z]{1})+([0-9]{4})')]],
			state: [this.oldMachine.machine.state, Validators.required],
			machineSite: [tag, Validators.required],
			lastMaintenance: [this.lastMaintenance, Validators.required],
			nbAnalysisBeforeMaintenance: [this.nbAnalysisBeforeMaintenance, Validators.required],
		});

		for (const inlet of this.oldMachine.inlets) {
			this.addInletRow(inlet, _.find(this.samplingPoints, ['sp_id', inlet.sp_id]));
		}

		this.machineForm.get('machineId').disable();
		this.machineForm.get('machineTag').disable();
	}

	SetFormCreateMachine() {
		this.formState = 'create';

		this.resetInletForm();

		this.createMachineForm();
		this.createInletForm();

		this.machineForm.markAllAsTouched();
		this.inletForm.markAllAsTouched();
	}

	async createMachine() {
		const i_control = this.inletForm.controls.inletRow as FormArray;

		const machine = new Machine(this.machineForm.get('machineId').value, this.machineForm.get('machineTag').value
			, 0, this.machineForm.get('state').value, this.possibleSites.find(x => x.site_tag === this.machineForm.get('machineSite').value).site_id, null, this.machineForm.get('lastMaintenance').value, this.machineForm.get('nbAnalysisBeforeMaintenance').value);

		/* Asks the user to approve the modifications */
		if (this.inletForm.valid) {
			const pos = this.machineList.findIndex(x => x.machine_id === machine.machine_id);

			if (pos === -1) {
				this.isLoading = true;

				try {
					const r = await this.service.insertMachine(machine);

					if (r) {

						for (const i_elem of i_control.value) {
							const m_input = new MachineInput(null, this.machineForm.get('machineId').value, i_elem.inlet, i_elem.sp.sp_id);
							const mi_id = await this.service.insertMachineInlet(m_input);
						}


						// create machine in hmi db
						// --- COMMENT THIS PART WHEN TESTING MACHINE CREATION ---
						/*const respCode = await this.service.createHmiMachine(machine);
						if (respCode != 200) {
							this.snackbar.open('La machine est insérée dans la base de données mais une erreur est survenue lors de sa création dans le PythonDispatcher', 'Ok');
						} else {
							this.snackbar.open('Machine créée avec succès.', 'Ok', {
								duration: 5000
							});
						}*/
						// --- ---

						this.resetForms();
					}
				} catch (error) {
					this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok');
				}
				this.isLoading = false;
			} else {
				this.snackbar.open('This machine already exist', 'Ok');
			}
		} else {
			this.openSnackBar('Please fill the fields correctly', 'Ok');
		}
	}

	updateMachine() {
		this.submit();
	}

	deleteMachine() {
		const dialogRef = this.dialog.open(EditApprovalDialogComponent, {
			width: '35%',
			data: [{mod_description: 'Deleting the machine: \'' + this.oldMachine.machine.machine_tag + '\'.'}]
		});

		/* If user approved the modifications */
		dialogRef.afterClosed().subscribe(async data => {
			if (data) {
				this.service.deleteMachine(this.oldMachine.machine.machine_id).subscribe(resp => {
					if (resp.status === 200) {
						this.snackbar.open(resp.message, 'Ok', {
							duration: 3000
						});
						this.machineList.splice(_.findIndex(this.machineList, ['machine.machine_id', this.oldMachine.machine.machine_id]), 1);
						this.resetForms();
						this.formState = '';
					} else if (resp.status === 501) {
						this.snackbar.open('An error has occurred: ' + resp.message, 'Ok');
					} else {
						this.snackbar.open('An error has occurred. Code: ' + resp.status, 'Ok');
					}
				}, error => {
					this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok');
				});
			}
		});
	}

	private resetForms() {
		this.machineList = [];
		this.createMachineForm();
		this.createInletForm();

		this.initForms();

		this.machineForm.markAllAsTouched();
		this.inletForm.markAllAsTouched();
	}

	restoreFormValue() {
		this.modifications = [];
		this.inletToDelete = [];
		this.setFormUpdateMachine(this.oldMachine);
	}

	cancel() {
		this.modifications = [];
		this.inletToDelete = [];
		this.machineForm.reset();
		this.inletForm.reset();
	}

	openSnackBar(message, action) {
		this.snackbar.open(message, action, {duration: 5000});
	}
}
