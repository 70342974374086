export class Inlets {

	public inlet_num: number;
	public sp_id: number;
	public sp_name: string;
	public mi_id: number;
	public machine_id: number;
	public access_level: number;

	constructor(mi_id: number, machine_id: number, inlet_num: number, sp_id: number, sp_name: string, access_level: number) {
		this.mi_id = mi_id;
		this.machine_id = machine_id;
		this.inlet_num = inlet_num;
		this.sp_id = sp_id;
		this.sp_name = sp_name;
		this.access_level = access_level;
	}
}
