export class Role {

    public role_id: number;
    public role_name: string;
    public role_description: string;
    public business_id: number;
    public business_tag: string;

    constructor(role_id: number, role_name: string, role_description: string, business_id: number, business_tag: string) {
        this.role_id = role_id;
        this.role_name = role_name;
        this.role_description = role_description;
        this.business_id = business_id;
        this.business_tag = business_tag;
    }
}