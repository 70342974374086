import {FullscreenChartService} from '../../../fullscreen-chart/fullscreen-chart.service';
import {CustomChartData} from '../../CustomChartData';
import {MChart} from '../MChart';

/* Concrete Chart for Factory Pattern */
export class MTemperatureMinMaxChart implements MChart {

	minTemp: any;
	maxTemp: any;
	datas: CustomChartData;

	private config = {
		responsive: true,
		displaylogo: false
	};

	private layout = {
		height: 450,
		// items with a `name` attribute in template.images will be added to any
		// plot using this template
		images: [{
			source: '../../../../favicon.ico',
			xref: 'paper',
			yref: 'paper',
			x: 0.5,
			y: 0.5,
			xanchor: 'center',
			yanchor: 'middle',
			sizex: 0.7,
			sizey: 0.7,
			opacity: 0.1,
			layer: 'below'
		}],
		legend: {
			x: 0,
			y: 1.15,
			orientation: 'h',
			itemsizing: 'constant',
			itemwidth: 25
		},
		margin: {
			l: 35,
			r: 35,
			b: 50,
			t: 10
		},
		xaxis: {
			showticklabels: true,
			tickformat: '%d %b',
			tickangle: -45,
			showgrid: true,
			nticks: 36,
			showline: true,
			zeroline: false,
			mirror: true,
			hoverformat: '%Y-%m-%d %H:%M:%S'
		},
		yaxis: {
			title: 'Temperature (°C)',
			showgrid: true,
			showline: true,
			zeroline: false,
			mirror: true
		},
		modebar: {
			color: '#000000',
			bgcolor: '#FFFFFF'
		}
	};

	constructor(private service: FullscreenChartService) {
	}

	public async createChart(machine_id: number, limit: number, time: string): Promise<CustomChartData> {

		this.minTemp = await this.service.getVariableHistory(machine_id, 'ambiant_temperature_min', time, limit);
		this.maxTemp = await this.service.getVariableHistory(machine_id, 'ambiant_temperature_max', time, limit);

		const minTemp = [];
		const maxTemp = [];
		const date_min = [];
		const date_max = [];

		if (this.minTemp.length === 0 && this.maxTemp.length === 0 ) {
			this.layout.xaxis.showticklabels = false;
		}

		this.datas = new CustomChartData([], this.layout, this.config);

		for (let i = 0; i < this.minTemp.length; i++) {
			minTemp.push(this.minTemp[i].map.value);
			date_min.push(this.minTemp[i].map.analysis_time);
		}
		for (let i = 0; i < this.maxTemp.length; i++) {
			maxTemp.push(this.maxTemp[i].map.value);
			date_max.push(this.maxTemp[i].map.analysis_time);
		}

		const trace_minTemp = {
			name: 'Temp min',
			x: date_min,
			y: minTemp,
			type: 'scatter',
			mode: 'lines+markers',
			hovertemplate: '%{y:.3f}',
			marker: {size: 10, color: '#4ecdc4'},
			line: {color: '#4ecdc4'}
		};

		const trace_maxTemp = {
			name: 'Temp max',
			x: date_max,
			y: maxTemp,
			type: 'scatter',
			mode: 'lines+markers',
			hovertemplate: '%{y:.3f}',
			marker: {size: 10, color: '#f89406'},
			line: {color: '#f89406'}
		};

		this.datas.chartData = [trace_minTemp, trace_maxTemp];
		return this.datas;
	}
}


