import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

import {CookieServiceService} from '../cookie-service.service';
import {environment} from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ExportFormsService {

	header;
	machineUrl = environment.machineUrl;
	analyseUrl = environment.analysisUrl;

	constructor(private http: HttpClient, private cookieService: CookieServiceService) {
		this.header = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + this.cookieService.get('token'),
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'DELETE, GET, POST, OPTIONS',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		});
	}

	/* Get every machine's TAG and ID with the state active or standby */
	getMachines(): Observable<string[]> {
		return this.http.get<string[]>(this.machineUrl + '/machines/notTestUnit', {headers: this.header});
	}

	getSamplingPointsByMachineId(selectedMachines: string[], machines: any[]): Observable<string[]> {
		let machineIds: number[] = [];
		let params = new HttpParams();

		for (let i = 0; i < selectedMachines.length; i++) {
			machineIds.push(machines.find(x => x.machine_tag === selectedMachines[i]).machine_id);
		}
		params = params.append('machines', machineIds.toString());

		return this.http.get<string[]>(this.machineUrl + '/machines/sampling_points', {headers: this.header, params});
	}

	fetchColumns(): Observable<any> {
		return this.http.get<any>(this.analyseUrl + '/analyses/exportForm/columns', {headers: this.header});
	}

	fetchAnalysisAndCalculations(selectedMachines, selectedSamplingPoints, startTime, endTime, parsedLotsConsommable, selectedColumns): Observable<string> {
		let params = new HttpParams();
		//selectedMachines.forEach(machine => params = params.append('selectedMachines', machine));
		params = params.append('selectedMachines', selectedMachines);
		selectedSamplingPoints.forEach(samplingPoint => params = params.append('samplingPoints', samplingPoint.toString()));
		params = params.append('startTime', startTime);
		params = params.append('endTime', endTime);
		params = params.append('lotsConsommables', parsedLotsConsommable);
		params = params.append('columns', selectedColumns);
		//selectedColumns.forEach(column => params = params.append('columns', column.toString()));

		return this.http.get<any>(this.analyseUrl + '/analyses/exportForm', {headers: this.header, params});
	}

	getVariableDefinitions() {
		return this.http.get<any>(this.analyseUrl + '/analyses/variables/definitions', {headers: this.header});
	}
}
