import {FullscreenChartService} from '../../../fullscreen-chart/fullscreen-chart.service';
import {CustomChartData} from '../../CustomChartData';
import {AChart} from '../AChart';


/* Concrete Chart for Factory Pattern */
export class AFluoDataChart implements AChart {

	fluo_baseline_w1: any;
	fluo_baseline_w2: any;
	fluo_baseline_w3: any;
	fluo_baseline_w4: any;
	exp_time: any;
	datas: CustomChartData;

	private config = {
		responsive: true,
		displaylogo: false
	};

	private layout = {
		// items with a `name` attribute in template.images will be added to any
		// plot using this template
		images: [{
			source: '../../../../favicon.ico',
			xref: 'paper',
			yref: 'paper',
			x: 0.5,
			y: 0.5,
			xanchor: 'center',
			yanchor: 'middle',
			sizex: 0.7,
			sizey: 0.7,
			opacity: 0.1,
			layer: 'below'
		}],
		legend: {
			x: 0,
			y: 1.15,
			orientation: 'h',
			itemsizing: 'constant',
			itemwidth: 25
		},
		margin: {
			l: 35,
			r: 5,
			b: 60,
			t: 10
		},
		xaxis: {
			tickangle: -45,
			showgrid: true,
			nticks: 36,
			showline: true,
			zeroline: false,
			mirror: true,
			rangemode: 'tozero'
		},
		yaxis: {
			showgrid: true,
			showline: true,
			zeroline: false,
			mirror: true
		},
		modebar: {
			color: '#000000',
			bgcolor: '#FFFFFF'
		}
	};


	constructor(private service: FullscreenChartService) {
	}

	public async createChart(analysis_id: number, sensor_id: number): Promise<CustomChartData> {

		const fluo_baseline_w1 = [];
		const fluo_baseline_w2 = [];
		const fluo_baseline_w3 = [];
		const fluo_baseline_w4 = [];
		const exp_time = [];

		this.fluo_baseline_w1 = await this.service.getTelemetryDataInterpolated(analysis_id, 1141, 26);
		this.fluo_baseline_w2 = await this.service.getTelemetryDataInterpolated(analysis_id, 1142, 27);
		this.fluo_baseline_w3 = await this.service.getTelemetryDataInterpolated(analysis_id, 1143, 28);
		this.fluo_baseline_w4 = await this.service.getTelemetryDataInterpolated(analysis_id, 1144, 29);
		this.exp_time = await this.service.getTelemetryDataInterpolated(analysis_id, 1141, 24);

		this.datas = new CustomChartData([], this.layout, this.config);

		for (let i = 0; i < this.fluo_baseline_w1.length; i++) {
			fluo_baseline_w1.push(this.fluo_baseline_w1[i].map.value);
			fluo_baseline_w2.push(this.fluo_baseline_w2[i].map.value);
			fluo_baseline_w3.push(this.fluo_baseline_w3[i].map.value);
			fluo_baseline_w4.push(this.fluo_baseline_w4[i].map.value);
			exp_time.push(this.exp_time[i].map.value);
		}

		this.datas.chartData = [
			{
				name: 'ue01',
				x: exp_time,
				y: fluo_baseline_w1,
				type: 'scatter',
				mode: 'lines+markers',
				marker: {
					size: 10,
					color: 'rgba(77, 19, 209, 1)'
				},
				line: {
					color: 'rgba(77, 19, 209, 1)'
				}
			},
			{
				name: 'ue02',
				x: exp_time,
				y: fluo_baseline_w2,
				type: 'scatter',
				mode: 'lines+markers',
				marker: {
					size: 10,
					color: '#4ecdc4'
				},
				line: {
					color: '#4ecdc4'
				}
			},
			{
				name: 'ue03',
				x: exp_time,
				y: fluo_baseline_w3,
				type: 'scatter',
				mode: 'lines+markers',
				marker: {
					size: 10,
					color: '#f89406'
				},
				line: {
					color: '#f89406'
				}
			},
			{
				name: 'ue04',
				x: exp_time,
				y: fluo_baseline_w4,
				type: 'scatter',
				mode: 'lines+markers',
				marker: {
					size: 10,
					color: '#066af8'
				},
				line: {
					color: '#066af8'
				}
			}];

		return this.datas;
	}
}

