import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomePageComponent} from './home-page/home-page.component';
import {ImportFormsComponent} from './import-forms/import-forms.component';
import {ImportDataFilesComponent} from './import-data-files/import-data-files.component';
import {ExportFormsComponent} from './export-forms/export-forms.component';
import {MachineDetailComponent} from './dashboard-machine/machine-detail/machine-detail.component';
import {MachineListComponent} from './dashboard-machine/machine-list/machine-list.component';
import {DashboardAnalysisComponent} from './dashboard-analysis/dashboard-analysis.component';
import {AuthGuard} from './auth.guard';
import {LoginComponent} from './login/login.component';
import {FullscreenChartComponent} from './fullscreen-chart/fullscreen-chart.component';
import {AdminPanelComponent} from './admin-panel/admin-panel.component';
import {MachineSettingsComponent} from './admin-panel/machine-settings/machine-settings.component';
import {SamplingPointSettingsComponent} from './admin-panel/sampling-point-settings/sampling-point-settings.component';
import {AnalysisSearchEngineComponent} from './dashboard-analysis/analysis-search-engine/analysis-search-engine.component';
import {MachineLimitsComponent} from './admin-panel/machine-settings/machine-limits/machine-limits.component';
import {SamplingPointListComponent} from './dashboard-sampling-point/sampling-point-list/sampling-point-list.component';
import {SamplingPointDetailComponent} from './dashboard-sampling-point/sampling-point-detail/sampling-point-detail.component';
import {DashboardTpiDetailComponent} from './dashboard-tpi/dashboard-tpi-detail.component';
import {AccountPageComponent} from './account-page/account-page.component';


const routes: Routes = [
	{ path: '', redirectTo: 'home', pathMatch: 'full' },
	{path: 'home', component: HomePageComponent, canActivate: [AuthGuard]},
	{path: 'admin', component: AdminPanelComponent, canActivate: [AuthGuard]},
	{path: 'account', component: AccountPageComponent, canActivate: [AuthGuard]},
	{path: 'admin/machines', component: MachineSettingsComponent, canActivate: [AuthGuard]},
	{path: 'admin/samplingPoint', component: SamplingPointSettingsComponent, canActivate: [AuthGuard]},
	{path: 'admin/machineLimits', component: MachineLimitsComponent, canActivate: [AuthGuard]},
	{path: 'import/forms', component: ImportFormsComponent, canActivate: [AuthGuard]},
	{path: 'import/dataFiles', component: ImportDataFilesComponent, canActivate: [AuthGuard]},
	{path: 'export/forms', component: ExportFormsComponent, canActivate: [AuthGuard]},
	{path: 'dashboards/machines', component: MachineListComponent, canActivate: [AuthGuard]},
	{path: 'dashboards/machines/:id', component: MachineDetailComponent, canActivate: [AuthGuard]},
	{path: 'dashboards/analysis', component: AnalysisSearchEngineComponent, canActivate: [AuthGuard]},
	{path: 'dashboards/analysis/:analysisId', component: DashboardAnalysisComponent, canActivate: [AuthGuard]},
	{path: 'dashboards/samplingPoint', component: SamplingPointListComponent, canActivate: [AuthGuard]},
	{path: 'dashboards/samplingPoint/:samplingPointId', component: SamplingPointDetailComponent, canActivate: [AuthGuard]},
	{path: 'dashboards/TPI', component: DashboardTpiDetailComponent, canActivate: [AuthGuard]},
	{path: 'chart/:name/:param1/:param2/:param3/:param4/:time/:limit', component: FullscreenChartComponent, canActivate: [AuthGuard]},
	{path: '**', component: LoginComponent},
	{path: 'login', component: LoginComponent}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
