<div style="height: auto;">
    <mat-toolbar color="primary" >
        <mat-toolbar-row >
            <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm *ngIf="nav._loggedIn">
                <mat-icon>menu</mat-icon>
            </button>

            <span class="example-spacer"></span>
            <div fxShow="true" fxHide.lt-md="true">
								<a href="/home" mat-button *ngIf="nav._loggedIn && checkForPermission()" style="font-size: unset">
									<mat-icon><img style="width: 100%" src="favicon.ico"></mat-icon>
									BioAlert Solutions
								</a>
							<a href="/dashboards/machines" mat-button *ngIf="nav._loggedIn && !checkForPermission()" style="font-size: unset">
								<mat-icon><img style="width: 100%" src="favicon.ico"></mat-icon>
								BioAlert Solutions
							</a>
                <a href="/home" mat-button *ngIf="nav._loggedIn && checkForPermission()">
                    <mat-icon>home</mat-icon>
                    Home
                </a>
                <a mat-button [matMenuTriggerFor]="afterMenuDash" *ngIf="nav._loggedIn">
                    <mat-icon>dashboard</mat-icon>
                    Dashboards
                </a>
                <mat-menu #afterMenuDash="matMenu" xPosition="after">
                    <a mat-menu-item href="/dashboards/machines">
                        <mat-icon>settings</mat-icon>
                        Machine
                    </a>
                    <a mat-menu-item href="/dashboards/analysis" *ngIf="checkForPermission()">
                        <mat-icon>show_chart</mat-icon>
                        Analysis
                    </a>
                    <a mat-menu-item href="/dashboards/samplingPoint">
                        <mat-icon>scatter_plot</mat-icon>
                        Sampling point
                    </a>
										<a mat-menu-item href="/dashboards/TPI">
											<mat-icon>dashboard</mat-icon>
											TPI
										</a>
                </mat-menu>
                <a mat-button [matMenuTriggerFor]="afterMenuImport" *ngIf="nav._loggedIn && checkForPermission()">
                    <mat-icon>file_upload</mat-icon>
                    Import
                </a>
                <mat-menu #afterMenuImport="matMenu" xPosition="after">
                    <a mat-menu-item href="/import/forms">
                        <mat-icon>border_color</mat-icon>
                        Forms
                    </a>
                    <a mat-menu-item href="/import/dataFiles">
                        <mat-icon>input</mat-icon>
                        Data Files
                    </a>
                </mat-menu>
                <a href="/export/forms" mat-button *ngIf="nav._loggedIn">
                    <mat-icon>file_download</mat-icon>
                    Export
                </a>
            </div>
            <span class="spacer"></span>
            <button mat-icon-button (click)="userPanel.toggle()" fxShow="true" *ngIf="nav._loggedIn">
                <mat-icon>account_circle</mat-icon>
            </button>

        </mat-toolbar-row>
    </mat-toolbar>

    <mat-sidenav-container fxFlexFill>
        <mat-sidenav #userPanel position="end">
            <mat-nav-list fxLayout="column" fxLayoutAlign="start start">
                <a mat-button href="/admin" *ngIf="this.nav._role === 'user-admin' || this.nav._role === 'super-admin'">
                    <mat-icon>verified_user</mat-icon>
                    Administrator Panel
                </a>
                <a mat-button href="/account">
                    <mat-icon>settings</mat-icon>
                    Account settings
                </a>
                <a mat-button (click)="signOut(); userPanel.toggle()">
                    <mat-icon>logout</mat-icon>
                    Sign out
                </a>
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content fxFlexFill>
            <router-outlet></router-outlet>
        </mat-sidenav-content>

        <mat-sidenav #sidenav>
            <mat-nav-list>
                <a href="/home" mat-list-item>
                    <mat-icon>home</mat-icon>
                    Home
                </a>
                <a [matMenuTriggerFor]="afterMenuDash" mat-list-item>
                    <mat-icon>dashboard</mat-icon>
                    Dashboards</a>
                <a mat-list-item [matMenuTriggerFor]="afterMenuImport">
                    <mat-icon>file_upload</mat-icon>
                    Import</a>
                <a href="/export/forms" mat-button>
                    <mat-icon>file_download</mat-icon>
                    Export
                </a>
                <a (click)="sidenav.toggle()" mat-list-item>
                    <mat-icon>close</mat-icon>
                    Close
                </a>
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content fxFlexFill>
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>

