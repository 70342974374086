import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CookieServiceService} from '../cookie-service.service';
import {ApiMsg} from '../Models/ApiMsg';
import {Business} from '../Models/Business';
import {CompleteMachine} from '../Models/CompleteMachine';
import {Machine} from '../Models/Machine';
import {MachineInput} from '../Models/MachineInput';
import {SamplingPoint} from '../Models/SamplingPoint';
import {Site} from '../Models/Site';
import {environment} from '../../environments/environment';
import {Inlets} from "../Models/Inlets";
import {User} from "../Models/User";
import {Role} from "../Models/Role";
import {Permission} from "../Models/Permission";

@Injectable({
	providedIn: 'root'
})
export class AdminPanelService {

	header;
	machineUrl = environment.machineUrl;
	clientUrl = environment.clientUrl;
	adminUrl = environment.adminUrl;
	analysisUrl = environment.analysisUrl;

	constructor(private http: HttpClient, private cookieService: CookieServiceService) {
		this.header = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + this.cookieService.get('token'),
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'DELETE, GET, POST, PATCH, OPTIONS',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		});
	}

	getCompleteMachines(): Observable<Machine[]> {
		return this.http.get<Machine[]>(this.machineUrl + '/machines', {headers: this.header});
	}

	getInlets(mid: number): Observable<Inlets[]> {
		return this.http.get<Inlets[]>(this.machineUrl + '/machines/inlets/' + mid, {headers: this.header});
	}

	getNewMachineTag(): Observable<ApiMsg> {
		return this.http.get<ApiMsg>(this.machineUrl + '/machines/newTag', {headers: this.header});
	}

	getNewMachineId(): Observable<number> {
		return this.http.get<number>(this.machineUrl + '/machines/newId', {headers: this.header});
	}

	getBusinesses(): Observable<Business[]> {
		return this.http.get<Business[]>( this.clientUrl + '/clients', {headers: this.header});
	}

	getBusinessById(business_id: number): Observable<any> {
		return this.http.get<any>( this.clientUrl + '/clients/' + business_id, {headers: this.header});
	}

	getBusinessBySiteId(site_id: number): Observable<any> {
		return this.http.get<any>(this.clientUrl + '/businesses/' + site_id + '/siteId', {headers: this.header});
	}

	createBusiness(b: Business): Observable<boolean> {
		return this.http.post<boolean>(this.clientUrl + '/clients' + '', b, {headers: this.header});
	}

	getSites(): Observable<Site[]> {
		return this.http.get<Site[]>(this.clientUrl + '/clients' + '/sites', {headers: this.header});
	}

	getSitesByBusinessId(businessId: number): Observable<Site[]> {
		return this.http.get<Site[]>(this.clientUrl + '/clients' + '/sites/clients?id=' + businessId , {headers: this.header});
	}

	createSite(s: Site): Observable<boolean> {
		return this.http.post<boolean>(this.clientUrl + '/clients' + '/sites', s, {headers: this.header});
	}

	getMachinesInObservable(): Observable<Machine[]> {
		return this.http.get<Machine[]>(this.machineUrl + '/machines', {headers: this.header});
	}

	getMachines() {
		return this.http.get<any[]>(this.machineUrl + '/machines', {headers: this.header});
	}

	updateMachineState(machine: Machine): Observable<boolean> {
		return this.http.patch<any>(this.machineUrl + '/machines', machine, {headers: this.header});
	}
	updateMachineMaintenanceDate(machine: Machine): Observable<boolean> {
		return this.http.patch<any>(this.machineUrl + '/machines/updateMaintenanceDate', machine, {headers: this.header});
	}
	updateMachineAnalysisPerMaintenance(machine: Machine): Observable<boolean> {
		return this.http.patch<any>(this.machineUrl + '/machines/updateNumberOfAnalysisPerMaintenance', machine, {headers: this.header});
	}

	deleteMachine(machine_id: number) {
		return this.http.delete<any>(this.machineUrl + '/machines/' + machine_id, {headers: this.header});
	}

	updateInletSpName(inlet: Inlets): Observable<boolean> {
		return this.http.patch<any>(this.machineUrl + '/machines/inlets', inlet, {headers: this.header});
	}

	updateInletNum(inlet: Inlets): Observable<boolean> {
		return this.http.patch<any>(this.machineUrl + '/machines/inlets', inlet, {headers: this.header});
	}

	deleteInlet(mi_id: number) {
		return this.http.delete<any>(this.machineUrl + '/machines/inlets/' + mi_id, {headers: this.header});
	}

	getSamplingPoints(): Observable<any[]> {
		return this.http.get<any[]>(this.clientUrl + '/clients' + '/samplingPoints', {headers: this.header});
	}

	getUniqueSamplingPoints(): Observable<any[]> {
		return this.http.get<any[]>(this.clientUrl + '/clients' + '/samplingPoints/unique', {headers: this.header});
	}

	createSamplingPoint(sp: SamplingPoint): Observable<any> {
		return this.http.post(this.clientUrl + '/clients' + '/samplingPoints', sp, {headers: this.header, responseType: 'text'});
	}

	updateSamplingPoint(sp: SamplingPoint): Observable<number> {
		return this.http.patch<number>(this.clientUrl + '/clients' + '/samplingPoints', sp, {headers: this.header});
	}

	deleteSamplingPoint(sp_id: number) {
		return this.http.delete<any>(this.clientUrl + '/clients' + '/samplingPoints/' + sp_id, {headers: this.header});
	}

	async insertMachine(machine: Machine) {
		return await this.http.post<any>(this.machineUrl + '/machines', machine, {headers: this.header}).toPromise();
	}

	async createHmiMachine(machine: Machine) {
		return await this.http.post<number>(this.machineUrl + '/admin/machines/hmiInsert', machine, {headers: this.header}).toPromise();
	}

	async insertMachineInlet(m_input: MachineInput) {
		return await this.http.post<any>(this.machineUrl + '/machines/inlets', m_input, {headers: this.header}).toPromise();
	}

	getVariableDefinitions() {
		return this.http.get<any>(this.analysisUrl + '/analyses/variables/definitions', {headers: this.header});
	}

	getLimits(): Observable<any[]> {
		return this.http.get<any[]>(this.analysisUrl + '/analyses/variables/controlLimits', {headers: this.header});
	}

	createLimit(newLimit: any): Observable<any> {
		return this.http.post(this.analysisUrl + '/analyses/variables/controlLimits', newLimit, {headers: this.header, responseType: 'text'});
	}

	updateLimit(limit: any): Observable<any> {
		return this.http.patch<boolean>(this.analysisUrl + '/analyses/variables/controlLimitsUpdate', limit, {headers: this.header});
	}

	deleteLimite(cl_id: number): Observable<boolean> {
		return this.http.delete<boolean>(this.analysisUrl + '/analyses/variables/controlLimits/' + cl_id, {headers: this.header});
	}

	getUsers(): Observable<any[]> {
		return this.http.get<any[]>(this.adminUrl + '/admin/users', {headers: this.header});
	}

	getUserById(user_id: string): Observable<any> {
		return this.http.get<any>(this.adminUrl + '/admin/users/' + user_id, {headers: this.header});
	}

	getUserRole(user_id: string): Observable<any> {
		return this.http.get<any>(this.adminUrl + '/admin/roles/' + user_id, {headers: this.header});
	}

	getRolesByBusinessId(business_id: string): Observable<any> {
		return this.http.get<any>(this.adminUrl + '/admin/roles?business_id=' + business_id, {headers: this.header});
	}

	getRoles(): Observable<any> {
		return this.http.get<any>(this.adminUrl + '/admin/roles', {headers: this.header});
	}

	createRole(role: Role) {
		return this.http.post<any>(this.adminUrl + '/admin/roles', role, {headers: this.header});
	}

	updateRole(role: Role): Observable<any> {
		return this.http.patch<any>(this.adminUrl + '/admin/roles', role, {headers: this.header});
	}

	updateUser(user: User): Observable<any> {
		return this.http.patch<any>(this.adminUrl + '/admin/users', user, {headers: this.header});
	}

	resetPassword(email: string): Observable<any> {
		return this.http.get<any>(this.adminUrl + '/admin/users/resetPassword?email=' + email, {headers: this.header});
	}

	createUser(user: User) {
		return this.http.post<any>(this.adminUrl + '/admin/users', user, {headers: this.header});
	}

	deleteUser(uid: string) {
		return this.http.delete<any>(this.adminUrl + '/admin/users/' + uid, {headers: this.header});
	}

	deleteRole(role_id: number) {
		return this.http.delete<any>(this.adminUrl + '/admin/roles/' + role_id, {headers: this.header});
	}

	getClaims(uid: string): Observable<any> {
		return this.http.get<any>(this.adminUrl + '/admin/users/claims?uid=' + uid, {headers: this.header});
	}

	updateClaims(uid: string, claim: string) {
		return this.http.patch<any>(this.adminUrl + '/admin/users/claims?uid=' + uid, claim, {headers: this.header});
	}

	getPermissions(role_id: number): Observable<any> {
		return this.http.get<any>(this.adminUrl + '/admin/permissions?role_id=' + role_id, {headers: this.header});
	}

	createPermissions(permission: Permission) {
		return this.http.post<any>(this.adminUrl + '/admin/permissions', permission, {headers: this.header});
	}

	updatePermissions(permissions: Permission): Observable<any> {
		return this.http.patch<any>(this.adminUrl + '/admin/permissions', permissions, {headers: this.header});
	}

	deletePermissions(role_id: number, acl: number) {
		if (acl === null){
			return this.http.delete<any>(this.adminUrl + '/admin/permissions?role_id=' + role_id, {headers: this.header});
		}else{
			return this.http.delete<any>(this.adminUrl + '/admin/permissions?role_id=' + role_id + '&acl=' + acl, {headers: this.header});
		}
	}
}
