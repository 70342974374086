import {FullscreenChartService} from '../../../fullscreen-chart/fullscreen-chart.service';
import {CustomChartData} from '../../CustomChartData';
import {MChart} from '../MChart';

/* Concrete Chart for Factory Pattern */
export class MCalibSuccessChart implements MChart {

	c1_successChart: any;
	c2_successChart: any;
	calibration_successChart: any;
	cartAvailable: any;
	datas: CustomChartData;

	private config = {
		responsive: true,
		displaylogo: false
	};

	private layout = {
		// items with a `name` attribute in template.images will be added to any
		// plot using this template
		images: [{
			source: '../../../../favicon.ico',
			xref: 'paper',
			yref: 'paper',
			x: 0.5,
			y: 0.5,
			xanchor: 'center',
			yanchor: 'middle',
			sizex: 0.7,
			sizey: 0.7,
			opacity: 0.1,
			layer: 'below'
		}],
		legend: {
			x: 0,
			y: 1.15,
			orientation: 'h',
			itemsizing: 'constant',
			itemwidth: 25
		},
		margin: {
			l: 35,
			r: 35,
			b: 50,
			t: 10
		},
		xaxis: {
			tickformat: '%d %b',
			tickangle: -45,
			showgrid: true,
			nticks: 36,
			showline: true,
			zeroline: false,
			mirror: true,
			hoverformat: '%Y-%m-%d %H:%M:%S'
		},
		yaxis: {
			range: [0, 100],
			showgrid: true,
			showline: true,
			zeroline: false,
			mirror: true
		},
		yaxis2: {
			title: 'Cart available',
			range: [0, 18],
			overlaying: 'y',
			side: 'right',
			showgrid: false,
			showline: false,
		},
		modebar: {
			color: '#000000',
			bgcolor: '#FFFFFF'
		}
	};


	constructor(private service: FullscreenChartService) {
	}

	public async createChart(machine_id: number, limit: number, time: string): Promise<CustomChartData> {

		this.c1_successChart = await this.service.getVariableHistory(machine_id, 'result_c1_validitytest', time, limit);
		this.c2_successChart = await this.service.getVariableHistory(machine_id, 'result_c2_validitytest', time, limit);
		this.calibration_successChart = await this.service.getVariableHistory(machine_id, 'result_calib_success', time, limit);
		this.cartAvailable = await this.service.getVariableHistory(machine_id, 'cart_available', time, limit);

		const calibrationSuccess = [];
		const c1Success = [];
		const c2Success = [];
		const cartAvailable = [];
		const date_calib = [];
		const date_C1 = [];
		const date_C2 = [];
		const date_cart = [];

		this.datas = new CustomChartData([], this.layout, this.config);

		for (const calib_pts of this.calibration_successChart) {
			calibrationSuccess.push(calib_pts.map.value);
			date_calib.push(calib_pts.map.analysis_time);
		}
		for (const c1_pts of this.c1_successChart) {
			c1Success.push(c1_pts.map.value);
			date_C1.push(c1_pts.map.analysis_time);
		}
		for (const c2_pts of this.c2_successChart) {
			c2Success.push(c2_pts.map.value);
			date_C2.push(c2_pts.map.analysis_time);
		}
		for (const cart_pts of this.cartAvailable) {
			cartAvailable.push(cart_pts.map.value);
			date_cart.push(cart_pts.map.analysis_time);
		}

		const trace_calibrationSuccess = {
			name: 'Calibration success',
			x: date_calib,
			y: calibrationSuccess,
			type: 'bar',
			mode: 'markers',
			marker: {
				size: 10,
				color: 'rgba(77, 19, 209, 1)'
			}
		};

		const trace_c1Success = {
			name: 'C1 success',
			x: date_C1,
			y: c1Success,
			type: 'bar',
			mode: 'markers',
			marker: {
				size: 10,
				color: '#4ecdc4'
			}
		};

		const trace_c2Success = {
			name: 'C2 success',
			x: date_C2,
			y: c2Success,
			type: 'bar',
			mode: 'markers',
			marker: {
				size: 10,
				color: '#f89406'
			}
		};

		const trace_cart_available = {
			line: {shape: 'vh'},
			mode: 'lines+markers',
			name: 'Cart available',
			x: date_cart,
			y: cartAvailable,
			type: 'sctatter',
			marker: {
				size: 5,
				color: 'rgb(96,96,96)'
			},
			yaxis: 'y2'
		};
		this.datas.chartData = [trace_calibrationSuccess, trace_c1Success, trace_c2Success, trace_cart_available];
		return this.datas;
	}
}


