import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CookieServiceService} from '../cookie-service.service';
import {Observable} from 'rxjs';
import {SamplingPoint} from '../Models/SamplingPoint';
import {Analysis} from '../Models/Analysis';
import {Business} from '../Models/Business';
import {Site} from '../Models/Site';
import {Machine} from '../Models/Machine';

@Injectable({
  providedIn: 'root'
})
export class DashboardSamplingPointService {

	header;
	machineUrl = environment.machineUrl;
	analysisUrl = environment.analysisUrl;
	clientUrl = environment.clientUrl;

  constructor(private http: HttpClient, private cookieService: CookieServiceService) {
		this.header = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + this.cookieService.get('token'),
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'DELETE, GET, POST, OPTIONS',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		});
	}

	getSamplingPoints(client_tag: string, site_id: number): Observable<SamplingPoint[]> {
	  	if (client_tag == null && site_id == null){
			return this.http.get<SamplingPoint[]>(this.clientUrl + '/clients/samplingPoints', {headers: this.header});
		}
	  	else if (client_tag != null && site_id != null){
			return this.http.get<SamplingPoint[]>(this.clientUrl + '/clients/samplingPoints?clientTag=' + client_tag + '&siteId=' + site_id, {headers: this.header});
		}
		else if (client_tag != null){
			return this.http.get<SamplingPoint[]>(this.clientUrl + '/clients/samplingPoints?clientTag=' + client_tag, {headers: this.header});
		}
		else {
			return this.http.get<SamplingPoint[]>(this.clientUrl + '/clients/samplingPoints?siteId=' + site_id, {headers: this.header});
		}
	}

	getSamplingPointAnalysisIdHistory(sp_id: number, limit: number, offset: number) {
		return this.http.get<Analysis[]>(this.analysisUrl + '/analyses/samplingPoint?spId=' + sp_id + '&limit=' + limit + '&offset=' + offset, {headers: this.header}).toPromise();
	}

	getSamplingPointAnalysisFullDetail(analysis_id: number) {
		return this.http.get<any>(this.analysisUrl + '/analyses/' + analysis_id + '/detailed', {headers: this.header}).toPromise();
	}

	getAnalysisMissingVariables(analysis_id: number) {
		return this.http.get<any>(this.analysisUrl + '/analyses/variables/' + analysis_id + '/MissingVariables', {headers: this.header}).toPromise();
	}

	getBusinesses(): Observable<Business[]> {
		return this.http.get<Business[]>( this.clientUrl + '/clients', {headers: this.header});
	}

	getSites(): Observable<Site[]> {
		return this.http.get<Site[]>(this.clientUrl + '/clients' + '/sites', {headers: this.header});
	}

	async getErrorCodeDefinition(error_code: number) {
		return await this.http.get<any>(this.machineUrl + '/machines/error/' + error_code, {headers: this.header}).toPromise();
	}

	getSamplingPointById<SamplingPoint>(sp_id: string): Observable<SamplingPoint> {
		return this.http.get<SamplingPoint>(this.clientUrl + '/clients/samplingPoints/' + sp_id, {headers: this.header});
	}

	async getLatestVariableBySamplingPoint(machine_id: number, var_tag: string, sp_id: number) {
		return await this.http.get<any>(this.analysisUrl + '/analyses/variables/' + var_tag + '/machine/' + machine_id + '/samplingPoints/' + sp_id + '/controlLevel', {headers: this.header}).toPromise();
	}

	async getSamplingPointLatestVariable(sp_id: number, var_tag: string) {
		return await this.http.get<number>(this.analysisUrl + '/analyses/variables/' + var_tag + '/samplingPoint/' + sp_id + '/latest', {headers: this.header}).toPromise();
	}

	async getSamplingPointLatestVariableWithClLevel(sp_id: number, var_tag: string) {
		return await this.http.get<any>(this.analysisUrl + '/analyses/variables/spId/' + sp_id + '/' + var_tag + '/controlLevel', {headers: this.header}).toPromise();
	}

	async getAverageVariableForNumberOfAnalysis(sp_id: number, var_id: number, nb_analysis: number) {
		const average = await this.http.get<any>(this.analysisUrl + '/analyses/variables/variableAverageLast?sp_id=' + sp_id + '&var_id=' + var_id + '&nb_analysis=' + nb_analysis, {headers: this.header}).toPromise();
		return {value: average.average}
	}

	getSitesByBusinessId(business_id: string, limit: number): Observable<any> {
		return this.http.get<Site>(this.clientUrl + '/clients/sites/clients?id=' + business_id + '&?limit=' + limit, {headers: this.header});
	}
}
