<body>
<mat-card class="machine-list-container">
	<mat-card-title fxLayoutAlign="space-between">
		<h2>Machines dashboard</h2>
		<button mat-raised-button color="basic" (click)="openLegend()">Calculation legend</button>
	</mat-card-title>

	<mat-form-field class="number-sample-sel" appearance="fill" [formGroup]="dynamicForm">
		<mat-label>Number of samples</mat-label>
		<mat-select (selectionChange)="numberOfSampleChanged($event)" formControlName="numberOfSample">
			<mat-option *ngFor="let num of numberOfSample" [value]="num">
				{{num}}
			</mat-option>
		</mat-select>
	</mat-form-field>

	<div class="machine-list">
		<div class="sorter">
			<button mat-raised-button color="primary" (click)="orderByMachineTag()" [disabled]="standbyMachineLoading">
				<mat-icon [style]="orderedByIdMachine ? {'visibility':'visible'}:{'visibility':'hidden'}" *ngIf="ascIdMachine && orderedByIdMachine">keyboard_arrow_up</mat-icon>
				<mat-icon [style]="orderedByIdMachine ? {'visibility':'visible'}:{'visibility':'hidden'}" *ngIf="!ascIdMachine && orderedByIdMachine">keyboard_arrow_down</mat-icon>
				Machine ID
			</button>

			<button mat-raised-button color="primary" (click)="orderByDaysWithoutAnalysis()" [disabled]="standbyMachineLoading">
				<mat-icon [style]="orderedByDaysWithoutAnalysis ? {'visibility':'visible'}:{'visibility':'hidden'}" *ngIf="ascDaysWithoutAnalysis  && orderedByDaysWithoutAnalysis">keyboard_arrow_up</mat-icon>
				<mat-icon [style]="orderedByDaysWithoutAnalysis ? {'visibility':'visible'}:{'visibility':'hidden'}" *ngIf="!ascDaysWithoutAnalysis && orderedByDaysWithoutAnalysis">keyboard_arrow_down</mat-icon>
				Days without analysis
			</button>

			<button mat-raised-button color="primary" (click)="orderByCartDisp()" [disabled]="standbyMachineLoading">
				<mat-icon [style]="orderedByCartDisp ? {'visibility':'visible'}:{'visibility':'hidden'}" *ngIf="ascCartDisp && orderedByCartDisp">keyboard_arrow_up</mat-icon>
				<mat-icon [style]="orderedByCartDisp ? {'visibility':'visible'}:{'visibility':'hidden'}" *ngIf="!ascCartDisp && orderedByCartDisp">keyboard_arrow_down</mat-icon>
				Cartridges available
			</button>

			<button mat-raised-button color="primary" (click)="orderBy5LastCalibSuccess()" [disabled]="standbyMachineLoading" *ngIf="checkForPermission()">
				<mat-icon [style]="orderedByLast5AnalysisSuccess ? {'visibility':'visible'}:{'visibility':'hidden'}" *ngIf="ascLast5AnalysisSuccess  && orderedByLast5AnalysisSuccess">keyboard_arrow_up</mat-icon>
				<mat-icon [style]="orderedByLast5AnalysisSuccess ? {'visibility':'visible'}:{'visibility':'hidden'}" *ngIf="!ascLast5AnalysisSuccess && orderedByLast5AnalysisSuccess">keyboard_arrow_down</mat-icon>
				<!--Succès de calibration des 5 dernières analyses-->
				Successful calibration of the last 5 analyzes
			</button>
		</div>

		<h2>Machines active or in standby</h2>
		<mat-divider></mat-divider>
		<mat-progress-bar *ngIf="standbyMachineLoading && allAnalysisHistoryStandbyLoading" mode="indeterminate" class="progress-bar"></mat-progress-bar>

		<ul class="machines">
			<li *ngFor="let machine of standbyMachines; let i = index">
				<mat-card class="card" *ngIf="machine?.state === 'standby' || machine?.state === 'active'">
					<mat-card-header style="height: 60px" fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="start center">
						<mat-card-title style="margin-bottom: 0" fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="start center">
							<button mat-mini-fab color="basic" (click)="showStandbyMachine[i]=!showStandbyMachine[i]; loadDashboardAnalysisHistory(machine)">
								<mat-icon *ngIf="showStandbyMachine[i]">remove</mat-icon>
								<mat-icon *ngIf="!showStandbyMachine[i]">add</mat-icon>
							</button>
							<a [routerLink]="['/dashboards/machines', machine.machine_tag ]" class="machine-tag">
								<button class="button-machine-tag" mat-raised-button *ngIf="machine.flagsLoaded" [ngStyle]="
								machine.flags.status == 'N/A'?{'background-color':'#999999'}:
								machine.flags.status == 'disconnected'?{'background-color':'#8E7CC3'}:
								machine.flags.status == 'standby'?{'background-color':'#8fce00'}:
								machine.flags.status == 'error'?{'background-color':'#ff7100'}:
								machine.flags.status == 'error_long'?{'background-color':'#E0242F'}:
								machine.flags.status == 'in execution'?{'background-color':'#F9D054'}:'#999999'
								">{{ machine?.machine_tag  }}</button>
							</a>
						</mat-card-title>

						<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" *ngIf="machine.flagsLoaded" [ngStyle]="
              machine.flags.days_without_analysis == null?{'border-color':'#D5D5D5'} :
              (machine.flags.days_without_analysis == 3 || machine.flags.days_without_analysis == 4)?{'border-color':'#F9D054'} :
              machine.flags.days_without_analysis>4?{'border-color':'#E0242F'} :
              {'border-color': 'green'}">
							<p>Days without analysis:
								<ng-container *ngIf="machine.flags.days_without_analysis != null; else defaultValue">
									{{machine.flags.days_without_analysis}}
								</ng-container>
							</p>
						</mat-card>

						<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" *ngIf="machine.flagsLoaded" [ngStyle]="
               machine.flags.cartouches_disponibles == null?{'border-color':'#D5D5D5'} :
               machine.flags.cartouches_disponibles.cl_level == 0?{'border-color': 'green'}:
               machine.flags.cartouches_disponibles.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
               machine.flags.cartouches_disponibles.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
              {'border-color': 'green'}">
							<p>Cartridges available:
								<ng-container *ngIf=" machine.flags.cartouches_disponibles != null; else defaultValue">
									{{ machine.flags.cartouches_disponibles.value}}
								</ng-container>
							</p>
						</mat-card>

						<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" *ngIf="machine.flagsLoaded && checkForPermission()" [ngStyle]="
              machine.flags.last_5_analysis_success == null?{'border-color':'#D5D5D5'} :
              machine.flags.last_5_analysis_success.cl_level == 0?{'border-color': 'green'}:
              machine.flags.last_5_analysis_success.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
              machine.flags.last_5_analysis_success.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
              {'border-color': 'green'}">
							<p>Success of the last 5 analyzes:
								<ng-container *ngIf="machine.flags.last_5_analysis_success != null; else defaultValue">
									{{machine.flags.last_5_analysis_success.value}} %
								</ng-container>
							</p>
						</mat-card>

						<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" *ngIf="machine.flagsLoaded && checkForPermission()" [ngStyle]="
              machine.flags.ug == null?{'border-color':'#D5D5D5'} :
              machine.flags.ug.cl_level == 0?{'border-color': 'green'}:
              machine.flags.ug.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
              machine.flags.ug.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
              {'border-color': 'green'}">
							<p>UG/mL:
								<ng-container *ngIf="machine.flags.ug != null; else defaultValue">
									{{machine.flags.ug.value}}
								</ng-container>
							</p>
						</mat-card>

						<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" *ngIf="machine.flagsLoaded && checkForPermission()" [ngStyle]="
              machine.flags.nextMaintenanceDate == null?{'border-color':'#D5D5D5'} :
              machine.flags.nextMaintenanceDate == 'Overdue'?{'border-color':'#E0242F'} :
              machine.flags.daysNextMaintenance > 60?{'border-color': 'green'}:
              {'border-color':'#F9D054'}">
							<p>Next maintenance:
								<ng-container *ngIf="machine.flags.nextMaintenanceDate != null; else defaultValue">
									{{machine.flags.nextMaintenanceDate}}
								</ng-container>
							</p>
						</mat-card>
						<ng-template #defaultValue>No Data</ng-template>
						<span fxFlex></span>
					</mat-card-header>
					<mat-card-content>

						<div *ngIf="showStandbyMachine[i]">
							<mat-progress-bar *ngIf="!machine.detailedAnalysisHistoryIsLoaded" mode="indeterminate" class="progress-bar"></mat-progress-bar>
							<table mat-table [dataSource]="machine.detailedAnalysisHistory" class="card-right-body-table">

								<ng-container matColumnDef="samplingpoint_name">
									<th mat-header-cell *matHeaderCellDef> Sampling point tag</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.sampling_name?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.sampling_name; else defaultValue">
											<a [routerLink]="['/dashboards/samplingPoint/', element.machine_spid.value]" class="analysis_id_ref">
												<button mat-button>
													{{element.sampling_name}}
												</button>
											</a>
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="analysis_id">
									<th mat-header-cell *matHeaderCellDef> Analysis id</th>
									<td mat-cell *matCellDef="let element; let i=index" [ngStyle]="
                  !element.analysis_id?{'background-color': '#EEEEEE'}:
                  {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.analysis_id; else defaultValue">
											<a [routerLink]="['/dashboards/analysis', element.analysis_id ]" class="analysis_id_ref">
												<button mat-button [disabled]="!checkForPermission()" [ngStyle]="
                          !element.analysis_id?{'background-color': '#EEEEEE'}:
                          element.missing_variable && element.missing_variable.length > 3?{'color':'#E0242F', 'background-color': '#EEEEEE'} :
                          {'background-color': '#FFFFFF'}">
													{{element.analysis_id}}
												</button>
											</a>
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="sampling_time">
									<th mat-header-cell *matHeaderCellDef> Sampling time</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.sampling_time?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.sampling_time; else defaultValue">
											{{element.sampling_time | date:'yyyy-MM-dd HH:mm'}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="analysis_time">
									<th mat-header-cell *matHeaderCellDef> Analysis time</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.analysis_time?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.analysis_time; else defaultValue">
											{{element.analysis_time | date:'yyyy-MM-dd HH:mm'}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="inlet_num">
									<th mat-header-cell *matHeaderCellDef> Inlet number</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.machine_inlet_number?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.machine_inlet_number; else defaultValue">
											{{element.machine_inlet_number.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="cartouchesdisponibles">
									<th mat-header-cell *matHeaderCellDef> Cartridges available</th>
									<td mat-cell *matCellDef="let element"
											[ngStyle]="
                    !element.cart_available?{'background-color': '#EEEEEE'}:
                    element.cart_available.cl_level==0?{'background-color': '#FFFFFF'}:
                    element.cart_available.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
                    element.cart_available.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
                    {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.cart_available; else defaultValue">
											{{element.cart_available.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="lotc01">
									<th mat-header-cell *matHeaderCellDef> Batch</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.cart_batch_number?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.cart_batch_number; else defaultValue">
											{{element.cart_batch_number.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="ug">
									<th mat-header-cell *matHeaderCellDef> UG/mL</th>
									<td mat-cell *matCellDef="let element; let i=index" [ngStyle]="
                    !element.result_value_ug_rounded?{'background-color': '#EEEEEE'}:
                    element.result_value_ug_rounded.cl_level==0?{'background-color': '#FFFFFF'}:
                    element.result_value_ug_rounded.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
                    element.result_value_ug_rounded.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
                    {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.result_value_ug_rounded; else defaultValue">
                    <span
											*ngIf="element.mrv_relation_symbol">{{convertASCIItoChar(element.mrv_relation_symbol.value)}}</span> {{element.result_value_ug_rounded.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="ttm">
									<th mat-header-cell *matHeaderCellDef> TTm</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
                  !element.result_ttm?{'background-color': '#EEEEEE'}:
                  element.result_ttm.cl_level==0?{'background-color': '#FFFFFF'}:
                  element.result_ttm.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
                  element.result_ttm.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
                  {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.result_ttm; else defaultValue">
											{{element.result_ttm.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="vmr">
									<th mat-header-cell *matHeaderCellDef> VMR</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_vmr?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.result_vmr; else defaultValue">
											{{element.result_vmr.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="c1">
									<th mat-header-cell *matHeaderCellDef> C1</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_tt1?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.result_tt1; else defaultValue">
											{{element.result_tt1.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="v1">
									<th mat-header-cell *matHeaderCellDef> V1</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_tt2?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.result_tt2; else defaultValue">
											{{element.result_tt2.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="v2">
									<th mat-header-cell *matHeaderCellDef> V2</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_tt3?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.result_tt3; else defaultValue">
											{{element.result_tt3.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="c2">
									<th mat-header-cell *matHeaderCellDef> C2</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_tt4?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.result_tt4; else defaultValue">
											{{element.result_tt4.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="c1MinusC2">
									<th mat-header-cell *matHeaderCellDef> C1-C2</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
                  !element.result_delta_c1_c2?{'background-color': '#EEEEEE'}:
                  element.result_delta_c1_c2.cl_level==0?{'background-color': '#FFFFFF'}:
                  element.result_delta_c1_c2.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
                  element.result_delta_c1_c2.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
                  {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.result_delta_c1_c2; else defaultValue">
											{{element.result_delta_c1_c2.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="v1MinusV2">
									<th mat-header-cell *matHeaderCellDef> V1-V2</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_delta_v1_v2?{'background-color': '#EEEEEE'}:
              element.result_delta_v1_v2.cl_level==0?{'background-color': '#FFFFFF'}:
              element.result_delta_v1_v2.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
              element.result_delta_v1_v2.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.result_delta_v1_v2; else defaultValue">
											{{element.result_delta_v1_v2.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="calibration_success">
									<th mat-header-cell *matHeaderCellDef> Calibration Success</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_calib_success?{'background-color': '#EEEEEE'}:
              element.result_calib_success.cl_level==0?{'background-color': '#FFFFFF'}:
              element.result_calib_success.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
              element.result_calib_success.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.result_calib_success; else defaultValue">
											{{element.result_calib_success.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="error_code">
									<th mat-header-cell *matHeaderCellDef> Error</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_error_code?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.result_error_code; else defaultValue">
											<button mat-raised-button
															matTooltip="{{element.error_desc}}"
															[matTooltipPosition]="'left'">
												{{element.result_error_code.value}}
											</button>
										</ng-container>

									</td>
								</ng-container>

								<ng-template #defaultValue>No Data</ng-template>
								<mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
								<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
							</table>
						</div>
					</mat-card-content>
				</mat-card>
			</li>
		</ul>

		<h2>Inactives machines</h2>
		<mat-divider></mat-divider>
		<mat-progress-bar *ngIf="inactiveMachineLoading && allAnalysisHistoryInactiveLoading && !standbyMachineLoading" mode="indeterminate" class="progress-bar"></mat-progress-bar>

		<ul class="machines" *ngIf="!standbyMachineLoading">
			<li *ngFor="let inactiveMachine of inactiveMachines; let i = index">
				<mat-card class="inactive-card" *ngIf="inactiveMachine?.state === 'inactive'">
					<mat-card-header style="height: 60px" fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="start center">
						<mat-card-title style="margin-bottom: 0" fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="start center">
							<button mat-mini-fab color="basic" (click)="showInactiveMachine[i]=!showInactiveMachine[i]; loadDashboardAnalysisHistory(inactiveMachine)">
								<mat-icon *ngIf="showInactiveMachine[i]">remove</mat-icon>
								<mat-icon *ngIf="!showInactiveMachine[i]">add</mat-icon>
							</button>
							<a [routerLink]="['/dashboards/machines', inactiveMachine.machine_tag ]">
								<button mat-raised-button *ngIf="inactiveMachine.flagsLoaded" [ngStyle]="
								inactiveMachine.flags.status == 'N/A'?{'background-color':'#999999'}:
								inactiveMachine.flags.status == 'disconnected'?{'background-color':'#8E7CC3'}:
								inactiveMachine.flags.status == 'standby'?{'background-color':'#8fce00'}:
								inactiveMachine.flags.status == 'error'?{'background-color':'#ff7100'}:
								inactiveMachine.flags.status == 'error_long'?{'background-color':'#E0242F'}:
								inactiveMachine.flags.status == 'in execution'?{'background-color':'#F9D054'}:'#999999'
								">{{ inactiveMachine?.machine_tag  }}</button>
							</a>
						</mat-card-title>

						<mat-card class="inactive-general-machine-info" fxLayout="column" fxLayoutAlign="center center" *ngIf="inactiveMachine.flagsLoaded" [ngStyle]="
              inactiveMachine.flags.days_without_analysis == null?{'border-color':'#D5D5D5'} :
              (inactiveMachine.flags.days_without_analysis == 3 || inactiveMachine.flags.days_without_analysis == 4)?{'border-color':'#F9D054'} :
              inactiveMachine.flags.days_without_analysis>4?{'border-color':'#E0242F'} :
              {'border-color': 'green'}">
							<p>Days without analysis:
								<ng-container *ngIf="inactiveMachine.flags.days_without_analysis != null; else defaultValue">
									{{inactiveMachine.flags.days_without_analysis}}
								</ng-container>
							</p>
						</mat-card>

						<mat-card class="inactive-general-machine-info" fxLayout="column" fxLayoutAlign="center center" *ngIf="inactiveMachine.flagsLoaded" [ngStyle]="
              inactiveMachine.flags.cartouches_disponibles == null?{'border-color':'#D5D5D5'} :
              inactiveMachine.flags.cartouches_disponibles.cl_level == 0?{'border-color': 'green'}:
              inactiveMachine.flags.cartouches_disponibles.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
              inactiveMachine.flags.cartouches_disponibles.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
              {'border-color': 'green'}">
							<p>Cartridges available:
								<ng-container *ngIf="inactiveMachine.flags.cartouches_disponibles != null; else defaultValue">
									{{inactiveMachine.flags.cartouches_disponibles.value}}
								</ng-container>
							</p>
						</mat-card>

						<mat-card class="inactive-general-machine-info" fxLayout="column" fxLayoutAlign="center center" *ngIf="inactiveMachine.flagsLoaded && checkForPermission()" [ngStyle]="
              inactiveMachine.flags.last_5_analysis_success == null?{'border-color':'#D5D5D5'} :
              inactiveMachine.flags.last_5_analysis_success.cl_level == 0?{'border-color': 'green'}:
              inactiveMachine.flags.last_5_analysis_success.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
              inactiveMachine.flags.last_5_analysis_success.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
              {'border-color': 'green'}">
							<p>Success of the last 5 analyzes:
								<ng-container *ngIf="inactiveMachine.flags.last_5_analysis_success != null; else defaultValue">
									{{inactiveMachine.flags.last_5_analysis_success.value}} %
								</ng-container>
							</p>
						</mat-card>

						<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" *ngIf="inactiveMachine.flagsLoaded && checkForPermission()" [ngStyle]="
              inactiveMachine.flags.ug == null?{'border-color':'#D5D5D5'} :
              inactiveMachine.flags.ug.cl_level == 0?{'border-color': 'green'}:
              inactiveMachine.flags.ug.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
              inactiveMachine.flags.ug.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
              {'border-color': 'green'}">
							<p>Ug:
								<ng-container *ngIf="inactiveMachine.flags.ug != null; else defaultValue">
									{{inactiveMachine.flags.ug.value}}
								</ng-container>
							</p>
						</mat-card>


						<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" *ngIf="inactiveMachine.flagsLoaded && checkForPermission()" [ngStyle]="
              inactiveMachine.flags.nextMaintenanceDate == null?{'border-color':'#D5D5D5'} :
              inactiveMachine.flags.nextMaintenanceDate == 'Overdue'?{'border-color':'#E0242F'} :
              inactiveMachine.flags.daysNextMaintenance > 60?{'border-color': 'green'}:
              {'border-color':'#F9D054'}">
							<p>Next maintenance:
								<ng-container *ngIf="inactiveMachine.flags.nextMaintenanceDate != null; else defaultValue">
									{{inactiveMachine.flags.nextMaintenanceDate}}
								</ng-container>
							</p>
						</mat-card>
						<ng-template #defaultValue>No Data</ng-template>
						<span fxFlex></span>
					</mat-card-header>
					<mat-card-content>


						<div *ngIf="showInactiveMachine[i]">
							<mat-progress-bar *ngIf="!inactiveMachine.detailedAnalysisHistoryIsLoaded" mode="indeterminate" class="progress-bar"></mat-progress-bar>
							<table mat-table [dataSource]="inactiveMachine.detailedAnalysisHistory"
										 class="card-right-body-table">
								<ng-container matColumnDef="samplingpoint_name">
									<th mat-header-cell *matHeaderCellDef> Sampling point tag</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.sampling_name?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.sampling_name; else defaultValue">
											(tmp ID) {{element.sampling_name}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="analysis_id">
									<th mat-header-cell *matHeaderCellDef> Analysis id</th>
									<td mat-cell *matCellDef="let element; let i=index" [ngStyle]="
              !element.analysis_id?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.analysis_id; else defaultValue">
											<a [routerLink]="['/dashboards/analysis', element.analysis_id ]" class="analysis_id_ref">
												<button mat-button [disabled]="!checkForPermission()" [ngStyle]="
                        !element.analysis_id?{'background-color': '#EEEEEE'}:
                        element.missing_variable && element.missing_variable.length > 3?{'color':'#E0242F', 'background-color': '#EEEEEE'} :
                        {'background-color': '#FFFFFF'}">
													{{element.analysis_id}}
												</button>
											</a>
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="sampling_time">
									<th mat-header-cell *matHeaderCellDef> Time of sample</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.sampling_time?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.sampling_time; else defaultValue">
											{{element.sampling_time | date:'yyyy-MM-dd HH:mm'}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="inlet_num">
									<th mat-header-cell *matHeaderCellDef> Inlet number</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.machine_inlet_number?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.machine_inlet_number; else defaultValue">
											{{element.machine_inlet_number.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="cartouchesdisponibles">
									<th mat-header-cell *matHeaderCellDef> Cartridges available</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
                  !element.cart_available?{'background-color': '#EEEEEE'}:
                  element.cart_available.cl_level==0?{'background-color': '#FFFFFF'}:
                  element.cart_available.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
                  element.cart_available.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
                  {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.cart_available; else defaultValue">
											{{element.cart_available.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="lotc01">
									<th mat-header-cell *matHeaderCellDef> Batch</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.cart_batch_number?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.cart_batch_number; else defaultValue">
											{{element.cart_batch_number.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="ug">
									<th mat-header-cell *matHeaderCellDef> UG/mL</th>
									<td mat-cell *matCellDef="let element; let i=index" [ngStyle]="
                  !element.result_value_ug_rounded?{'background-color': '#EEEEEE'}:
                  element.result_value_ug_rounded.cl_level==0?{'background-color': '#FFFFFF'}:
                  element.result_value_ug_rounded.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
                  element.result_value_ug_rounded.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
                  {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.result_value_ug_rounded; else defaultValue">
                    <span
											*ngIf="element.mrv_relation_symbol">{{convertASCIItoChar(element.mrv_relation_symbol.value)}}</span> {{element.result_value_ug_rounded.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="ttm">
									<th mat-header-cell *matHeaderCellDef> TTm</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_ttm?{'background-color': '#EEEEEE'}:
              element.result_ttm.cl_level==0?{'background-color': '#FFFFFF'}:
              element.result_ttm.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
              element.result_ttm.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.result_ttm; else defaultValue">
											{{element.result_ttm.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="vmr">
									<th mat-header-cell *matHeaderCellDef> VMR</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_vmr?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.result_vmr; else defaultValue">
											{{element.result_vmr.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="c1">
									<th mat-header-cell *matHeaderCellDef> C1</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_tt1?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.result_tt1; else defaultValue">
											{{element.result_tt1.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="v1">
									<th mat-header-cell *matHeaderCellDef> V1</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_tt2?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.result_tt2; else defaultValue">
											{{element.result_tt2.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="v2">
									<th mat-header-cell *matHeaderCellDef> V2</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_tt3?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.result_tt3; else defaultValue">
											{{element.result_tt3.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="c2">
									<th mat-header-cell *matHeaderCellDef> C2</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_tt4?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.result_tt4; else defaultValue">
											{{element.result_tt4.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="c1MinusC2">
									<th mat-header-cell *matHeaderCellDef> C1-C2</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_delta_c1_c2?{'background-color': '#EEEEEE'}:
              element.result_delta_c1_c2.cl_level==0?{'background-color': '#FFFFFF'}:
              element.result_delta_c1_c2.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
              element.result_delta_c1_c2.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.result_delta_c1_c2; else defaultValue">
											{{element.result_delta_c1_c2.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="v1MinusV2">
									<th mat-header-cell *matHeaderCellDef> V1-V2</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_delta_v1_v2?{'background-color': '#EEEEEE'}:
              element.result_delta_v1_v2.cl_level==0?{'background-color': '#FFFFFF'}:
              element.result_delta_v1_v2.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
              element.result_delta_v1_v2.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.result_delta_v1_v2; else defaultValue">
											{{element.result_delta_v1_v2.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="calibration_success">
									<th mat-header-cell *matHeaderCellDef> Calibration success</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_calib_success?{'background-color': '#EEEEEE'}:
              element.result_calib_success.cl_level==0?{'background-color': '#FFFFFF'}:
              element.result_calib_success.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
              element.result_calib_success.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.result_calib_success; else defaultValue">
											{{element.result_calib_success.value}}
										</ng-container>
									</td>
								</ng-container>

								<ng-container matColumnDef="error_code">
									<th mat-header-cell *matHeaderCellDef> Error</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_error_code?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
										<ng-container *ngIf="element.result_error_code; else defaultValue">
											<button mat-raised-button
															matTooltip="{{element.error_desc}}"
															[matTooltipPosition]="'left'">
												{{element.result_error_code.value}}
											</button>
										</ng-container>
									</td>
								</ng-container>

								<ng-template #defaultValue>No Data</ng-template>
								<mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
								<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
							</table>
						</div>
					</mat-card-content>
				</mat-card>
			</li>
		</ul>
	</div>
</mat-card>


</body>


