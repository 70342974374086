import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import jwt_decode from "jwt-decode";
import {LoginService} from "./login.service";
import {CookieServiceService} from "../cookie-service.service";
import {LocalStorageService} from "../local-storage.service";
import {NavbarService} from "../header/navbar.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SocialUser} from "angularx-social-login";
import {Router} from "@angular/router";

@Component({
	templateUrl: './md-login-poput.component.html',
})
export class MdLoginPopupComponentComponent implements OnInit {
	user: SocialUser;
	email: string;
	password: string;
	loggedInViaMS: boolean;
	loadingLogin: boolean;

	constructor(
		public dialogRef: MatDialogRef<MdLoginPopupComponentComponent>,
		public service: LoginService,
		public cookieService: CookieServiceService,
		public localStorageService: LocalStorageService,
		public nav: NavbarService,
		private snackbar: MatSnackBar,
		private router: Router
	) {
	}

	ngOnInit(): void {}

	closeDialog() {
		this.dialogRef.close();
		this.router.navigate(['/login'])
	}
	signInWithMicroService(): void {
		this.loadingLogin = true;

		this.service.signInWithMicroService(this.email, this.password).subscribe(resp => {

			if (resp.idToken != null) {
				const decoded = jwt_decode(resp.idToken);
				this.cookieService.set('token', resp.idToken);
				this.localStorageService.set('userFirstName', resp.displayName);
				this.localStorageService.set('userEmail', resp.email);
				this.nav.loggedIn(decoded['role']);
				this.snackbar.open('Connexion successful!', 'Ok', {
					duration: 3000
				});
				this.loggedInViaMS = true;
				this.dialogRef.close()
				this.redirectTo(this.localStorageService.get('current_url'));

			} else if (resp.status === 400) {
				console.log(resp.message);
				this.snackbar.open('Error during connection, Please retry', 'Ok');
			} else {
				this.snackbar.open('An unknown error has occurred.', 'Ok');
			}


			this.loadingLogin = false;
		}, error => {
			this.loadingLogin = false;
			console.log(error.message);
			this.snackbar.open('Error during connection, Please retry', 'Ok');
		});
	}

	redirectTo(url: string) {
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
			this.router.navigate([url]));
	}

}
