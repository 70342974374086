<body>
<mat-card class="main-card">
	<div class="main-container" fxLayout="column">
		<div fxLayoutAlign="space-between">
			<div>
				<mat-card-title>Users
					<!-- The button is only used for alignment (with the other settings pages) -->
					<button style="visibility: hidden" mat-button>
						<mat-icon>help_outline</mat-icon>
					</button>
				</mat-card-title>
			</div>
		</div>
		<mat-divider class="title-divider"></mat-divider>

		<div fxLayout="column" fxFlex="50%">
			<mat-drawer-container class="drawer-container">
				<div class="drawer-sidenav-content">

					<mat-card class="limits-container">

						<div class="overlay" *ngIf="isLoading">
							<div class="overlay__wrapper">
								<div class="overlay__spinner">
									<button mat-raised-button color="primary" class="spinner"></button>
								</div>
							</div>
						</div>

						<div fxLayout="column" fxFlex="100%">
							<mat-nav-list [style]="{'background':'white'}">
								<mat-divider></mat-divider>
								<mat-list-item *ngFor="let user of userList;  let i = index"
											   (click)="drawer.opened && user.user_id == oldUser?.user_id && formState == 'update' ?
											    drawer.close() : drawer.open(); setFormUpdateUser(user); onSelected(user.user_name);"
											   [style]="selected == user.user_name ? {'background':'#DDDDDD'}:{'background':'white'}"
											   matTooltip="Modify user." matTooltipPosition="above" matTooltipShowDelay="500">

									<div class="account-icon">
										<span style="margin-right:20px">
											<mat-icon class="icon-display">account_circle</mat-icon>
										</span>
									</div>
									<div class="account-description">
										<div mat-line>
											<div class="account-description" style="width: 400px;">
												{{user.user_name == null ? "No name" : user.user_name}}
											</div>
											<div class="account-description" style="width: 400px;">
												{{user.user_email}}
											</div>
											<div class="account-description">
												{{user.user_roleName}}
											</div>
										</div>
									</div>
									<mat-icon style="margin-left: auto;">edit</mat-icon>
									<mat-divider></mat-divider>
								</mat-list-item>
							</mat-nav-list>
						</div>
					</mat-card>
					<button class="button-add" mat-button (click)="SetFormCreateUser(); !drawer.opened ? drawer.toggle() : drawer.open" matTooltip="Create user." matTooltipPosition="above" matTooltipShowDelay="500">
						<mat-icon>add</mat-icon>
					</button>
				</div>
				<mat-drawer #drawer class="drawer-sidenav" mode="side" position="end" [opened]="formState != ''">

					<mat-card class="form-container">

						<mat-card-title *ngIf="formState == 'update'">User modification</mat-card-title>
						<mat-card-title *ngIf="formState == 'create'">User creation</mat-card-title>

						<mat-divider class="title-divider"></mat-divider>

						<div *ngIf="userForm">
							<form [formGroup]="userForm">
								<div fxLayout="column" fxLayoutAlign="center start">
									<mat-progress-bar *ngIf="isLoading" mode="indeterminate" class="progress-bar"></mat-progress-bar>

									<h2>User</h2>

									<mat-form-field appearance="fill" style="margin: 0 5px 5px 0; width: 400px">
										<mat-label>Name</mat-label>
										<input matInput type="text" formControlName="userName" required>
										<mat-error *ngIf="userForm.get('userName').hasError('pattern')">Format: John Doe</mat-error>
									</mat-form-field>

									<mat-form-field appearance="fill" style="margin: 0 5px 5px 0; width: 400px">
										<mat-label>Email</mat-label>
										<input matInput type="text" formControlName="userEmail" required>
										<mat-error *ngIf="userForm.get('userEmail').hasError('email')">Format: abc@spibio.ca</mat-error>
									</mat-form-field>

									<mat-form-field appearance="fill" style="margin: 0 5px 5px 0; width: 400px" *ngIf="formState == 'create'">
										<mat-label>Password</mat-label>
										<input matInput type="password" formControlName="userPassword" required>
									</mat-form-field>

									<mat-form-field appearance="fill" style="width: 100px">
										<mat-label>Client</mat-label>
										<mat-select (selectionChange)="userClientChanged($event)" formControlName="userClient">
											<mat-option *ngFor="let client of possibleClients" [value]="client">
												{{client.business_tag}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<div>
										<mat-form-field appearance="fill" style="width: 300px">
											<mat-label>Role</mat-label>
											<mat-select (selectionChange)="userRoleChanged($event)" formControlName="userRole" required>
												<mat-option>-- No role --</mat-option>
												<mat-option *ngFor="let role of possibleRoles" [value]="role">
													{{role.role_name}}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div>
										<mat-form-field appearance="fill" style="width: 300px">
											<mat-label>Claims</mat-label>
											<mat-select formControlName="userClaim" required>
												<mat-option *ngFor="let claim of claims" [value]="claim">
													{{claim}}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
								</div>

								<div fxLayoutAlign="space-between">
									<div>
										<button mat-raised-button style="margin-right: 10px" (click)="cancel(); drawer.close()" type="button">Cancel</button>
										<button *ngIf="formState == 'update'" mat-raised-button style="min-width: 40px; padding: 0" (click)="restoreFormValue()" type="button" [disabled]="userForm.pristine"
												matTooltip="Initial values"
												matTooltipPosition="above" matTooltipShowDelay="300">
											<mat-icon>restore</mat-icon>
										</button>
									</div>
									<div *ngIf="formState == 'update'">
										<button mat-raised-button color="warn" *ngIf="oldUser.access_level==40" (click)="deleteUser()" type="button" style="margin-right: 10px">Delete</button>
										<button mat-raised-button color="primary" *ngIf="oldUser.access_level>=20" (click)="updateUser()" type="button" [disabled]="userForm.pristine || userForm.invalid">Apply modifications</button>
									</div>
									<button mat-raised-button color="primary" *ngIf="formState == 'create'" (click)="createUser()" [disabled]="userForm.invalid">
										Create
									</button>
								</div>
							</form>
						</div>
					</mat-card>
				</mat-drawer>
			</mat-drawer-container>
		</div>
	</div>
</mat-card>
</body>
