import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';


@Component({
	selector: 'app-import-text-dialog',
	templateUrl: './import-text-dialog.component.html',
	styleUrls: ['./import-text-dialog.component.css']
})
export class ImportTextDialogComponent {

	data: any;

	constructor(
		public dialogRef: MatDialogRef<ImportTextDialogComponent>
	) {
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

}
