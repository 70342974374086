import {FullscreenChartService} from 'src/app/fullscreen-chart/fullscreen-chart.service';
import {AChart} from '../../AnalysisProduct/AChart';
import {AChartFactory} from '../AChartFactory';
import {ATmCallingChart} from '../../AnalysisProduct/ConcreteAnalysisProduct/a-tm-calling-chart';

/**
 * The Creator class declares the factory method that is supposed to return an
 * object of a Custom Chart Data class. The Creator's subclasses usually provide the
 * implementation of this method.
 */
export class ATmCallingChartFactory extends AChartFactory {
	/**
	 * Note that the signature of the method still uses the abstract product
	 * type, even though the concrete product is actually returned from the
	 * method. This way the Creator can stay independent of concrete product
	 * classes.
	 */
	constructor(private _service: FullscreenChartService) {
		super();
	}

	public factoryMethod(): AChart {
		return new ATmCallingChart(this._service);
	}
}
