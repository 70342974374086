<body>
    <mat-card class=account-form>
        <form [formGroup]="accountForm">
            <mat-card-title>Account Settings</mat-card-title>
            <mat-divider></mat-divider>

            <div>
                <br>
                <div>
                    <div fxLayout="row wrap" fxLayoutGap="0.5%" fxLayoutAlign="start center">
                        <h3>Name :</h3>
                        <mat-form-field appearance="fill" style="width: 400px;">
                            <input matInput placeholder="John Doe" type="text" formControlName="name" required>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="0.5%" fxLayoutAlign="start center">
                        <h3>Email :</h3>
                        <mat-form-field appearance="fill" style="width: 400px;">
                            <input matInput placeholder="example@spibio.ca" type="text" formControlName="email" required>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <mat-divider></mat-divider>
            <mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
                <button mat-raised-button color="primary" (click)="saveChanges()" [disabled]="accountForm.invalid">
                    <mat-icon>save</mat-icon>
                    Save changes
                </button>
                <button mat-raised-button color="primary" (click)="resetPassword()" [disabled]="true">
                    <mat-icon>settings_backup_restore</mat-icon>
                    Reset password
                </button>
            </mat-card-actions>
        </form>
    </mat-card>
</body>
