<body>
<mat-tab-group mat-align-tabs="start">

	<!-- BIO ALERT -->
	<mat-tab label="Analysis and calculations">
		<mat-card class=bioAlert-form>
			<mat-card-title fxLayoutAlign="start center">
				Parameter selection
				<button (click)="openHelp()" mat-button>
					<mat-icon>help_outline</mat-icon>
					Help
				</button>
			</mat-card-title>
			<mat-divider></mat-divider>
			<div style="flex-direction: row; box-sizing: border-box; display: flex; padding-top: 20px;">
				<div fxLayout="row" fxLayout.md="column" fxLayout.sm="column" fxLayout.xl="row" fxLayout.xs="column" fxLayoutGap.lg="10vw" fxLayoutGap.xl="10vw"
						 style="flex-direction: row; box-sizing: border-box; display: flex;">
					<div style="box-sizing: border-box; min-width: 300px;">
						<div style="height: 100%; flex-direction: column; box-sizing: content-box; display: flex; place-content: flex-start; align-items: flex-start;">
							<div>
								<mat-form-field appearance="fill" class="param-input">
									<mat-label>Machine</mat-label>
									<mat-select [(ngModel)]="selectedMachines" (ngModelChange)='machineSelectionChange()' multiple>
										<mat-option *ngFor="let machine of machines" [value]="machine.machine_tag">
											{{machine.machine_tag}}
										</mat-option>
									</mat-select>
								</mat-form-field>
								<button (click)="selectedMachines= []; machineSelectionChange()" aria-label="Clear" mat-button mat-icon-button matSuffix>
									<mat-icon>close</mat-icon>
								</button>
							</div>

							<div>
								<mat-form-field appearance="fill" class="param-input">
									<mat-label>Sampling points</mat-label>
									<mat-select [(ngModel)]="selectedSamplingPoints" multiple>
										<mat-option *ngFor="let samplingPoint of samplingPoints" [value]="samplingPoint.map.sampling_name">
											{{samplingPoint.map.sampling_name}}
										</mat-option>
									</mat-select>
								</mat-form-field>
								<button (click)="selectedSamplingPoints= []" aria-label="Clear" mat-button mat-icon-button matSuffix>
									<mat-icon>close</mat-icon>
								</button>
							</div>

							<div>
								<mat-form-field appearance="fill" class="param-input">
									<mat-label>Date interval</mat-label>
									<mat-date-range-input [rangePicker]="picker">
										<input #startDatePicker='ngModel' [(ngModel)]="startDate" matInput matStartDate placeholder="Start">
										<input #endDatePicker='ngModel' [(ngModel)]="endDate" matEndDate matInput placeholder="End">
									</mat-date-range-input>
									<mat-datepicker-toggle [for]="picker" matSuffix>
									</mat-datepicker-toggle>
									<mat-date-range-picker #picker></mat-date-range-picker>
									<mat-hint>MM/DD/YYYY</mat-hint>
								</mat-form-field>
								<button (click)="startDate = undefined; endDate = undefined" aria-label="Clear" mat-button mat-icon-button matSuffix>
									<mat-icon>close</mat-icon>
								</button>
							</div>

							<div style="padding-bottom: 30px">
								<mat-form-field appearance="fill" class="param-input">
									<mat-label>Consumable lots</mat-label>
									<input #lotsConsommableTxtBox='ngModel' [(ngModel)]="lotsConsommable" matInput name='LotsConsommableTxtBox' ngModel
												 pattern="^(?:[0-9]+)(?:-(?:[0-9]+))?(?:,\s?(?:[0-9]+)(?:-(?:[0-9]+))?)*$"
												 type="text"/>
									<mat-hint>21, 23, 51-59</mat-hint>
									<mat-error *ngIf="lotsConsommableTxtBox.invalid">Invalid selection.</mat-error>
								</mat-form-field>
								<button (click)="lotsConsommable= ''" aria-label="Clear" mat-button mat-icon-button matSuffix>
									<mat-icon>close</mat-icon>
								</button>
							</div>
						</div>
					</div>

					<div style="box-sizing: border-box; align-items: flex-start;">
						<div cdkDropListGroup style="height: 100%; flex-direction: row; box-sizing: border-box; display: flex; place-content: flex-start; align-items: flex-start;">
							<div style="min-width: 300px;">
								<mat-card-title>Available columns</mat-card-title>
								<div class="dual-list-container">
									<div *ngIf="fetchingColumns" class="overlay">
										<div class="overlay__wrapper">
											<div class="overlay__spinner">
												<button mat-raised-button color="primary" class="spinner"></button>
											</div>
										</div>
									</div>

									<multi-drag-drop *ngIf="!fetchingColumns" [items]="unselectedColumns">
										<ng-template let-item>
											{{ item }}
										</ng-template>
									</multi-drag-drop>
								</div>
							</div>
							<div style="height: 100%; flex-direction: column; box-sizing: border-box; display: flex; place-content: center center;">
								<button (click)="openColumnInfo()" mat-button matTooltip="Show the description of the columns" matTooltipPosition="above" matTooltipShowDelay="100">
									<mat-icon>info_outline</mat-icon>
								</button>
								<button (click)="initDualList()" mat-button matTooltip="Reset both lists to initial values" matTooltipPosition="above" matTooltipShowDelay="100">
									<mat-icon>restore</mat-icon>
								</button>
								<button (click)="selectAll()" mat-button matTooltip="Select all" matTooltipPosition="above" matTooltipShowDelay="100">
									<mat-icon>select_all</mat-icon>
								</button>
							</div>
							<div>
								<mat-card-title>Selected columns</mat-card-title>
								<div class="dual-list-container">
									<div *ngIf="fetchingColumns" class="overlay">
										<div class="overlay__wrapper">
											<div class="overlay__spinner">
												<button mat-raised-button color="primary" class="spinner"></button>
											</div>
										</div>
									</div>

									<multi-drag-drop *ngIf="!fetchingColumns" [items]="selectedColumns">
										<ng-template let-item>
											{{ item }}
										</ng-template>
									</multi-drag-drop>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style="padding: 20px 20px 20px 0">
				<mat-checkbox [(ngModel)]="saveQueryToCsv">Add the generated query to the CSV.</mat-checkbox>
			</div>
			<div>
				<button (click)="exportToCSV()" [class.spinner]="fetchingAnalysisAndCalculations"
								[disabled]="fetchingAnalysisAndCalculations || lotsConsommableTxtBox.invalid || startDatePicker.invalid || endDatePicker.invalid" color="primary" mat-raised-button>Export data
				</button>
			</div>
		</mat-card>

		<mat-card class=bioAlert-form>
			<mat-card-title style="padding-bottom: 5px">
				Generating / Using Queries
				<button (click)="openHelp()" mat-button>
					<mat-icon>help_outline</mat-icon>
					Help
				</button>
			</mat-card-title>
			<mat-divider></mat-divider>
			<div style="height: 100%; flex-direction: column; box-sizing: border-box; display: flex; place-content: center space-around; align-items: flex-start; padding-top: 20px;  padding-bottom: 50px;">
				<div>
					<button (click)="generateTextualQuery()" mat-button matTooltip="Generate the query based on the parameters entered" matTooltipPosition="above" matTooltipShowDelay="500">
						<mat-icon>update</mat-icon>
						Generate query
					</button>
					<button (click)="copyQueryToClipboard(textualQuery)" aria-label="Copy" mat-button mat-icon-button matSuffix matTooltip="Copy to clipboard" matTooltipPosition="above"
									matTooltipShowDelay="500">
						<mat-icon>content_copy</mat-icon>
					</button>
					<button (click)="textualQueryString = ''; textualQueryChange()" aria-label="Clear" mat-button mat-icon-button matSuffix matTooltip="Delete" matTooltipPosition="above"
									matTooltipShowDelay="500">
						<mat-icon>close</mat-icon>
					</button>
				</div>
				<div>
					<mat-form-field appearance="fill" style="width:75vw">
						<textarea #textualQuery [(ngModel)]="textualQueryString" (ngModelChange)="textualQueryChange()" matInput ngModel style="height: 100px"></textarea>
					</mat-form-field>
					<mat-error *ngIf="!queryIsValid">Invalid request! Make sure it is in JSON format. You can generate an empty query as an example.</mat-error>
					<mat-divider></mat-divider>
				</div>
			</div>
		</mat-card>
	</mat-tab>

</mat-tab-group>
</body>
