import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from '../local-storage.service';
import {HomePageService} from './home-page.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as _ from 'lodash';

import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {NavbarService} from "../header/navbar.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

registerLocaleData(localeFr, 'fr');

@Component({
	selector: 'app-home-page',
	templateUrl: './home-page.component.html',
	styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

	userFirstname;
	userLastname;
	userEmail;
	photoUrl;

	displayDismissedNotifications: boolean;
	fetchingNotifications: boolean;
	emptyList: boolean = true;
	notifications: any;

	displayedNotifications: any[] = [];

	appliedNotifTypeFilter: any[] = [];
	notifTypeFilters: any[] = [{'gravity': 'Info', 'cl_level': 0},
		{'gravity': 'Warning', 'cl_level': 1},
		{'gravity': 'Alert', 'cl_level': 2},
		{'gravity': 'Dismissed', 'cl_level': 3}];

	timeFrame: Date;
	appliedNotifTimeFrameFilter: String = 'Last 7 days';
	notifTimeFrameFilters: String[] = ['All', 'Last 30 days', 'Last 14 days', 'Last 7 days', 'Today'];

	constructor(
		private service: HomePageService,
		private snackbar: MatSnackBar,
		public localStorageService: LocalStorageService,
		public nav: NavbarService
	) {
	}

	ngOnInit(): void {

		this.userFirstname = this.localStorageService.get('userFirstName');
		this.userLastname = this.localStorageService.get('userLastName');
		this.userEmail = this.localStorageService.get('userEmail');
		this.photoUrl = this.localStorageService.get('userImg');

		this.updateTimeFrameAndNotif();

	}

	updateTimeFrameAndNotif() {
		this.timeFrame = new Date();

		if (this.appliedNotifTimeFrameFilter == 'Last 30 days') {
			this.timeFrame.setDate(this.timeFrame.getDate() - 30);
		} else if (this.appliedNotifTimeFrameFilter == 'Last 14 days') {
			this.timeFrame.setDate(this.timeFrame.getDate() - 14);
		} else if (this.appliedNotifTimeFrameFilter == 'Last 7 days') {
			this.timeFrame.setDate(this.timeFrame.getDate() - 7);
		} else if (this.appliedNotifTimeFrameFilter == 'All') {
			this.timeFrame.setFullYear(this.timeFrame.getFullYear() - 100);
		}
		// Else: today. Only change the time like the others.
		this.timeFrame.setHours(0, 0, 0, 0);

		this.getNotifications();
	}

	getNotifications(): void {
		this.fetchingNotifications = true;
		this.service.getNotifications(this.timeFrame.toISOString()).subscribe(value => {
			this.notifications = value;

			this.notifications.forEach(x => {
				if (x.exceeded_cl != null) {
					x.exceeded_cl = x.exceeded_cl.replace('(', '').replace(')', '').split(',');
					if (x.exceeded_cl[0]) {
						x.lcl_a = +x.exceeded_cl[0];
					}
					if (x.exceeded_cl[1]) {
						x.lcl_w = +x.exceeded_cl[1];
					}
					if (x.exceeded_cl[2]) {
						x.ucl_w = +x.exceeded_cl[2];
					}
					if (x.exceeded_cl[3]) {
						x.ucl_a = +x.exceeded_cl[3];
					}
				}
			});

			this.filterAndDisplayNotifications();
			this.fetchingNotifications = false;
		}, err => {
			this.fetchingNotifications = false;
			this.snackbar.open('An error has occurred : ' + err.message, 'Try again', {
				duration: 5000
			}).onAction().subscribe(() => {
				this.getNotifications();
			});
		});
	}

	dismissNotification(notif_id: number) {
		this.service.dismissNotification(notif_id, this.userEmail).subscribe(value => {
			if (value != 204) {
				this.snackbar.open('An error has occurred . Code : ' + value, 'Ok', {
					duration: 3000
				});
			}
			this.getNotifications();
		});
	}

	RaiseBackNotification(notif_id: number) {
		this.service.raiseNotification(notif_id).subscribe(value => {
			if (value != 204) {
				this.snackbar.open('An error has occurred . Code : ' + value, 'Ok', {
					duration: 3000
				});
			}
			this.getNotifications();
		});
	}

	filterAndDisplayNotifications() {
		if (this.appliedNotifTypeFilter.length === 0) {
			this.displayedNotifications = this.notifications;
		}else if (this.appliedNotifTypeFilter.includes(3)) {
			this.displayDismissedNotifications = true;
			this.displayedNotifications = this.notifications.filter(notification => notification.dismissed || this.appliedNotifTypeFilter.includes(Math.abs(notification.cl_level)));
		} else {
			this.displayedNotifications = this.notifications.filter(notification => this.appliedNotifTypeFilter.includes(Math.abs(notification.cl_level)) && notification.dismissed == this.appliedNotifTypeFilter.includes(3));
		}
		this.emptyList = !this.displayDismissedNotifications && _.filter(this.notifications, item => item.dismissed == true).length == this.notifications.length;
	}


	refreshNotifications() {
		this.getNotifications();
	}

	displayDismissedNotificationsCBChange() {
		this.emptyList = !this.displayDismissedNotifications && _.filter(this.notifications, item => item.dismissed == true).length == this.notifications.length;
		if (this.displayDismissedNotifications) {
			this.notifTypeFilters = [{'gravity': 'Info', 'cl_level': 0}, {'gravity': 'Warning', 'cl_level': 1}, {
				'gravity': 'Alert',
				'cl_level': 2
			}, {'gravity': 'Dismissed', 'cl_level': 3}];
		} else {
			this.notifTypeFilters = [{'gravity': 'Info', 'cl_level': 0}, {'gravity': 'Warning', 'cl_level': 1}, {
				'gravity': 'Alert',
				'cl_level': 2
			}];
		}
	}
}
