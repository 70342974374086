import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Event, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {FullscreenChartService} from '../fullscreen-chart/fullscreen-chart.service';
import {DashboardTPIService} from './dashboard-tpi.service';
import {NavbarService} from '../header/navbar.service';
import {ReplaySubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CustomChartData} from 'src/app/ChartFactories/CustomChartData';
import {ExportToCsv} from 'export-to-csv';
import {TChlorineChartFactory} from '../ChartFactories/TpiFactory/TpiFactories/t-chlorine-factory';
import {TConductivityChartFactory} from '../ChartFactories/TpiFactory/TpiFactories/t-conductivity-factory';
import {TCopperCorrosionChartFactory} from '../ChartFactories/TpiFactory/TpiFactories/t-copper-corrosion-factory';
import {TRiskIndexChartFactory} from '../ChartFactories/TpiFactory/TpiFactories/t-risk-index-factory';
import {TSteelCorrosionChartFactory} from '../ChartFactories/TpiFactory/TpiFactories/t-steel-corrosion-factory';
import {TPhChartFactory} from '../ChartFactories/TpiFactory/TpiFactories/t-ph-factory';
import {TTurbidityChartFactory} from '../ChartFactories/TpiFactory/TpiFactories/t-turbidity-factory';
import {TTemperatureChartFactory} from '../ChartFactories/TpiFactory/TpiFactories/t-temperature-factory';
import {TChartFactory} from '../ChartFactories/TpiFactory/TChartFactory';
import _moment from 'moment';
const moment = _moment;

@Component({
  selector: 'app-dashboard-tpi-detail',
  templateUrl: './dashboard-tpi-detail.html',
  styleUrls: ['./dashboard-tpi-detail.component.css']
})
export class DashboardTpiDetailComponent implements OnInit {
	timeDisplay: any[] = ['custom', '1h', '6h', '1d', '7d', '14d', '30d', '90d'];
	currentTimeSelected: string;
	showTpiChart: boolean[] = [true, true, true, true, true, true, true, true, true];
	showCustomTimeSelector = true;

	t_chlorine_chart: CustomChartData;
	t_conductivity_chart: CustomChartData;
	t_ph_chart: CustomChartData;
	t_risk_index_chart: CustomChartData;
	t_turbidity_chart: CustomChartData;
	t_temperature_chart: CustomChartData;
	t_copper_corrosion_chart: CustomChartData;
	t_steel_corrosion_chart: CustomChartData;

	currentMachineTag: string = null;
	currentMachineID: number = null;
	machineForm: FormGroup;
	selectTimeForm: FormGroup;

	machineTags: string[] = [];
	machines: any[] = [];

	startDate: Date;
	endDate: Date;

	public machinesFilterCtrl: FormControl = new FormControl();
	protected _onDestroy = new Subject<void>();
	public filteredMachines: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private service: DashboardTPIService,
		private chartService: FullscreenChartService,
		private formBuilder: FormBuilder,
		private snackbar: MatSnackBar,
		public dialog: MatDialog,
		public nav: NavbarService) {
	}

	ngOnInit(): void {
		this.service.getMachines().subscribe(data => {
			this.machines = data;
			this.machineTags = data.map(m => m.machine_tag);
			this.filteredMachines.next(this.machines.slice());

			this.machinesFilterCtrl.valueChanges
				.pipe(takeUntil(this._onDestroy))
				.subscribe(() => {
					this.filterMachines();
				});
		}, error => {
			this.snackbar.open('Une erreur est survenue. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
		});

		this.machineForm = this.formBuilder.group({
			machineTag: [this.currentMachineTag],
			machineID: [this.currentMachineID]
		});

		this.currentTimeSelected = 'custom';
		this.selectTimeForm = this.formBuilder.group({
			currentTimeSelected: [this.currentTimeSelected],
		});
	}

	machineSelectedChanged($event: any) {
		this.currentMachineTag = $event.value.machine_tag;
		this.currentMachineID = $event.value.machine_id;
		this.selectionChanged();
	}

	timeSelectionChanged($event: any) {
		this.currentTimeSelected = $event.value;
		this.showCustomTimeSelector = this.currentTimeSelected === 'custom';
		this.selectionChanged();
	}

	customTimeSelectionStartChanged($event: any) {
		if (moment.isMoment($event.value)) {
			this.startDate = $event.value;
			this.selectionChanged();
		}
	}
	customTimeSelectionStopChanged($event: any) {
		if (moment.isMoment($event.value)) {
			this.endDate = $event.value;
			this.selectionChanged();
		}
	}

	selectionChanged(){
		if (this.currentMachineID && (this.currentTimeSelected !== 'custom' || moment.isMoment(this.startDate) && moment.isMoment(this.endDate))){
			const time = this.calculateStartStopTime();
			this.createChart(new TConductivityChartFactory(this.chartService), this.currentMachineID, time.start, time.end, 'Conductivity Chart');
			this.createChart(new TPhChartFactory(this.chartService), this.currentMachineID, time.start, time.end, 'pH Chart');
			this.createChart(new TTurbidityChartFactory(this.chartService), this.currentMachineID, time.start, time.end, 'Turbidity Chart');
			this.createChart(new TTemperatureChartFactory(this.chartService), this.currentMachineID, time.start, time.end, 'Temperature AEGIS Chart');
			this.createChart(new TChlorineChartFactory(this.chartService), this.currentMachineID, time.start, time.end, 'Free Chlorine Chart');
			this.createChart(new TCopperCorrosionChartFactory(this.chartService), this.currentMachineID, time.start, time.end, 'Copper Corrosion Chart');
			this.createChart(new TSteelCorrosionChartFactory(this.chartService), this.currentMachineID, time.start, time.end, 'Steel Corrosion Chart');
			this.createChart(new TRiskIndexChartFactory(this.chartService), this.currentMachineID, time.start, time.end, 'Risk Index Chart');
		}
	}

	calculateStartStopTime(){
		let start;
		let end;
		if (this.currentTimeSelected === 'custom') {
			start = moment(this.startDate).format('YYYY-MM-DD HH:mm:ss');
			end = moment(this.endDate).format('YYYY-MM-DD HH:mm:ss');
		} else {
			const nb_time =  parseInt(this.currentTimeSelected.substring(0, this.currentTimeSelected.length - 1), 10);
			const str_time = this.currentTimeSelected[this.currentTimeSelected.length - 1];
			// @ts-ignore
			start = moment().subtract(nb_time, str_time).format('YYYY-MM-DD HH:mm:ss');
			end = moment().format('YYYY-MM-DD HH:mm:ss');
		}
		return {start, end};
	}

	async createChart(facto: TChartFactory, machine_id: number, start: string, end: string, chartName: string) {
		if (chartName === 'Conductivity Chart'){
			this.t_conductivity_chart = await facto.createChart(machine_id, start, end);
		}
		if (chartName === 'pH Chart'){
			this.t_ph_chart = await facto.createChart(machine_id, start, end);
		}
		if (chartName === 'Turbidity Chart'){
			this.t_turbidity_chart = await facto.createChart(machine_id, start, end);
		}
		if (chartName === 'Temperature AEGIS Chart'){
			this.t_temperature_chart = await facto.createChart(machine_id, start, end);
		}
		if (chartName === 'Free Chlorine Chart'){
			this.t_chlorine_chart = await facto.createChart(machine_id, start, end);
		}
		if (chartName === 'Copper Corrosion Chart'){
			this.t_copper_corrosion_chart = await facto.createChart(machine_id, start, end);
		}
		if (chartName === 'Steel Corrosion Chart'){
			this.t_steel_corrosion_chart = await facto.createChart(machine_id, start, end);
		}
		if (chartName === 'Risk Index Chart'){
			this.t_risk_index_chart = await facto.createChart(machine_id, start, end);
		}
	}

	exportToCsv(chartName: string) {
		let temp = [];
		if (chartName === 'Conductivity Chart') {
			temp = this.t_conductivity_chart.chartData;
		}
		if (chartName === 'pH Chart') {
			temp = this.t_ph_chart.chartData;
		}
		if (chartName === 'Turbidity Chart') {
			temp = this.t_turbidity_chart.chartData;
		}
		if (chartName === 'Temperature AEGIS Chart') {
			temp = this.t_temperature_chart.chartData;
		}
		if (chartName === 'Free Chlorine Chart') {
			temp = this.t_chlorine_chart.chartData;
		}
		if (chartName === 'Copper Corrosion Chart') {
			temp = this.t_copper_corrosion_chart.chartData;
		}
		if (chartName === 'Steel Corrosion Chart') {
			temp = this.t_steel_corrosion_chart.chartData;
		}
		if (chartName === 'Risk Index Chart') {
			temp = this.t_risk_index_chart.chartData;
		}

		const file = [];
		let i = 0;
		for (const t of temp) {
			const header = t.name;
			const data = t.y;
			const xaxis = t.x;
			data.forEach(element => {
				file.push([header, element, xaxis[i]]);
				i++;
			});
			i = 0;
		}

		const options = {
			filename: chartName,
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalSeparator: '.',
			showLabels: true,
			showTitle: true,
			title: chartName,
			useTextFile: false,
			useBom: true,
			// useKeysA
			sHeaders: true,
		};
		const csvExporter = new ExportToCsv(options);

		csvExporter.generateCsv(file);
	}

	openChartFullscreen(chartName: string) {
		if (chartName === 'Conductivity Chart' && this.currentMachineID && (this.currentTimeSelected !== 'custom' || moment.isMoment(this.startDate) && moment.isMoment(this.endDate))) {
			const time = this.calculateStartStopTime();
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Conductivity Chart', this.currentMachineID, this.currentMachineTag, time.start, time.end, null, null])
			);
			window.open(url, '_blank');
		} else if (chartName === 'pH Chart' && this.currentMachineID && (this.currentTimeSelected !== 'custom' || moment.isMoment(this.startDate) && moment.isMoment(this.endDate))) {
			const time = this.calculateStartStopTime();
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'pH Chart', this.currentMachineID, this.currentMachineTag, time.start, time.end, null, null])
			);
			window.open(url, '_blank');
		} else if (chartName === 'Turbidity Chart' && this.currentMachineID && (this.currentTimeSelected !== 'custom' || moment.isMoment(this.startDate) && moment.isMoment(this.endDate))) {
			const time = this.calculateStartStopTime();
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Turbidity Chart', this.currentMachineID, this.currentMachineTag, time.start, time.end, null, null])
			);
			window.open(url, '_blank');
		} else if (chartName === 'Temperature AEGIS Chart' && this.currentMachineID && (this.currentTimeSelected !== 'custom' || moment.isMoment(this.startDate) && moment.isMoment(this.endDate))) {
			const time = this.calculateStartStopTime();
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Temperature AEGIS Chart', this.currentMachineID, this.currentMachineTag, time.start, time.end, null, null])
			);
			window.open(url, '_blank');
		} else if (chartName === 'Free Chlorine Chart' && this.currentMachineID && (this.currentTimeSelected !== 'custom' || moment.isMoment(this.startDate) && moment.isMoment(this.endDate))) {
			const time = this.calculateStartStopTime();
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Free Chlorine Chart', this.currentMachineID, this.currentMachineTag, time.start, time.end, null, null])
			);
			window.open(url, '_blank');
		} else if (chartName === 'Copper Corrosion Chart' && this.currentMachineID && (this.currentTimeSelected !== 'custom' || moment.isMoment(this.startDate) && moment.isMoment(this.endDate))) {
			const time = this.calculateStartStopTime();
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Copper Corrosion Chart', this.currentMachineID, this.currentMachineTag, time.start, time.end, null, null])
			);
			window.open(url, '_blank');
		} else if (chartName === 'Steel Corrosion Chart' && this.currentMachineID && (this.currentTimeSelected !== 'custom' || moment.isMoment(this.startDate) && moment.isMoment(this.endDate))) {
			const time = this.calculateStartStopTime();
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Steel Corrosion Chart', this.currentMachineID, this.currentMachineTag, time.start, time.end, null, null])
			);
			window.open(url, '_blank');
		} else if (chartName === 'Risk Index Chart' && this.currentMachineID && (this.currentTimeSelected !== 'custom' || moment.isMoment(this.startDate) && moment.isMoment(this.endDate))) {
			const time = this.calculateStartStopTime();
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Risk Index Chart', this.currentMachineID, this.currentMachineTag, time.start, time.end, null, null])
			);
			window.open(url, '_blank');
		}
	}

	protected filterMachines() {
		if (!this.machines) {
			return;
		}
		let search = this.machinesFilterCtrl.value;

		if (!search) {
			this.filteredMachines.next(this.machines.slice());
			return;
		} else {
			search = search.toLowerCase();
		}

		this.filteredMachines.next(
			this.machines.filter(machine => machine.machine_tag.toLowerCase().indexOf(search) > -1)
		);
	}

	checkForPermission(): boolean{
		return this.nav._role === 'admin' || this.nav._role === 'super-admin';
	}
}
