import {FullscreenChartService} from '../../../fullscreen-chart/fullscreen-chart.service';
import {CustomChartData} from '../../CustomChartData';
import {MChart} from '../MChart';

/* Concrete Chart for Factory Pattern */
export class MCalibratorsChart implements MChart {

	c1: any;
	c2: any;
	c1minc2: any;
	cartAvailable: any;
	datas: CustomChartData;

	private config = {
		responsive: true,
		displaylogo: false
	};

	private layout = {
		height: 450,
		// items with a `name` attribute in template.images will be added to any
		// plot using this template
		images: [{
			source: '../../../../favicon.ico',
			xref: 'paper',
			yref: 'paper',
			x: 0.5,
			y: 0.5,
			xanchor: 'center',
			yanchor: 'middle',
			sizex: 0.7,
			sizey: 0.7,
			opacity: 0.1,
			layer: 'below'
		}],
		legend: {
			x: 0,
			y: 1.15,
			orientation: 'h',
			itemsizing: 'constant',
			itemwidth: 25
		},
		margin: {
			l: 35,
			r: 35,
			b: 50,
			t: 10
		},
		xaxis: {
			tickformat: '%d %b',
			tickangle: -45,
			showgrid: true,
			nticks: 36,
			showline: true,
			zeroline: false,
			mirror: true,
			hoverformat: '%Y-%m-%d %H:%M:%S'
		},
		yaxis: {
			range: [350, 700],
			showgrid: true,
			showline: true,
			zeroline: false,
			mirror: true
		},
		yaxis2: {
			title: 'Cart available',
			range: [0, 18],
			overlaying: 'y',
			side: 'right',
			showgrid: false,
			showline: false,
		},
		modebar: {
			color: '#000000',
			bgcolor: '#FFFFFF'
		}
	};

	constructor(private service: FullscreenChartService) {
	}

	public async createChart(machine_id: number, limit: number, time: string): Promise<CustomChartData> {

		this.c1 = await this.service.getVariableHistory(machine_id, 'result_tt1', time, limit);
		this.c2 = await this.service.getVariableHistory(machine_id, 'result_tt4', time, limit);
		this.c1minc2 = await this.service.getVariableHistory(machine_id, 'result_delta_c1_c2', time, limit);
		this.cartAvailable = await this.service.getVariableHistory(machine_id, 'cart_available', time, limit);

		const c1 = [];
		const c2 = [];
		const c1minc2 = [];
		const cartAvailable = [];
		const date_c1 = [];
		const date_c2 = [];
		const date_c1minc2 = [];
		const date_cart = [];

		this.datas = new CustomChartData([], this.layout, this.config);

		for (const c1_pts of this.c1) {
			c1.push(c1_pts.map.value);
			date_c1.push(c1_pts.map.analysis_time);
		}
		for (const c2_pts of this.c2) {
			c2.push(c2_pts.map.value);
			date_c2.push(c2_pts.map.analysis_time);
		}
		for (const c1minc2_pts of this.c1minc2) {
			c1minc2.push(c1minc2_pts.map.value);
			date_c1minc2.push(c1minc2_pts.map.analysis_time);
		}
		for (const cart_pts of this.cartAvailable) {
			cartAvailable.push(cart_pts.map.value);
			date_cart.push(cart_pts.map.analysis_time);
		}

		const trace_c1 = {
			name: 'C1',
			x: date_c1,
			y: c1,
			type: 'scatter',
			mode: 'markers',
			hovertemplate: '%{y:.3f}',
			marker: {size: 10, color: '#4ecdc4'}
		};

		const trace_c2 = {
			name: 'C2',
			x: date_c2,
			y: c2,
			type: 'scatter',
			mode: 'markers',
			hovertemplate: '%{y:.3f}',
			marker: {size: 10, color: '#f89406'}
		};

		const trace_c1_c2 = {
			name: 'C1-C2',
			x: date_c1minc2,
			y: c1minc2,
			type: 'scatter',
			mode: 'markers',
			hovertemplate: '%{y:.3f}',
			marker: {size: 10, color: 'rgb(118,118,118)'}
		};

		const trace_bar = {
			name: '',
			base: c2,
			x: date_c1minc2,
			y: c1minc2,
			type: 'bar',
			marker: {size: 10, color: 'rgba(77, 19, 209, 1)'},
			hoverinfo: 'skip',
			showlegend: false
		};

		const trace_cart_available = {
			line: {shape: 'vh'},
			mode: 'lines+markers',
			name: 'Cart available',
			x: date_cart,
			y: cartAvailable,
			type: 'sctatter',
			marker: {
				size: 5,
				color: 'rgb(96,96,96)'
			},
			yaxis: 'y2'
		};

		this.datas.chartData = [trace_c1, trace_c2, trace_c1_c2, trace_bar, trace_cart_available];
		return this.datas;
	}
}


