export class Business {

	public business_id: number;
	public business_tag: string;
	public business_name: string;
	public access_level: number;

	constructor(business_id: number, business_tag: string, business_name: string, access_level: number) {
		this.business_id = business_id;
		this.business_tag = business_tag;
		this.business_name = business_name;
		this.access_level = access_level;
	}

}
