import {FullscreenChartService} from '../../../fullscreen-chart/fullscreen-chart.service';
import {MCalibSuccessChart} from '../../MachineProduct/ConcreteMachineProduct/m-calib-success-chart';
import {MChart} from '../../MachineProduct/MChart';
import {MChartFactory} from '../MChartFactory';


/**
 * The Creator class declares the factory method that is supposed to return an
 * object of a Custom Chart Data class. The Creator's subclasses usually provide the
 * implementation of this method.
 */
export class MCalibSuccessChartFactory extends MChartFactory {
	/**
	 * Note that the signature of the method still uses the abstract product
	 * type, even though the concrete product is actually returned from the
	 * method. This way the Creator can stay independent of concrete product
	 * classes.
	 */
	constructor(private _service: FullscreenChartService) {
		super();
	}

	public factoryMethod(): MChart {
		return new MCalibSuccessChart(this._service);
	}
}

