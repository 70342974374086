<body>
<mat-card class="dashboard-tpi-header">
	<mat-card-title fxLayoutAlign="space-around">
		<h2>TPI dashboard</h2>
	</mat-card-title>
</mat-card>

<!--TPI DASHBOARD-->
<mat-card class="tpi-dashboard-page">
	<mat-card-content>
		<mat-form-field class="mat-sel" appearance="fill" [formGroup]="machineForm" >
			<mat-label>Machine tag</mat-label>
			<mat-select (selectionChange)="machineSelectedChanged($event)" formControlName="machineTag" required>
				<mat-option>
					<ngx-mat-select-search [formControl]="machinesFilterCtrl" placeholderLabel="Search" noEntriesFoundLabel="No result"></ngx-mat-select-search>
				</mat-option>
				<mat-option *ngFor="let machine of filteredMachines | async" [value]="machine">
					{{machine.machine_tag}}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field class="mat-sel" appearance="fill" [formGroup]="selectTimeForm">
			<mat-label>Time selection</mat-label>
			<mat-select (selectionChange)="timeSelectionChanged($event)" formControlName="currentTimeSelected" required>
				<mat-option *ngFor="let time of timeDisplay" [value]="time">
					{{time}}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field *ngIf="showCustomTimeSelector" appearance="fill" class="param-input">
			<mat-label>Date interval</mat-label>
			<mat-date-range-input [rangePicker]="picker">
				<input #startDatePicker='ngModel' [(ngModel)]="startDate" (dateChange)="customTimeSelectionStartChanged($event)" matInput matStartDate placeholder="Start">
				<input #endDatePicker='ngModel' [(ngModel)]="endDate" (dateChange)="customTimeSelectionStopChanged($event)" matEndDate matInput placeholder="End">
			</mat-date-range-input>
			<mat-datepicker-toggle [for]="picker" matSuffix>
			</mat-datepicker-toggle>
			<mat-date-range-picker #picker></mat-date-range-picker>
			<mat-hint>YYYY/MM/DD - YYYY/MM/DD</mat-hint>
		</mat-form-field>
		<button *ngIf="showCustomTimeSelector" (click)="startDate = undefined; endDate = undefined" aria-label="Clear" mat-button mat-icon-button matSuffix>
			<mat-icon>close</mat-icon>
		</button>

		<!--TPI Charts-->
		<mat-card class="chart-container" fxLayout="row wrap" fxLayoutAlign="start center">
			<!--Conductivity Chart-->
			<mat-card class="dashboard-tpi-chart" *ngIf="checkForPermission()">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					Conductivity (&#181;S)
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('Conductivity Chart')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="t_conductivity_chart && showTpiChart[0]">
					<plotly-plot
						[data]="t_conductivity_chart.chartData"
						[layout]="t_conductivity_chart.chartLayout"
						[config]="t_conductivity_chart.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!t_conductivity_chart || !showTpiChart[0]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showTpiChart[0]= !showTpiChart[0]">
						<mat-icon *ngIf="showTpiChart[0]">remove</mat-icon>
						<mat-icon *ngIf="!showTpiChart[0]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv" (click)="exportToCsv('Conductivity Chart')">
						<mat-icon>cloud_download</mat-icon>
					</button>
				</mat-card-actions>
			</mat-card>
			<!--pH Chart-->
			<mat-card class="dashboard-tpi-chart" *ngIf="checkForPermission()">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					pH
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('pH Chart')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="t_ph_chart && showTpiChart[1]">
					<plotly-plot
						[data]="t_ph_chart.chartData"
						[layout]="t_ph_chart.chartLayout"
						[config]="t_ph_chart.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!t_ph_chart || !showTpiChart[1]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showTpiChart[1]= !showTpiChart[1]">
						<mat-icon *ngIf="showTpiChart[1]">remove</mat-icon>
						<mat-icon *ngIf="!showTpiChart[1]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv" (click)="exportToCsv('pH Chart')">
						<mat-icon>cloud_download</mat-icon>
					</button>
				</mat-card-actions>
			</mat-card>
			<!--Turbidity Chart-->
			<mat-card class="dashboard-tpi-chart" *ngIf="checkForPermission()">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					Turbidity (NTU)
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('Turbidity Chart')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="t_turbidity_chart && showTpiChart[2]">
					<plotly-plot
						[data]="t_turbidity_chart.chartData"
						[layout]="t_turbidity_chart.chartLayout"
						[config]="t_turbidity_chart.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!t_turbidity_chart || !showTpiChart[2]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showTpiChart[2]= !showTpiChart[2]">
						<mat-icon *ngIf="showTpiChart[2]">remove</mat-icon>
						<mat-icon *ngIf="!showTpiChart[2]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv" (click)="exportToCsv('Turbidity Chart')">
						<mat-icon>cloud_download</mat-icon>
					</button>
				</mat-card-actions>
			</mat-card>
			<!--Temperature AEGIS Chart-->
			<mat-card class="dashboard-tpi-chart" *ngIf="checkForPermission()">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					Temperature (&#176;C)
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('Temperature AEGIS Chart')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="t_temperature_chart && showTpiChart[3]">
					<plotly-plot
						[data]="t_temperature_chart.chartData"
						[layout]="t_temperature_chart.chartLayout"
						[config]="t_temperature_chart.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!t_temperature_chart || !showTpiChart[3]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showTpiChart[3]= !showTpiChart[3]">
						<mat-icon *ngIf="showTpiChart[3]">remove</mat-icon>
						<mat-icon *ngIf="!showTpiChart[3]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv" (click)="exportToCsv('Temperature AEGIS Chart')">
						<mat-icon>cloud_download</mat-icon>
					</button>
				</mat-card-actions>
			</mat-card>
			<!--Free Chlorine Chart-->
			<mat-card class="dashboard-tpi-chart" *ngIf="checkForPermission()">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					Free Chlorine (ppm)
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('Free Chlorine Chart')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="t_chlorine_chart && showTpiChart[4]">
					<plotly-plot
						[data]="t_chlorine_chart.chartData"
						[layout]="t_chlorine_chart.chartLayout"
						[config]="t_chlorine_chart.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!t_chlorine_chart || !showTpiChart[4]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showTpiChart[4]= !showTpiChart[4]">
						<mat-icon *ngIf="showTpiChart[4]">remove</mat-icon>
						<mat-icon *ngIf="!showTpiChart[4]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv" (click)="exportToCsv('Free Chlorine Chart')">
						<mat-icon>cloud_download</mat-icon>
					</button>
				</mat-card-actions>
			</mat-card>
			<!--Copper Corrosion Chart-->
			<mat-card class="dashboard-tpi-chart" *ngIf="checkForPermission()">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					Copper Corrosion (mpy)
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('Copper Corrosion Chart')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="t_copper_corrosion_chart && showTpiChart[5]">
					<plotly-plot
						[data]="t_copper_corrosion_chart.chartData"
						[layout]="t_copper_corrosion_chart.chartLayout"
						[config]="t_copper_corrosion_chart.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!t_copper_corrosion_chart || !showTpiChart[5]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showTpiChart[5]= !showTpiChart[5]">
						<mat-icon *ngIf="showTpiChart[5]">remove</mat-icon>
						<mat-icon *ngIf="!showTpiChart[5]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv" (click)="exportToCsv('Copper Corrosion Chart')">
						<mat-icon>cloud_download</mat-icon>
					</button>
				</mat-card-actions>
			</mat-card>
			<!--Steel Corrosion Chart-->
			<mat-card class="dashboard-tpi-chart" *ngIf="checkForPermission()">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					Steel Corrosion (mpy)
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('Steel Corrosion Chart')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="t_steel_corrosion_chart && showTpiChart[6]">
					<plotly-plot
						[data]="t_steel_corrosion_chart.chartData"
						[layout]="t_steel_corrosion_chart.chartLayout"
						[config]="t_steel_corrosion_chart.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!t_steel_corrosion_chart || !showTpiChart[6]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showTpiChart[6]= !showTpiChart[6]">
						<mat-icon *ngIf="showTpiChart[6]">remove</mat-icon>
						<mat-icon *ngIf="!showTpiChart[6]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv" (click)="exportToCsv('Steel Corrosion Chart')">
						<mat-icon>cloud_download</mat-icon>
					</button>
				</mat-card-actions>
			</mat-card>
			<!--Risk Index Chart-->
			<mat-card class="dashboard-tpi-chart" *ngIf="checkForPermission()">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					Risk Index
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('Risk Index Chart')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="t_risk_index_chart && showTpiChart[7]">
					<plotly-plot
						[data]="t_risk_index_chart.chartData"
						[layout]="t_risk_index_chart.chartLayout"
						[config]="t_risk_index_chart.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!t_risk_index_chart || !showTpiChart[7]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showTpiChart[7]= !showTpiChart[7]">
						<mat-icon *ngIf="showTpiChart[7]">remove</mat-icon>
						<mat-icon *ngIf="!showTpiChart[7]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv" (click)="exportToCsv('Risk Index Chart')">
						<mat-icon>cloud_download</mat-icon>
					</button>
				</mat-card-actions>
			</mat-card>
		</mat-card>
	</mat-card-content>
</mat-card>

<mat-divider></mat-divider>
</body>
