import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ExportFormsService} from '../export-forms.service';


@Component({
	selector: 'column-info-dialog',
	templateUrl: './column-info-dialog.component.html',
	styleUrls: ['./column-info-dialog.component.scss']
})
export class ExportFormColumnInfoDialog implements OnInit {

	columnsDefinitions: any[] = [];
	searchText = '';
	title = 'angular-text-search-highlight';
	toggle: boolean[] = [];

	constructor(
		private service: ExportFormsService,
		public dialogRef: MatDialogRef<ExportFormColumnInfoDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
	}

	ngOnInit(): void {
		this.initColumnsInfo();
	}

	async initColumnsInfo() {

		if (this.data.caller === 'ExportForm') {
			// Manual Definitions because these variables are in the analyses.view_analyses and not in analyses.variable_definitions.
			this.columnsDefinitions.push({description: 'Unique Analysis Number', var_id: 2, var_tag: 'analysis_id'});
			this.columnsDefinitions.push({description: 'Unique machine tag', var_id: 2, var_tag: 'machine_tag'});
			this.columnsDefinitions.push({description: 'Start time of the analysis', var_id: 2, var_tag: 'analysis_time'});
			this.columnsDefinitions.push({description: 'Sampling Point ID (integer)', var_id: 2, var_tag: 'sp_id'});
			this.columnsDefinitions.push({description: 'Sampling Point name', var_id: 2, var_tag: 'sp_name'});
			this.columnsDefinitions.push({description: 'Time at which the sample was taken', var_id: 2, var_tag: 'sampling_time'});
			this.columnsDefinitions.push({
				description: 'Method of the analysis (bioAlert, ATP, qPCR, culture, qLAMP)', var_id: 2, var_tag: 'method_tag'
			});
		}
		(await this.service.getVariableDefinitions().toPromise()).forEach(def => this.columnsDefinitions.push(def));

	}

	// On search text change, close all elements from the list.
	closeListElements() {
		this.toggle = [];
	}
}
