<mat-card>
	<mat-card-header>
		<mat-card-title>Sampling point creation</mat-card-title>
	</mat-card-header>
	<mat-card-content *ngIf="spCreatorForm">
		<form [formGroup]="spCreatorForm">
			<mat-card fxLayout="column" fxLayoutAlign="center start">
				<mat-progress-bar *ngIf="isLoading" mode="indeterminate" class="progress-bar"></mat-progress-bar>

				<div fxLayout="row" fxLayoutGap="2.5%" fxLayoutAlign="start center" style="margin-bottom: 5px">
					<div fxLayout="row" *ngIf="!addBusiness" style="align-items: center">
						<mat-form-field appearance="fill">
							<mat-label>Client</mat-label>

							<mat-select (selectionChange)="clientSelectedChanged($event)" formControlName="businessTag" required>
								<mat-option>
									<ngx-mat-select-search [formControl]="businessFilterCtrl" placeholderLabel="Search" noEntriesFoundLabel="No result"></ngx-mat-select-search>
								</mat-option>
								<mat-option *ngFor="let business of filteredBusinesses | async" [value]="business">
									{{business.businness_tag}}
								</mat-option>
							</mat-select>

						</mat-form-field>
						<button (click)="clearBusinessSelect();" aria-label="Clear" mat-button mat-icon-button>
							<mat-icon>close</mat-icon>
						</button>
					</div>

					<mat-form-field *ngIf="addBusiness" appearance="fill" style="margin-bottom: 5px">
						<mat-label>Create client</mat-label>
						<input formControlName="newBusinessTagInput" matInput placeholder="Client tag" type="text"
									 (change)='newBusinessTag=$event.target.value' [value]="newBusinessTag">
						<mat-error *ngIf="spCreatorForm.get('newBusinessTagInput').invalid">Format: AA00</mat-error>
					</mat-form-field>
					<button mat-mini-fab color="primary" *ngIf="addBusiness" (click)="createBusiness();" [disabled]="spCreatorForm.get('newBusinessTagInput').invalid">
						Create
					</button>

					<button mat-mini-fab color="basic" (click)="addBusiness=!addBusiness">
						<mat-icon *ngIf="addBusiness">close</mat-icon>
						<mat-icon *ngIf="!addBusiness">add</mat-icon>
					</button>
				</div>

				<div fxLayout="row" fxLayoutGap="2.5%" fxLayoutAlign="start center" style="margin-bottom: 5px">

					<mat-form-field appearance="fill" *ngIf="!addSite">
						<mat-label>Sites</mat-label>
						<mat-select formControlName="siteTag" (selectionChange)='siteSelectedChanged($event)' required>
							<mat-option *ngFor="let site of filteredSites | async" [value]="site">
								{{site.site_tag}}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field *ngIf="addSite" appearance="fill">
						<mat-label>Create site</mat-label>
						<input formControlName="newSiteTagInput" matInput placeholder="Site tag" type="text"
									 (change)='newSite=$event.target.value' [value]="newSite">
						<mat-error *ngIf="spCreatorForm.get('newSiteTagInput').invalid">Format: AA0</mat-error>
					</mat-form-field>

					<button mat-mini-fab color="primary" *ngIf="addSite" (click)="createSite()" [disabled]="spCreatorForm.get('newSiteTagInput').invalid">
						Create
					</button>
					<button mat-mini-fab color="basic" (click)="addSite=!addSite" [disabled]="selectedBusiness == null">
						<mat-icon *ngIf="addSite">close</mat-icon>
						<mat-icon *ngIf="!addSite">add</mat-icon>
					</button>
				</div>

				<mat-form-field appearance="fill" style="margin-bottom: 5px">
					<mat-label>More information</mat-label>
					<input matInput placeholder="" formControlName="description">
				</mat-form-field>

				<mat-form-field appearance="fill" style="margin-bottom: 5px">
					<mat-label>Sampling point number</mat-label>
					<input matInput placeholder="" formControlName="sp_num" required>
					<mat-error *ngIf="spCreatorForm.get('sp_num').hasError('pattern')">Format: 00</mat-error>
				</mat-form-field>
			</mat-card>
		</form>
	</mat-card-content>

	<mat-card-actions fxLayout="flex" fxLayoutAlign="end center">
		<button mat-raised-button color="primary" (click)="submitAndClose()"
						[disabled]="(spCreatorForm.get('businessTag').invalid || spCreatorForm.get('siteTag').invalid || spCreatorForm.get('sp_num').invalid) || addBusiness || addSite">
			Create
		</button>
		<button mat-raised-button color="basic" (click)="cancelAndClose()">
			<mat-icon>
				close
			</mat-icon>
		</button>
	</mat-card-actions>
</mat-card>
