import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'export-help-dialog',
	templateUrl: './export-help-dialog.component.html',
	styleUrls: ['./export-help-dialog.component.css']
})
export class ExportFormHelpDialog implements OnInit {

	caller: string = '';

	constructor(
		public dialogRef: MatDialogRef<ExportFormHelpDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
	}

	ngOnInit(): void {
		this.caller = this.data.caller;
	}

}
