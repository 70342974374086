export class ResultLaboCulture {

	constructor(
		public analysis_id: number,
		public rbq: Boolean,
		public treatment: Boolean,
		public vmr_spp: string,
		public spp: number,
		public vmr_sg1_15: string,
		public sg1_15: number,
		public vmr_sg1: string,
		public sg1: number,
		public vmr_sg2_15: string,
		public sg2_15: number,
		public comment: string,
		public treatment_description: string
	) {
	}
}
