import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {User} from '../Models/User';
import {EditApprovalDialogComponent} from "../admin-panel/edit-approval-dialog/edit-approval-dialog.component";
import {AdminPanelService} from "../admin-panel/admin-panel.service";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NavbarService} from "../header/navbar.service";
import {CookieServiceService} from "../cookie-service.service";
import jwt_decode from "jwt-decode";
import {LocalStorageService} from "../local-storage.service";

@Component({
  selector: 'app-account-page',
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.css']
})
export class AccountPageComponent implements OnInit {

  constructor(
      private formBuilder: FormBuilder,
      private service: AdminPanelService,
      public dialog: MatDialog,
      private snackbar: MatSnackBar,
      public cookieService: CookieServiceService,
      public localStorageService: LocalStorageService
  ) { }

  accountForm: FormGroup;
  current_user: User;

  ngOnInit(): void {
    const token = jwt_decode(this.cookieService.get('token'));
    this.current_user = new User(token['user_id'], this.localStorageService.get('userEmail'), this.localStorageService.get('userFirstName'), null, null, null, null, null);

    this.accountForm = this.formBuilder.group({
      name: [this.current_user.user_name, [Validators.required]],
      email: [this.current_user.user_email, [Validators.required, Validators.pattern('(\\S+@\\S+)')]]
    });
  }

  saveChanges(): void {
    const dialogRef = this.dialog.open(EditApprovalDialogComponent, {
      width: '60%'
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        const user = new User(this.current_user.user_id, this.accountForm.get('email').value, this.accountForm.get('name').value, null, null, null, null, null);
        this.service.updateUser(user).subscribe(resp => {
          this.snackbar.open('User successfully updated.', 'Ok', {
            duration: 3000
          });
          this.localStorageService.set('userFirstName', this.accountForm.get('name').value);
          this.localStorageService.set('userEmail', this.accountForm.get('email').value);
        }, error => {
          this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
            duration: 5000
          });
        });
      }
    });
  }

  resetPassword(): void {
    this.service.resetPassword(this.current_user.user_email).subscribe(resp => {
      this.snackbar.open(resp, 'Ok', {
        duration: 3000
      });
    });
  }
}
