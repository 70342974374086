export class ResultLaboQlamp {

	constructor(
		public analysis_id: number,
		public analysis_type: string,
		public result: number,
		public comment: string,
		public vmr_result: string
	) {
	}
}
