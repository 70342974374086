import {FullscreenChartService} from '../../../fullscreen-chart/fullscreen-chart.service';
import {CustomChartData} from '../../CustomChartData';
import {AChart} from '../AChart';


/* Concrete Chart for Factory Pattern */
export class APressurePerWellVS0Chart implements AChart {

	telemetryLog: any;
	datas: CustomChartData;

	private config = {
		responsive: true,
		displaylogo: false
	};

	private layout = {
		// items with a `name` attribute in template.images will be added to any
		// plot using this template
		images: [{
			source: '../../../../favicon.ico',
			xref: 'paper',
			yref: 'paper',
			x: 0.5,
			y: 0.5,
			xanchor: 'center',
			yanchor: 'middle',
			sizex: 0.7,
			sizey: 0.7,
			opacity: 0.1,
			layer: 'below'
		}],
		legend: {
			x: 0,
			y: 1.15,
			orientation: 'h',
			itemsizing: 'constant',
			itemwidth: 25
		},
		margin: {
			l: 35,
			r: 5,
			b: 60,
			t: 10
		},
		xaxis: {
			tickformat: '%X',
			tickangle: -45,
			showgrid: true,
			nticks: 36,
			showline: true,
			zeroline: false,
			mirror: true,
			hoverformat: '%Y-%m-%d %H:%M:%S'
		},
		yaxis: {
			showgrid: true,
			showline: true,
			zeroline: false,
			mirror: true
		},
		modebar: {
			color: '#000000',
			bgcolor: '#FFFFFF'
		}
	};


	constructor(private service: FullscreenChartService) {
	}

	public async createChart(analysis_id: number, sensor_id: number): Promise<CustomChartData> {

		this.telemetryLog = await this.service.getTelemetryChart(analysis_id, sensor_id);

		let values = [];
		let date = [];

		this.datas = new CustomChartData([], this.layout, this.config);

		for (let i = 0; i < this.telemetryLog.length; i++) {
			values.push(this.telemetryLog[i].map.value);
			date.push(new Date(this.telemetryLog[i].map.ts));
		}

		this.datas.chartData = [
			{
				name: 'psi',
				x: date,
				y: values,
				type: 'scatter',
				mode: 'lines+markers',
				marker: {
					size: 10,
					color: '#066af8'
				},
				line: {
					color: '#066af8'
				}
			}];

		return this.datas;
	}
}

