import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AdminPanelService} from '../admin-panel.service';
import * as _ from 'lodash';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Business} from '../../Models/Business';
import {Site} from '../../Models/Site';
import {SamplingPoint} from '../../Models/SamplingPoint';
import {takeUntil} from 'rxjs/operators';
import {ReplaySubject, Subject} from 'rxjs';
import {MatSelect} from '@angular/material/select';
import {EditApprovalDialogComponent} from '../edit-approval-dialog/edit-approval-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
	selector: 'app-sampling-point-settings',
	templateUrl: './sampling-point-settings.component.html',
	styleUrls: ['./sampling-point-settings.component.css']
})
export class SamplingPointSettingsComponent implements OnInit {

	spList: any[];
	fetchingSamplingPoints = true;
	formState = '';

	spCreatorForm: FormGroup;

	newBusinessTag = '';
	newBusinessName = '';
	newSite = '';
	access_level: number;

	businesses: any[];
	sites: any[];

	selectedBusiness: any;
	selectedSite: any;

	addBusiness = false;
	addSite = false;
	isLoading = false;

	public businessFilterCtrl: FormControl = new FormControl();
	public filteredBusinesses: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
	@ViewChild('businessSelect', {static: true}) businessSelect: MatSelect;

	public siteFilterCtrl: FormControl = new FormControl();
	public filteredSites: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
	@ViewChild('siteSelect', {static: true}) siteSelect: MatSelect;

	protected _onDestroy = new Subject<void>();
	oldSamplingPoint: any;

	constructor(private service: AdminPanelService, public dialog: MatDialog, private snackbar: MatSnackBar, public formBuilder: FormBuilder) {
	}

	ngOnInit(): void {
		this.getSamplingPointNameList();

		this.service.getBusinesses().subscribe(data => {
			this.businesses = _.orderBy(data, 'business_tag', 'asc');
			this.filteredBusinesses.next(this.businesses.slice());

			this.businessFilterCtrl.valueChanges
				.pipe(takeUntil(this._onDestroy))
				.subscribe(() => {
					this.filterBusinesses();
				});
		}, error => {
			this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
			this.isLoading = false;
		});

		this.service.getSites().subscribe(data => {
			this.sites = _.orderBy(data, 'site_tag', 'asc');
			this.filteredSites.next(this.sites.slice());

			this.siteFilterCtrl.valueChanges
				.pipe(takeUntil(this._onDestroy))
				.subscribe(() => {
					this.filterSites();
				});
		}, error => {
			this.snackbar.open('Une erreur est survenue. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
			this.isLoading = false;
		});

		this.buildSamplingPointCreatorForm();

		this.spCreatorForm.markAllAsTouched();
	}

	getSamplingPointNameList() {
		this.service.getSamplingPoints().subscribe(data => {
			this.spList = _.orderBy(data, 'sampling_name', 'asc');
			this.fetchingSamplingPoints = false;
		});
	}

	addSamplingPoint() {
		// const dialogRef = this.dialog.open(SamplingPointCreatorComponent, {
		// 	width: '60%'
		// });
		//
		// dialogRef.afterClosed().subscribe(data => {
		// 	window.location.reload();
		// });
	}

	setFormUpdateSamplingPoint(samplingPoint: any, i: number) {
		this.formState = 'update';
		this.addBusiness = false;
		this.addSite = false;

		this.oldSamplingPoint = samplingPoint;

		const site = _.find(this.sites, ['site_id', samplingPoint.site_id]);
		const business = _.find(this.businesses, ['business_id', site.business_id]);

		this.spCreatorForm.get('businessTag').setValue(business);
		this.spCreatorForm.get('businessTag').disable();

		this.spCreatorForm.get('newBusinessTagInput').disable();

		this.spCreatorForm.get('siteTag').setValue(site);
		this.spCreatorForm.get('siteTag').disable();

		this.spCreatorForm.get('newSiteTagInput').disable();

		this.spCreatorForm.get('sp_num').setValue(samplingPoint.sp_tag);
		this.spCreatorForm.get('sp_num').disable();

		this.spCreatorForm.get('description').setValue(samplingPoint.description);
	}

	SetFormCreateSamplingPoint() {
		this.formState = 'create';
		this.selectedBusiness = null;
		this.selectedSite = null;

		this.spCreatorForm.get('businessTag').enable();
		this.spCreatorForm.get('newBusinessTagInput').enable();
		this.spCreatorForm.get('siteTag').enable();
		this.spCreatorForm.get('newSiteTagInput').enable();
		this.spCreatorForm.get('sp_num').enable();
		this.spCreatorForm.get('businessName').enable();
		this.spCreatorForm.get('newBusinessNameInput').enable();

		this.resetForm();
	}

	private buildSamplingPointCreatorForm() {
		this.spCreatorForm = this.formBuilder.group({
			businessTag: ['', Validators.required],
			newBusinessTagInput: ['', [Validators.required, Validators.pattern('([A-Z]{2})([0-9]{2})')]],
			siteTag: ['', Validators.required],
			newSiteTagInput: ['', [Validators.required, Validators.pattern('([A-Z]{2})([0-9]{1})')]],
			sp_num: ['', [Validators.required, Validators.pattern('([0-9]{2})')]],
			description: [''],
			businessName: ['', Validators.required],
			newBusinessNameInput: ['', [Validators.required, Validators.pattern('([A-Z]{2})([0-9]{2})')]]
		});
	}

	protected filterSites() {
		if (!this.sites) {
			return;
		}

		let search = this.siteFilterCtrl.value;
		if (!search) {
			this.filteredSites.next(this.sites.slice());
			return;
		} else {
			search = search.toLowerCase();
		}

		this.filteredSites.next(
			this.sites.filter(site => site.site_tag.toLowerCase().indexOf(search) > -1)
		);
	}

	protected filterBusinesses() {
		if (!this.businesses) {
			return;
		}

		let search = this.businessFilterCtrl.value;

		if (!search) {
			this.filteredBusinesses.next(this.businesses.slice());
			return;
		} else {
			search = search.toLowerCase();
		}

		this.filteredBusinesses.next(
			this.businesses.filter(business => business.business_tag.toLowerCase().indexOf(search) > -1)
		);
	}

	cancelAndClose() {
		// this.dialogRef.close();
	}

	clientSelectedChanged($event) {
		this.selectedBusiness = $event.value;

		this.service.getSitesByBusinessId(this.selectedBusiness.business_id).subscribe(sites => {
			this.filteredSites.next(sites.slice());

			this.spCreatorForm.get('siteTag').setValue(null);
			this.spCreatorForm.get('siteTag').updateValueAndValidity();
		});
	}

	async siteSelectedChanged($event) {
		this.selectedSite = $event.value;
	}

	createBusiness() {
		this.isLoading = true;

		const business = new Business(null, this.newBusinessTag, this.newBusinessName, this.access_level);
		this.service.createBusiness(business).subscribe(data => {
			if (data) {
				this.isLoading = false;
				this.addBusiness = false;

				this.service.getBusinesses().subscribe(data => {
					this.businesses = _.orderBy(data, 'business_tag', 'asc');
					this.businesses = _.orderBy(data, 'business_name', 'asc');
					this.filteredBusinesses.next(this.businesses.slice());
				});

				this.snackbar.open('Client successfully created.', 'Ok', {
					duration: 5000
				});
			}
		}, error => {
			this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
			this.isLoading = false;
		});
	}

	createSite() {
		this.isLoading = true;

		const site_tag = this.newSite;
		const client_id = this.selectedBusiness.business_id;
		const access_level = this.access_level;

		const site = new Site(null, site_tag, client_id, access_level);

		this.service.createSite(site).subscribe(data => {
			if (data) {
				this.isLoading = false;
				this.addSite = false;

				this.service.getSites().subscribe(data => {
					this.sites = _.orderBy(data, 'site_tag', 'asc');
					this.filteredSites.next(this.sites.slice());
					this.filterSites();
				});
				this.snackbar.open('Site successfully created.', 'Ok', {
					duration: 5000
				});
			}
		}, error => {
			this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
			this.isLoading = false;
		});
	}

	createSamplingPoint() {

		this.isLoading = true;

		const description = this.spCreatorForm.get('description').value;
		const sp_num = this.spCreatorForm.get('sp_num').value;

		const sp = new SamplingPoint(null, this.selectedSite.site_id, description, sp_num, sp_num.length === 1 ? '0' + sp_num.toString() : sp_num);
		const pos_siteId = this.spList.findIndex(x => x.site_id === sp.site_id);
		const pos_siteTag = this.spList.findIndex(x => x.sp_tag === sp.sp_tag);

		if ((pos_siteId === -1 && pos_siteTag === -1) || (pos_siteId >= 0 && pos_siteTag === -1)) {
			this.service.createSamplingPoint(sp).subscribe(() => {

				this.isLoading = false;

				this.getSamplingPointNameList();

				this.snackbar.open('Sampling point successfully created.', 'Ok', {
					duration: 3000
				});
				this.resetForm();

			}, error => {
				this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
					duration: 5000
				});
				this.isLoading = false;
			});
		}else {
			this.snackbar.open('This sampling points already exist', 'Ok');
			this.resetForm();
			this.isLoading = false;
		}
	}

	updateSamplingPoint() {

		const tmpDescBackup = this.oldSamplingPoint.description;
		this.oldSamplingPoint.description = this.spCreatorForm.get('description').value;

		this.service.updateSamplingPoint(this.oldSamplingPoint).subscribe(resp => {
			console.log(resp);
			if (resp) {
				this.snackbar.open('Sampling point successfully updated.', 'Ok', {
					duration: 3000
				});
				this.spCreatorForm.markAsPristine();
			} else {
				this.oldSamplingPoint.description = tmpDescBackup;
			}
		}, error => {
			this.oldSamplingPoint.description = tmpDescBackup;
			this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
		});
	}

	deleteSamplingPoint(samplingPoint: any) {


		this.oldSamplingPoint = samplingPoint;
		console.log(this.oldSamplingPoint.description);

		const dialogRef = this.dialog.open(EditApprovalDialogComponent, {
			width: '35%',
			data: [{mod_description: 'Deleting the sampling point: \'' + this.oldSamplingPoint.sampling_name + '\'.'}]
		});


		/* If user approved the modifications */
		dialogRef.afterClosed().subscribe(async data => {
			if (data) {
				this.service.deleteSamplingPoint(this.oldSamplingPoint.sp_id).subscribe(resp => {
					if (resp) {
						this.snackbar.open('Sampling point successfully deleted.', 'Ok', {
							duration: 3000
						});
						this.spList.splice(_.findIndex(this.spList, ['sp_id', this.oldSamplingPoint.sp_id]), 1);
						this.resetForm();
						this.formState = '';
					} else if (resp.status === 501) {
						this.snackbar.open('An error has occurred: ' + resp.message, 'Ok');
					} else {
						this.snackbar.open('An error has occurred. Code: ' + resp.status, 'Ok');
					}
				}, error => {
					this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
						duration: 5000
					});
				});
			}
		});
	}

	clearBusinessSelect() {
		this.spCreatorForm.get('businessTag').setValue(null);
		this.selectedBusiness = null;
		this.spCreatorForm.get('businessTag').markAsTouched();

		this.spCreatorForm.get('siteTag').setValue(null);
		this.selectedSite = null;
		this.spCreatorForm.get('siteTag').markAsTouched();

		this.filteredSites.next(this.sites.slice());
	}

	restoreFormValue() {
		this.spCreatorForm.get('description').setValue(this.oldSamplingPoint.description);
		this.spCreatorForm.markAsPristine();
	}

	private resetForm() {
		this.selectedBusiness = null;
		this.selectedSite = null;
		this.spCreatorForm.reset();
		this.spCreatorForm.markAllAsTouched();
	}
}
