import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Business} from 'src/app/Models/Business';
import {SamplingPoint} from 'src/app/Models/SamplingPoint';
import {Site} from 'src/app/Models/Site';
import {AdminPanelService} from '../../admin-panel.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as _ from 'lodash';
import {ReplaySubject, Subject} from 'rxjs';
import {MatSelect} from '@angular/material/select';
import {takeUntil} from 'rxjs/operators';

@Component({
	selector: 'app-sampling-point-creator',
	templateUrl: './sampling-point-creator.component.html',
	styleUrls: ['./sampling-point-creator.component.css']
})
export class SamplingPointCreatorComponent implements OnInit {

	spCreatorForm: FormGroup;

	newBusinessTag = '';
	newBusinessName = '';
	newSite = '';
	access_level: number;

	businesses: any[];
	sites: any[];

	selectedBusiness: any;
	selectedSite: any;

	addBusiness: boolean = false;
	addSite: boolean = false;
	isLoading: boolean = false;

	public businessFilterCtrl: FormControl = new FormControl();
	public filteredBusinesses: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
	@ViewChild('businessSelect', {static: true}) businessSelect: MatSelect;

	public siteFilterCtrl: FormControl = new FormControl();
	public filteredSites: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
	@ViewChild('siteSelect', {static: true}) siteSelect: MatSelect;

	protected _onDestroy = new Subject<void>();

	constructor(private service: AdminPanelService, private snackbar: MatSnackBar, public formBuilder: FormBuilder) {
	}

	ngOnInit() {

		this.service.getBusinesses().subscribe(data => {
			this.businesses = _.orderBy(data, 'business_tag', 'asc');
			this.filteredBusinesses.next(this.businesses.slice());


			this.businessFilterCtrl.valueChanges
				.pipe(takeUntil(this._onDestroy))
				.subscribe(() => {
					this.filterBusinesses();
				});
		}, error => {
			this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
			this.isLoading = false;
		});

		this.service.getSites().subscribe(data => {
			this.sites = _.orderBy(data, 'site_tag', 'asc');
			this.filteredSites.next(this.sites.slice());

			this.siteFilterCtrl.valueChanges
				.pipe(takeUntil(this._onDestroy))
				.subscribe(() => {
					this.filterSites();
				});
		}, error => {
			this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
			this.isLoading = false;
		});

		this.buildSamplingPointCreatorForm();

		this.spCreatorForm.markAllAsTouched();
	}

	private buildSamplingPointCreatorForm() {
		this.spCreatorForm = this.formBuilder.group({
			businessTag: ['', Validators.required],
			newBusinessTagInput: ['', [Validators.required, Validators.pattern('([A-Z]{2})([0-9]{2})')]],
			siteTag: ['', Validators.required],
			newSiteTagInput: ['', [Validators.required, Validators.pattern('([A-Z]{2})([0-9]{1})')]],
			description: [''],
			sp_num: ['', [Validators.required, Validators.pattern('([0-9]{2})')]]
		});
	}

	protected filterSites() {
		if (!this.sites) {
			return;
		}

		let search = this.siteFilterCtrl.value;
		if (!search) {
			this.filteredSites.next(this.sites.slice());
			return;
		} else {
			search = search.toLowerCase();
		}

		this.filteredSites.next(
			this.sites.filter(site => site.site_tag.toLowerCase().indexOf(search) > -1)
		);
	}

	protected filterBusinesses() {
		if (!this.businesses) {
			return;
		}

		let search = this.businessFilterCtrl.value;
		if (!search) {
			this.filteredBusinesses.next(this.businesses.slice());
			return;
		} else {
			search = search.toLowerCase();
		}

		this.filteredBusinesses.next(
			this.businesses.filter(business => business.business_tag.toLowerCase().indexOf(search) > -1)
		);
	}

	cancelAndClose() {
		// this.dialogRef.close();
	}


	clientSelectedChanged($event: any) {
		this.selectedBusiness = $event.value;

		this.service.getSitesByBusinessId(this.selectedBusiness.business_id).subscribe(sites => {
			this.filteredSites.next(sites.slice());
		});
	}

	async siteSelectedChanged($event) {
		this.selectedSite = $event.value;
	}

	createBusiness() {
		this.isLoading = true;

		const business = new Business(null, this.newBusinessTag, this.newBusinessName, this.access_level);

		this.service.createBusiness(business).subscribe(data => {
			if (data) {
				this.isLoading = false;
				this.addBusiness = false;


				this.service.getBusinesses().subscribe(data => {
					this.businesses = _.orderBy(data, 'business_tag', 'asc');
					this.businesses = _.orderBy(data, 'business_name', 'asc');
					this.filteredBusinesses.next(this.businesses.slice());
				});

				this.snackbar.open('Client successfully created.', 'Ok', {
					duration: 5000
				});
			}
		}, error => {
			this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
			this.isLoading = false;
		});
	}

	createSite() {
		this.isLoading = true;

		const site_tag = this.newSite;
		const client_id = this.selectedBusiness.business_id;
		const access_level = this.access_level;

		const site = new Site(null, site_tag, client_id, access_level);

		this.service.createSite(site).subscribe(data => {
			if (data) {
				this.isLoading = false;
				this.addSite = false;

				this.service.getSites().subscribe(data => {
					this.sites = _.orderBy(data, 'site_tag', 'asc');
					this.filteredSites.next(this.sites.slice());
					this.filterSites();
				});
				this.snackbar.open('Site successfully created.', 'Ok', {
					duration: 5000
				});
			}
		}, error => {
			this.snackbar.open('An error as occured. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
			this.isLoading = false;
		});
	}

	submitAndClose() {

		this.isLoading = true;

		const description = this.spCreatorForm.get('description').value;
		const sp_num = this.spCreatorForm.get('sp_num').value;

		const sp = new SamplingPoint(null, this.selectedSite.site_id, description, sp_num, sp_num.length === 1 ? '0' + sp_num.toString() : sp_num);

		this.service.createSamplingPoint(sp).subscribe(() => {
			this.isLoading = false;

			this.snackbar.open('Sampling point successfully created.', 'Ok', {
				duration: 5000
			});

			this.spCreatorForm.reset();
			this.spCreatorForm.markAllAsTouched();
		}, error => {
			this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
			this.isLoading = false;
		});
	}

	clearBusinessSelect() {
		this.spCreatorForm.get('businessTag').setValue(null);
		this.selectedBusiness = null;
		this.spCreatorForm.get('businessTag').markAsTouched();

		this.spCreatorForm.get('siteTag').setValue(null);
		this.selectedSite = null;
		this.spCreatorForm.get('siteTag').markAsTouched();

		this.filteredSites.next(this.sites.slice());
	}
}
