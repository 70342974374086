<body>
<mat-card class="main-card">
	<div class="main-container" fxLayout="column">
		<div fxLayoutAlign="space-between">
			<div>
				<mat-card-title>Machines
					<!-- The button is only used for alignment (with the other settings pages) -->
					<button style="visibility: hidden" mat-button>
						<mat-icon>help_outline</mat-icon>
					</button>
				</mat-card-title>
			</div>
		</div>
		<mat-divider class="title-divider"></mat-divider>

		<div fxLayout="column" fxFlex="50%">
			<mat-drawer-container class="drawer-container">
				<div class="drawer-sidenav-content">

					<mat-card class="limits-container">

						<div class="overlay" *ngIf="isLoading">
							<div class="overlay__wrapper">
								<div class="overlay__spinner">
									<button mat-raised-button color="primary" class="spinner"></button>
								</div>
							</div>
						</div>

						<div fxLayout="column" fxFlex="100%">
							<mat-accordion *ngFor="let machine of machineList;  let i = index">
								<mat-expansion-panel [ngStyle]="machine.machine.state == 'inactive' ? {'background-color': '#DDDDDD'} : {'background-color': 'white'}">
									<mat-expansion-panel-header>
										<mat-panel-title class="panel-header-info">
											{{machine.machine.machine_tag}}
										</mat-panel-title>

										<mat-panel-description>
											<div style="width: 150px">
												<mat-label *ngIf="machine.inlets.length == 0">-</mat-label>
												<mat-label *ngIf="machine.inlets.length != 0">({{machine.inlets.length > 1 ? machine.inlets.length + ' inlets' : machine.inlets.length + ' inlet'}})</mat-label>
											</div>
											<mat-label>{{machine.machine.state}}</mat-label>
										</mat-panel-description>

									</mat-expansion-panel-header>
									<div fxFlex fxLayout="row" fxLayoutGap="2.5%" fxLayoutAlign="start start">
										<div fxLayout="column">
											<button class="button-edit" mat-button color="primary" *ngIf="machine.machine.access_level>=20"
															(click)="drawer.opened && machine.machine.machine_id == oldMachine?.machine.machine_id && formState == 'update' ? drawer.toggle() : drawer.open(); setFormUpdateMachine(machine);"
															matTooltip="Modifier la machine." matTooltipPosition="above"
															matTooltipShowDelay="500">
												<mat-icon>edit</mat-icon>
											</button>
										</div>

										<div fxLayout="column">
											<!--<mat-label style="font-weight: bold">Statut</mat-label>-->
											<mat-label style="font-weight: bold">Status</mat-label>
											<mat-label>{{machine.machine.state}}</mat-label>
										</div>

										<div fxLayout="column">
											<mat-label style="font-weight: bold">Infos</mat-label>
											<mat-label>ID: {{machine.machine.machine_id}}</mat-label>
											<mat-label>TAG: {{machine.machine.machine_tag}}</mat-label>
										</div>

										<div fxLayout="column" [fxLayoutAlign]="machine.inlets.length == 0 ? 'start start' : 'start center'">
											<mat-label style="font-weight: bold">Inlets</mat-label>
											<mat-label *ngIf="machine.inlets.length == 0" style="color: #797979">No inlet</mat-label>

											<!-- OG inlets display -->
											<!-- <div *ngFor="let inlet of machine.inlets" fxLayout="row">-->
											<!-- 	<mat-label>inlet: {{inlet.inlet_num}} - Sp: {{inlet.sp_name}} - Id: {{inlet.sp_id}}</mat-label>-->
											<!-- </div>-->

											<!-- Trying other ways to display the inlets -->
											<div *ngIf="machine.inlets.length != 0" fxLayout="row" fxLayoutGap="5%">
												<div fxLayout="column" fxLayoutAlign="start start">
													<mat-label style="text-decoration: underline">#</mat-label>
													<div *ngFor="let inlet of machine.inlets" fxLayout="row">
														{{inlet.inlet_num}}
													</div>
												</div>

												<div fxLayout="column" style="width: 90px" fxLayoutAlign="start center">
													<mat-label style="text-decoration: underline">Sp name</mat-label>
													<div *ngFor="let inlet of machine.inlets" fxLayout="row">
														{{inlet.sp_name}}
													</div>
												</div>

												<div fxLayout="column" style="width: 50px" fxLayoutAlign="start center">
													<mat-label style="text-decoration: underline">Sp ID</mat-label>
													<div *ngFor="let inlet of machine.inlets" fxLayout="row">
														{{inlet.sp_id}}
													</div>
												</div>
											</div>
										</div>
									</div>
								</mat-expansion-panel>
							</mat-accordion>
						</div>
					</mat-card>
					<button class="button-add" *ngIf="possibleSites.length > 0" mat-button (click)="SetFormCreateMachine(); !drawer.opened ? drawer.toggle() : drawer.open" matTooltip="Create machine." matTooltipPosition="above" matTooltipShowDelay="500">
						<mat-icon>add</mat-icon>
					</button>
				</div>

				<mat-drawer #drawer class="drawer-sidenav" mode="side" position="end" [opened]="formState != ''">
					<mat-card class="form-container">

						<mat-card-title *ngIf="formState == 'update'">Machine modification</mat-card-title>
						<mat-card-title *ngIf="formState == 'create'">Machine creation</mat-card-title>

						<mat-divider class="title-divider"></mat-divider>

						<div *ngIf="machineForm">
							<form [formGroup]="machineForm">
								<div fxLayout="column" fxLayoutAlign="center start">
									<mat-progress-bar *ngIf="isLoading" mode="indeterminate" class="progress-bar"></mat-progress-bar>

									<h2>Machine</h2>

									<div>
										<mat-form-field appearance="fill" style="margin: 0 5px 5px 0; width: 100px">
											<mat-label>ID machine</mat-label>
											<input matInput [placeholder]="suggestedMachineId" formControlName="machineId" required>
											<mat-error *ngIf="machineForm.get('machineId').hasError('pattern')">Format: 000</mat-error>
										</mat-form-field>

										<mat-form-field appearance="fill" style="margin: 0 5px 5px 0; width: 100px">
											<mat-label>Machine Tag</mat-label>
											<input matInput [placeholder]="suggestedMachineTag" formControlName="machineTag" required>
											<mat-error *ngIf="machineForm.get('machineTag').hasError('pattern')">Format: B0000</mat-error>
										</mat-form-field>
									</div>
									<div>
									<mat-form-field appearance="fill" style="width: 100px">
										<mat-label>State</mat-label>
										<mat-select (selectionChange)="machineStateChanged($event)" formControlName="state">
											<mat-option *ngFor="let state of possibleStates" [value]="state">
												{{state}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="fill">
										<mat-label>Site</mat-label>
										<mat-select formControlName="machineSite" [disabled]="formState=='update'">
											<mat-option *ngFor="let site of possibleSites" [value]="site.site_tag">
												{{site.site_tag}}
											</mat-option>
										</mat-select>
									</mat-form-field>
									</div>
									<div>
										<mat-form-field color="primary" appearance="fill" [formGroup]="machineForm">
											<mat-label>Last maintenance date</mat-label>
											<input matInput [matDatepicker]="picker2" formControlName="lastMaintenance" (dateChange)="machineMaintenanceDateChanged($event)" [value] ="lastMaintenance">
											<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
											<mat-datepicker #picker2 color="primary"></mat-datepicker>
										</mat-form-field>
									</div>
									<div>
										<mat-form-field appearance="fill" [formGroup]="machineForm">
											<mat-label style="color:rgb(0,0,0,0.6)">Nb of analysis between maintenance</mat-label>
											<input matInput placeholder="---"  formControlName="nbAnalysisBeforeMaintenance" (change)="machineNbAnalysisBeforeMaintenanceChanged($event)" [value] ="nbAnalysisBeforeMaintenance">
										</mat-form-field>
									</div>
									<form [formGroup]="inletForm">
										<h2>Inlet</h2>
										<div *ngIf="inletForm.get('inletRow')['controls'].length == 0" style="margin-bottom: 20px">
											<mat-label>No inlet.</mat-label>
										</div>
										<div fxLayout="column" fxLayoutAlign="space-arround center" formArrayName="inletRow">
											<div *ngFor="let row of inletForm.get('inletRow')['controls']; let y=index" fxLayout="row" fxLayoutGap="2.5%" fxLayoutAlign="space-between center" style="margin-bottom: 5px">
												<mat-form-field appearance="fill" [formGroupName]="y" style="width: 100px">
													<mat-label>Inlet {{y + 1}}</mat-label>
													<input (change)="inletNumChanged($event, y)" matInput formControlName="inlet" required>
													<mat-error *ngIf="row.get('inlet').hasError('pattern')">Format: 00</mat-error>
												</mat-form-field>

												<mat-form-field appearance="fill" [formGroupName]="y" style="width: 150px">
													<mat-label>Sampling point</mat-label>
													<mat-select (selectionChange)="samplingPointSelectedChanged($event, y)" formControlName="sp" required>
														<mat-option>
															<ngx-mat-select-search [formControl]="samplingPointFilterCtrl" placeholderLabel="Search" noEntriesFoundLabel="No results"></ngx-mat-select-search>
														</mat-option>
														<mat-option *ngFor="let samplingPoint of filteredSamplingPoint | async" [value]="samplingPoint">
															{{samplingPoint.sp_tag}}
														</mat-option>
													</mat-select>
												</mat-form-field>

												<button mat-mini-fab color="warn" *ngIf="inletForm.get('inletRow')['controls'].length > 1 && row.get('access').value" (click)="removeInletRow(y)">
													<mat-icon>delete forever</mat-icon>
												</button>
											</div>
										</div>

										<button mat-raised-button color="primary" (click)="createInlet(oldMachine.machine.machine_id)" *ngIf="inletForm.get('inletRow')['controls'].length < 6 && formState != 'create' && oldMachine?.machine.access_level>=30" style="margin-bottom: 10px">
											<mat-icon>add box</mat-icon>
											Inlet
										</button>
									</form>
								</div>

								<div fxLayoutAlign="space-between">
									<div>
										<button mat-raised-button style="margin-right: 10px" (click)="cancel(); drawer.close()" type="button">Cancel</button>
										<button *ngIf="formState == 'update'" mat-raised-button style="min-width: 40px; padding: 0" (click)="restoreFormValue()" type="button" [disabled]="machineForm.pristine && inletForm.pristine"
														matTooltip="Initial values"
														matTooltipPosition="above" matTooltipShowDelay="300">
											<mat-icon>restore</mat-icon>
										</button>
									</div>
									<div *ngIf="formState == 'update'">
										<button mat-raised-button color="warn" *ngIf="oldMachine.machine.access_level==40" (click)="deleteMachine()" type="button" style="margin-right: 10px">Delete</button>
										<button mat-raised-button color="primary" (click)="updateMachine()" type="button" [disabled]="machineForm.pristine && inletForm.pristine">Apply modifications</button>
									</div>
									<button mat-raised-button color="primary" *ngIf="formState == 'create'" (click)="createMachine()" [disabled]="machineForm.invalid || inletForm.invalid">
										Create
									</button>
								</div>
							</form>
						</div>
					</mat-card>
				</mat-drawer>
			</mat-drawer-container>
		</div>
	</div>
</mat-card>
</body>
