import {FullscreenChartService} from '../../../fullscreen-chart/fullscreen-chart.service';
import {CustomChartData} from '../../CustomChartData';
import {MChart} from '../MChart';

/* Concrete Chart for Factory Pattern */
export class MTotalFiltrationTimeS0S1Chart implements MChart {

	total_filtration_time_s0_s1: any;
	filtrationVolume: any;
	cartAvailable: any;
	datas: CustomChartData;

	private config = {
		responsive: true,
		displaylogo: false
	};

	private layout = {
		// items with a `name` attribute in template.images will be added to any
		// plot using this template
		images: [{
			source: '../../../../favicon.ico',
			xref: 'paper',
			yref: 'paper',
			x: 0.5,
			y: 0.5,
			xanchor: 'center',
			yanchor: 'middle',
			sizex: 0.7,
			sizey: 0.7,
			opacity: 0.1,
			layer: 'below'
		}],
		legend: {
			x: 0,
			y: 1.15,
			orientation: 'h',
			itemsizing: 'constant',
			itemwidth: 25
		},
		margin: {
			l: 35,
			r: 70,
			b: 50,
			t: 25
		},
		xaxis: {
			tickformat: '%d %b',
			tickangle: -45,
			showgrid: true,
			nticks: 36,
			showline: true,
			zeroline: false,
			mirror: true,
			hoverformat: '%Y-%m-%d %H:%M:%S'
		},
		yaxis: {
			showgrid: true,
			showline: true,
			zeroline: false,
			mirror: true
		},
		yaxis2: {
			title: 'volume (mL)',
			range: [0, 2],
			overlaying: 'y',
			side: 'right',
			showgrid: false,
			showline: false,
			zeroline: false,
			mirror: true
		},
		modebar: {
			color: '#000000',
			bgcolor: '#FFFFFF'
		}
	};


	constructor(private service: FullscreenChartService) {
	}

	public async createChart(machine_id: number, limit: number, time: string): Promise<CustomChartData> {

		this.total_filtration_time_s0_s1 = await this.service.getVariableHistory(machine_id, 'total_filtration_time_s0_s1', time, limit);
		this.filtrationVolume = await this.service.getVariableHistory(machine_id, 'result_volume', time, limit);

		const total_filtration_time_s0_s1 = [];
		const filtrationVolume = [];
		const ideal_filtration_time = [];
		const date_total_filt = [];
		const date_filt_volume = [];

		this.datas = new CustomChartData([], this.layout, this.config);

		for (const total_filt_pts of this.total_filtration_time_s0_s1) {
			total_filtration_time_s0_s1.push(total_filt_pts.map.value);
			date_total_filt.push(total_filt_pts.map.analysis_time);
		}
		for (const filt_volume_pts of this.filtrationVolume) {
			filtrationVolume.push(filt_volume_pts.map.value);
			date_filt_volume.push(filt_volume_pts.map.analysis_time);
			ideal_filtration_time.push(filt_volume_pts.map.value * 500);
		}

		const trace_filtrationTime =
			{
				name: 'Total filtration time (s)',
				x: date_total_filt,
				y: total_filtration_time_s0_s1,
				type: 'scatter',
				mode: 'lines+markers',
				marker: {
					color: 'rgba(77, 19, 209, 1)'
				},
				line: {
					color: 'rgba(44, 130, 201, 1)'
				}
			};

		const trace_volume =
			{
				name: 'Volume (mL)',
				x: date_filt_volume,
				y: filtrationVolume,
				type: 'scatter',
				yaxis: 'y2',
				mode: 'lines+markers',
				marker: {
					color: '#4ecdc4'
				},
				line: {
					color: '#4ecdc4'
				}
			};

		const trace_ideal_filtration_time =
			{
				name: 'Ideal filtration time (s)',
				x: date_filt_volume,
				y: ideal_filtration_time,
				type: 'scatter',
				yaxis: 'x',
				mode: 'lines',
				line: {
					dash: 'dot',
					width: 3,
					color: 'rgb(192,192,192)'
				}
			};

		this.datas.chartData = [trace_filtrationTime, trace_volume, trace_ideal_filtration_time];

		return this.datas;
	}
}


