<h1 mat-dialog-title>Import txt</h1>
<div mat-dialog-content>
	<mat-form-field>
		<input matInput placeholder=".txt" [(ngModel)]="data">
	</mat-form-field>
</div>
<div mat-dialog-actions>
	<button mat-button (click)="onNoClick()">Close</button>
	<button mat-button [mat-dialog-close]="data" cdkFocusInitial>Enter</button>

</div>
