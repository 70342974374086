import {FullscreenChartService} from '../../../fullscreen-chart/fullscreen-chart.service';
import {CustomChartData} from '../../CustomChartData';
import {SPChart} from '../SPChart';

/* Concrete Chart for Factory Pattern */
export class SPUgChart implements SPChart {

	ug: any;
	cartAvailable: any;
	datas: CustomChartData;

	private config = {
		responsive: true,
		displaylogo: false
	};

	private layout = {
		// items with a `name` attribute in template.images will be added to any
		// plot using this template
		images: [{
			source: '../../../../favicon.ico',
			xref: 'paper',
			yref: 'paper',
			x: 0.5,
			y: 0.5,
			xanchor: 'center',
			yanchor: 'middle',
			sizex: 0.7,
			sizey: 0.7,
			opacity: 0.1,
			layer: 'below'
		}],
		legend: {
			x: 0,
			y: 1.15,
			orientation: 'h',
			itemsizing: 'constant',
			itemwidth: 25
		},
		margin: {
			l: 35,
			r: 35,
			b: 50,
			t: 10
		},
		xaxis: {
			tickformat: '%d %b',
			tickangle: -45,
			showgrid: true,
			nticks: 36,
			showline: true,
			zeroline: false,
			mirror: true,
			hoverformat: '%Y-%m-%d %H:%M:%S'
		},
		yaxis: {
			range: [0, 500],
			showgrid: true,
			showline: true,
			zeroline: false,
			mirror: true
		},
		/*
		yaxis2: {
			title: 'Cart available',
			range: [0, 18],
			overlaying: 'y',
			side: 'right',
			showgrid: false,
			showline: false,
		},
		modebar: {
			color: '#000000',
			bgcolor: '#FFFFFF'
		}

		 */
	};


	constructor(private service: FullscreenChartService) {
	}

	public async createChart(sp_id: number, limit: number, time: string): Promise<CustomChartData> {

		this.ug = await this.service.getSamplingPointVariableHistory(sp_id, 'result_value_ug_rounded', time, limit);
		//this.cartAvailable = await this.service.getVariableHistory(sp_id, 'cart_available', time, limit);

		this.datas = new CustomChartData([], this.layout, this.config);

		const ug = [];
		const cartAvailable = [];
		const date = [];

		this.datas = new CustomChartData([], this.layout, this.config);


		for (let i = 0; i < this.ug.length; i++) {
			ug.push(this.ug[i].map.value);
			date.push(this.ug[i].map.analysis_time);
		}

		/*
		for (let i = 0; i < this.cartAvailable.length; i++) {
			cartAvailable.push(this.cartAvailable[i].map.value);
		}
		 */

		const trace_ug = {
				name: 'Concentration (UG)',
				x: date,
				y: ug,
				type: 'scatter',
				marker: {
					size: 10,
					color: 'rgba(77, 19, 209, 1)'
				}
			};
		const trace_cart_available = {
			line: {shape: 'hv'},
			mode: 'lines+markers',
			name: 'Cart available',
			x: date,
			y: cartAvailable,
			type: 'sctatter',
			marker: {
				size: 5,
				color: 'rgb(96,96,96)'
			},
			yaxis: 'y2'
		};
		this.datas.chartData = [trace_ug];
		return this.datas;
	}
}


