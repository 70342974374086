import {FullscreenChartService} from '../../../fullscreen-chart/fullscreen-chart.service';
import {CustomChartData} from '../../CustomChartData';
import {MChart} from '../MChart';
import {mean} from 'lodash';

/* Concrete Chart for Factory Pattern */
export class MTresholdFluorescenceChart implements MChart {

	fluoDifferenceW1: any;
	fluoDifferenceW4: any;
	cartAvailable: any;
	datas: CustomChartData;

	private config = {
		responsive: true,
		displaylogo: false
	};

	private layout = {
		height: 450,
		// items with a `name` attribute in template.images will be added to any
		// plot using this template
		images: [{
			source: '../../../../favicon.ico',
			xref: 'paper',
			yref: 'paper',
			x: 0.5,
			y: 0.5,
			xanchor: 'center',
			yanchor: 'middle',
			sizex: 0.7,
			sizey: 0.7,
			opacity: 0.1,
			layer: 'below'
		}],
		legend: {
			x: 0,
			y: 1.15,
			orientation: 'h',
			itemsizing: 'constant',
			itemwidth: 25
		},
		margin: {
			l: 35,
			r: 35,
			b: 50,
			t: 10
		},
		xaxis: {
			showticklabels: true,
			tickformat: '%d %b',
			tickangle: -45,
			showgrid: true,
			nticks: 36,
			showline: true,
			zeroline: false,
			mirror: true,
			hoverformat: '%Y-%m-%d %H:%M:%S'
		},
		yaxis: {
			showgrid: true,
			showline: true,
			zeroline: false,
			mirror: true
		},
		yaxis2: {
			title: 'Cart available',
			range: [0, 18],
			overlaying: 'y',
			side: 'right',
			showgrid: false,
			showline: false,
		},
		modebar: {
			color: '#000000',
			bgcolor: '#FFFFFF'
		}
	};

	constructor(private service: FullscreenChartService) {
	}

	public async createChart(machine_id: number, limit: number, time: string): Promise<CustomChartData> {

		this.fluoDifferenceW1 = await this.service.getVariableHistory(machine_id, 'fluo_difference_w1', time, limit);
		this.fluoDifferenceW4 = await this.service.getVariableHistory(machine_id, 'fluo_difference_w4', time, limit);
		this.cartAvailable = await this.service.getVariableHistory(machine_id, 'cart_available', time, limit);

		const fluoDifferenceW1 = [];
		const fluoDifferenceW4 = [];
		const dateW1 = [];
		const dateW4 = [];
		const fluoDifferenceW1_5average = [];
		const fluoDifferenceW4_5average = [];
		const dateW1_5average = [];
		const dateW4_5average = [];
		const cartAvailable = [];
		const dateCart = [];

		if (this.fluoDifferenceW1.length === 0 && this.fluoDifferenceW4.length === 0 ) {
			this.layout.xaxis.showticklabels = false;
		}

		this.datas = new CustomChartData([], this.layout, this.config);

		for (const fluoW1 of this.fluoDifferenceW1) {
			fluoDifferenceW1.push(fluoW1.map.value);
			dateW1.push(fluoW1.map.analysis_time);
		}
		for (const fluoW4 of this.fluoDifferenceW4) {
			fluoDifferenceW4.push(fluoW4.map.value);
			dateW4.push(fluoW4.map.analysis_time);
		}
		for (const cart of this.cartAvailable) {
			cartAvailable.push(cart.map.value);
			dateCart.push(cart.map.analysis_time);
		}
		// Calculate last 5 analysis average
		if (fluoDifferenceW1.length >= 5) {
			for (let ii = 4; ii < fluoDifferenceW1.length; ii++){
				fluoDifferenceW1_5average.push(mean(fluoDifferenceW1.slice(ii - 4, ii)));
				dateW1_5average.push(dateW1[ii - 4]);
			}
		}
		if (fluoDifferenceW4.length >= 5) {
			for (let ii = 4; ii < fluoDifferenceW4.length; ii++){
				fluoDifferenceW4_5average.push(mean(fluoDifferenceW4.slice(ii - 4, ii)));
				dateW4_5average.push(dateW4[ii - 4]);
			}
		}

		const trace_W1 = {
			name: 'Treshold Well1',
			x: dateW1,
			y: fluoDifferenceW1,
			type: 'scatter',
			mode: 'markers',
			hovertemplate: '%{y:.3f}',
			marker: {size: 10, color: 'rgba(77, 19, 209, 1)'}
		};
		const trace_W1_5average = {
			name: 'Average TL W1',
			x: dateW1_5average,
			y: fluoDifferenceW1_5average,
			type: 'line',
			mode: 'line',
			hovertemplate: '%{y:.3f}',
			line: {color: 'rgba(44, 130, 201, 1)'}
		};

		const trace_W4 = {
			name: 'Treshold Well4',
			x: dateW4,
			y: fluoDifferenceW4,
			type: 'scatter',
			mode: 'markers',
			hovertemplate: '%{y:.3f}',
			marker: {size: 10, color: '#f89406'}
		};
		const trace_W4_5average = {
			name: 'Average TL W4',
			x: dateW4_5average,
			y: fluoDifferenceW4_5average,
			type: 'line',
			mode: 'line',
			hovertemplate: '%{y:.3f}',
			line: {color: '#f86706'}
		};
		const trace_cart_available = {
			line: {shape: 'vh'},
			mode: 'lines+markers',
			name: 'Cart available',
			x: dateCart,
			y: cartAvailable,
			type: 'sctatter',
			marker: {
				size: 5,
				color: 'rgb(96,96,96)'
			},
			yaxis: 'y2'
		};

		this.datas.chartData = [trace_W1, trace_W4, trace_W1_5average, trace_W4_5average, trace_cart_available];
		return this.datas;
	}
}


