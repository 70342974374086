import {FullscreenChartService} from '../../../fullscreen-chart/fullscreen-chart.service';
import {CustomChartData} from '../../CustomChartData';
import {MChart} from '../MChart';

/* Concrete Chart for Factory Pattern */
export class MFillTimeS0Chart implements MChart {

	fillTimeS0: any;
	cartAvailable: any;
	datas: CustomChartData;

	private config = {
		responsive: true,
		displaylogo: false
	};

	private layout = {
		// items with a `name` attribute in template.images will be added to any
		// plot using this template
		images: [{
			source: '../../../../favicon.ico',
			xref: 'paper',
			yref: 'paper',
			x: 0.5,
			y: 0.5,
			xanchor: 'center',
			yanchor: 'middle',
			sizex: 0.7,
			sizey: 0.7,
			opacity: 0.1,
			layer: 'below'
		}],
		legend: {
			x: 0,
			y: 1.15,
			orientation: 'h',
			itemsizing: 'constant',
			itemwidth: 25
		},
		margin: {
			l: 35,
			r: 35,
			b: 50,
			t: 25
		},
		xaxis: {
			tickformat: '%d %b',
			tickangle: -45,
			showgrid: true,
			nticks: 36,
			showline: true,
			zeroline: false,
			mirror: true,
			hoverformat: '%Y-%m-%d %H:%M:%S'
		},
		yaxis: {
			range: [200, 400],
			showgrid: true,
			showline: true,
			zeroline: false,
			mirror: true
		},
		yaxis2: {
			title: 'Cart available',
			range: [0, 18],
			overlaying: 'y',
			side: 'right',
			showgrid: false,
			showline: false,
		},
		modebar: {
			color: '#000000',
			bgcolor: '#FFFFFF'
		}
	};

	constructor(private service: FullscreenChartService) {
	}

	public async createChart(machine_id: number, limit: number, time: string): Promise<CustomChartData> {

		this.fillTimeS0 = await this.service.getVariableHistory(machine_id, 'samp_filltime_s0', time, limit);
		this.cartAvailable = await this.service.getVariableHistory(machine_id, 'cart_available', time, limit);

		const fillTimeS0 = [];
		const cartAvailable = [];
		const date_fillTimeS0 = [];
		const date_cart = [];

		this.datas = new CustomChartData([], this.layout, this.config);

		for (const fillTime_pts of this.fillTimeS0) {
			fillTimeS0.push(fillTime_pts.map.value);
			date_fillTimeS0.push(fillTime_pts.map.analysis_time);
		}
		for (const cart_pts of this.cartAvailable) {
			cartAvailable.push(cart_pts.map.value);
			date_cart.push(cart_pts.map.analysis_time);
		}

		const trace_fillTime =
			{
				name: 'Filling time S0',
				x: date_fillTimeS0,
				y: fillTimeS0,
				type: 'scatter',
				mode: 'lines+markers',
				marker: {
					color: 'rgba(77, 19, 209, 1)'
				},
				line: {
					color: 'rgba(44, 130, 201, 1)'
				}
			};
		const trace_cart_available = {
			line: {shape: 'vh'},
			mode: 'lines+markers',
			name: 'Cart available',
			x: date_cart,
			y: cartAvailable,
			type: 'sctatter',
			marker: {
				size: 5,
				color: 'rgb(96,96,96)'
			},
			yaxis: 'y2'
		};
		this.datas.chartData = [trace_fillTime, trace_cart_available];
		return this.datas;
	}
}


