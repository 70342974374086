import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CookieServiceService} from '../cookie-service.service';
import {Analysis} from '../Models/Analysis';
import {environment} from '../../environments/environment';
import {map} from "rxjs/operators";

@Injectable({
	providedIn: 'root'
})
export class DashboardAnalysisService {

	header;
	baseUrl = environment.machineUrl;
	analysisUrl = environment.analysisUrl;

	constructor(private http: HttpClient, private cookieService: CookieServiceService) {
		this.header = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + this.cookieService.get('token'),
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'DELETE, GET, POST, PATCH, OPTIONS',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		});
	}

	reprocessCalculations(analysis_id: number): Observable<number> {
		return this.http.post<number>(this.analysisUrl + '/analyses/reprocessCalculations', analysis_id, {headers: this.header});
	}

	searchAnalysis(search: string, limit: number, offset: number): Observable<any> {
		return this.http.get<any>(this.analysisUrl + '/analyses/search' + '?limit=' + limit + '&offset=' + offset + '&search=' + search, {headers: this.header});
	}

	getMachineAnalysisFullDetail(analysis_id: number) {
		return this.http.get<any>(this.analysisUrl + '/analyses/' + analysis_id + '/detailed', {headers: this.header}).toPromise();
	}

	getAnalysisMissingVariables(analysis_id: number) {
		return this.http.get<any>(this.analysisUrl + '/analyses/variables/' + analysis_id + '/MissingVariables', {headers: this.header}).toPromise();
	}
}
