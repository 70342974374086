<body>
<mat-card class="search-engine">
	<mat-card-header fxLayout="flex" fxLayoutAlign="center start">
		<mat-card-title>
			Search analysis
		</mat-card-title>
	</mat-card-header>

	<mat-card-content class="search-bar" fxLayout="row" fxLayoutAlign="space-around center">
		<button class="search-icon" mat-icon-button disabled>
			<mat-icon>search</mat-icon>
		</button>
		<input class="search-control" type="text" placeholder="Search" (input)="onSearchChange($event.target.value)">
	</mat-card-content>


	<div>
		<mat-option *ngFor="let search of searchText; index as i  " [value]="search.entity.map.analysis_id">
			<a class="searched-item" [routerLink]="['/dashboards/analysis', search.entity.map.analysis_id]">
				<button mat-button>
					{{search.entity.map.machine_tag}}, Analysis Id: {{search.entity.map.analysis_id}}, Start time: {{search.entity.map.analysis_time | date: 'yyyy-MM-dd HH:mm:ss'}}
				</button>
			</a>
		</mat-option>
	</div>


</mat-card>
</body>
