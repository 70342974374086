<body>
<div class="page-header">
	<div class="welcome-container" fxLayout="row" fxLayoutAlign="center">
		<!--<img class="user-picture" src="{{photoUrl}}">-->
		<mat-icon class="icon-2x">account_circle</mat-icon>
		<h2 class="welcome-message">Welcome {{ userFirstname }}</h2>
	</div>
</div>

<div class="home-content-container" fxFlex fxLayout="row" fxLayout.xs="column" fxLayout.sm="column">
	<div class="notification-container" fxFlex="65%" fxFlex.xs="65%" fxFlex.sm="65%">
		<mat-card-title>Events</mat-card-title>
		<mat-divider></mat-divider>
		<div fxLayoutAlign="space-between">
			<div fxLayoutAlign="center center">
				<button style="height: 70%" (click)="refreshNotifications()" [disabled]="fetchingNotifications" mat-button
								matTooltip="Check for new notifications"
								matTooltipPosition="above" matTooltipShowDelay="100">
					<mat-icon>refresh</mat-icon>
				</button>
			</div>
			<div>
				<mat-checkbox style="margin-right: 10px;" [(ngModel)]="displayDismissedNotifications" (ngModelChange)="displayDismissedNotificationsCBChange()" color="primary">Show archives
				</mat-checkbox>
				<mat-form-field style="margin-right: 10px; width: 130px;">
					<mat-select [(ngModel)]="appliedNotifTimeFrameFilter" (ngModelChange)="updateTimeFrameAndNotif()">
						<mat-option *ngFor="let timeFramefilter of notifTimeFrameFilters"
												[value]="timeFramefilter">{{timeFramefilter}}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field style="width: 130px;">
					<mat-select [(ngModel)]="appliedNotifTypeFilter" (ngModelChange)="filterAndDisplayNotifications()"
											placeholder="Notification filter" multiple>
						<mat-option *ngFor="let typeFilter of notifTypeFilters" [value]="typeFilter.cl_level">{{typeFilter.gravity}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>

		<div class="notification-list-container">

			<div class="overlay" *ngIf="fetchingNotifications">
				<div class="overlay__wrapper">
					<div class="overlay__spinner">
						<button mat-raised-button color="primary" class="spinner"></button>
					</div>
				</div>
			</div>

			<div *ngIf="(displayedNotifications.length == 0 || emptyList) && !fetchingNotifications" fxLayoutAlign="center center" style="height: 65vh">
				<mat-label>No notification</mat-label>
			</div>

			<mat-accordion class="example-headers-align" *ngFor="let notification of displayedNotifications" hideToggle>
				<mat-expansion-panel *ngIf="!notification.dismissed" [ngStyle]="
          notification.cl_level==0?{'background-color': '#FFFFFF'}:
          notification.cl_level % 2 != 0 ?{'background-color': '#FFF44F88'}:
          notification.cl_level % 2 == 0 ?{'background-color': '#FF634788'}:
          {'background-color': 'whitesmoke'}">
					<mat-expansion-panel-header>
						<mat-panel-title class="panel-header-info" style="width: 20%">
							{{notification.var_definition}}
						</mat-panel-title>
						<mat-panel-description fxLayoutAlign="space-between">
							<div class="panel-header-info">
								Machine:
								<a class="analysis_id_ref" [routerLink]="['/dashboards/machines', notification.machine_tag ]">
									{{notification.machine_tag}}
								</a>
							</div>
							<div class="panel-header-info">
								Analysis:
								<a class="machine_id_ref" [routerLink]="['/dashboards/analysis', notification.analysis_id ]">
									{{notification.analysis_id}}
								</a>
							</div>
							<div fxLayoutAlign="center">
								<button mat-button color="primary" (click)="dismissNotification(notification.notification_id)">
									Archive
								</button>
								<mat-icon class="panel-header-info" *ngIf="notification.cl_level == 0">info</mat-icon>
								<mat-icon class="panel-header-info" *ngIf="notification.cl_level % 2 != 0">warning</mat-icon>
								<mat-icon class="panel-header-info" *ngIf="notification.cl_level % 2 == 0 && notification.cl_level != 0">dangerous</mat-icon>
							</div>
						</mat-panel-description>
					</mat-expansion-panel-header>
					<div fxFlex fxLayout="column">
						<div class="notification-info">
							<div fxFlex="100%" fxLayout="column">
								<div style="margin-bottom: 10px">
									<mat-label>Tag: {{notification.var_tag}}</mat-label>
								</div>
								<div>
									<mat-label><span style="font-weight: bold">Until: </span>{{notification.creation_timestamp | date:'yyyy-MM-dd HH:mm' : 'GMT-4' : 'fr'}}</mat-label>
								</div>
								<div>
									<mat-label><span style="font-weight: bold">Value: </span>{{notification.var_value}}
										<mat-icon *ngIf="notification.cl_level > 0">arrow_upward</mat-icon>
										<mat-icon *ngIf="notification.cl_level < 0">arrow_downward</mat-icon>
									</mat-label>
								</div>
								<div *ngIf="notification.cl_level != 0">
									<table>
										<tr>
											<td [ngStyle]="notification.cl_level==-2?{'background-color': '#FF634788'}: {'background-color': '#CECECE'}">lcl_a</td>
											<td [ngStyle]="notification.cl_level==-1?{'background-color': '#FFF44F88'}: {'background-color': '#CECECE'}">lcl_w</td>
											<td [ngStyle]="notification.cl_level==1?{'background-color': '#FFF44F88'}: {'background-color': '#CECECE'}">ucl_w</td>
											<td [ngStyle]="notification.cl_level==2?{'background-color': '#FF634788'}: {'background-color': '#CECECE'}">ucl_a</td>
										</tr>
										<tr>
											<!--The check for 0.1 is just to correctly show the cart_available lower alert limit-->
											<td>{{ notification.lcl_a == 0.1 ? 0 : notification.lcl_a }}</td>
											<td>{{ notification.lcl_w }}</td>
											<td>{{ notification.ucl_w }}</td>
											<td>{{ notification.ucl_a }}</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					</div>
				</mat-expansion-panel>
			</mat-accordion>

			<div *ngIf="displayDismissedNotifications">
				<mat-accordion class="example-headers-align" *ngFor="let notification of displayedNotifications" hideToggle>
					<mat-expansion-panel *ngIf="notification.dismissed" [ngStyle]="
          notification.dismissed?{'background-color': '#CCCCCC'}:{'background-color': 'whitesmoke'}">
						<mat-expansion-panel-header>
							<mat-panel-title class="panel-header-info" style="width: 20%">
								{{notification.var_definition}}
							</mat-panel-title>
							<mat-panel-description fxLayoutAlign="space-between">
								<div class="panel-header-info">
									Machine:
									<a class="analysis_id_ref" [routerLink]="['/dashboards/machines', notification.machine_tag ]">
										{{notification.machine_tag}}
									</a>
								</div>
								<div class="panel-header-info">
									Analysis:
									<a class="machine_id_ref" [routerLink]="['/dashboards/analysis', notification.analysis_id ]">
										{{notification.analysis_id}}
									</a>
								</div>

								<div fxLayoutAlign="center">
									<!-- (The button) Really dumb way to fix Panel-Header alignment of dismissed notification-->
									<button mat-button color="primary" disabled style="visibility: hidden">Archive</button>
									<mat-icon class="panel-header-info">done</mat-icon>
								</div>
							</mat-panel-description>
						</mat-expansion-panel-header>
						<div>
							<div class="notification-info">
								<div fxFlex="60%" fxLayout="column">
									<div style="margin-bottom: 10px">
										<mat-label>Tag: {{notification.var_tag}}</mat-label>
									</div>
									<div>
										<mat-label><span style="font-weight: bold">Until: </span>{{notification.creation_timestamp | date:'yyyy-MM-dd HH:mm' : 'GMT-4' : 'fr'}}</mat-label>
									</div>
									<div>
										<mat-label><span style="font-weight: bold">Value: </span>{{notification.var_value}}
											<mat-icon *ngIf="notification.cl_level > 0">arrow_upward</mat-icon>
											<mat-icon *ngIf="notification.cl_level < 0">arrow_downward</mat-icon>
										</mat-label>
									</div>
									<div *ngIf="notification.cl_level != 0">
										<table>
											<tr>
												<td [ngStyle]="notification.cl_level==-2?{'background-color': '#FF634788'}: {'background-color': '#CECECE'}">lcl_a</td>
												<td [ngStyle]="notification.cl_level==-1?{'background-color': '#FFF44F88'}: {'background-color': '#CECECE'}">lcl_w</td>
												<td [ngStyle]="notification.cl_level==1?{'background-color': '#FFF44F88'}: {'background-color': '#CECECE'}">ucl_w</td>
												<td [ngStyle]="notification.cl_level==2?{'background-color': '#FF634788'}: {'background-color': '#CECECE'}">ucl_a</td>
											</tr>
											<tr>
												<td>{{ notification.lcl_a }}</td>
												<td>{{ notification.lcl_w }}</td>
												<td>{{ notification.ucl_w }}</td>
												<td>{{ notification.ucl_a }}</td>
											</tr>
										</table>
									</div>
								</div>
								<div fxFlex="40%" fxLayout="column">
									<mat-label><span style="font-weight: bold">Archive by: </span>{{notification.dismissed_by}}
									</mat-label>
									<mat-label><span
										style="font-weight: bold">On: </span>{{notification.dismissed_timestamp | date:'yyyy-MM-dd HH:mm' : 'GMT-4' : 'fr'}}
									</mat-label>
								</div>
							</div>
							<mat-dialog-actions fxLayoutAlign="end">
								<button mat-button color="warn" (click)="RaiseBackNotification(notification.notification_id)">Raise
								</button>
							</mat-dialog-actions>
						</div>
					</mat-expansion-panel>
				</mat-accordion>
			</div>
		</div>
	</div>

	<div class="temp-class-for-content" fxFlex="35%" fxFlex.xs="35%" fxFlex.sm="35%">
		<mat-card-title>In development</mat-card-title>
		<mat-divider></mat-divider>
	</div>
</div>

</body>


