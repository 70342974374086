import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CookieServiceService} from '../cookie-service.service';
import {environment} from '../../environments/environment';
import {Observable} from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class HomePageService {

	header;
	baseUrl = environment.notificationUrl;

	constructor(private http: HttpClient, private cookieService: CookieServiceService) {
		this.header = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + this.cookieService.get('token'),
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'DELETE, GET, POST, OPTIONS',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		});
	}

	getNotifications(notifTimeFrame: string): Observable<Notification[]> {
		let params = new HttpParams();
		params = params.append('notification_timeframe', notifTimeFrame);

		return this.http.get<Notification[]>(this.baseUrl + '/notifications', {headers: this.header, params});
	}

	dismissNotification(notif_id: number, userEmail: string): Observable<number> {
		const params = new HttpParams();
		return this.http.patch<number>(this.baseUrl + '/notifications/dismiss?notification_id=' + notif_id + '&dismissed_by=' + userEmail, {headers: this.header, params});
	}

	raiseNotification(notif_id: number) {
		const params = new HttpParams();
		return this.http.patch<number>(this.baseUrl + '/notifications/raise?notification_id=' + notif_id, {headers: this.header, params});
	}
}
