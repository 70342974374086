export class ResultBioAlert {

	constructor(
		public machine_tag: string,
		public machine_inlet_number: number,
		public machine_timestamp: string,
		public result_sampling_time: string,
		public result_risk_level: number,
		public result_vmr: number,
		public result_ttm: number,
		public result_error_code: number,
		public result_tt1: number,
		public result_tt2: number,
		public result_tt3: number,
		public result_tt4: number,
		public result_tl: number,
		public cart_available: number,
		public cart_batch_number: number,
		public cart_ttc_a: number,
		public cart_ttc_b: number,
		public cart_ttc_c: number,
		public cart_ttc_cal: number,
		public result_value_ug_rounded: number,
		public mrv_relation_symbol: string,
		public ttc_diff_max: number,
		public tt_diff_max: number,
		public ttm_max: number,
		public ttm_min: number,
		public comments: string
	) {
	}
}
