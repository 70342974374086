export class Site {

	public site_id: number;
	public site_tag: string;
	public business_id: number;
	public access_level: number;

	constructor(site_id: number, site_tag: string, business_id: number, access_level: number) {
		this.site_id = site_id;
		this.site_tag = site_tag;
		this.business_id = business_id;
		this.access_level = access_level;
	}
}
