import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute} from '@angular/router';
import {FullscreenChartService} from './fullscreen-chart.service';
import {CustomChartData} from '../ChartFactories/CustomChartData';
import {MChartFactory} from '../ChartFactories/MachineFactory/MChartFactory';
import {MCalibSuccessHistoryChartFactory} from '../ChartFactories/MachineFactory/ConcreteMachineFactories/m-calib-success-history-chart-factory';
import {AChartFactory} from '../ChartFactories/AnalysisFactory/AChartFactory';
import {APressurePerWellVS0ChartFactory} from '../ChartFactories/AnalysisFactory/ConcreteAnalysisFactories/a-pressure-per-well-vs0-chart-factory';
import {AFluoDataChartFactory} from '../ChartFactories/AnalysisFactory/ConcreteAnalysisFactories/a-fluo-data-chart-factory';
import {MAvgDeltaPe01Pe03ChartFactory} from '../ChartFactories/MachineFactory/ConcreteMachineFactories/m-avg-delta-pe01-pe03-chart-factory';
import {MTotalFiltrationTimeS0S1ChartFactory} from '../ChartFactories/MachineFactory/ConcreteMachineFactories/m-total-filtration-time-s0-s1-chart-factory';
import {MTtmChartFactory} from '../ChartFactories/MachineFactory/ConcreteMachineFactories/m-ttm-chart-factory';
import {MCalibSuccessChartFactory} from '../ChartFactories/MachineFactory/ConcreteMachineFactories/m-calib-success-chart-factory';
import {MCalibratorsChartFactory} from '../ChartFactories/MachineFactory/ConcreteMachineFactories/m-calibrators-chart-factory';
import {MUgChartFactory} from '../ChartFactories/MachineFactory/ConcreteMachineFactories/m-ug-chart-factory';
import {MTemperatureMinMaxChartFactory} from '../ChartFactories/MachineFactory/ConcreteMachineFactories/m-temperature-min-max-chart-factory';
import {APressureSeq24ChartFactory} from '../ChartFactories/AnalysisFactory/ConcreteAnalysisFactories/a-pressure-seq-2.4-factory';
import {SPChartFactory} from '../ChartFactories/SamplingPointFactory/SPChartFactory';
import {SPCalibratorsChartFactory} from '../ChartFactories/SamplingPointFactory/ConcreteSamplingPointFactories/sp-calibrators-chart-factory';
import {SPTtmChartFactory} from '../ChartFactories/SamplingPointFactory/ConcreteSamplingPointFactories/sp-ttm-chart-factory';
import {SPCalibSuccessChartFactory} from '../ChartFactories/SamplingPointFactory/ConcreteSamplingPointFactories/sp-calib-success-chart-factory';
import {SPUgChartFactory} from '../ChartFactories/SamplingPointFactory/ConcreteSamplingPointFactories/sp-ug-factory';
import {SPCalibSuccessHistoryChartFactory} from '../ChartFactories/SamplingPointFactory/ConcreteSamplingPointFactories/sp-calib-success-history-chart-factory';
import {SPC1minC2ChartFactory} from '../ChartFactories/SamplingPointFactory/ConcreteSamplingPointFactories/sp-c1-min-c2-chart-factory';
import {SPTTmVariationChartFactory} from '../ChartFactories/SamplingPointFactory/ConcreteSamplingPointFactories/sp-ttm-variation-chart-factory';
import {TChartFactory} from '../ChartFactories/TpiFactory/TChartFactory';
import {TConductivityChartFactory} from '../ChartFactories/TpiFactory/TpiFactories/t-conductivity-factory';
import {TChlorineChartFactory} from '../ChartFactories/TpiFactory/TpiFactories/t-chlorine-factory';
import {TCopperCorrosionChartFactory} from '../ChartFactories/TpiFactory/TpiFactories/t-copper-corrosion-factory';
import {TPhChartFactory} from '../ChartFactories/TpiFactory/TpiFactories/t-ph-factory';
import {TRiskIndexChartFactory} from '../ChartFactories/TpiFactory/TpiFactories/t-risk-index-factory';
import {TSteelCorrosionChartFactory} from '../ChartFactories/TpiFactory/TpiFactories/t-steel-corrosion-factory';
import {TTurbidityChartFactory} from '../ChartFactories/TpiFactory/TpiFactories/t-turbidity-factory';
import {TTemperatureChartFactory} from '../ChartFactories/TpiFactory/TpiFactories/t-temperature-factory';
import {MFillTimeS0ChartFactory} from '../ChartFactories/MachineFactory/ConcreteMachineFactories/m-fill-time-s0-chart-factory';
import {ATmCallingChartFactory} from '../ChartFactories/AnalysisFactory/ConcreteAnalysisFactories/a-tm-calling-chart-factory';
import {
	MTresholdFluorescenceChartFactory
} from '../ChartFactories/MachineFactory/ConcreteMachineFactories/m-treshold-fluorescence-chart-factory';

@Component({
	selector: 'app-fullscreen-chart',
	templateUrl: './fullscreen-chart.component.html',
	styleUrls: ['./fullscreen-chart.component.css']
})
export class FullscreenChartComponent implements OnInit {

	chartName: string;
	time: string;
	limit: number;
	machine_id: number;
	machine_tag: string;
	sensor_id: number;
	inletNo: number;
	sp_id: number;
	sp_name: string;
	startTime: string;
	endTime: string;

	analysis_id: number;

	analysisChart: boolean;

	/* These params can represente the machine_id, inlet_no, analysis_id, sensor_id, etc */
	/* They are interpreted by the chartname so  */
	param1: any;
	param2: any;
	param3: any;
	param4: any;

	chart: CustomChartData;

	constructor(
		private route: ActivatedRoute,
		private service: FullscreenChartService,
		private snackbar: MatSnackBar,
	) {
	}

	async ngOnInit() {
		this.chartName = this.route.snapshot.paramMap.get('name');
		this.param1 = this.route.snapshot.paramMap.get('param1');
		this.param2 = this.route.snapshot.paramMap.get('param2');
		this.param3 = this.route.snapshot.paramMap.get('param3');
		this.param4 = this.route.snapshot.paramMap.get('param4');
		this.time = this.route.snapshot.paramMap.get('time');
		this.limit = +this.route.snapshot.paramMap.get('limit'); // convert string to number

		if (this.chartName === 'Machine Calibration Success History Chart') {
			this.machine_id = this.param1;
			this.machine_tag = this.param2;
			this.analysisChart = false;
			await this.createMachineChart(new MCalibSuccessHistoryChartFactory(this.service), this.machine_id, this.limit, this.time);
		} else if (this.chartName === 'Pressure per well') {
			this.analysis_id = this.param1;
			this.machine_id = this.param2;
			this.sensor_id = this.param3;
			this.analysisChart = true;
			await this.createAnalysisChart(new APressurePerWellVS0ChartFactory(this.service), this.analysis_id, this.sensor_id);
		} else if (this.chartName === 'Fluo Data Chart') {
			this.analysis_id = this.param1;
			this.machine_id = this.param2;
			this.sensor_id = this.param3;
			this.analysisChart = true;
			await this.createAnalysisChart(new AFluoDataChartFactory(this.service), this.analysis_id, this.sensor_id);
		} else if (this.chartName === 'Pressure Seq 2.4 Chart') {
			this.analysis_id = this.param1;
			this.machine_id = this.param2;
			this.sensor_id = this.param3;
			this.analysisChart = true;
			await this.createAnalysisChart(new APressureSeq24ChartFactory(this.service), this.analysis_id, this.sensor_id);
		} else if (this.chartName === 'TM Calling') {
			this.analysis_id = this.param1;
			this.machine_id = this.param2;
			this.sensor_id = this.param3;
			this.analysisChart = true;
			await this.createAnalysisChart(new ATmCallingChartFactory(this.service), this.analysis_id, this.sensor_id);
		}else if (this.chartName === 'Avg Delta PE01 PE03') {
			this.machine_id = this.param1;
			this.machine_tag = this.param2;
			this.analysisChart = false;
			await this.createMachineChart(new MAvgDeltaPe01Pe03ChartFactory(this.service), this.machine_id, this.limit, this.time);
		} else if (this.chartName === 'Total Filtration Time S0 S1') {
			this.machine_id = this.param1;
			this.machine_tag = this.param2;
			this.analysisChart = false;
			await this.createMachineChart(new MTotalFiltrationTimeS0S1ChartFactory(this.service), this.machine_id, this.limit, this.time);
		} else if (this.chartName === 'Calibrators Chart') {
			this.machine_id = this.param1;
			this.machine_tag = this.param2;
			this.analysisChart = false;
			await this.createMachineChart(new MCalibratorsChartFactory(this.service), this.machine_id, this.limit, this.time);
		} else if (this.chartName === 'TTm Chart') {
			this.machine_id = this.param1;
			this.machine_tag = this.param2;
			this.analysisChart = false;
			await this.createMachineChart(new MTtmChartFactory(this.service), this.machine_id, this.limit, this.time);
		} else if (this.chartName === 'Success Calibration Chart') {
			this.machine_id = this.param1;
			this.machine_tag = this.param2;
			this.analysisChart = false;
			await this.createMachineChart(new MCalibSuccessChartFactory(this.service), this.machine_id, this.limit, this.time);
		} else if (this.chartName === 'UG Chart') {
			this.machine_id = this.param1;
			this.machine_tag = this.param2;
			this.analysisChart = false;
			await this.createMachineChart(new MUgChartFactory(this.service), this.machine_id, this.limit, this.time);
		} else if (this.chartName === 'Filling Time S0') {
				this.machine_id = this.param1;
				this.machine_tag = this.param2;
				this.analysisChart = false;
				await this.createMachineChart(new MFillTimeS0ChartFactory(this.service), this.machine_id, this.limit, this.time);
		} else if (this.chartName === 'Temperature Chart') {
			this.machine_id = this.param1;
			this.machine_tag = this.param2;
			this.analysisChart = false;
			await this.createMachineChart(new MTemperatureMinMaxChartFactory(this.service), this.machine_id, this.limit, this.time);
		} else if (this.chartName === 'Treshold Fluorescence Chart') {
			this.machine_id = this.param1;
			this.machine_tag = this.param2;
			this.analysisChart = false;
			await this.createMachineChart(new MTresholdFluorescenceChartFactory(this.service), this.machine_id, this.limit, this.time);
		}else if (this.chartName === 'Sampling point Calibrators Chart'){
			this.sp_id = this.param1;
			this.sp_name = this.param2;
			this.analysisChart = false;
			await this.createSamplingPointChart(new SPCalibratorsChartFactory(this.service), this.sp_id, this.limit, this.time);
		} else if (this.chartName === 'Sampling point TTm Chart'){
			this.sp_id = this.param1;
			this.sp_name = this.param2;
			this.analysisChart = false;
			await this.createSamplingPointChart(new SPTtmChartFactory(this.service), this.sp_id, this.limit, this.time);
		}else if (this.chartName === 'Sampling point Calibration Success Chart'){
			this.sp_id = this.param1;
			this.sp_name = this.param2;
			this.analysisChart = false;
			await this.createSamplingPointChart(new SPCalibSuccessChartFactory(this.service), this.sp_id, this.limit, this.time);
		}else if (this.chartName === 'Sampling point Ug Chart'){
			this.sp_id = this.param1;
			this.sp_name = this.param2;
			this.analysisChart = false;
			await this.createSamplingPointChart(new SPUgChartFactory(this.service), this.sp_id, this.limit, this.time);
		}else if (this.chartName === 'Sampling point Calibration Success History Chart'){
			this.sp_id = this.param1;
			this.sp_name = this.param2;
			this.analysisChart = false;
			await this.createSamplingPointChart(new SPCalibSuccessHistoryChartFactory(this.service), this.sp_id, this.limit, this.time);
		}else if (this.chartName === 'Sampling point C1-C2 Chart'){
			this.sp_id = this.param1;
			this.sp_name = this.param2;
			this.analysisChart = false;
			await this.createSamplingPointChart(new SPC1minC2ChartFactory(this.service), this.sp_id, this.limit, this.time);
		}else if (this.chartName === 'Sampling point TTm Variation Chart'){
			this.sp_id = this.param1;
			this.sp_name = this.param2;
			this.analysisChart = false;
			await this.createSamplingPointChart(new SPTTmVariationChartFactory(this.service), this.sp_id, this.limit, this.time);
		} else if (this.chartName === 'Conductivity Chart'){
			this.machine_id = this.param1;
			this.machine_tag = this.param2;
			this.startTime = this.param3;
			this.endTime = this.param4;
			this.analysisChart = false;
			await this.createTpiChart(new TConductivityChartFactory(this.service), this.machine_id, this.startTime, this.endTime);
		} else if (this.chartName === 'pH Chart'){
			this.machine_id = this.param1;
			this.machine_tag = this.param2;
			this.startTime = this.param3;
			this.endTime = this.param4;
			this.analysisChart = false;
			await this.createTpiChart(new TPhChartFactory(this.service), this.machine_id, this.startTime, this.endTime);
		} else if (this.chartName === 'Turbidity Chart'){
			this.machine_id = this.param1;
			this.machine_tag = this.param2;
			this.startTime = this.param3;
			this.endTime = this.param4;
			this.analysisChart = false;
			await this.createTpiChart(new TTurbidityChartFactory(this.service), this.machine_id, this.startTime, this.endTime);
		} else if (this.chartName === 'Temperature AEGIS Chart'){
			this.machine_id = this.param1;
			this.machine_tag = this.param2;
			this.startTime = this.param3;
			this.endTime = this.param4;
			this.analysisChart = false;
			await this.createTpiChart(new TTemperatureChartFactory(this.service), this.machine_id, this.startTime, this.endTime);
		} else if (this.chartName === 'Free Chlorine Chart'){
			this.machine_id = this.param1;
			this.machine_tag = this.param2;
			this.startTime = this.param3;
			this.endTime = this.param4;
			this.analysisChart = false;
			await this.createTpiChart(new TChlorineChartFactory(this.service), this.machine_id, this.startTime, this.endTime);
		} else if (this.chartName === 'Copper Corrosion Chart'){
			this.machine_id = this.param1;
			this.machine_tag = this.param2;
			this.startTime = this.param3;
			this.endTime = this.param4;
			this.analysisChart = false;
			await this.createTpiChart(new TCopperCorrosionChartFactory(this.service), this.machine_id, this.startTime, this.endTime);
		} else if (this.chartName === 'Steel Corrosion Chart'){
			this.machine_id = this.param1;
			this.machine_tag = this.param2;
			this.startTime = this.param3;
			this.endTime = this.param4;
			this.analysisChart = false;
			await this.createTpiChart(new TSteelCorrosionChartFactory(this.service), this.machine_id, this.startTime, this.endTime);
		} else if (this.chartName === 'Risk Index Chart'){
			this.machine_id = this.param1;
			this.machine_tag = this.param2;
			this.startTime = this.param3;
			this.endTime = this.param4;
			this.analysisChart = false;
			await this.createTpiChart(new TRiskIndexChartFactory(this.service), this.machine_id, this.startTime, this.endTime);
		}
	}

	/* Factory Pattern
	* Chart creation from a concrete factory through its base interface */
	async createMachineChart(facto: MChartFactory, machine_id: number, limit: number, time: string) {
		this.chart = await facto.createChart(machine_id, limit, time);
		this.chart.chartLayout.height = 800;
	}

	/* Factory Pattern
	* Chart creation from a concrete factory through its base interface */
	async createSamplingPointChart(facto: SPChartFactory, sp_id: number, limit: number, time: string) {
		this.chart = await facto.createChart(sp_id, limit, time);
		this.chart.chartLayout.height = 800;
	}

	/* Factory Pattern
	* Chart creation from a concrete factory through its base interface */
	async createAnalysisChart(facto: AChartFactory, analysis_id: number, sensor_id: number) {
		this.chart = await facto.createChart(analysis_id, sensor_id);
		this.chart.chartLayout.height = 800;
	}

	/* Factory Pattern
	* Chart creation from a concrete factory through its base interface */
	async createTpiChart(facto: TChartFactory, machine_id: number, start: string, end: string) {
		this.chart = await facto.createChart(machine_id, start, end);
		this.chart.chartLayout.height = 800;
	}

	modifyChart(chart: CustomChartData, newX: [string, string], newY: [string, string]) {
		if (this.analysisChart) {
			chart.chartLayout = {
				// items with a `name` attribute in template.images will be added to any
				// plot using this template
				images: [{
					source: '../../../../favicon.ico',
					xref: 'paper',
					yref: 'paper',
					x: 0.5,
					y: 0.5,
					xanchor: 'center',
					yanchor: 'middle',
					sizex: 0.7,
					sizey: 0.7,
					opacity: 0.1,
					layer: 'below'
				}],
				legend: {
					x: 0,
					y: 1.15,
					orientation: 'h',
					itemsizing: 'constant',
					itemwidth: 25
				},
				margin: {
					l: 45,
					r: 5,
					b: 60,
					t: 10
				},
				xaxis: {
					tickformat: '%X',
					tickangle: -45,
					showgrid: true,
					nticks: 36,
					showline: true,
					zeroline: false,
					mirror: true,
					hoverformat: '%Y-%m-%d %H:%M:%S',
					autorange: false,
					range: [this.time.substring(0, 11) + newX[0], this.time.substring(0, 11) + newX[1]]
				},
				yaxis: {
					range: newY,
					showgrid: true,
					showline: true,
					zeroline: false,
					mirror: true
				},
				modebar: {
					color: '#000000',
					bgcolor: '#FFFFFF'
				}
			};
		} else {
			chart.chartLayout = {
				// items with a `name` attribute in template.images will be added to any
				// plot using this template
				images: [{
					source: '../../../../favicon.ico',
					xref: 'paper',
					yref: 'paper',
					x: 0.5,
					y: 0.5,
					xanchor: 'center',
					yanchor: 'middle',
					sizex: 0.7,
					sizey: 0.7,
					opacity: 0.1,
					layer: 'below'
				}],
				legend: {
					x: 0,
					y: 1.15,
					orientation: 'h',
					itemsizing: 'constant',
					itemwidth: 25
				},
				margin: {
					l: 35,
					r: 5,
					b: 50,
					t: 10
				},
				xaxis: {
					tickformat: '%d %b',
					tickangle: -45,
					showgrid: true,
					nticks: 36,
					showline: true,
					zeroline: false,
					mirror: true,
					hoverformat: '%Y-%m-%d %H:%M:%S',
					autorange: false,
					range: [new Date(newX[0]), new Date(newX[1])]
				},
				yaxis: {
					range: newY,
					showgrid: true,
					showline: true,
					zeroline: false,
					mirror: true
				},
				modebar: {
					color: '#000000',
					bgcolor: '#FFFFFF'
				}
			};
		}
	}
}
