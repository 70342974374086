<body>
<!--Machine Header -->
<mat-card class="machine-header">
	<mat-card-title fxLayoutAlign="space-between">
		<button mat-mini-fab color="primary" (click)="back()">
			<mat-icon>keyboard_arrow_left</mat-icon>
		</button>
		<!--<h2>Tableau de bord de la machine : {{ machine?.machine_tag }} ({{machine?.state}})</h2>-->
		<h2>Machine dashboard : {{ machine?.machine_tag }}</h2>
		<div>
			<button mat-raised-button color="basic" (click)="openLegend()" *ngIf="checkForPermission()">Calculation legend</button>
		</div>
	</mat-card-title>
</mat-card>
<!--Machine Information -->
<mat-card class="machine-information">
	<mat-card-title fxLayoutAlign="space-between">
		Machine information
		<button mat-mini-fab color="basic" (click)="showMachineInfo=!showMachineInfo">
			<mat-icon *ngIf="showMachineInfo">remove</mat-icon>
			<mat-icon *ngIf="!showMachineInfo">add</mat-icon>
		</button>
	</mat-card-title>
	<mat-card-content class="alert-information" *ngIf="checkForPermission()">
		<mat-card>
		<mat-card-title fxLayoutAlign="space-between">
			Notifications history
			<button mat-mini-fab color="basic" (click)="showEventHistory=!showEventHistory">
				<mat-icon *ngIf="showEventHistory">remove</mat-icon>
				<mat-icon *ngIf="!showEventHistory">add</mat-icon>
			</button>
		</mat-card-title>
			<mat-card-content *ngIf="showEventHistory">
				<mat-form-field style="margin-right: 10px; width: 130px;">
					<mat-select [(ngModel)]="appliedNotifTimeFrameFilter" (ngModelChange)="updateTimeFrameAndNotif()">
						<mat-option *ngFor="let timeFramefilter of notifTimeFrameFilters"
												[value]="timeFramefilter">
							{{timeFramefilter}}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<div *ngIf="(notifications.length == 0)" fxLayoutAlign="center center" style="height: auto">
					<mat-label>No notification</mat-label>
				</div>
				<div>
					<mat-card-title class="notification-title" *ngIf="(notifications.length != 0)">Active notifications</mat-card-title>
					<mat-accordion class="example-headers-align" *ngFor="let notification of notifications" hideToggle>
						<mat-expansion-panel *ngIf="!notification.dismissed" [ngStyle]="
						notification.cl_level==0?{'background-color': '#FFFFFF'}:
						notification.cl_level % 2 != 0 ?{'background-color': '#FFF44F88'}:
						notification.cl_level % 2 == 0 ?{'background-color': '#FF634788'}:
						{'background-color': 'whitesmoke'}" >
							<mat-expansion-panel-header>
								<mat-panel-title class="panel-header-info" style="width: 20%">
									{{notification.var_definition}}
								</mat-panel-title>
								<mat-panel-description fxLayoutAlign="space-between">
									<div class="panel-header-info">
										Analysis:
										<a class="machine_id_ref" [routerLink]="['/dashboards/analysis', notification.analysis_id ]">
											{{notification.analysis_id}}
										</a>
									</div>
									<div fxLayoutAlign="center">
										<mat-icon class="panel-header-info" *ngIf="notification.cl_level == 0">info</mat-icon>
										<mat-icon class="panel-header-info" *ngIf="notification.cl_level % 2 != 0">warning</mat-icon>
										<mat-icon class="panel-header-info" *ngIf="notification.cl_level % 2 == 0 && notification.cl_level != 0">dangerous</mat-icon>
									</div>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<div fxFlex fxLayout="column">
								<div class="notification-info">
									<div fxFlex="100%" fxLayout="column">
										<div style="margin-bottom: 10px">
											<mat-label>Tag: {{notification.var_tag}}</mat-label>
										</div>
										<div>
											<mat-label><span style="font-weight: bold">Until: </span>{{notification.creation_timestamp | date:'yyyy-MM-dd HH:mm' : 'GMT-4' : 'fr'}}</mat-label>
										</div>
										<div>
											<mat-label><span style="font-weight: bold">Value: </span>{{notification.var_value}}
												<mat-icon *ngIf="notification.cl_level > 0">arrow_upward</mat-icon>
												<mat-icon *ngIf="notification.cl_level < 0">arrow_downward</mat-icon>
											</mat-label>
										</div>
										<div *ngIf="notification.cl_level != 0">
											<table class="notification-table">
												<tr class="notification-tr">
													<td class="notification-td" [ngStyle]="notification.cl_level==-2?{'background-color': '#FF634788'}: {'background-color': '#CECECE'}">lcl_a</td>
													<td class="notification-td" [ngStyle]="notification.cl_level==-1?{'background-color': '#FFF44F88'}: {'background-color': '#CECECE'}">lcl_w</td>
													<td class="notification-td" [ngStyle]="notification.cl_level==1?{'background-color': '#FFF44F88'}: {'background-color': '#CECECE'}">ucl_w</td>
													<td class="notification-td" [ngStyle]="notification.cl_level==2?{'background-color': '#FF634788'}: {'background-color': '#CECECE'}">ucl_a</td>
												</tr>
												<tr class="notification-tr">
													<!--The check for 0.1 is just to correctly show the cart_available lower alert limit-->
													<td class="notification-td">{{ notification.lcl_a == 0.1 ? 0 : notification.lcl_a }}</td>
													<td class="notification-td">{{ notification.lcl_w }}</td>
													<td class="notification-td">{{ notification. ucl_w }}</td>
													<td class="notification-td">{{ notification.ucl_a }}</td>
												</tr>
											</table>
										</div>
									</div>
								</div>
							</div>
						</mat-expansion-panel>
					</mat-accordion>
				</div>
				<mat-divider></mat-divider>
				<div>
					<mat-card-title class="notification-title" *ngIf="(notifications.length != 0)">Dismissed notifications</mat-card-title>
					<mat-accordion class="example-headers-align" *ngFor="let notification of notifications" hideToggle>
						<mat-expansion-panel *ngIf="notification.dismissed" [ngStyle]="
          notification.dismissed?{'background-color': '#CCCCCC'}:{'background-color': 'whitesmoke'}">
							<mat-expansion-panel-header>
								<mat-panel-title class="panel-header-info" style="width: 20%">
									{{notification.var_definition}}
								</mat-panel-title>
								<mat-panel-description fxLayoutAlign="space-between">
									<div class="panel-header-info">
										Analysis:
										<a class="machine_id_ref" [routerLink]="['/dashboards/analysis', notification.analysis_id ]">
											{{notification.analysis_id}}
										</a>
									</div>

									<div fxLayoutAlign="center">
										<!-- (The button) Really dumb way to fix Panel-Header alignment of dismissed notification-->
										<button mat-button color="primary" disabled style="visibility: hidden">Archive</button>
										<mat-icon class="panel-header-info">done</mat-icon>
									</div>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<div>
								<div class="notification-info">
									<div fxFlex="60%" fxLayout="column">
										<div style="margin-bottom: 10px">
											<mat-label>Tag: {{notification.var_tag}}</mat-label>
										</div>
										<div>
											<mat-label><span style="font-weight: bold">Until: </span>{{notification.creation_timestamp | date:'yyyy-MM-dd HH:mm' : 'GMT-4' : 'fr'}}</mat-label>
										</div>
										<div>
											<mat-label><span style="font-weight: bold">Value: </span>{{notification.var_value}}
												<mat-icon *ngIf="notification.cl_level > 0">arrow_upward</mat-icon>
												<mat-icon *ngIf="notification.cl_level < 0">arrow_downward</mat-icon>
											</mat-label>
										</div>
										<div *ngIf="notification.cl_level != 0">
											<table class="notification-table">
												<tr class="notification-tr">
													<td class="notification-td" [ngStyle]="notification.cl_level==-2?{'background-color': '#FF634788'}: {'background-color': '#CECECE'}">lcl_a</td>
													<td class="notification-td" [ngStyle]="notification.cl_level==-1?{'background-color': '#FFF44F88'}: {'background-color': '#CECECE'}">lcl_w</td>
													<td class="notification-td" [ngStyle]="notification.cl_level==1?{'background-color': '#FFF44F88'}: {'background-color': '#CECECE'}">ucl_w</td>
													<td class="notification-td" [ngStyle]="notification.cl_level==2?{'background-color': '#FF634788'}: {'background-color': '#CECECE'}">ucl_a</td>
												</tr>
												<tr class="notification-tr">
													<td class="notification-td">{{ notification.lcl_a }}</td>
													<td class="notification-td">{{ notification.lcl_w }}</td>
													<td class="notification-td">{{ notification.ucl_w }}</td>
													<td class="notification-td">{{ notification.ucl_a }}</td>
												</tr>
											</table>
										</div>
									</div>
									<div fxFlex="40%" fxLayout="column">
										<mat-label><span style="font-weight: bold">Archive by: </span>{{notification.dismissed_by}}
										</mat-label>
										<mat-label><span
											style="font-weight: bold">On: </span>{{notification.dismissed_timestamp | date:'yyyy-MM-dd HH:mm' : 'GMT-4' : 'fr'}}
										</mat-label>
									</div>
								</div>
								<mat-dialog-actions fxLayoutAlign="end">
								</mat-dialog-actions>
							</div>
						</mat-expansion-panel>
					</mat-accordion>
				</div>
			</mat-card-content>
		</mat-card>
	</mat-card-content>

	<mat-card-content *ngIf="showMachineInfo">
		<mat-form-field class="number-sample-sel" appearance="fill" [formGroup]="machineSettingsForm">
			<mat-label>Number of samples</mat-label>
			<mat-select (selectionChange)="numberOfSampleMachineChanged($event)" formControlName="numberOfSample">
				<mat-option *ngFor="let num of numberOfSample" [value]="num">
					{{num}}
				</mat-option>
			</mat-select>
		</mat-form-field>


		<mat-form-field color="primary" appearance="fill" [formGroup]="machineSettingsForm">
			<mat-label>Last sample date</mat-label>
			<input matInput [matDatepicker]="picker2" [max]="maxDate" formControlName="dateFormat">
			<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
			<mat-datepicker #picker2 color="primary"></mat-datepicker>
		</mat-form-field>

		<button mat-raised-button color="primary" (click)=refreshMachineInfo()>Submit</button>

		<mat-grid-list cols="7" rowHeight="65px">
			<mat-grid-tile>
				State:
				<button  mat-fab class="status-info" [disabled]="true" [ngStyle]="{'background-color': 'gray'}">
					{{machine?.state}}
				</button>
			</mat-grid-tile>
			<mat-grid-tile>
				Status:
				<button  mat-fab class="status-info" [disabled]="true" [ngStyle]="
					this.machineStatus == 'N/A'?{'background-color':'#999999'}:
					this.machineStatus == 'disconnected'?{'background-color':'#8E7CC3'}:
					this.machineStatus == 'standby'?{'background-color':'#8fce00'}:
					this.machineStatus == 'error'?{'background-color':'#ff7100'}:
					this.machineStatus == 'error_long'?{'background-color':'#E0242F'}:
					this.machineStatus == 'in execution'?{'background-color':'#F9D054'}:'#999999'">
					{{this.machineStatus}}
				</button>
			</mat-grid-tile>
			<mat-grid-tile>
				Number of analysis:
				<button  mat-fab class="status-info" [disabled]="true" [ngStyle]="{'background-color': 'black'}">
					{{machine_nb_analysis}}
				</button>
			</mat-grid-tile>
			<mat-grid-tile *ngIf="machineCalibrationSuccessMoy && checkForPermission()">
				Average calibration :
				<button mat-fab class="status-info" [disabled]="true"
								[ngStyle]="
          machineCalibrationSuccessMoy == null?{'background-color':'#EEEEEE'} :
          machineCalibrationSuccessMoy.cl_level == 0?{'background-color': 'green'}:
          machineCalibrationSuccessMoy.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
          machineCalibrationSuccessMoy.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
          {'background-color': '#EEEEEE'}">
					{{machineCalibrationSuccessMoy.value}}%
				</button>
			</mat-grid-tile>
			<mat-grid-tile *ngIf="latestMachineCalibrationSuccess_5 && checkForPermission()">
				Calibration 5A :
				<button mat-fab class="status-info" [disabled]="true"
								[ngStyle]="
        latestMachineCalibrationSuccess_5 == null?{'background-color':'#EEEEEE'} :
        latestMachineCalibrationSuccess_5.cl_level == 0?{'background-color': 'green'}:
        latestMachineCalibrationSuccess_5.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
        latestMachineCalibrationSuccess_5.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
        {'background-color': '#EEEEEE'}">
					{{latestMachineCalibrationSuccess_5.value}}%
				</button>
			</mat-grid-tile>
			<mat-grid-tile *ngIf="averageQualityScore_5 && checkForPermission()">
				Quality Score 5A :
				<button mat-fab class="status-info" [disabled]="true"
								[ngStyle]="
        averageQualityScore_5.value == null?{'background-color':'#999999'} :
        averageQualityScore_5.value <= 50? {'background-color': '#E0242F'}:
        averageQualityScore_5.value <= 75 ?{'background-color':'#FFA500'} :
        averageQualityScore_5.value <= 85 ?{'background-color':'#F9D054'} :
        {'background-color': 'green'}">
					<p *ngIf="averageQualityScore_5.value != null; else defaultValue">{{averageQualityScore_5.value}}%</p>
				</button>
			</mat-grid-tile>
			<mat-grid-tile *ngIf="numberInvalidLoaded && checkForPermission()">
				Invalid UG 5A :
				<button mat-fab class="status-info" [disabled]="true"
								[ngStyle]="
        numberInvalidUgLast_5 == null?{'background-color':'#999999'} :
        numberInvalidUgLast_5 >= 3 ? {'background-color': '#E0242F'}:
        numberInvalidUgLast_5 == 2 ? {'background-color':'#FFA500'} :
        numberInvalidUgLast_5 == 1 ? {'background-color':'#F9D054'} :
        {'background-color': 'green'}">
					<p *ngIf="numberInvalidUgLast_5 != null; else defaultValue">{{numberInvalidUgLast_5}}</p>
				</button>
			</mat-grid-tile>
			<ng-template #defaultValue>
				<p>No data</p>
			</ng-template>
		</mat-grid-list>


		<!--MACHINE Charts-->
		<mat-card class="chart-container" fxLayout="row wrap" fxLayoutAlign="start center">
			<!--Reaction time of C1 & C2 [1][1]-->
			<mat-card class="machine-chart" *ngIf="checkForPermission()">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					Reaction time of C1 and C2
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('Calibrators Chart')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="m_calibrators_chart && showMachineChart[1]">
					<plotly-plot
						[data]="m_calibrators_chart.chartData"
						[layout]="m_calibrators_chart.chartLayout"
						[config]="m_calibrators_chart.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!m_calibrators_chart || !showMachineChart[1]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showMachineChart[1]= !showMachineChart[1]">
						<mat-icon *ngIf="showMachineChart[1]">remove</mat-icon>
						<mat-icon *ngIf="!showMachineChart[1]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv" (click)="exportToCsv('Calibrators Chart')">
						<mat-icon>cloud_download</mat-icon>
					</button>
				</mat-card-actions>

			</mat-card>
			<!--Ttm [0][2]-->
			<mat-card class="machine-chart" *ngIf="checkForPermission()">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					TTm
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('TTm Chart')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="m_ttm_chart && showMachineChart[0]">
					<plotly-plot
						[data]="m_ttm_chart.chartData"
						[layout]="m_ttm_chart.chartLayout"
						[config]="m_ttm_chart.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!m_ttm_chart || !showMachineChart[0]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showMachineChart[0]= !showMachineChart[0]">
						<mat-icon *ngIf="showMachineChart[0]">remove</mat-icon>
						<mat-icon *ngIf="!showMachineChart[0]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv" (click)="exportToCsv('TTm Chart')">
						<mat-icon>cloud_download</mat-icon>
					</button>
<!--
					<button mat-mini-fab color="primary" (click)="machineChartSettings[2]=!machineChartSettings[2]">
						<mat-icon *ngIf="machineChartSettings[2]">remove</mat-icon>
						<mat-icon *ngIf="!machineChartSettings[2]">format_list_bulleted</mat-icon>
					</button>
-->
				</mat-card-actions>
<!--
				<mat-card *ngIf="machineChartSettings[2]">
					<mat-form-field class="number-sample-sel" appearance="fill" [formGroup]="ttmForm">
						<mat-label>Number of samples</mat-label>
						<mat-select (selectionChange)="numberOfSampleMachineChanged($event)" formControlName="numberOfSample">
							<mat-option *ngFor="let num of numberOfSample" [value]="num">
								{{num}}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill" [formGroup]="ttmForm">
						<mat-label>Last sample date</mat-label>
						<input matInput [matDatepicker]="picker2" [max]="maxDate" formControlName="dateFormat">
						<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
						<mat-datepicker #picker2 color="primary"></mat-datepicker>
					</mat-form-field>
					<button mat-raised-button color="primary" (click)="submitTTmSettings()">Submit</button>

					<mat-divider></mat-divider>
					<mat-form-field class="primary" appearance="fill">
						<mat-label>X axis range</mat-label>
						<mat-date-range-input [rangePicker]="picker">
							<input matStartDate placeholder="Start date" #x1_m_ttm>
							<input matEndDate placeholder="End date" #x2_m_ttm>
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill">
						<mat-label>Min y axis</mat-label>
						<input matInput type="number" #y1_m_ttm>
					</mat-form-field>
					<mat-form-field color="primary" appearance="fill">
						<mat-label>Max y axis</mat-label>
						<input matInput type="number" #y2_m_ttm>
					</mat-form-field>
					<button mat-raised-button color="primary"
									*ngIf="x1_m_ttm.value && x2_m_ttm.value && y1_m_ttm.value && y2_m_ttm.value" matSuffix mat-icon-button
									(click)="modifyChart(m_ttm_chart,
           [x1_m_ttm.value, x2_m_ttm.value], [y1_m_ttm.value, y2_m_ttm.value])">
						<mat-icon>send</mat-icon>
					</button>
				</mat-card>
-->
			</mat-card>
			<!--Calibration success [2][3]-->
			<mat-card class="machine-chart" *ngIf="checkForPermission()">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					Calibration success
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('Success Calibration Chart')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="m_calibSuccess_chart && showMachineChart[2]">
					<plotly-plot
						[data]="m_calibSuccess_chart.chartData"
						[layout]="m_calibSuccess_chart.chartLayout"
						[config]="m_calibSuccess_chart.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!m_calibSuccess_chart || !showMachineChart[2]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showMachineChart[2]= !showMachineChart[2]">
						<mat-icon *ngIf="showMachineChart[2]">remove</mat-icon>
						<mat-icon *ngIf="!showMachineChart[2]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv" (click)="exportToCsv('Success Calibration Chart')">
						<mat-icon>cloud_download</mat-icon>
					</button>
<!--
					<button mat-mini-fab color="primary" (click)="machineChartSettings[3]=!machineChartSettings[3]">
						<mat-icon *ngIf="machineChartSettings[3]">remove</mat-icon>
						<mat-icon *ngIf="!machineChartSettings[3]">format_list_bulleted</mat-icon>
					</button>
-->
				</mat-card-actions>
<!--
				<mat-card *ngIf="machineChartSettings[3]">
					<mat-form-field class="number-sample-sel" appearance="fill" [formGroup]="calibrationSuccessForm">
						<mat-label>Number of samples</mat-label>
						<mat-select (selectionChange)="numberOfSampleMachineChanged($event)" formControlName="numberOfSample">
							<mat-option *ngFor="let num of numberOfSample" [value]="num">
								{{num}}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill" [formGroup]="calibrationSuccessForm">
						<mat-label>Last sample date</mat-label>
						<input matInput [matDatepicker]="picker2" [max]="maxDate" formControlName="dateFormat">
						<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
						<mat-datepicker #picker2 color="primary"></mat-datepicker>
					</mat-form-field>
					<button mat-raised-button color="primary" (click)="submitCalibrationSuccessSettings()">Submit</button>

					<mat-divider></mat-divider>
					<mat-form-field class="primary" appearance="fill">
						<mat-label>X axis range</mat-label>
						<mat-date-range-input [rangePicker]="picker">
							<input matStartDate placeholder="Start date" #x1_m_calibSuccess>
							<input matEndDate placeholder="End date" #x2_m_calibSuccess>
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill">
						<mat-label>Min y axis</mat-label>
						<input matInput type="number" #y1_m_calibSuccess>
					</mat-form-field>
					<mat-form-field color="primary" appearance="fill">
						<mat-label>Max y axis</mat-label>
						<input matInput type="number" #y2_m_calibSuccess>
					</mat-form-field>
					<button mat-raised-button color="primary"
									*ngIf="x1_m_calibSuccess.value && x2_m_calibSuccess.value && y1_m_calibSuccess.value && y2_m_calibSuccess.value"
									matSuffix mat-icon-button (click)="modifyChart(m_calibSuccess_chart,
           [x1_m_calibSuccess.value, x2_m_calibSuccess.value], [y1_m_calibSuccess.value, y2_m_calibSuccess.value])">
						<mat-icon>send</mat-icon>
					</button>
				</mat-card>
-->
			</mat-card>
			<!--Ug chart [3][4]-->
			<mat-card class="machine-chart" >
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					UG/mL Chart
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('UG Chart')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="m_ug_chart && showMachineChart[3]">
					<plotly-plot
						[data]="m_ug_chart.chartData"
						[layout]="m_ug_chart.chartLayout"
						[config]="m_ug_chart.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!m_ug_chart || !showMachineChart[3]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showMachineChart[3]= !showMachineChart[3]">
						<mat-icon *ngIf="showMachineChart[3]">remove</mat-icon>
						<mat-icon *ngIf="!showMachineChart[3]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv" (click)="exportToCsv('UG Chart')">
						<mat-icon>cloud_download</mat-icon>
					</button>
<!--
					<button mat-mini-fab color="primary" (click)="machineChartSettings[4]=!machineChartSettings[4]">
						<mat-icon *ngIf="machineChartSettings[4]">remove</mat-icon>
						<mat-icon *ngIf="!machineChartSettings[4]">format_list_bulleted</mat-icon>
					</button>
-->
				</mat-card-actions>
<!--
				<nmat-card *ngIf="machineChartSettings[4]">
					<mat-form-field class="number-sample-sel" appearance="fill" [formGroup]="ugForm">
						<mat-label>Number of samples</mat-label>
						<mat-select (selectionChange)="numberOfSampleMachineChanged($event)" formControlName="numberOfSample">
							<mat-option *ngFor="let num of numberOfSample" [value]="num">
								{{num}}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill" [formGroup]="ugForm">
						<mat-label>Last sample date</mat-label>
						<input matInput [matDatepicker]="picker2" [max]="maxDate" formControlName="dateFormat">
						<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
						<mat-datepicker #picker2 color="primary"></mat-datepicker>
					</mat-form-field>
					<button mat-raised-button color="primary" (click)="submitUgSettings()">Submit</button>

					<mat-divider></mat-divider>
					<mat-form-field class="primary" appearance="fill">
						<mat-label>X axis range</mat-label>
						<mat-date-range-input [rangePicker]="picker">
							<input matStartDate placeholder="Start date" #x1_m_ug>
							<input matEndDate placeholder="End date" #x2_m_ug>
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill">
						<mat-label>Min y axis</mat-label>
						<input matInput type="number" #y1_m_ug>
					</mat-form-field>
					<mat-form-field color="primary" appearance="fill">
						<mat-label>Max y axis</mat-label>
						<input matInput type="number" #y2_m_ug>
					</mat-form-field>
					<button mat-raised-button color="primary"
									*ngIf="x1_m_ug.value && x2_m_ug.value && y1_m_ug.value && y2_m_ug.value" matSuffix mat-icon-button
									(click)="modifyChart(m_ug_chart,
           [x1_m_ug.value, x2_m_ug.value], [y1_m_ug.value, y2_m_ug.value])">
						<mat-icon>send</mat-icon>
					</button>
				</nmat-card>
-->
			</mat-card>
			<!--History of sampling-point calibration success[4][0]-->
			<mat-card class="machine-chart" *ngIf="checkForPermission()">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">

					History of machine calibration success
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('Machine Calibration Success History Chart')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="m_calibSuccessHistory_chart && showMachineChart[4]">
					<plotly-plot
						[data]="m_calibSuccessHistory_chart.chartData"
						[layout]="m_calibSuccessHistory_chart.chartLayout"
						[config]="m_calibSuccessHistory_chart.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!m_calibSuccessHistory_chart || !showMachineChart[4]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showMachineChart[4]= !showMachineChart[4]">
						<mat-icon *ngIf="showMachineChart[4]">remove</mat-icon>
						<mat-icon *ngIf="!showMachineChart[4]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv"
									(click)="exportToCsv('Machine Calibration Success History Chart')">
						<mat-icon>cloud_download</mat-icon>
					</button>

<!--
					<button mat-mini-fab color="primary" (click)="machineChartSettings[0]=!machineChartSettings[0]">
						<mat-icon *ngIf="machineChartSettings[0]">remove</mat-icon>
						<mat-icon *ngIf="!machineChartSettings[0]">format_list_bulleted</mat-icon>
					</button>
-->
				</mat-card-actions>

<!--
				<mat-card *ngIf="machineChartSettings[0]">
					<mat-form-field class="number-sample-sel" appearance="fill" [formGroup]="machineCalSuccessHistoryForm">
						<mat-label>Number of samples</mat-label>
						<mat-select (selectionChange)="numberOfSampleMachineChanged($event)" formControlName="numberOfSample">
							<mat-option *ngFor="let num of numberOfSample" [value]="num">
								{{num}}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill" [formGroup]="machineCalSuccessHistoryForm">
						<mat-label>Last sample date</mat-label>
						<input matInput [matDatepicker]="picker2" [max]="maxDate" formControlName="dateFormat">
						<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
						<mat-datepicker #picker2 color="primary"></mat-datepicker>
					</mat-form-field>
					<button mat-raised-button color="primary" (click)="submitMachineCalSuccessHistorySettings()">Submit</button>

					<mat-divider></mat-divider>

					<mat-form-field class="primary divider-setting" appearance="fill">
						<mat-label>X axis range</mat-label>
						<mat-date-range-input [rangePicker]="picker">
							<input matStartDate placeholder="Start date" #x1_m_calibSuccessHistory>
							<input matEndDate placeholder="End date" #x2_m_calibSuccessHistory>
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill">
						<mat-label>Min y axis</mat-label>
						<input matInput type="number" #y1_m_calibSuccessHistory>
					</mat-form-field>
					<mat-form-field color="primary" appearance="fill">
						<mat-label>Max y axis</mat-label>
						<input matInput type="number" #y2_m_calibSuccessHistory>
					</mat-form-field>
					<button mat-raised-button color="primary"
									*ngIf="x1_m_calibSuccessHistory.value && x2_m_calibSuccessHistory.value && y1_m_calibSuccessHistory.value && y2_m_calibSuccessHistory.value"
									matSuffix mat-icon-button (click)="modifyChart(m_calibSuccessHistory_chart,
           [x1_m_calibSuccessHistory.value, x2_m_calibSuccessHistory.value], [y1_m_calibSuccessHistory.value, y2_m_calibSuccessHistory.value])">
						<mat-icon>send</mat-icon>
					</button>
				</mat-card>
-->
			</mat-card>
			<!--Delta moyen aux pré-filtres E01-PE-01 et E01-PE-02-->
			<mat-card class="machine-chart" *ngIf="checkForPermission()">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					Average delta at E01-PE-01 and E01-PE-02 pre-filters
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('Avg Delta PE01 PE03')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="m_avg_delta_pe01_pe03 && showMachineChart[5]">
					<plotly-plot
						[data]="m_avg_delta_pe01_pe03.chartData"
						[layout]="m_avg_delta_pe01_pe03.chartLayout"
						[config]="m_avg_delta_pe01_pe03.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!m_avg_delta_pe01_pe03 || !showMachineChart[5]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showMachineChart[5]= !showMachineChart[5]">
						<mat-icon *ngIf="showMachineChart[5]">remove</mat-icon>
						<mat-icon *ngIf="!showMachineChart[5]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv" (click)="exportToCsv('Avg Delta PE01 PE03')">
						<mat-icon>cloud_download</mat-icon>
					</button>
				</mat-card-actions>

			</mat-card>
			<!--Temps de filtration total S0 et S1[6][6]-->
			<mat-card class="machine-chart" *ngIf="checkForPermission()">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					Total filtration time S0 and S1
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('Total Filtration Time S0 S1')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="m_total_filtration_time_s0_s1 && showMachineChart[6]">
					<plotly-plot
						[data]="m_total_filtration_time_s0_s1.chartData"
						[layout]="m_total_filtration_time_s0_s1.chartLayout"
						[config]="m_total_filtration_time_s0_s1.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!m_total_filtration_time_s0_s1 || !showMachineChart[6]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showMachineChart[6]= !showMachineChart[6]">
						<mat-icon *ngIf="showMachineChart[6]">remove</mat-icon>
						<mat-icon *ngIf="!showMachineChart[6]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv"
									(click)="exportToCsv('Total Filtration Time S0 S1')">
						<mat-icon>cloud_download</mat-icon>
					</button>
<!--
					<button mat-mini-fab color="primary" (click)="machineChartSettings[6]=!machineChartSettings[6]">
						<mat-icon *ngIf="machineChartSettings[6]">remove</mat-icon>
						<mat-icon *ngIf="!machineChartSettings[6]">format_list_bulleted</mat-icon>
					</button>
-->
				</mat-card-actions>
<!--
				<mat-card *ngIf="machineChartSettings[6]">
					<mat-form-field class="number-sample-sel" appearance="fill" [formGroup]="filtrationS0S1Form">
						<mat-label>Number of samples</mat-label>
						<mat-select (selectionChange)="numberOfSampleMachineChanged($event)" formControlName="numberOfSample">
							<mat-option *ngFor="let num of numberOfSample" [value]="num">
								{{num}}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill" [formGroup]="filtrationS0S1Form">
						<mat-label>Last sample date</mat-label>
						<input matInput [matDatepicker]="picker2" [max]="maxDate" formControlName="dateFormat">
						<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
						<mat-datepicker #picker2 color="primary"></mat-datepicker>
					</mat-form-field>
					<button mat-raised-button color="primary" (click)="submitFiltrationS0S1Settings()">Submit</button>

					<mat-divider></mat-divider>
					<mat-form-field class="primary" appearance="fill">
						<mat-label>X axis range</mat-label>
						<mat-date-range-input [rangePicker]="picker">
							<input matStartDate placeholder="Start date" #x1_m_total_filtration_time_s0_s1>
							<input matEndDate placeholder="End date" #x2_m_total_filtration_time_s0_s1>
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>

					<mat-form-field color="primary" appearance="fill">
						<mat-label>Min y axis</mat-label>
						<input matInput type="number" #y1_m_total_filtration_time_s0_s1>
					</mat-form-field>
					<mat-form-field color="primary" appearance="fill">
						<mat-label>Max y axis</mat-label>
						<input matInput type="number" #y2_m_total_filtration_time_s0_s1>
					</mat-form-field>
					<button mat-raised-button color="primary"
									*ngIf="x1_m_total_filtration_time_s0_s1.value && x2_m_total_filtration_time_s0_s1.value && y1_m_total_filtration_time_s0_s1.value && y2_m_total_filtration_time_s0_s1.value"
									matSuffix mat-icon-button (click)="modifyChart(m_total_filtration_time_s0_s1,
           [x1_m_total_filtration_time_s0_s1.value, x2_m_total_filtration_time_s0_s1.value], [y1_m_total_filtration_time_s0_s1.value, y2_m_total_filtration_time_s0_s1.value])">
						<mat-icon>send</mat-icon>
					</button>
				</mat-card>
-->
			</mat-card>
			<!--Temps de remplissage S0 [7][0]-->
			<mat-card class="machine-chart" *ngIf="checkForPermission()">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					Filling Time S0
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('Filling Time S0')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="m_fillTime_S0 && showMachineChart[7]">
					<plotly-plot
						[data]="m_fillTime_S0.chartData"
						[layout]="m_fillTime_S0.chartLayout"
						[config]="m_fillTime_S0.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!m_fillTime_S0 || !showMachineChart[7]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showMachineChart[7]= !showMachineChart[7]">
						<mat-icon *ngIf="showMachineChart[7]">remove</mat-icon>
						<mat-icon *ngIf="!showMachineChart[7]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv"
									(click)="exportToCsv('Filling Time S0')">
						<mat-icon>cloud_download</mat-icon>
					</button>
<!--
					<button mat-mini-fab color="primary" (click)="machineChartSettings[0]=!machineChartSettings[0]">
						<mat-icon *ngIf="machineChartSettings[0]">remove</mat-icon>
						<mat-icon *ngIf="!machineChartSettings[0]">format_list_bulleted</mat-icon>
					</button>
-->
				</mat-card-actions>
			</mat-card>
			<!--Threshold Fluorescence Graph -->
			<mat-card class="machine-chart" *ngIf="checkForPermission()">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					Threshold Fluorescence
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('Treshold Fluorescence Chart')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="m_treshold_fluorescence_chart && showMachineChart[1]">
					<plotly-plot
						[data]="m_treshold_fluorescence_chart.chartData"
						[layout]="m_treshold_fluorescence_chart.chartLayout"
						[config]="m_treshold_fluorescence_chart.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!m_treshold_fluorescence_chart || !showMachineChart[1]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showMachineChart[1]= !showMachineChart[1]">
						<mat-icon *ngIf="showMachineChart[1]">remove</mat-icon>
						<mat-icon *ngIf="!showMachineChart[1]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv" (click)="exportToCsv('Treshold Fluorescence Chart')">
						<mat-icon>cloud_download</mat-icon>
					</button>
				</mat-card-actions>
			</mat-card>
			<!--Minimum and Maximum Temperature-->
			<mat-card class="machine-chart" *ngIf="checkForPermission()">
				<mat-card-title fxLayout="row" fxLayoutAlign="space-between">
					Temperature minimum and maximum
					<button mat-mini-fab color="basic" (click)="openChartFullscreen('Temperature Chart')">
						<mat-icon>fullscreen</mat-icon>
					</button>
				</mat-card-title>
				<mat-divider></mat-divider>
				<div class="plotly-chart" *ngIf="m_temperature_min_max_chart && showMachineChart[1]">
					<plotly-plot
						[data]="m_temperature_min_max_chart.chartData"
						[layout]="m_temperature_min_max_chart.chartLayout"
						[config]="m_temperature_min_max_chart.chartConfig">
					</plotly-plot>
				</div>
				<div [class.spinner]="!m_temperature_min_max_chart || !showMachineChart[1]"></div>
				<mat-divider></mat-divider>
				<mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
					<button mat-mini-fab color="basic" (click)="showMachineChart[1]= !showMachineChart[1]">
						<mat-icon *ngIf="showMachineChart[1]">remove</mat-icon>
						<mat-icon *ngIf="!showMachineChart[1]">add</mat-icon>
					</button>
					<button mat-mini-fab color="primary" title="Download Csv" (click)="exportToCsv('Temperature Chart')">
						<mat-icon>cloud_download</mat-icon>
					</button>
				</mat-card-actions>
			</mat-card>
		</mat-card>
	</mat-card-content>
</mat-card>
<mat-divider></mat-divider>
<!--Machine TABLE -->
<mat-card class="machine-table">
	<mat-card-title fxLayoutAlign="space-between">
		<!--Historique des analyses de la machine {{machine?.machine_tag}}-->
		Machine analysis history {{machine?.machine_tag}}
		<button mat-mini-fab color="basic" (click)="showAnalysisHistory=!showAnalysisHistory">
			<mat-icon *ngIf="showAnalysisHistory">remove</mat-icon>
			<mat-icon *ngIf="!showAnalysisHistory">add</mat-icon>
		</button>
	</mat-card-title>
	<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

	<table mat-table *ngIf="detailedAnalysisHistory && showAnalysisHistory" [dataSource]="dataSource"
				 class="card-right-body-table" matSort (matSortChange)="sortData($event)">
		<ng-container matColumnDef="samplingpoint_name">
			<th mat-header-cell *matHeaderCellDef> Sampling point tag</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.sampling_name?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.sampling_name; else defaultValue">
					<a [routerLink]="['/dashboards/samplingPoint/', element.machine_spid.value]" class="analysis_id_ref">
						<button mat-button>
							{{element.sampling_name}}
						</button>
					</a>
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="analysis_id">
			<th mat-header-cell *matHeaderCellDef> Analysis id</th>
			<td mat-cell *matCellDef="let element; let i=index" [ngStyle]="
              !element.analysis_id?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.analysis_id; else defaultValue">
					<a [routerLink]="['/dashboards/analysis', element.analysis_id ]" class="analysis_id_ref">
						<button mat-button [disabled]="!checkForPermission()" [ngStyle]="
            !element.analysis_id?{'background-color': '#EEEEEE'}:
            element.missing_variable && element.missing_variable.length > 3?{'color':'#E0242F', 'background-color': '#EEEEEE'} :
            {'background-color': '#FFFFFF'}">
							{{element.analysis_id}}
						</button>
					</a>
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="sampling_time">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="sampling_time"> Sampling time</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.sampling_time?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.sampling_time; else defaultValue">
					{{element.sampling_time | date:'yyyy-MM-dd HH:mm'}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="analysis_time">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="analysis_time"> Analysis time</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.analysis_time?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.analysis_time; else defaultValue">
					{{element.analysis_time | date:'yyyy-MM-dd HH:mm'}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="inlet_num">
			<th mat-header-cell *matHeaderCellDef> Inlet number</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.machine_inlet_number?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.machine_inlet_number; else defaultValue">
					{{element.machine_inlet_number.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="cartouchesdisponibles">
			<th mat-header-cell *matHeaderCellDef> Cartridges available</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
          !element.cart_available?{'background-color': '#EEEEEE'}:
          element.cart_available.cl_level==0?{'background-color': '#FFFFFF'}:
          element.cart_available.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
          element.cart_available.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
          {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.cart_available; else defaultValue">
					{{element.cart_available.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="lotc01">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="lotc01"> Batch</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.cart_batch_number?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.cart_batch_number; else defaultValue">
					{{element.cart_batch_number.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="ug">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="ug"> UG/mL</th>
			<td mat-cell *matCellDef="let element; let i=index" [ngStyle]="
            !element.result_value_ug_rounded?{'background-color': '#EEEEEE'}:
            element.result_value_ug_rounded.cl_level==0?{'background-color': '#FFFFFF'}:
            element.result_value_ug_rounded.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
            element.result_value_ug_rounded.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
            {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.result_value_ug_rounded; else defaultValue">
          <span
						*ngIf="element.mrv_relation_symbol">{{convertASCIItoChar(element.mrv_relation_symbol.value)}}</span> {{element.result_value_ug_rounded.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="ug*">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="ug*"> UG*/mL</th>
			<td mat-cell *matCellDef="let element; let i=index" [ngStyle]="
            !element.result_ug_optimized_rounded ?{'background-color': '#EEEEEE'}:
            element.result_ug_optimized_rounded.cl_level==0 || element.result_ug_optimized_rounded.value == null?{'background-color': '#FFFFFF'}:
            element.result_ug_optimized_rounded.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
            element.result_ug_optimized_rounded.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
            {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.result_ug_optimized_rounded ; else defaultValue">
          {{element.result_value_ug_optimized.value < element.result_vmr.value && element.result_ug_optimized_rounded.value != null && element.result_ug_optimized_rounded.value != -1 ?'< ':''}} {{element.result_ug_optimized_rounded.value == Null?'-1':element.result_ug_optimized_rounded.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="ttm">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="ttm"> TTm</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_ttm?{'background-color': '#EEEEEE'}:
              element.result_ttm.cl_level==0?{'background-color': '#FFFFFF'}:
              element.result_ttm.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
              element.result_ttm.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.result_ttm; else defaultValue">
					{{element.result_ttm.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="vmr">
			<th mat-header-cell *matHeaderCellDef> VMR</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_vmr?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.result_vmr; else defaultValue">
					{{element.result_vmr.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="c1">
			<th mat-header-cell *matHeaderCellDef> C1</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.fluo_advanced_tt_w1?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.fluo_advanced_tt_w1; else defaultValue">
					{{element.fluo_advanced_tt_w1.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="v1">
			<th mat-header-cell *matHeaderCellDef> V1</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.fluo_advanced_tt_w2?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.fluo_advanced_tt_w2; else defaultValue">
					{{element.fluo_advanced_tt_w2.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="v2">
			<th mat-header-cell *matHeaderCellDef> V2</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.fluo_advanced_tt_w3?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.fluo_advanced_tt_w3; else defaultValue">
					{{element.fluo_advanced_tt_w3.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="c2">
			<th mat-header-cell *matHeaderCellDef> C2</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.fluo_advanced_tt_w4?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.fluo_advanced_tt_w4; else defaultValue">
					{{element.fluo_advanced_tt_w4.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="c1MinusC2">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="c1MinusC2"> C1-C2</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_delta_c1_c2?{'background-color': '#EEEEEE'}:
              element.result_delta_c1_c2.cl_level==0?{'background-color': '#FFFFFF'}:
              element.result_delta_c1_c2.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
              element.result_delta_c1_c2.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.fluo_advanced_tt_w1 && element.fluo_advanced_tt_w4; else defaultValue">
					{{Math.round(element.fluo_advanced_tt_w1.value - element.fluo_advanced_tt_w4.value)}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="v1MinusV2">
			<th mat-header-cell *matHeaderCellDef> V1-V2</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_delta_v1_v2?{'background-color': '#EEEEEE'}:
              element.result_delta_v1_v2.cl_level==0?{'background-color': '#FFFFFF'}:
              element.result_delta_v1_v2.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
              element.result_delta_v1_v2.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.fluo_advanced_tt_w2 && element.fluo_advanced_tt_w3; else defaultValue">
					{{Math.round(element.fluo_advanced_tt_w2.value - element.fluo_advanced_tt_w3.value)}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="calibration_success">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="calibration_success"> Calibration Success</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_calib_success?{'background-color': '#EEEEEE'}:
              element.result_calib_success.cl_level==0?{'background-color': '#FFFFFF'}:
              element.result_calib_success.cl_level % 2 != 0 ?{'background-color':'#F9D054'} :
              element.result_calib_success.cl_level % 2 == 0 ?{'background-color':'#E0242F'} :
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.result_calib_success; else defaultValue">
					{{element.result_calib_success.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="quality_score">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="quality_score"> Quality score</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.quality_score?{'background-color': '#EEEEEE'}:
              element.quality_score.value <= 50?{'background-color':'#E0242F'}:
              element.quality_score.value <= 75?{'background-color':'#FFA500'}:
              element.quality_score.value <= 85?{'background-color':'#F9D054'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.quality_score; else defaultValue">
					{{element.quality_score.value}}
				</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="error_code">
			<th mat-header-cell *matHeaderCellDef> Error</th>
			<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.result_error_code?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
				<ng-container *ngIf="element.result_error_code; else defaultValue">
					<button mat-raised-button
									matTooltip="{{element.error_desc}}"
									[matTooltipPosition]="'left'">
						{{element.result_error_code.value}}
					</button>
				</ng-container>

			</td>
		</ng-container>

		<ng-template #defaultValue>No Data</ng-template>
		<mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
	</table>
	<mat-paginator [length]="machine_nb_analysis" [pageSize]="10"
								 [pageSizeOptions]="[5, 10, 15, 20]" [ngStyle] = "{'visibility':(showAnalysisHistory)?'visible':'hidden'}" ></mat-paginator>
</mat-card>
<mat-divider></mat-divider>
<!--Machine Maintenance Data TABLE -->
<mat-card class="machine-table">
	<mat-card-title fxLayoutAlign="space-between">
		Machine maintenance data {{machine?.machine_tag}}
		<button mat-mini-fab color="basic" (click)="showMaintenanceData=!showMaintenanceData">
			<mat-icon *ngIf="showMaintenanceData">remove</mat-icon>
			<mat-icon *ngIf="!showMaintenanceData">add</mat-icon>
		</button>
	</mat-card-title>
	<mat-form-field appearance="fill" [formGroup]="machineMaintenanceForm">
		<mat-label style="color:rgb(0,0,0,0.6)">Next Maintenance estimation</mat-label>
		<input matInput placeholder="YYYY-MM-DD"  formControlName="nextMaintenance" [value] ="nextMaintenance">
	</mat-form-field>
	<mat-form-field appearance="fill" [formGroup]="machineMaintenanceForm">
		<mat-label style="color:rgb(0,0,0,0.6)">Last Maintenance</mat-label>
		<input matInput placeholder="YYYY-MM-DD"  formControlName="lastMaintenance" [value] ="lastMaintenace">
	</mat-form-field>
	<mat-form-field appearance="fill" [formGroup]="machineMaintenanceForm">
		<mat-label style="color:rgb(0,0,0,0.6)">Nb of analysis between maintenance</mat-label>
		<input matInput placeholder="---"  formControlName="nbAnalysisBeforeMaintenance" [value] ="nbAnalysisBeforeMaintenance">
	</mat-form-field>
	<mat-form-field appearance="fill" [formGroup]="machineMaintenanceForm">
		<mat-label style="color:rgb(0,0,0,0.6)">Nb of analysis since maintenance</mat-label>
		<input matInput placeholder="---"  formControlName="nbAnalysisSinceMaintenace" [value] ="nbAnalysisSinceMaintenace">
	</mat-form-field>
	<mat-form-field appearance="fill" [formGroup]="machineMaintenanceForm">
		<mat-label style="color:rgb(0,0,0,0.6)">Nb of days since maintenance</mat-label>
		<input matInput placeholder="---"  formControlName="nbDaysSinceMaintenance" [value] ="nbDaysSinceMaintenance">
	</mat-form-field>
</mat-card>
</body>
