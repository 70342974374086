export class Machine {

	public machine_id: number;
	public machine_tag: string;
	public nb_analysis: number;
	public state: string;
	public site_id: number;
	public access_level: number;
	public last_maintenance: any;
	public nb_analysis_before_maintenance: number;

	constructor(machine_id: number, machine_tag: string, nb_analysis: number, state: string, site_id: number, access_level: number, last_maintenance: string, nb_analysis_before_maintenance: number) {
		this.machine_id = machine_id;
		this.machine_tag = machine_tag;
		this.nb_analysis = nb_analysis;
		this.state = state;
		this.site_id = site_id;
		this.access_level = access_level;
		this.last_maintenance = last_maintenance;
		this.nb_analysis_before_maintenance = nb_analysis_before_maintenance;
	}
}
