<body>
	<div class="logginForm" fxLayout="column" fxLayoutAlign="center center">
		<div fxFlex="30">
			<img class="image" src="assets/img/RefonteLogo.png">
		</div>

		<div fxLayout="column" fxLayoutAlign="center center" fxFlex="30">
			<h1>Please sign in</h1>
			<!--<button mat-raised-button color=primary style="margin-bottom: 30px" *ngIf="!user" (click)="signInWithGoogle()">
                <mat-icon>login</mat-icon>
                Sign in
            </button>-->

			<div class="overlay" *ngIf="loadingLogin">
				<div class="overlay__wrapper">
					<div class="overlay__spinner">
						<mat-spinner></mat-spinner>
					</div>
				</div>
			</div>

			<mat-form-field class="input-headline" appearance="fill" style="width: 300px">
				<mat-label>Email</mat-label>
				<input class="input" type="email" matInput [(ngModel)]="email" (keyup.enter)="signInWithMicroService()">
			</mat-form-field>

			<mat-form-field class="input-headline" appearance="fill" style="width: 300px">
				<mat-label>Password</mat-label>
				<input class="input" type="password" matInput [(ngModel)]="password" (keyup.enter)="signInWithMicroService()">
			</mat-form-field>

			<button class="loginButton" mat-raised-button color=primary style="margin-bottom: 10px" *ngIf="!user" (click)="signInWithMicroService()">
				Sign in
			</button>
		</div>
	</div>
</body>

