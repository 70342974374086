export const ISO_DATE_FORMATS = {
	parse: {
		dateInput: 'YYYY-MM-DD'
	},
	display: {
		dateInput: 'YYYY-MM-DD',
		monthYearLabel: 'MMMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY'
	},
};
