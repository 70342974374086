import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
//import {NgxCsvParser, NgxCSVParserError} from 'ngx-csv-parser';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';

import {Machine} from '../Models/Machine';
import {Sample} from '../Models/Sample';
import {Analysis} from '../Models/Analysis';
import {ResultBioAlert} from '../Models/ResultBioAlert';
import {ImportFormsService} from './import-forms.service';
import {ResultLaboCulture} from '../Models/ResultLaboCulture';
import {ResultLaboQpcr} from '../Models/ResultLaboQpcr';
import {ResultLaboQlamp} from '../Models/ResultLaboQlamp';
import {ResultLaboAtp} from '../Models/ResultLaboAtp';
import {ImportTextDialogComponent} from './import-text-dialog/import-text-dialog.component';
import {LocalStorageService} from '../local-storage.service';
import {ApiMsg} from '../Models/ApiMsg';


@Component({
	selector: 'app-import-forms',
	templateUrl: './import-forms.component.html',
	styleUrls: ['./import-forms.component.css']
})

export class ImportFormsComponent implements OnInit {

	constructor(
		private importFormsService: ImportFormsService,
		//private ngxCsvParser: NgxCsvParser,
		private formBuilder: FormBuilder,
		private snackbar: MatSnackBar,
		public dialog: MatDialog,
		public localStorageService: LocalStorageService
	) {
	}

	machines: Machine;
	bioAlertFields: any[] = [];
	header = true;
	spId: number;
	miId: number;
	sample_id: number;
	analysis_id: number;
	machine_tag: string;
	machine_id: number;
	spName: string;
	bioAlertForm: FormGroup;
	cultureForm: FormGroup;
	qpcrForm: FormGroup;
	qlampForm: FormGroup;
	atpForm: FormGroup;
	isLinear = false;

	userEmail;

	businesses: any[] = [];
	sites: any[] = [];
	samplingPoints: any[] = [];

	selectedClients: string[] = [];
	selectedSites: string[] = [];
	selectedSamplingPoints: number[] = [];

	resultLaboCulture_id: number;
	resultLaboQpcr_id: number;
	resultLaboQlamp_id: number;
	resultLaboAtp_id: number;

	bioAlertTxt: string;

	machineIdIsValid = false;
	isLoading = false;
	isDuplicated: any;
	respStatus: any;

	invalidRow = false;

	YesNo = ['true', 'false'];

	vmrOptions = ['<', '='];

	labs = ['SPI Bio Inc.', 'Eurofins Environex', 'Genochemia', 'Lab\' eau air sol', 'Maxxam', 'Proventus', 'Other (See comment)'];

	standards = ['Correct standards', 'Out of the ordinary'];

	treatment_options = ['Thermal', 'Acid', 'Other'];

	treatment_val: number[] = [];

	/* #region FILE HANDLER */

	@ViewChild('fileInput', {static: false}) fileInput: ElementRef;

	ngOnInit(): void {

		this.userEmail = this.localStorageService.get('userEmail');

		this.importFormsService.getBusinesses(1000).subscribe(data => {
			this.businesses = data;
		});

		this.bioAlertForm = this.formBuilder.group({
			bioAlertRow: this.formBuilder.array([
				this.getBioAlertForm()
			])
		});

		this.cultureForm = this.formBuilder.group({
			cultureRow: this.formBuilder.array([
				this.getCultureForm()
			])
		});

		this.qpcrForm = this.formBuilder.group({
			qpcrRow: this.formBuilder.array([
				this.getQpcrForm()
			])
		});

		this.qlampForm = this.formBuilder.group({
			qlampRow: this.formBuilder.array([
				this.getQlampForm()
			])
		});

		this.atpForm = this.formBuilder.group({
			atpRow: this.formBuilder.array([
				this.getAtpForm()
			])
		});
	}

	fileChangeListener($event: any): void {
		this.isLoading = true;

		const files = $event.srcElement.files;
		this.header = (this.header as unknown as string) === 'true' || this.header === true;

		try {
			/*this.ngxCsvParser.parse(files[0], {header: this.header, delimiter: ','})
				.pipe().subscribe((result: Array<any>) => {

				let rowstart;
				rowstart = this.bioAlertFields.length;

				if (this.bioAlertFields.length == 0) {
					for (let i = 0; i < result.length - 1; i++) {
						this.addBioAlertRow();
					}
				} else {
					for (let i = 0; i < result.length; i++) {
						this.addBioAlertRow();
					}
				}

				for (let i = 0; i < result.length; i++) {
					Object.keys(result[i]).forEach(element => {
						if (element == 'No. Lot') {
							element = element.replace(/[. \t\r]+/g, '');
							result[i][element] = result[i]['No. Lot'];
							delete result[i]['No. Lot'];
						} else if (element == 'Cart.Disp.') {
							element = element.replace(/[. \t\r]+/g, '');
							result[i][element] = result[i]['Cart.Disp.'];
							delete result[i]['Cart.Disp.'];
						}
					});
				}

				result.forEach(element => {
					this.bioAlertFields.push(element);
				});

				this.updateBioAlertForm(rowstart, rowstart + result.length);
			}, (error: NgxCSVParserError) => {
				this.isLoading = false;
				this.openSnackBar('Error in csv file', 'Ok');
			});*/
		} catch {
			this.openSnackBar('Error in csv file', 'Ok');
			this.isLoading = false;
		}

	}

	parseTxtFile(txt: any, rowstart: number) {
		this.isLoading = true;

		try {
			const r1 = /(\d{1,2}[\/]\d{1,2}[\/]\d{4}) (\d{1,2}:\d{1,2}:\d{1,2})/g;
			const r2 = /(\w+) (:) (\d+(,\d{3})*(\.\d+)?)/g;

			const newstr = [];
			let tempstr;
			let tempstr2;

			tempstr = (txt.match(r1));

			tempstr.forEach(element => {
				const r = element.replace(/(\d{1,2})[\/](\d{1,2})[\/](\d{4}) (\d{1,2})(:\d{1,2}:\d{1,2})/,
					function(m, t1, t2, t3, t4, t5) {
						return t3 + '-' + t1.padStart(2, '0') + '-' + t2 + 'T' + t4.padStart(2, '0') + t5;
					});
				newstr.push(r);
			});
			tempstr2 = (txt.match(r2));

			tempstr2.forEach(element => {
				newstr.push(element);
			});

			this.bioAlertFields[rowstart] = [];

			const dateAndTime = newstr[0].split('T');
			const date = dateAndTime[0].split('-');
			const dateString = date[0] + '-' + (date[1].length<2 ? '0' + date[1]:date[1]) + '-' + (date[2].length<2 ? '0' + date[2]:date[2]);
			this.bioAlertFields[rowstart].Date =dateString;
			this.bioAlertFields[rowstart].Time = dateAndTime[1];

			newstr.forEach(row => {
				if (row.includes('DISPONNIBLE') || row.includes('DISPONIBLE')) { // Sometimes, there is a typo in the htm -> dispobible with two "n"
					const cartDisp = row.split(': ');
					this.bioAlertFields[rowstart].CartDisp = cartDisp[1].split('.')[0];
				} else if (row.includes('T1 :')) {
					const tt1 = row.split(': ');
					this.bioAlertFields[rowstart].TT1 = tt1[1].replace(',', '');
				} else if (row.includes('T2 :')) {
					const tt2 = row.split(': ');
					this.bioAlertFields[rowstart].TT2 = tt2[1].replace(',', '');
				} else if (row.includes('T3 :')) {
					const tt3 = row.split(': ');
					this.bioAlertFields[rowstart].TT3 = tt3[1].replace(',', '');
				} else if (row.includes('T4 :')) {
					const tt4 = row.split(': ');
					this.bioAlertFields[rowstart].TT4 = tt4[1].replace(',', '');
				} else if (row.includes('ALGO')) {
					const tl = row.split(': ');
					this.bioAlertFields[rowstart].TL = tl[1].replace(',', '');
				} else if (row.includes('ANALYSE')) {
					const ug = row.split(': ');
					this.bioAlertFields[rowstart].UG = ug[1];
				}
			});
			this.updateBioAlertForm(rowstart, rowstart + 1);
		} catch (error) {
			//Le fichier n'est pas valide
			this.openSnackBar('The file is not valid', 'Ok');
			this.isLoading = false;
		}
	}

	/* #endregion */

	/* #region API CALL */
	async getMiId(machine_id: number, noInlet: number) {
		this.miId = +(await this.importFormsService.getMiIdByMachineIdAndInletNum(machine_id, noInlet));
	}

	parseMachineId(id: any): string {
		this.machineIdIsValid = /^([0-9][.][0-9]{3})$/.test(id);
		if (this.machineIdIsValid) {
			const roundup = (Math.round(id * 1000) / 1000).toFixed(3).toString(); // WORKARROUND BECAUSE THE CSV SOMETIMES HAS 2.0069696969 AS UNIT NO FOR EXAMPLE
			const re = /2\.([0-9]{3})/i;
			return roundup.replace(re, 'B0$1');
		} else {
			return null;
		}
	}

	async checkDuplicatedSampleAndAnalysis(timestamp: string, spid: number, methodId: number) {
		const temp = (await this.importFormsService.checkDuplicatedSampleAndAnalysis(timestamp, spid, methodId));
		this.isDuplicated = temp;
	}

	async checkDuplicatedAnalysis(timestamp: string, machine_tag: string){
		const temp = (await this.importFormsService.checkIfAnalysisIsDuplicate(timestamp, machine_tag));
		this.isDuplicated = temp;
	}


	/* #endregion */

	/* #region  BIO ALERT FORM */

	private getBioAlertForm() {
		return this.formBuilder.group({
			date: ['', [Validators.required, Validators.pattern('[0-9]{4}-[0-9]{2}-[0-9]{2}')]],
			time: ['12:00:00', [Validators.required, Validators.pattern('[0-9]{2}:[0-9]{2}:[0-9]{2}')]],
			millisecond: [],
			sousVmr: [''],
			ug: [],
			niveau: ['', Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')],
			code: ['', Validators.pattern('[0-9]*$')],
			ttm: [],
			inletNo: ['', Validators.required],
			timestamp: [],
			tt1: ['', Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')],
			tt2: ['', Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')],
			tt3: ['', Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')],
			tt4: ['', Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')],
			tl: ['', Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')],
			cartDisp: ['', Validators.pattern('[0-9]*$')],
			siteNo: [],
			unitNo: ['', Validators.required],
			tempDebut: [],
			timestampOffset: [],
			ttc_a: ['', Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')],
			ttc_b: ['', Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')],
			ttc_c: ['', Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')],
			ttc_cal: ['', Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')],
			ttm_min: ['', Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')],
			ttm_max: ['', Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')],
			tt_diff_max: ['', Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')],
			ttc_diff_max: ['', Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')],
			noLot: ['', Validators.pattern('[0-9]*$')],
			vmr: ['', Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')],
			dateEchant: ['', Validators.pattern('[0-9]{4}-[0-9]{2}-[0-9]{2}')],
			heureEchant: ['12:00:00', Validators.pattern('[0-9]{2}:[0-9]{2}:[0-9]{2}')],
			commentaire: [null, Validators.maxLength(50)]
		});
	}

	updateBioAlertForm(rowstart: number, arraylength: number) {
		const control = this.bioAlertForm.controls.bioAlertRow as FormArray;

		for (let i = rowstart; i < arraylength; i++) {
			control.at(i).get('date').setValue(this.bioAlertFields[i].Date.replace(/[\/]/g, '-'));
			control.at(i).get('time').setValue(this.bioAlertFields[i].Time);
			control.at(i).get('millisecond').setValue(this.bioAlertFields[i].Millisecond);
			control.at(i).get('sousVmr').setValue(this.bioAlertFields[i].SousVMR);
			control.at(i).get('ug').setValue(this.bioAlertFields[i].UG);
			control.at(i).get('niveau').setValue(this.bioAlertFields[i].Niveau);
			control.at(i).get('code').setValue(this.bioAlertFields[i].Code);
			control.at(i).get('ttm').setValue(this.bioAlertFields[i].TTm);
			control.at(i).get('inletNo').setValue(this.bioAlertFields[i].InletNo);
			control.at(i).get('ug').setValue(this.bioAlertFields[i].UG);
			control.at(i).get('timestamp').setValue(this.bioAlertFields[i].Timestamp);
			control.at(i).get('tt1').setValue(this.bioAlertFields[i].TT1);
			control.at(i).get('tt2').setValue(this.bioAlertFields[i].TT2);
			control.at(i).get('tt3').setValue(this.bioAlertFields[i].TT3);
			control.at(i).get('tt4').setValue(this.bioAlertFields[i].TT4);
			control.at(i).get('tl').setValue(this.bioAlertFields[i].TL);
			control.at(i).get('cartDisp').setValue(this.bioAlertFields[i].CartDisp);
			control.at(i).get('siteNo').setValue(this.bioAlertFields[i].SiteNo);
			control.at(i).get('unitNo').setValue(this.bioAlertFields[i].UnitNo);
			control.at(i).get('tempDebut').setValue(this.bioAlertFields[i].TemperatureDebutAnalyse);
			control.at(i).get('timestampOffset').setValue(this.bioAlertFields[i].TimestampOffset);
			control.at(i).get('ttc_a').setValue(this.bioAlertFields[i].TTC_A);
			control.at(i).get('ttc_b').setValue(this.bioAlertFields[i].TTC_B);
			control.at(i).get('ttc_c').setValue(this.bioAlertFields[i].TTC_C);
			control.at(i).get('ttc_cal').setValue(this.bioAlertFields[i].TTC_CAL);
			control.at(i).get('ttm_min').setValue(this.bioAlertFields[i].TTm_min);
			control.at(i).get('ttm_max').setValue(this.bioAlertFields[i].TTm_max);
			control.at(i).get('tt_diff_max').setValue(this.bioAlertFields[i].TT_diff_max);
			control.at(i).get('ttc_diff_max').setValue(this.bioAlertFields[i].TTC_diff_max);
			control.at(i).get('noLot').setValue(this.bioAlertFields[i].NoLot);
			control.at(i).get('vmr').setValue(this.bioAlertFields[i].VMR);
		}
		this.isLoading = false;
	}



	async uploadBioAlertForm() {
		const control = this.bioAlertForm.controls.bioAlertRow as FormArray;
		let i = 0;
		if (this.bioAlertForm.valid) {
			try {

				for (const element of control.value) {

					this.machine_tag = this.parseMachineId(element.unitNo);
					this.machine_id = await this.importFormsService.getMachineIdByMachineTag(this.machine_tag);

					if (this.machineIdIsValid) {

						await this.getMiId(this.machine_id, element.inletNo);

						let machine_timestamp;
						let result_sampling_time;

						if (element.millisecond != '' && element.millisecond != null) {
							machine_timestamp = element.date + 'T' + element.time + '.' + element.millisecond;
						} else {
							machine_timestamp = element.date + 'T' + element.time + '.000';
						}

						if (element.dateEchant != '' && element.dateEchant != null) {
							result_sampling_time = element.dateEchant + ' ' + element.heureEchant;
						} else {
							result_sampling_time = null;
						}
						await this.checkDuplicatedAnalysis(machine_timestamp, this.machine_tag);
						if (this.isDuplicated.exists == false) {

							if (element.sousVmr == 0) {
								element.sousVmr = null;
							}

							await this.createResultBioAlert(this.machine_tag, element.inletNo, machine_timestamp, result_sampling_time,
								element.niveau, element.vmr, element.ttm, element.code, element.tt1, element.tt2, element.tt3, element.tt4, element.tl,
								element.cartDisp, element.noLot, element.ttc_a, element.ttc_b, element.ttc_c, element.ttc_cal, element.ug, element.sousVmr,
								element.ttc_diff_max, element.tt_diff_max, element.ttm_max, element.ttm_min, this.generateComment());

							if (this.respStatus.status == 200) {
								//Formulaires correctement envoyés à l'exception des formulaires restants.
								this.openSnackBar('Forms correctly sent with the exception of the remaining forms.', 'Ok');
								this.removeBioAlertRow(i);
								i--;
							} else if (this.respStatus.status != 200) {
								if (this.respStatus.status == 500) {
									//Cette analyses existe déjà dans la base de données.
									this.openSnackBar('This analysis already exist in the database', 'Ok');
								} else {
									//Une erreur est survenue lors de l'insertion de l'analyse. Code:
									this.openSnackBar('An error occurred while creating the analysis. Code: ' + this.respStatus, 'Ok');
								}
							}
						} else {
							control.at(i).setErrors({duplicated: true});
							//Cette analyse se trouve déjà dans la base de données.
							this.openSnackBar('This analysis already exist in the database', 'Ok');
						}
					} else {
						control.at(i).setErrors({badUnitNumberFormat: true});
						//Veuillez entrer 'Unit No.' valide (2.00XX).
						this.openSnackBar('Please enter a valid \'Unit No.\' (2.0XX).', 'Ok');
					}
					i++;
				}
				this.isLoading = false;
			} catch (e) {
				this.openSnackBar('An error occurred while creating the analysis. Code: ' + e.status, 'Ok');
			}
		} else {
			//Veuillez remplir les champs correctement.
			this.openSnackBar('Please fill the fields correctly.', 'Ok');
		}
		this.isLoading = false;
		this.clearAllBioAlertRow();
	}

	clearBioAlertForm(): void {
		this.fileInput.nativeElement.value = null;
		this.bioAlertForm.reset();
		this.bioAlertFields = [];
	}

	addBioAlertRow(): number {
		const control = this.bioAlertForm.controls.bioAlertRow as FormArray;
		control.push(this.getBioAlertForm());
		return control.length;
	}

	addBioAlertFromButtonRow(): number {
		const control = this.bioAlertForm.controls.bioAlertRow as FormArray;
		control.push(this.getBioAlertForm());
		this.bioAlertFields[control.length - 1] = [];
		return control.length;
	}

	removeBioAlertRow(i: number) {
		const control = this.bioAlertForm.controls.bioAlertRow as FormArray;
		control.removeAt(i);
		this.bioAlertFields.splice(i, 1);
	}

	clearAllBioAlertRow() {
		const control = this.bioAlertForm.controls.bioAlertRow as FormArray;
		while (control.length) {
			control.removeAt(control.length - 1);
		}
		control.clearValidators();
		control.push(this.getBioAlertForm());
		this.bioAlertFields = [];
	}

	clearSpecificBioAlertRow(rowToSave: number []) {
		const control = this.bioAlertForm.controls.bioAlertRow as FormArray;

		let y = control.length;
		while (y) {
			if (rowToSave.includes(y)) {
				y--;
			} else {
				control.removeAt(y - 1);
			}
		}
	}

	/* #endregion */

	/* #region Qlamp FORM */

	private getQlampForm() {
		return this.formBuilder.group({
			businessTag: [this.businesses, Validators.required],
			siteTag: ['', Validators.required],
			spId: ['', Validators.required],
			datePrelevement: ['', [Validators.required, Validators.pattern('[0-9]{4}-[0-9]{2}-[0-9]{2}')]],
			timePrelevement: ['12:00:00', [Validators.required, Validators.pattern('[0-9]{2}:[0-9]{2}:[0-9]{2}')]],
			dateAnalyse: ['', [Validators.required, Validators.pattern('[0-9]{4}-[0-9]{2}-[0-9]{2}')]],
			heureAnalyse: ['12:00:00', [Validators.required, Validators.pattern('[0-9]{2}:[0-9]{2}:[0-9]{2}')]],
			labName: ['SPI Bio Inc.', Validators.required],
			analysisType: ['BA-LP v10.0', Validators.required],
			vmr_result: ['', Validators.required],
			result: ['', [Validators.required, Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')]],
			commentaire: [''],
		});
	}

	addQlampRow() {
		const control = this.qlampForm.controls.qlampRow as FormArray;
		control.push(this.getQlampForm());
	}

	removeQlampRow(i: number) {
		const control = this.qlampForm.controls.qlampRow as FormArray;
		control.removeAt(i);
	}

	clearAllQlampRow() {
		const control = this.qlampForm.controls.qlampRow as FormArray;
		while (control.length) {
			control.removeAt(control.length - 1);
		}
		control.clearValidators();
		control.push(this.getQlampForm());
	}

	async uploadQlampForm() {
		this.isLoading = true;
		const control = this.qlampForm.controls.qlampRow as FormArray;
		if (this.qlampForm.valid) {
			try {
				let i = 0;
				for (const element of control.value) {
					const sampling_time = (element.datePrelevement + 'T' + element.timePrelevement);
					const date_anal = (element.dateAnalyse + ' ' + element.heureAnalyse);
					const analysis_time = (element.dateAnalyse + 'T' + element.heureAnalyse);
					const sp_id = this.samplingPoints.find(x => x.sp_tag === element.spId).sp_id;
					await this.checkDuplicatedSampleAndAnalysis(date_anal, sp_id, 5);

					if (this.isDuplicated.value === 'not duplicated') {
						await this.createSampleFromInletAndSite(
							sampling_time,
							sp_id
						);
						await this.createAnalysis(
							this.sample_id,
							5,
							analysis_time,
						);

						await this.createResultLaboQlamp(
							this.analysis_id,
							element.analysisType,
							element.result,
							this.generateComment(),
							element.vmr_result,
						);


						this.removeQlampRow(i);
						i--;

					} else if (this.isDuplicated.value == 'duplicated') {
						control.at(i).setErrors({duplicated: true});
					}
					i++;
				}

				this.openSnackBar('Forms correctly sent with the exception of the remaining forms.', 'Ok');

				this.isLoading = false;

			} catch {
				this.openSnackBar('Please fill the fields correctly.', 'Ok');
			}
		} else {
			this.openSnackBar('Please fill the fields correctly.', 'Ok');
		}
		this.isLoading = false;
		this.clearAllQlampRow();
	}

	/* #endregion */

	/* #region QPCR FORM */

	private getQpcrForm() {
		return this.formBuilder.group({
			businessTag: [this.businesses, Validators.required],
			siteTag: ['', Validators.required],
			spId: ['', Validators.required],
			datePrelevement: ['', [Validators.required, Validators.pattern('[0-9]{4}-[0-9]{2}-[0-9]{2}')]],
			timePrelevement: ['12:00:00', [Validators.required, Validators.pattern('[0-9]{2}:[0-9]{2}:[0-9]{2}')]],
			dateAnalyse: ['', [Validators.required, Validators.pattern('[0-9]{4}-[0-9]{2}-[0-9]{2}')]],
			heureAnalyse: ['12:00:00', [Validators.required, Validators.pattern('[0-9]{2}:[0-9]{2}:[0-9]{2}')]],
			labName: ['SPI Bio Inc.', Validators.required],
			vmr_sg1_15: ['', Validators.required],
			sg1_15: ['', [Validators.required, Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')]],
			vmr_sg1: ['', Validators.required],
			sg1: ['', [Validators.required, Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')]],
			commentaire: [''],
		});
	}

	addQpcrRow() {
		const control = this.qpcrForm.controls.qpcrRow as FormArray;
		control.push(this.getQpcrForm());
	}

	removeQpcrRow(i: number) {
		const control = this.qpcrForm.controls.qpcrRow as FormArray;
		control.removeAt(i);
	}

	clearAllQpcrRow() {
		const control = this.qpcrForm.controls.qpcrRow as FormArray;
		while (control.length) {
			control.removeAt(control.length - 1);
		}
		control.clearValidators();
		control.push(this.getQpcrForm());
	}

	async uploadQpcrForm() {
		this.isLoading = true;
		const control = this.qpcrForm.controls.qpcrRow as FormArray;

		if (this.qpcrForm.valid) {
			try {
				let i = 0;
				for (const element of control.value) {
					const sampling_time = (element.datePrelevement + 'T' + element.timePrelevement);
					const date_anal = (element.dateAnalyse + ' ' + element.heureAnalyse);
					const analysis_time = (element.dateAnalyse + 'T' + element.heureAnalyse);
					const sp_id = this.samplingPoints.find(x => x.sp_tag == element.spId).sp_id;

					await this.checkDuplicatedSampleAndAnalysis(date_anal, sp_id, 3);

					if (this.isDuplicated.value == 'not duplicated') {
						await this.createSampleFromInletAndSite(
							sampling_time,
							sp_id
						);

						await this.createAnalysis(
							this.sample_id,
							3,
							analysis_time
						);
						await this.createResultLaboQpcr(
							this.analysis_id,
							element.vmr_sg1_15,
							element.sg1_15,
							element.vmr_sg1,
							element.sg1,
							// element.vmr_sg2_15,
							// element.sg2_15,
							this.generateComment()
						);

						this.removeQpcrRow(i);
						i--;
					} else if (this.isDuplicated.value == 'duplicated') {
						control.at(i).setErrors({duplicated: true});
					}


					i++;
				}

				this.isLoading = false;
				this.openSnackBar('Forms correctly sent with the exception of the remaining forms.', 'Ok');
			} catch {
				this.openSnackBar('Please fill the fields correctly.', 'Ok');
			}
		} else {
			this.openSnackBar('Please fill the fields correctly.', 'Ok');
		}
		this.isLoading = false;
		this.clearAllQpcrRow();
	}


	/* #endregion */

	/* #region CULTURE FORM */

	private getCultureForm() {
		return this.formBuilder.group({
			businessTag: [this.businesses, Validators.required],
			siteTag: ['', Validators.required],
			spId: ['', Validators.required],
			datePrelevement: ['', [Validators.required, Validators.pattern('[0-9]{4}-[0-9]{2}-[0-9]{2}')]],
			timePrelevement: ['12:00:00', [Validators.required, Validators.pattern('[0-9]{2}:[0-9]{2}:[0-9]{2}')]],
			dateAnalyse: ['', [Validators.required, Validators.pattern('[0-9]{4}-[0-9]{2}-[0-9]{2}')]],
			heureAnalyse: ['12:00:00', [Validators.required, Validators.pattern('[0-9]{2}:[0-9]{2}:[0-9]{2}')]],
			labName: ['SPI Bio Inc.', Validators.required],
			rbq: ['false', Validators.required],
			treatment: ['false', Validators.required],
			treatment_desc: [''],
			vmr_spp: ['', Validators.required],
			spp: ['', [Validators.required, Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')]],
			vmr_sg1_15: ['', Validators.required],
			sg1_15: ['', [Validators.required, Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')]],
			vmr_sg1: ['', Validators.required],
			sg1: ['', [Validators.required, Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')]],
			vmr_sg2_15: ['', Validators.required],
			sg2_15: ['', [Validators.required, Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')]],
			commentaire: [''],
		});
	}

	addCultureRow() {
		const control = this.cultureForm.controls.cultureRow as FormArray;
		control.push(this.getCultureForm());
	}

	removeCultureRow(i: number) {
		const control = this.cultureForm.controls.cultureRow as FormArray;
		control.removeAt(i);
		this.selectedClients.splice(i, 1);
	}

	clearAllCultureRow() {
		const control = this.cultureForm.controls.cultureRow as FormArray;
		while (control.length) {
			control.removeAt(control.length - 1);
		}
		control.clearValidators();
		control.push(this.getCultureForm());
		this.selectedClients = [];
		this.samplingPoints = [];
		this.sites = [];
		this.treatment_val = [];
	}

	async uploadCultureForm() {
		this.isLoading = true;
		const control = this.cultureForm.controls.cultureRow as FormArray;

		if (this.cultureForm.valid) {
			try {
				let i = 0;
				for (const element of control.value) {
					const sampling_time = (element.datePrelevement + 'T' + element.timePrelevement);
					const date_anal = (element.dateAnalyse + ' ' + element.heureAnalyse);
					const sp_id = this.samplingPoints.find(x => x.sp_tag == element.spId).sp_id;
					const analysis_time = (element.dateAnalyse + 'T' + element.heureAnalyse);
					await this.checkDuplicatedSampleAndAnalysis(date_anal, sp_id, 4);

					if (this.isDuplicated.value == 'not duplicated') {
						await this.createSampleFromInletAndSite(
							sampling_time,
							sp_id
						);
						await this.createAnalysis(
							this.sample_id,
							4,
							analysis_time
						);
						await this.createResultLaboCulture(
							this.analysis_id,
							element.rbq,
							element.treatment,
							element.vmr_spp,
							element.spp,
							element.vmr_sg1_15,
							element.sg1_15,
							element.vmr_sg1,
							element.sg1,
							element.vmr_sg2_15,
							element.sg2_15,
							this.generateComment(),
							element.treatment_desc
						);

						this.removeCultureRow(i);
						i--;
					} else if (this.isDuplicated.value == 'duplicated') {
						control.at(i).setErrors({duplicated: true});
					}

					i++;
				}
				this.isLoading = false;
				this.openSnackBar('Forms correctly sent with the exception of the remaining forms.', 'Ok');
			} catch {
				this.openSnackBar('Please fill the fields correctly.', 'Ok');
			}
		} else {
			this.openSnackBar('Please fill the fields correctly.', 'Ok');
		}
		this.isLoading = false;
		this.clearAllCultureRow();
	}


	/* #endregion */

	/* #region ATP Form */

	private getAtpForm() {
		return this.formBuilder.group({
			businessTag: [this.businesses, Validators.required],
			siteTag: ['', Validators.required],
			spId: ['', Validators.required],
			datePrelevement: ['', [Validators.required, Validators.pattern('[0-9]{4}-[0-9]{2}-[0-9]{2}')]],
			timePrelevement: ['12:00:00', [Validators.required, Validators.pattern('[0-9]{2}:[0-9]{2}:[0-9]{2}')]],
			dateAnalyse: ['', [Validators.required, Validators.pattern('[0-9]{4}-[0-9]{2}-[0-9]{2}')]],
			heureAnalyse: ['12:00:00', [Validators.required, Validators.pattern('[0-9]{2}:[0-9]{2}:[0-9]{2}')]],
			labName: ['SPI Bio Inc.', Validators.required],
			company: ['AquaSnap', Validators.required],
			free: ['', [Validators.required, Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')]],
			total: ['', [Validators.required, Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')]],
			bacterial: ['', [Validators.required, Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')]],
			equivalent: ['', [Validators.required, Validators.pattern('[-]?[0-9]+(\\.[0-9]+)?$')]],
			standard: ['Dans les normes', Validators.required],
			commentaire: [''],
		});
	}

	addAtpRow() {
		const control = this.atpForm.controls.atpRow as FormArray;
		control.push(this.getAtpForm());
	}

	removeAtpRow(i: number) {
		const control = this.atpForm.controls.atpRow as FormArray;
		control.removeAt(i);
		this.selectedClients.splice(i, 1);
	}

	clearAllAtpRow() {
		const control = this.atpForm.controls.atpRow as FormArray;
		while (control.length) {
			control.removeAt(control.length - 1);
		}
		control.clearValidators();
		control.push(this.getAtpForm());
	}

	async uploadAtpForm() {
		this.isLoading = true;
		const control = this.atpForm.controls.atpRow as FormArray;

		if (this.atpForm.valid) {
			try {
				let i = 0;
				for (const element of control.value) {
					const sampling_time = (element.datePrelevement + 'T' + element.timePrelevement);
					const date_anal = (element.dateAnalyse + ' ' + element.heureAnalyse);
					const analysis_time = (element.dateAnalyse + 'T' + element.heureAnalyse);
					const sp_id = this.samplingPoints.find(x => x.sp_tag == element.spId).sp_id;

					await this.checkDuplicatedSampleAndAnalysis(date_anal, sp_id, 2);

					if (this.isDuplicated.value == 'not duplicated') {
						await this.createSampleFromInletAndSite(
							sampling_time,
							sp_id
						);
						await this.createAnalysis(
							this.sample_id,
							2,
							analysis_time
						);
						await this.createResultLaboAtp(
							this.analysis_id,
							element.company,
							element.free,
							element.total,
							element.bacterial,
							element.equivalent,
							element.standard,
							this.generateComment()
						);

						this.removeAtpRow(i);
						i--;
					} else if (this.isDuplicated.value == 'duplicated') {
						control.at(i).setErrors({duplicated: true});
					}
					i++;
				}
				this.isLoading = false;
				this.openSnackBar('Forms correctly sent with the exception of the remaining forms.', 'Ok');
			} catch {
				this.openSnackBar('Please fill the fields correctly.', 'Ok');
			}
		} else {
			this.openSnackBar('Please fill the fields correctly.', 'Ok');
		}
		this.isLoading = false;
		this.clearAllAtpRow();
	}

	/* #endregion */

	/* #region UX COMPONENT */

	openSnackBar(message, action) {
		this.snackbar.open(message, action, {duration: 5000});
	}

	openDialog(): void {
		const dialogRef = this.dialog.open(ImportTextDialogComponent, {
			width: '250px',
			// data: { txt: '' }
		});

		try {
			dialogRef.afterClosed().subscribe(result => {
				this.bioAlertTxt = result;

				const control = this.bioAlertForm.controls.bioAlertRow as FormArray;

				if (control.at(0).invalid) {
					this.parseTxtFile(result, 0);
				} else {
					const i = this.addBioAlertRow() - 1;
					this.parseTxtFile(result, i);
				}
			});
		} catch {
			this.openSnackBar('The file is not valid', 'Ok');
		}

	}

	/* #endregion */

	/* #region CONTROLLERS */
	treatmentSelectionChanged($event, i: number) {
		this.treatment_val[i] = $event.value;
	}

	clientSelectedChanged($event, i: number, formgroup: string) {
		try {
			this.importFormsService.getSitesByBusinessId($event.value, 1000).subscribe(data => {
				if (data.length != 0) {
					this.sites = data;
					try {
						if (formgroup == 'qlamp') {
							const control = this.qlampForm.controls.qlampRow as FormArray;
							control.at(i).get('siteTag').setValue(this.sites[0].site_id);

						} else if (formgroup == 'qpcr') {
							const control = this.qpcrForm.controls.qpcrRow as FormArray;
							control.at(i).get('siteTag').setValue(this.sites[0].site_id);

						} else if (formgroup == 'culture') {
							const control = this.cultureForm.controls.cultureRow as FormArray;
							control.at(i).get('siteTag').setValue(this.sites[0].site_id);

						} else if (formgroup == 'atp') {
							const control = this.atpForm.controls.atpRow as FormArray;
							control.at(i).get('siteTag').setValue(this.sites[0].site_id);
						}
						this.sitePreSelectionChanged(this.sites[0].site_id, i, formgroup);
					} catch {
						this.openSnackBar('Error in the preselection of the site, please select it manually', 'Ok');
					}
				} else {
					this.openSnackBar('This client does not contain any sites', 'Ok');
				}
			});
		} catch {
			this.openSnackBar('Error in the acquisition of the site', 'Ok');
		}

	}

	sitePreSelectionChanged(site: string, i: number, formgroup: string) {
		try {
			this.importFormsService.getSamplingPointBySiteId(site, 1000).subscribe(data => {
				if (data.length != 0) {
					this.samplingPoints = data;
					try {
						if (formgroup == 'qlamp') {
							const control = this.qlampForm.controls.qlampRow as FormArray;
							control.at(i).get('spId').setValue(this.samplingPoints[0].sp_tag);

						} else if (formgroup == 'qpcr') {
							const control = this.qpcrForm.controls.qpcrRow as FormArray;
							control.at(i).get('spId').setValue(this.samplingPoints[0].sp_tag);

						} else if (formgroup == 'culture') {
							const control = this.cultureForm.controls.cultureRow as FormArray;
							control.at(i).get('spId').setValue(this.samplingPoints[0].sp_tag);

						} else if (formgroup == 'atp') {
							const control = this.atpForm.controls.atpRow as FormArray;
							control.at(i).get('spId').setValue(this.samplingPoints[0].sp_tag);
						}
					} catch {
						this.openSnackBar('Error in the preselection of the site, please select it manually', 'Ok');
					}
				} else {
					this.openSnackBar('This site does not contain any sampling point', 'Ok');
				}
			});
		} catch {
			this.openSnackBar('Error in the acquisition of sampling points', 'Ok');
		}

	}

	sitesSelectedChanged($event, i: number) {
		try {
			this.importFormsService.getSamplingPointBySiteId($event.value, 1000).subscribe(data => {
				if (data.length != 0) {
					this.samplingPoints = data;
				} else {
					this.openSnackBar('This site does not contain any sampling point', 'Ok');
				}
			});
		} catch {
			this.openSnackBar('Error in the acquisition of sampling points', 'Ok');
		}
	}

	async createSampleFromInletAndSite(sampling_time: any, sp_id: number): Promise<void> {

		const sample = new Sample(
			null,
			sp_id,
			sampling_time
		);
		this.sample_id = await this.importFormsService.insertSample(sample);
	}

	async createAnalysis(sample_id: any, methodeId: number, analysis_time: any) {
		const analysis = new Analysis(
			null,
			sample_id.sample_id,
			methodeId,
			analysis_time,
			null,
			null
		);
		this.analysis_id = await this.importFormsService.insertAnalysis(analysis);
	}

	async createResultBioAlert(machine_tag: string, machine_inlet_number: number, machine_timestamp: string, result_sampling_time: string,
														              result_risk_level: number, result_vmr: number,
														              result_ttm: number, result_error_code: number, result_tt1: number, result_tt2: number, result_tt3: number,
														              result_tt4: number, result_tl: number, cart_available: number,
														              cart_batch_number: number, cart_ttc_a: number, cart_ttc_b: number, cart_ttc_c: number, cart_ttc_cal: number,
														              result_value_ug_rounded: number, mrv_relation_symbol: string,
														              ttc_diff_max: number, tt_diff_max: number, ttm_max: number, ttm_min: number, commentaire: string) {

		let sousvmr_charcode;

		if (mrv_relation_symbol == null || mrv_relation_symbol == '') {
			sousvmr_charcode = 32;
		} else {
			sousvmr_charcode = mrv_relation_symbol.charCodeAt(0);
		}

		const result = new ResultBioAlert(
			machine_tag,
			machine_inlet_number,
			machine_timestamp,
			result_sampling_time,
			result_risk_level,
			result_vmr,
			result_ttm,
			result_error_code,
			result_tt1,
			result_tt2,
			result_tt3,
			result_tt4,
			result_tl,
			cart_available,
			cart_batch_number,
			cart_ttc_a,
			cart_ttc_b,
			cart_ttc_c,
			cart_ttc_cal,
			result_value_ug_rounded,
			sousvmr_charcode,
			ttc_diff_max,
			tt_diff_max,
			ttm_max,
			ttm_min,
			commentaire
		);
		this.respStatus = await this.importFormsService.insertResultBioAlert(result);
	}

	async createResultLaboCulture(analysis_id: any, rbq: boolean, treatment: boolean, vmr_spp: string, spp: number, vmr_sg1_15: string,
																               sg1_15: number, vmr_sg1: string, sg1: number, vmr_sg2_15: string, sg2_15: number, comment: string, treatment_desc: string) {

		const result = new ResultLaboCulture(
			analysis_id.analysis_id,
			rbq,
			treatment,
			vmr_spp,
			spp,
			vmr_sg1_15,
			sg1_15,
			vmr_sg1,
			sg1,
			vmr_sg2_15,
			sg2_15,
			comment,
			treatment_desc
		);
		this.importFormsService.insertResultLaboCulture(result).subscribe(
			res => {
				this.resultLaboCulture_id = res;
			}
		);
	}

	async createResultLaboQpcr(analysis_id: any, vmr_sg1_15: string,
														              sg1_15: number, vmr_sg1: string, sg1: number, comment: string) {

		const result = new ResultLaboQpcr(
			analysis_id.analysis_id,
			vmr_sg1_15,
			sg1_15,
			vmr_sg1,
			sg1,
			comment
		);
		this.importFormsService.insertResultLaboQpcr(result).subscribe(
			res => {
				this.resultLaboQpcr_id = res;
			}
		);
	}

	async createResultLaboQlamp(analysis_id: any, analysis_type: string, result: number, comment: string, vmr_result: string) {

		const r = new ResultLaboQlamp(
			analysis_id.analysis_id,
			analysis_type,
			result,
			comment,
			vmr_result
		);
		this.importFormsService.insertResultLaboQlamp(r).subscribe(
			res => {
				this.resultLaboQlamp_id = res;
			}
		);


	}

	async createResultLaboAtp(analysis_id: any, company_name: string, free: number, total: number, bacterial: number,
														             equivalent: number, standard: string, comment: string) {

		const r = new ResultLaboAtp(
			analysis_id.analysis_id,
			company_name,
			free,
			total,
			bacterial,
			equivalent,
			standard,
			comment
		);
		this.importFormsService.insertResultLaboAtp(r).subscribe(
			res => {
				this.resultLaboAtp_id = res;
			}
		);
	}

	/* #endregion */
	private generateComment() {
		const d = new Date();
		return 'Form insertion by ' + this.userEmail + ' on ' + d.toISOString();
	}
}
