import {Injectable} from "@angular/core";
import {
	HTTP_INTERCEPTORS,
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from "@angular/common/http";
import {CookieServiceService} from "./cookie-service.service";
import {Observable, throwError} from "rxjs";
import {catchError, finalize, retry} from "rxjs/operators";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MdLoginPopupComponentComponent} from "./login/md-login-popup.component";
import {LocalStorageService} from "./local-storage.service";
import {MatDialog} from "@angular/material/dialog";
import {SocialUser} from "angularx-social-login";



@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private cookieServices: CookieServiceService, private router: Router, private snackbar: MatSnackBar, public localStorageService: LocalStorageService, public dialog: MatDialog) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		request = request.clone({
			headers: request.headers.set('Authorization',
				'Bearer ' + this.cookieServices.get('token'))
		});
		return next.handle(request).pipe(
			catchError(err => {
				if (err instanceof HttpErrorResponse){

					if (err.status === 401 || err.status === 403){
						this.snackbar.open('Non autorisé, veuillez-vous reconnecter ' + err.message, 'Ok');
						this.localStorageService.set('current_url', window.location.pathname)
						//prevent multiple popup opening if multiple request failed
						if (this.dialog.openDialogs.length == 0){
							this.dialog.open(MdLoginPopupComponentComponent, {
								disableClose: true
							});
						}

					}
					// return the error back to the caller
					return throwError(err);
				}
			}), finalize(() => {
				//clean up
			})
		);
	}
}

export const AuthInterceptorProvider = {
	provide : HTTP_INTERCEPTORS,
	useClass: AuthInterceptor,
	multi: true,
};

