// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	machineUrl: 'https://api.bioalert.io/dev',
	clientUrl: 'https://api.bioalert.io/dev',
	adminUrl: 'https://api.bioalert.io/dev',
	analysisUrl: 'https://api.bioalert.io/dev',
	notificationUrl: 'https://api.bioalert.io/dev',
	authUrl: 'https://api.bioalert.io/dev'
};

// Example environment for local development (over-write the "environment")
// Configure as needed
// DO NOT COMMIT CHANGES
export const environmentLocal = {
	production: false,
	machineUrl: 'http://localhost:8080',
	clientUrl: 'http://localhost:8084',
	adminUrl: 'https://api.bioalert.io',
	analysisUrl: 'http://localhost:9000',
	notificationUrl: 'https://api.bioalert.io/dev',
	authUrl: 'https://api.bioalert.io'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
