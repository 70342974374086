<body>
<mat-card class="main-card">
    <div class="main-container" fxLayout="column">
        <div fxLayoutAlign="space-between">
            <div>
                <mat-card-title>Roles
                    <!-- The button is only used for alignment (with the other settings pages) -->
                    <button style="visibility: hidden" mat-button>
                        <mat-icon>help_outline</mat-icon>
                    </button>
                </mat-card-title>
            </div>
        </div>
        <mat-divider class="title-divider"></mat-divider>

        <div fxLayout="column" fxFlex="50%">
            <mat-drawer-container class="drawer-container">
                <div class="drawer-sidenav-content">

                    <mat-card class="limits-container">

                        <div class="overlay" *ngIf="isLoading">
                            <div class="overlay__wrapper">
                                <div class="overlay__spinner">
                                    <button mat-raised-button color="primary" class="spinner"></button>
                                </div>
                            </div>
                        </div>

                        <div fxLayout="column" fxFlex="100%">
                            <mat-nav-list [style]="{'background':'white'}">
                                <mat-divider></mat-divider>
                                <mat-list-item *ngFor="let role of roleList;  let i = index"
                                               (click)="drawer.opened && role.role_id == oldRole?.role_id && formState == 'update' ?
											    drawer.close() : drawer.open(); setFormUpdateRole(role); onSelected(role.role_name);"
                                               [style]="selected == role.role_name ? {'background':'#DDDDDD'}:{'background':'white'}"
                                               matTooltip="Modify Role." matTooltipPosition="above" matTooltipShowDelay="500">

                                    <div class="role-description">
                                        <div mat-line>
                                            <div class="role-description" style="width: 300px;">
                                                {{role.role_name}}
                                            </div>
                                            <div class="role-description" style="width: 300px;">
                                                {{role.role_description}}
                                            </div>

                                            <div class="role-description">
                                                {{role.business_tag}}
                                            </div>
                                        </div>
                                    </div>
                                    <mat-icon style="margin-left: auto;">edit</mat-icon>
                                    <mat-divider></mat-divider>
                                </mat-list-item>
                            </mat-nav-list>
                        </div>
                    </mat-card>
                    <button class="button-add" mat-button (click)="SetFormCreateRole(); !drawer.opened ? drawer.toggle() : drawer.open" matTooltip="Create role." matTooltipPosition="above" matTooltipShowDelay="500">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                <mat-drawer #drawer class="drawer-sidenav" mode="side" position="end" [opened]="formState != ''">

                    <mat-card class="form-container">

                        <mat-card-title *ngIf="formState == 'update'">Role modification</mat-card-title>
                        <mat-card-title *ngIf="formState == 'create'">Role creation</mat-card-title>

                        <mat-divider class="title-divider"></mat-divider>

                        <div *ngIf="roleForm">
                            <form [formGroup]="roleForm">
                                <h2>Role</h2>
                                <div fxLayout="row wrap" fxLayoutAlign="start">
                                    <mat-progress-bar *ngIf="isLoading" mode="indeterminate" class="progress-bar"></mat-progress-bar>

                                    <mat-form-field fxFlex="40" appearance="fill" style="margin: 0 5px 5px 0; width: 250px">
                                        <mat-label>Name</mat-label>
                                        <input matInput type="text" formControlName="roleName" required>
                                        <mat-error *ngIf="roleForm.get('roleName').hasError('pattern')">Format: Admin</mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex="50" appearance="fill" style="margin: 0 5px 5px 0; width: 400px">
                                        <mat-label>Description</mat-label>
                                        <input matInput type="text" formControlName="roleDesc" required>
                                    </mat-form-field>

                                    <mat-form-field fxFlex="25" appearance="fill" style="width: 100px">
                                        <mat-label>Client</mat-label>
                                        <mat-select formControlName="roleClient" required>
                                            <mat-option *ngFor="let client of clientsList" [value]="client">
                                                {{client.business_tag}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="overlay" *ngIf="isLoadingPermissions">
                                    <div class="overlay__wrapper">
                                        <div class="overlay__spinner">
                                            <button mat-raised-button color="primary" class="spinner"></button>
                                        </div>
                                    </div>
                                </div>
                                <h2>Permissions</h2>
                                <mat-tab-group class="tab" mat-align-tabs="start" (selectedTabChange)="onTabClick($event)">
                                    <mat-tab label="Read"></mat-tab>
                                    <mat-tab label="Read/Update"></mat-tab>
                                    <mat-tab label="Read/Update/Create"></mat-tab>
                                    <mat-tab label="Read/Update/Create/Delete"></mat-tab>
                                </mat-tab-group>
                                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                                    <mat-form-field appearance="fill" fxFlex="30">
                                        <mat-label>Clients</mat-label>
                                        <mat-select #selectClient formControlName="clientMultiCtrl" [multiple]="true" >
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="clientMultiFilterCtrl" placeholderLabel="Find ..." noEntriesFoundLabel="'No match found'"></ngx-mat-select-search>
                                            </mat-option>
                                            <div class="select-all">
                                                <button mat-icon-button (click)="toggleAllSelection(selectClient, 'clients')">
                                                    <mat-icon>done_all</mat-icon>
                                                    <span> Select All / None </span>
                                                </button>
                                            </div>
                                            <mat-option *ngFor="let client of filteredClientsMulti | async" (click)="clientChanged(client)" [value]="client">
                                                {{client.business_tag}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-slide-toggle fxFlex="20" (change)="onChange($event, notificationsPermissions)" formControlName="notificationsToggle">Notifications</mat-slide-toggle>
                                    <mat-slide-toggle fxFlex="20" (change)="onChange($event, usersPermissions)" formControlName="usersToggle">Users</mat-slide-toggle>
                                    <mat-slide-toggle fxFlex="20" (change)="onChange($event, rolesPermissions)" formControlName="rolesToggle">Roles</mat-slide-toggle>
                                    <mat-form-field appearance="fill" fxFlex="30">
                                        <mat-label>Sites</mat-label>
                                        <mat-select #selectSite formControlName="siteMultiCtrl" [multiple]="true" >
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="siteMultiFilterCtrl" placeholderLabel="Find ..." noEntriesFoundLabel="'No match found'"></ngx-mat-select-search>
                                            </mat-option>
                                            <div class="select-all">
                                                <button mat-icon-button (click)="toggleAllSelection(selectSite, 'sites')">
                                                    <mat-icon>done_all</mat-icon>
                                                    <span> Select All / None </span>
                                                </button>
                                            </div>
                                            <mat-option *ngFor="let site of filteredSitesMulti | async" (click)="siteChanged(site)" [value]="site">
                                                {{site.site_tag}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-slide-toggle fxFlex="20" (change)="onChange($event, analysisPermissions)" formControlName="analysisToggle">Analysis</mat-slide-toggle>
                                    <mat-slide-toggle fxFlex="20" (change)="onChange($event, samplesPermissions)" formControlName="samplesToggle">Samples</mat-slide-toggle>
                                    <mat-slide-toggle fxFlex="20" (change)="onChange($event, variablesPermissions)" formControlName="variablesToggle">Variables</mat-slide-toggle>
                                    <mat-form-field appearance="fill" fxFlex="30">
                                        <mat-label>Machines</mat-label>
                                        <mat-select #selectMachine formControlName="machineMultiCtrl" [multiple]="true" >
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="machineMultiFilterCtrl" placeholderLabel="Find ..." noEntriesFoundLabel="'No match found'"></ngx-mat-select-search>
                                            </mat-option>
                                            <div class="select-all">
                                                <button mat-icon-button (click)="toggleAllSelection(selectMachine, 'machines')">
                                                    <mat-icon>done_all</mat-icon>
                                                    <span> Select All / None </span>
                                                </button>
                                            </div>
                                            <mat-option *ngFor="let machine of filteredMachinesMulti | async" (click)="machineChanged(machine)" [value]="machine">
                                                {{machine.machine_tag}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-slide-toggle fxFlex="43" (change)="onChange($event, permissionsPermissions)" formControlName="permissionToggle">Permissions</mat-slide-toggle>
                                    <mat-slide-toggle fxFlex="20" (change)="onChange($event, samplingPermissions)" formControlName="samplingToggle">Sampling Points</mat-slide-toggle>
                                </div>
                                <div fxLayoutAlign="space-between">
                                    <div>
                                        <button mat-raised-button style="margin-right: 10px" (click)="cancel(); drawer.close()" type="button">Cancel</button>
                                        <button *ngIf="formState == 'update'" mat-raised-button style="min-width: 40px; padding: 0; margin-right: 10px" (click)="restoreFormValue()" type="button" [disabled]="roleForm.pristine"
                                                matTooltip="Initial values"
                                                matTooltipPosition="above" matTooltipShowDelay="300">
                                            <mat-icon>restore</mat-icon>
                                        </button>
                                        <button mat-raised-button color="primary" (click)="allAccessSelection()" type="button">Provide all access</button>
                                    </div>
                                    <div *ngIf="formState == 'update'">
                                        <button mat-raised-button color="warn" *ngIf="oldRole.access_level==40" (click)="deleteRole()" type="button" style="margin-right: 10px">Delete</button>
                                        <button mat-raised-button color="primary" (click)="updateRole()" type="button" [disabled]="roleForm.pristine || roleForm.invalid">Apply modifications</button>
                                    </div>
                                    <button mat-raised-button color="primary" *ngIf="formState == 'create'" (click)="createRole()" [disabled]="roleForm.invalid">
                                        Create
                                    </button>
                                </div>
                            </form>
                        </div>
                    </mat-card>
                </mat-drawer>
            </mat-drawer-container>
        </div>
    </div>
</mat-card>
</body>
