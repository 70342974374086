import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'app-md-legend-dialog',
	templateUrl: './md-legend-dialog.component.html',
	styleUrls: ['./md-legend-dialog.component.css']
})
export class MdLegendDialogComponent implements OnInit {

	constructor(
		public dialogRef: MatDialogRef<MdLegendDialogComponent>
	) {
	}

	ngOnInit(): void {
	}

	closeDialog() {
		this.dialogRef.close();
	}
}
