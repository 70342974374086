export class MachineInput {

	constructor(
		public mi_id: number,
		public machine_id: number,
		public inlet_num: number,
		public sp_id: number
	) {
	}

}
