import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {ExportFormsService} from './export-forms.service';
import {ExportToCsv} from 'export-to-csv';
import {ExportFormHelpDialog} from './export-help-dialog/export-help-dialog.component';
import {ExportFormColumnInfoDialog} from './column-info-dialog/column-info-dialog.component';
import * as _ from 'lodash';
import {objectKeys} from 'codelyzer/util/objectKeys';
import {NavbarService} from "../header/navbar.service";


@Component({
	selector: 'app-export-forms',
	templateUrl: './export-forms.component.html',
	styleUrls: ['./export-forms.component.css']
})

export class ExportFormsComponent implements OnInit {

	parameters: any = {};
	textualQueryString = '';
	results: any = {};

	machines: any[] = [];
	selectedMachines: any[] = [];

	samplingPoints: any[] = [];
	selectedSamplingPoints: String[] = [];

	startDate: Date;
	endDate: Date;

	lotsConsommable = '';
	parsedLotsConsommable: number[] = [];

	initialColumns: any[] = [];
	unselectedColumns: string[] = [];
	selectedColumns: string[] = [];

	saveQueryToCsv = false;
	queryIsValid = true;
	fetchingAnalysisAndCalculations = false;
	fetchingColumns = true;
	displayedColumns: any[] = [];


	constructor(
		private exportFormsService: ExportFormsService,
		private snackbar: MatSnackBar,
		public dialog: MatDialog,
		public nav: NavbarService
	) {
	}

	ngOnInit(): void {
		this.exportFormsService.getMachines().subscribe(machines => this.machines = machines);
		this.exportFormsService.getSamplingPointsByMachineId(this.selectedMachines, this.machines).subscribe(samplingPoints => this.samplingPoints = samplingPoints);
		// (columns from view_analysis_and_calculations)
		this.exportFormsService.fetchColumns().subscribe(column => {
			if (this.nav._role === 'admin' || this.nav._role === 'super-admin'){
				this.displayedColumns = column;
			}else {
				this.displayedColumns = [column[0], column[1], column[2], column[3], column[5], column[6], column[7], column[9]];
			}
			this.fetchingColumns = false;
			this.initDualList();
		});
	}

	// Updates sampling points on machine selection change.
	machineSelectionChange(): void {
		this.exportFormsService.getSamplingPointsByMachineId(this.selectedMachines, this.machines).subscribe(samplingPoints => {
			this.samplingPoints = samplingPoints;
		});
	}

	// Requests data from DB and creates CSV with it.
	exportToCSV(): void {
		this.fetchingAnalysisAndCalculations = true;
		this.generateTextualQuery();

		// Data fetching
		this.exportFormsService.fetchAnalysisAndCalculations(this.selectedMachines,
			this.selectedSamplingPoints,
			this.startDate == null ? this.startDate : this.startDate.toISOString(),
			this.endDate == null ? this.endDate : this.endDate.toISOString(),
			this.lotsConsommable == null ? '' : this.lotsConsommable,
			this.selectedColumns).subscribe(results => {
				this.results = results;
			// Check if data is empty. If so, pops up a snackbar. If not, creates CVS with param.
			 if (this.results[0].map.array_to_json != null) {
				if (this.results[0].map.array_to_json.length !== 0) {
					const options = {
						filename: 'CsvExport ' + this.getNowTimeStamp(), // TimeStamp in string to create easily identifiable CSV.
						fieldSeparator: ',',
						quoteStrings: '',
						decimalSeparator: '.',
						showLabels: true,
						showTitle: this.saveQueryToCsv, // Puts query as CSV title if user want's it.
						title: this.textualQueryString, // The query.
						useTextFile: false,
						useBom: true,
						useKeysAsHeaders: true
					};
					const csvExporter = new ExportToCsv(options);
					csvExporter.generateCsv(JSON.stringify(this.results[0].map.array_to_json.list));
				}
			} else {
				this.snackbar.open('No data found with these parameters.', 'Ok', {
					duration: 5000
				});
			}
			 this.fetchingAnalysisAndCalculations = false;
		}, error => {
			if (error.status === 401) {
				this.snackbar.open('Error 401 (Unauthorized). Please log in.', 'Ok', {
					duration: 5000
				});
			} else {
				this.snackbar.open('An error has occurred. Code: ' + error.status, 'Ok', {
					duration: 5000
				});
			}
			this.fetchingAnalysisAndCalculations = false;
		});
	}

	// Updates columns lists on query paste in textual query text area
	fillDualListWithNewData(): void {
		this.unselectedColumns = _.filter(this.displayedColumns, item => !this.selectedColumns.includes(item));
	}

	initDualList(): void {
		this.selectedColumns = [];
		this.unselectedColumns = _.map(this.displayedColumns, item => item);

		// Pre-select some data (columns from view_analysis_and_calculations)
		if (this.nav._role === 'admin' || this.nav._role === 'super-admin'){
			this.selectedColumns.push(this.unselectedColumns.splice(0, 1)[0]);
			this.selectedColumns.push(this.unselectedColumns.splice(0, 1)[0]);
			this.selectedColumns.push(this.unselectedColumns.splice(6, 1)[0]);
			this.selectedColumns.push(this.unselectedColumns.splice(0, 1)[0]);
		}else {
			this.selectedColumns.push(this.unselectedColumns.splice(0, 1)[0]);
			this.selectedColumns.push(this.unselectedColumns.splice(0, 1)[0]);
			this.selectedColumns.push(this.unselectedColumns.splice(0, 1)[0]);
			this.selectedColumns.push(this.unselectedColumns.splice(0, 1)[0]);
		}

	}

	// Puts every columns  in the selected columns list
	selectAll(): void {
		_.map(this.unselectedColumns, item => this.selectedColumns.push(item));
		this.unselectedColumns = [];
	}

	// Generates query according to entered params to easily recreate a CSV.
	// Users can later paste it in the textual query text area and the params will fill themselves.
	generateTextualQuery(): void {
		this.parameters.Machines = this.selectedMachines;
		this.parameters.SamplingPoints = this.selectedSamplingPoints;
		this.parameters.StartDate = this.startDate == null ? this.startDate : this.startDate.toISOString();
		this.parameters.EndDate = this.endDate == null ? this.endDate : this.endDate.toISOString();
		this.parameters.LotsConsommable = this.lotsConsommable;
		this.parameters.Columns = this.selectedColumns;

		this.textualQueryString = JSON.stringify(this.parameters);
		this.queryIsValid = true;
	}

	// Detects textual query change, validates it and fills the params with its content.
	// Alerts users if the query is invalid.
	// Clears params if the query is empty (maybe annoying for the users? we'll see).
	textualQueryChange(): void {
		try {
			this.parameters = JSON.parse(this.textualQueryString);
			this.selectedMachines = this.parameters.Machines;
			this.selectedSamplingPoints = this.parameters.SamplingPoints;
			this.startDate = this.parameters.StartDate;
			this.endDate = this.parameters.EndDate;
			this.lotsConsommable = this.parameters.LotsConsommable;
			this.selectedColumns = this.parameters.Columns;
			this.fillDualListWithNewData();
			this.queryIsValid = true;
		} catch (e) {
			this.queryIsValid = this.textualQueryString === '';
			this.resetParams();
		}
	}

	resetParams(): void {
		this.selectedMachines = [];
		this.selectedSamplingPoints = [];
		this.startDate = null;
		this.endDate = null;
		this.lotsConsommable = '';
		this.parsedLotsConsommable = [];
		this.selectedColumns = [];
		this.initDualList();
	}

	// Returns string timestamp for CSV title
	getNowTimeStamp(): string {
		const now = new Date();
		const minutes = '000' + now.getMinutes();
		return now.toDateString() + '_' + now.getHours() + 'h' + minutes.substr(minutes.length - 2); // Last part adds a 0 to the minutes: 8h1 -> 8h01.
	}

	// Easy way to copy a query to the clipboard. Tried a paste button but angular considers it as a security risk.
	copyQueryToClipboard(textualQuery): void {
		if (this.textualQueryString !== '') {
			textualQuery.select();
			document.execCommand('copy');
			textualQuery.setSelectionRange(0, 0);
			this.snackbar.open('Request copied to clipboard.', 'Ok', {
				duration: 2000
			});
		} else {
			this.snackbar.open('Empty request!', 'Ok', {
				duration: 2000
			});
		}

	}

	// Opens help dialog that explains how the tool works.
	openHelp(): void {
		this.dialog.open(ExportFormHelpDialog, {
			width: '60%',
			height: '80%',
			data: {
				caller: 'ExportForm'
			}
		});
	}

	// Opens Columns info dialog that explains each columns.
	openColumnInfo(): void {
		this.dialog.open(ExportFormColumnInfoDialog, {
			height: '80%',
			data: {
				caller: 'ExportForm'
			}
		});
	}
}
