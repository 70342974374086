<body>

<mat-card>
	<mat-card-title>
		Please sign in
	</mat-card-title>
	<mat-card-content>
		<mat-card>
			<mat-card-content>
				<div class="overlay" *ngIf="loadingLogin">
					<div class="overlay__wrapper">
						<div class="overlay__spinner">
							<mat-spinner></mat-spinner>
						</div>
					</div>
				</div>

				<mat-form-field class="input-headline" appearance="fill" style="width: 300px">
					<mat-label>Email</mat-label>
					<input class="input" type="email" matInput [(ngModel)]="email" (keyup.enter)="signInWithMicroService()">
				</mat-form-field>
			</mat-card-content>
			<mat-card-content>
				<mat-form-field class="input-headline" appearance="fill" style="width: 300px">
					<mat-label>Password</mat-label>
					<input class="input" type="password" matInput [(ngModel)]="password" (keyup.enter)="signInWithMicroService()">
				</mat-form-field>
			</mat-card-content>
			<mat-card-content>
				<button class="loginButton" mat-raised-button color=primary style="margin-bottom: 10px" *ngIf="!user" (click)="signInWithMicroService()">
					Sign in
				</button>
				<button mat-raised-button color="warn" style="margin-left: 10px" (click)="closeDialog()">Cancel</button>
			</mat-card-content>
		</mat-card>
	</mat-card-content>
</mat-card>

</body>
