import {CompleteMachine} from 'src/app/Models/CompleteMachine';

enum ModType {
	create,
	update_inlet_num,
	update_sp_name,
	update_machine_state,
	delete,
	update_maintenance_date,
	update_nb_of_analysis_before_maintenance
}

export class Modification {

	private mod_description: String;
	private mod_type: ModType;
	private new_cm: CompleteMachine;
	private old_cm: CompleteMachine;

	constructor() {
	}

	set_mod_description(txt: string) {
		this.mod_description = txt;
	}

	get_mod_description(txt: string) {
		return this.mod_description;
	}

	set_new_cm(cm: CompleteMachine) {
		this.new_cm = cm;
	}

	get_new_cm() {
		return this.new_cm;
	}

	set_old_cm(cm: CompleteMachine) {
		this.old_cm = cm;
	}

	get_old_cm() {
		return this.old_cm;
	}

	set_mod_type(mt: number) {
		this.mod_type = mt;
	}

	get_mod_type() {
		return this.mod_type;
	}


}
