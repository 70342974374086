<mat-card>

	<mat-card-header>
		<mat-card-title>
			Machine creation
		</mat-card-title>
	</mat-card-header>

	<mat-card-content *ngIf="mCreatorForm">
		<form [formGroup]="mCreatorForm">
			<mat-card>
				<div fxLayout="column" fxLayoutAlign="center start">
					<mat-progress-bar *ngIf="isLoading" mode="indeterminate" class="progress-bar"></mat-progress-bar>

					<h2>Machine</h2>

					<mat-form-field appearance="fill" style="margin-bottom: 5px">
						<mat-label>ID machine</mat-label>
						<input matInput [placeholder]="suggestedMachineId" formControlName="machineId" required>
						<mat-error *ngIf="mCreatorForm.get('machineId').hasError('pattern')">Format: 000</mat-error>
					</mat-form-field>

					<mat-form-field appearance="fill" style="margin-bottom: 5px">
						<mat-label>Machine Tag</mat-label>
						<input matInput [placeholder]="suggestedMachineTag" formControlName="machineTag" required>
						<mat-error *ngIf="mCreatorForm.get('machineTag').hasError('pattern')">Format: B0000</mat-error>
					</mat-form-field>

					<mat-form-field appearance="fill">
						<mat-label>State</mat-label>
						<mat-select formControlName="state">
							<mat-option *ngFor="let state of possibleStates" [value]="state">
								{{state}}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field appearance="fill">
						<mat-label>Site</mat-label>
						<input matInput formControlName="machineSite" required>
						<mat-error *ngIf="mCreatorForm.get('machineSite').hasError('pattern')">Format: 000</mat-error>
					</mat-form-field>

					<form [formGroup]="inletForm">
						<h2>Inlet</h2>
						<div fxLayout="column" fxLayoutAlign="space-arround center" formArrayName="inletRow">
							<div *ngFor="let row of inletForm.get('inletRow')['controls']; let y=index" fxLayout="row" fxLayoutGap="2.5%" fxLayoutAlign="space-between center" style="margin-bottom: 5px">
								<mat-form-field appearance="fill" [formGroupName]="y">
									<mat-label>Inlet {{y + 1}}</mat-label>
									<input matInput formControlName="inlet" required>
									<mat-error *ngIf="row.get('inlet').hasError('pattern')">Format: 00</mat-error>
								</mat-form-field>

								<mat-form-field appearance="fill" [formGroupName]="y">
									<mat-label>Sampling point</mat-label>
									<mat-select (selectionChange)="samplingPointSelectedChanged($event, y)" formControlName="sp" required>
										<mat-option>
											<ngx-mat-select-search [formControl]="samplingPointFilterCtrl" placeholderLabel="Search" noEntriesFoundLabel="No result"></ngx-mat-select-search>
										</mat-option>
										<mat-option *ngFor="let samplingPoint of filteredSamplingPoint | async" [value]="samplingPoint">
											{{samplingPoint.sp_tag}}
										</mat-option>
									</mat-select>
								</mat-form-field>

								<button mat-mini-fab color="warn" *ngIf="inletForm.get('inletRow')['controls'].length > 1" (click)="removeInletRow(y)">
									<mat-icon>delete forever</mat-icon>
								</button>
							</div>
						</div>

						<button mat-raised-button color="primary" (click)="addInletRow()" *ngIf="inletForm.get('inletRow')['controls'].length < 6">
							<mat-icon>add box</mat-icon>
							Inlet
						</button>
					</form>
				</div>
			</mat-card>
		</form>
	</mat-card-content>

	<mat-card-actions fxLayout="flex" fxLayoutAlign="end center">

		<button mat-raised-button color="primary" (click)="submitAndClose()" [disabled]="mCreatorForm.invalid || inletForm.invalid">
			Create
		</button>

		<button mat-raised-button color="basic" (click)="cancelAndClose()">
			<mat-icon>
				close
			</mat-icon>
		</button>

	</mat-card-actions>
</mat-card>
